export const addResena = (resena, productoID, vendedorID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Resenas').add({
            titulo: resena.titulo,
            descripcion: resena.descripcion,
            calificacion: Number(resena.calificacion),
            esVistoPor: resena.esVistoPor,
            productoID: productoID,
            vendedorID: vendedorID,
            imagen: resena.downloadURLs,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'ADD_RESENA', resena}); 
        }).catch((err) => {
            dispatch({ type: 'ADD_RESENA_ERROR', err});
        }) 
    }
};