const initState = {
    retiros: [
        {id: '1', aUsuariosIDs:[], tCreatedAt:200}
    ]
};

const retiroReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SEND_MESSAGE':
            console.log('Sent Message');
            return state;
        case 'SEND_MESSAGE_ERROR':
            console.log('Sent Message Error', action.err);
            return state;
        case 'ADDED_CHAT':
            console.log('Added Chat');
            return state;
        case 'ADDED_CHAT_ERROR':
            console.log('Added Chat Error', action.err);
            return state;
        case 'UPDATED_LAST_SENT_MESSAGE':
            console.log('Updated Las Sent Message');
            return state;
        case 'UPDATED_LAST_SENT_MESSAGE_ERROR':
            console.log('Updated Las Sent Message Error', action.err);
            return state;
        case 'UPDATED_LAST_OPENED':
            console.log('Updated Last Opened');
            return state;
        case 'UPDATED_LAST_OPENED_ERROR':
            console.log('Updated Last Opened Error', action.err);
            return state;
        case 'ADDED_CHAT_WARNING':
            console.log('Added Chat Warning');
            return state;
        case 'ADDED_CHAT_WARNING_ERROR':
            console.log('Added Chat Warning', action.err);
            return state;
        default:
            return state;
    }
}


export default retiroReducer;