import React, { useEffect, useState } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import '../../App.css';
import {signOut} from '../../store/actions/authActions';
import {connect} from 'react-redux';
import Searchbar from './Searchbar';

const SignedInLinksNegocio = (props) => {
    const {auth, profile} = props;
    const [showBanner, setShowBanner] = useState(false);
    const location = useLocation();
    let history = useHistory ();

    const handleClick = () => {
        //Para refrescar la historia y que aparesca home y no la última vista categoría al momento de picarle al kanguru
        history.push('/', { from: '' } );;
    } 
    
    //If location changes, checks whether banner should be shown
    useEffect(() => {
        if(window.location.href.includes('dashboard-negocio') || window.location.href.includes('capacitacion-negocios')){
            setShowBanner(false);
        }
        else{
            setShowBanner(true);
        }
    }, [location]); 

    let banner;
    if(showBanner){
        banner = (
            <div className="banner">
                <p className="banner-text">Esto es lo que ven los afiliados, puedes explorar qué productos y precios ofrecen otros proveedores.</p>
            </div>
        );     
    }
    else{
        banner = (
            null
        );     
    }

    let logo;
    if(auth.isLoaded){
        logo = (
            <img src="/img/Kanguru_logo.png" alt="" className="logo"/>
        );
    }

    if(logo){
        return(
            <div>
                {/* Banner */}
                {banner}
                <nav class="navbar navbar-expand-md navbar-light fixed">
                    <div class="container-fluid custom-container">
                       {/* Toggle Botton for mobile nav*/}
                       <button class="navbar-toggler mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        
                        {/* Logo and Title of Page */}
                        <Link to="/" className="me-md-5" onClick={handleClick}>
                            {logo}
                        </Link>
                        
                        {/* Search Bar*/}
                        <Searchbar className="searchBarNav" profile={profile}/>
    
                        {/* Main Nav */}
                        <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
                            <ul className="navbar-nav nav-black-text">
                                <li className="nav-item">
                                    <Link to={'/capacitacion-negocios'} className="nav-link nav-black-text"><i class="bi bi-info-circle me-2"></i>Ayuda</Link>
                                </li>
                                <li className="nav-item dashboardLink">
                                    <Link to={'/chats'} className="nav-link nav-black-text"><i class="bi bi-chat-square-text me-2"></i>Chats</Link>
                                </li>
                                <li className="nav-item dashboardLink">
                                    <Link to={'/dashboard-negocio/' + auth.uid} className="nav-link nav-black-text"><i class="bi bi-bar-chart-fill me-2"></i>Dashboard</Link>
                                </li>
                                <li className="nav-item md-ms-3">
                                    <Link to="/" onClick={props.signOut} className="nav-link">Cerrar Sesión<i  className="bi bi-arrow-bar-right ms-2"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div> 
        );
    }else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        ); 
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinksNegocio);