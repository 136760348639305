import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import algoliasearch from 'algoliasearch';
import { editNombreCatalogo } from '../../../store/actions/catalogoActions';
import ProductosActualizadosList from './ProductosActualizadosList';
import './ProductosActualizados.css';
import '../../../App.css';
import toast, { Toaster } from 'react-hot-toast';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";

const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';

const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);

//Muesta los productos con actualizaciones en precio, isDisplayed, o agotado en los últimos 7 días
const ProductosActualizados = (props) => {
   
    const [originalProducts, setOriginalProducts] = useState("");
    const [products, setProducts] = useState("");
    const [query, setQuery] = useState("");
    const [pageAlgolia, setPageAlgolia] = useState(0);

    const fechaPresente = new Date();
    let fechaPresenteMenosUnaSemana = fechaPresente.setDate(fechaPresente.getDate() - 7);
    const filter = 'oUltimaActualizacion.tUpdatedAt >= ' + fechaPresenteMenosUnaSemana;

    const notify = (type) => {
        if(type == "noProducts"){
            toast.error("¡Uy! Ya no hay más productos.");
        }
    }

    const fetchData = async () => {
        const products = await indexProductos.search("", {filters: filter, page: 0, hitsPerPage: 48});
        setOriginalProducts(products.hits);
        setProducts(products.hits);
    }

    const filterData = async (e) => {
        e.preventDefault();
        const filteredProducts = await indexProductos.search(query, {filters: filter, page: 0, hitsPerPage: 48});
        setProducts(filteredProducts.hits);
    }

    const loadMore = async (e) => {
        e.preventDefault();
        
        const pageAlgoliaNew = pageAlgolia + 1;
        
        const moreProducts = await indexProductos.search(query, {filters: filter, page: pageAlgoliaNew, hitsPerPage: 48});
        const productsNew = products.concat(moreProducts.hits);

        if(moreProducts.hits.length === 0){
            notify("noProducts");
        }
        else{
            setPageAlgolia(pageAlgoliaNew);
            setProducts(productsNew);
        }
    }

    useEffect(() => {

        fetchData();

    }, []); 

    let verMasBtn;
    if(products.length >= 48){
        verMasBtn = (
            <div className="ver-mas-parent-home">
                <p onClick={(e) => loadMore(e)} className="btn btn-ver-mas">Ver Más</p>
            </div>
        )
    }
    else{
        verMasBtn = (
            null
        )
    }

    
    return(
        <div className="">
            {/* Dynamic Description*/}
            <Helmet>
                <title>Productos Actualizados</title>
                <meta name="title" content="ProductosActualizados" />
            </Helmet>
            
            {/* Notifications Toast*/}
            <Toaster 
                toastOptions={{
                    duration: 5000,
                    position: 'top-center',
                }}
            />

            <div>
                <div className="landingClabeImgVendedor">
                    <div className="textCapacitacion">
                        <h4 className="textNosotros2">Productos Actualizados</h4>
                    </div>
                    <img src="img/capacitacion-negocios.png" className="landingClabeImgVendedor2" alt=""/>
                </div>
            </div>
            <div className="landingPage2-Capacitacion">
                <div className="container-fluid custom-container">
    
                    {/* Info */}
                    <div className="mt-3 col-sm-10">
                        <h5 className="mt-5 mt-md-0">Se muestran los productos con cambios en precio, disponibibilidad, o que se han agotado en los últimos 7 días.</h5>
                        <h5 className="mt-5 mt-md-0">Puedes utilizar esta página para revisar si un producto que vendes ha cambiado.</h5>
                    </div>

                    {/* SearchBar */}
                    <form id="searchForm" onSubmit={filterData} autoComplete="off" className="d-flex search-bar-PA mt-5">
                        <input onInput={e => setQuery(e.target.value)} className="form-control me-2 input-search-bar-PA" name="s" placeholder="Buscar Producto por Nombre" aria-label="Search"/>
                        <button type="submit" className="search-bar-button-PA"><i class="bi bi-search"></i></button>           
                    </form>     
                    
                    {/* Lista de Productos */}
                    <div className="mt-5">
                        <ProductosActualizadosList products={products}/>
                    </div>

                    {/* Ver Más Button */}
                    {verMasBtn}
                </div>
            </div>
            
        </div>
   );
    
}

export default ProductosActualizados;