import React, { Component } from 'react';
import {forgotPass} from '../../store/actions/authActions';
import {connect} from 'react-redux';
import firebase from 'firebase';
import { Redirect } from 'react-router-dom';

class ForgotPassword extends Component {
  state = {
    email: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
      e.preventDefault();
      this.props.forgotPass(this.state.email);
  }
  render() {
    return (
        <div>
          <div className="container position-absolute start-50 translate-middle recuperarContraseña row">
              {/* Titulo */}
              <h3 className = "text-black signUpTitle">Recuperar Contraseña</h3>
            
              <form className="mt-4 col-11">
                  {/* Email */}
                  <div className="mb-3">
                      <label for="email" class="form-label">Correo Electrónico</label>
                      <input onChange={this.handleChange} type="email" class="form-control" id="email"/>
                  </div>

                  {/* Submit Button */}
                  <div className="">
                      <button onClick={this.handleSubmit} type="button" class="btn btn-primary buttonSignUp">Enviar</button>
                  </div>
              </form>
          </div>
        </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      forgotPass: (email) => dispatch(forgotPass(email))
  }
}

export default connect(null, mapDispatchToProps)(ForgotPassword);