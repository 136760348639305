import React, { Component} from 'react';
import ProductList from '../products/ProductList';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import { Redirect, withRouter} from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import {Helmet} from "react-helmet";
import gtag, { install } from 'ga-gtag';
import toast, { Toaster } from 'react-hot-toast';
import StoreList from '../stores/StoreList';
import './Categorias.css';

install('G-GQ8M7NNRJ7');

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";

//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";

const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';

const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);

class BuscarPorTienda extends Component {

    state={
       stores: [],
       pageAlgolia: 0
    }

    notify = (type) => {
        if(type == "noMoreStores"){
            toast.error("¡Uy! Ya no hay más tiendas.");
        }
    }

    showVerMasBtn = (action) => {
        const verMasBtn = document.getElementById("verMasBtn");
        
        if(verMasBtn){
            if(this.state.products.length < 48 || action === "hide"){
                verMasBtn.style.display = 'none';
                
            }
            else{
                verMasBtn.style.display = 'inline';
            }
        }
    }

    loadMoreStores = async (e) => {
        e.preventDefault();
        
        const pageAlgolia = this.state.pageAlgolia + 1;
        const stores = await indexUsuarios.search("", {filters: 'isBusiness: true', page: pageAlgolia, hitsPerPage: 48});
        const storesNew = this.state.stores.concat(stores.hits);
        
        if(stores.hits.length === 0){
            this.notify("noMoreStores");
            this.handleBtnFiltro("hide");
        }
        else{
            this.setState({
                pageAlgolia: pageAlgolia,
                stores: storesNew
            })
            this.handleBtnFiltro("show");
        }
    }

    
    async componentDidMount(){
        const stores = await indexUsuarios.search("", {filters: 'isBusiness: true', page: 0, hitsPerPage: 48});

        this.setState({
            stores: stores.hits,
        }, async () => {
            this.handleBtnFiltro();
        });
    }

    render(){
        const stores = this.state.stores;

        return(
            <div className="container-fluid categorias">
                {/* Dynamic Title*/}
                <Helmet>
                    <title>Buscar Por Tienda</title>
                    <meta name="title" content="Buscar por Tienda" />
                </Helmet>

                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                <h3 className="mt-5">Buscar por Tienda</h3>
                
                {/* Lista de Tiendas */}
                <div className="store-list">
                    <StoreList stores={stores} mobile={true}/> 
                </div>
                
                
                {/* Botón para ver más */}
                <div className="ver-mas-parent-tiendas">
                    <p onClick={this.loadMoreStores} id="verMasBtn" className="btn btn-ver-mas">Ver Más</p>
                </div>
            </div>
        )  
    }
}

export default BuscarPorTienda;