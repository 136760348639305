import React from 'react'
import '../../App.css';
import {Link} from 'react-router-dom';

const Footer = () => {
    let footer;
    
    if(window.location.href.indexOf("catalogoenlinea.mx") > -1 && window.location.href.indexOf("checkout") > -1){
        footer = (
            null
        );
    }
    else if(window.location.href.indexOf("catalogoenlinea.mx") > -1){
        footer = (
            <div className="footer">
                <div className="container-fluid custom-container footers">
                    <div className="d-flex">
                        <Link to="/envios-y-reembolsos" id="envios" className="footerLink ms-3">Envíos y Devoluciones</Link>
                        <Link to="/terminos-y-condiciones" id="terminosCondiciones" className="footerLink ms-3">Términos y Condiciones</Link>
                        {/* <img src="/img/Catalogo_en_linea_logo.png" alt="" className="logo_catalogo_footer"/> */}
                    </div> 
                </div>
            </div> 
        );
    }
    else if(window.location.href.indexOf("kangupago.com") > -1){
        footer = (
            <div className="footer_kangupago">
                <div className="container-fluid custom-container footers">
                    <div className="d-flex">
                        <Link to="/envios-y-reembolsos" id="envios" className="footerLink ms-3">Envíos y Devoluciones</Link>
                        <Link to="/terminos-y-condiciones" id="terminosCondiciones" className="footerLink ms-3">Términos y Condiciones</Link>
                    </div> 
                    {/* <img src="/img/Kangupago_Logo.png" alt="" className="logo_kangupago_footer"/> */}
                </div>
            </div> 
        );
    }
    else{
        footer = (
            <div className="footer">
                <div className="container-fluid custom-container footers">
                    <div className="d-flex">
                        <Link to="/nosotros" id="nosotros" className="footerLink">Nosotros</Link>
                        <Link to="/contacto" id="contacto" className="footerLink ms-3">Contacto</Link>
                        <Link to="/cambios-y-devoluciones" id="envios" className="footerLink ms-3">Envíos y Devoluciones</Link>
                        
                        <Link to="/preguntas-frecuentes" id="preguntasFrecuentes"  className="footerLink">Preguntas Frecuentes</Link>
                    </div>
                    <div className="d-flex mt-md-5">
                        <Link to="/terminos-y-condiciones" id="terminosCondiciones" className="footerLink">Términos y Condiciones</Link>
                        <Link to="/aviso-de-privacidad" id="avisoPrivacidad" className="footerLink ms-3">Políticas de Privacidad</Link>
                    </div>
                    <div className="d-flex mt-md-5">
                        <a target="_blank" href="https://www.instagram.com/kanguru.mx/" className="footerLink"><i className="bi bi-instagram"></i></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCqYbGgZB1vaD0f950RJZUmA" className="footerLink"><i className="bi bi-youtube ms-3"></i></a>
                        <a target="_blank" href="https://www.facebook.com/Kanguru.mx/" className="footerLink"><i className="bi bi-facebook ms-3"></i></a>
                    </div> 
                </div>
                
            </div> 
        );
    }

    return (
        <div>
            {footer}
        </div>
    );
}
    
    

export default Footer;