import React, { useState, useEffect, useRef } from 'react';
import MensajeSummary from './MensajeSummary';

const MensajesList = (props) => {

    const {mensajes, auth} = props;
    const bottomOfChat = useRef();

    useEffect(() => {
        if(mensajes.length != 0){
            setTimeout(
                bottomOfChat.current.scrollIntoView(false), 
            100);
        }
       
    }, [mensajes])

    if(mensajes.length != 0){
        return(
            <div>
                <div className="position-relative">
                    {mensajes && mensajes.map(mensaje => {
                        return (
                            <div key={mensaje.objectID}>
                                <MensajeSummary mensaje={mensaje} auth={auth}/>
                            </div>  
                        )
                    })}
                </div> 
                <div ref={bottomOfChat}></div>
            </div>
        )
    }
    else{
        return(
            <div>
            </div>
        ); 
        
    }
}

export default MensajesList;