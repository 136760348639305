import firebase from 'firebase/app'
import arrayUnion from 'firebase/firestore'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    //Production
    apiKey: "AIzaSyD0VI-jnHK0FfSNumY4QiP3_Xlkg8UUN5Y",
    authDomain: "kilig-cac58.firebaseapp.com",
    projectId: "kilig-cac58",
    storageBucket: "kilig-cac58.appspot.com",
    messagingSenderId: "762495107139",
    appId: "1:762495107139:web:3ac52d574f26067ba314f6",
    measurementId: "G-GQ8M7NNRJ7"

    //Development
    // apiKey: "AIzaSyCf4Kpi_I-btzNaNKwz8RXnk8SVKWo26U0",
    // authDomain: "kanguru-dev.firebaseapp.com",
    // projectId: "kanguru-dev",
    // storageBucket: "kanguru-dev.appspot.com",
    // messagingSenderId: "664673151069",
    // appId: "1:664673151069:web:72813e5bb46f16360805b4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots:true });

export default firebase;