import React, { Component } from 'react';
import {addCodigoRastreo} from '../../store/actions/ventaActions';
import {connect} from 'react-redux';
import axios from 'axios';
import './ventas.css';
import algoliasearch from 'algoliasearch';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);

class VentaSummaryNegocio extends Component{
    
    state = {
        codigoRastreo:this.props.venta.codigoRastreo,
        paqueteria:this.props.venta.paqueteria,
        venta_id: this.props.venta.objectID,
        // checkedStatus: false,
        isDraft: false,
        product: ""
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleChangeCodigoRastreo = (e) => {
        this.setState({
            venta_id: this.props.venta.objectID
        }, () => {
            if(this.state.codigoRastreo != '' && this.state.paquteria != ''){
                this.props.addCodigoRastreo(this.state);
            }
            else{
                alert('Información Incompleta')
            }
            
        });
    }

    downloadPDF = (pdf, trackingId) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = trackingId + ".pdf";
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    downloadGuide = (e) => {
        const {venta} = this.props;
        const account = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/getGuidePDF99Minutos", {
            params: {
                codigoRastreo: venta.codigoRastreo
            }
        })
        .then((response) => {
            //get base64
            const pdfBase64 = response.data.pdfBase64;
            //call donwloadPDF function
            return this.downloadPDF(pdfBase64, venta.codigoRastreo);;
        })
        .catch(function (error) {
            console.log(error);
        })
        
    }

    confirmOrder = (e) => {
        const {venta} = this.props;
        
        const confirmOrder = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/postConfirmOrder99Minutos", {
            params: {
                ordenDeEnvioID: venta.ordenDeEnvioID,
                codigoRastreo: venta.codigoRastreo,
                ventaID: venta.objectID
            }
        })
        .then((response) => {
            //Order is Confirmed
            this.setState({
                isDraft: false
            })
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    // checkTrackingStatus = (venta) => {
    //     //Gets Tracking Status
    //     const account = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/getTrackingStatus99Minutos", {
    //         params: {
    //             codigoRastreo: venta.codigoRastreo,
    //             ventaID: venta.objectID
    //         }
    //     })
    //     .then((response) => {
    //         this.setState({
    //             checkedStatus: true
    //         });

    //         const trackingStatus = response.data.tracking_status;
    //         console.log(trackingStatus);
    //         console.log(venta.codigoRastreo);
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     })
    // }

    async componentDidMount(){
        const {venta} = this.props;
        // if(venta.codigoRastreo != "" && (venta.paqueteria === "99 Minutos" || venta.paqueteria === "99minutos") && !venta.entregado && !this.state.checkedStatus && venta.estatusDeOrden !== "CANCELLED"){
        //     this.checkTrackingStatus(venta);
        // } 

        if(venta.estatusDeOrden === "DRAFT"){
            this.setState({
                isDraft: true
            })
        }

        const product = await indexProductos.getObject(venta.product_id);
        this.setState({
            product: product
        })
   }

    render(){
        const {venta} = this.props;
        const product = this.state.product;
        let detallesAdminNegocio;
        let ingresoNetoNegocio;
        let descargarGuia;
        let dateCompare = venta.createdAt < new Date('01/26/2022');
        let nuevoEsquemaDeComisiones = venta.createdAt >= new Date('07/15/2022');
        let isDraft = this.state.isDraft;
        
        let variacion = null;
        if(venta.variacion != '' && venta.variacion){
            variacion = (
                <h6 className="">Variación: {venta.variacion}</h6>
            );
        }
        else{
            variacion = (
                null
            );
        }

        let personalizado = null;
        if(venta.personalizado != ''){
            personalizado = (
                <h6 className="">Personalizado: {venta.personalizado}</h6>
            );
        }
        else{
            personalizado = (
                null
            );
        }

        let estatusEnviado;
        if(venta.paqueteria !== "99 Minutos" && venta.paqueteria !== "99minutos" && venta.paqueteria !== ""){
            estatusEnviado = (
                <h5 className="btn btn-revisar me-5">Revisar Manualmente</h5>
            );
        }
        else if(isDraft){
            estatusEnviado = (
                <h5 data-bs-toggle="modal" data-bs-target={'#'+'confirmarOrden'+venta.objectID} className="btn btn-confirmar me-5">Confirmar Orden</h5>
           );
        }
        else if(venta.estatusDeOrden === "CANCELLED"){
            estatusEnviado = (
                <h5 className="btn btn-danger me-5">Cancelada</h5>
           );
        }
        else if(venta.estatusDeOrden !== "UNSUCCESSFUL_PICKUP" && venta.estatusDeOrden !== "UNSUCCESSFULPICKUP" && venta.estatusDeOrden !== "CONFIRMED" && venta.estatusDeOrden !== "ON_ROAD_TO_PICKUP" && venta.estatusDeOrden !== "ONROADTOPICKUP" && venta.estatusDeOrden !== "" && venta.estatusDeOrden !== "DRAFT"){
            estatusEnviado = (
                <h5 className="btn btn-enviado me-5">Enviado</h5>
            );
        }
        else{
            estatusEnviado = (
                 <h5 className="btn btn-danger me-5">No Enviado</h5>
            );
        }
        
        //DIVISION DE NUEVO ESQUEMA DE COMISIONES Y VIEJO ESQUEMA
        if(nuevoEsquemaDeComisiones){
            let ingresoTotalNegocio = Number(venta.ingresoTotalNegocio);
            let ingresoTotalSinComision = Math.round(Number(venta.ingresoTotal) * 0.96);
            let comisionDelVendedor = Math.round(ingresoTotalNegocio * 0.01 * venta.comision);
            let comisionDeKanguru = Math.round(ingresoTotalNegocio * 0.07);
            let ingresoNeto = ingresoTotalNegocio - comisionDeKanguru;

            detallesAdminNegocio = (
                <div className="mb-3">
                    <h4 className="cuentaVendedorLabelForm">Detalles Administrativos</h4>
                    <h6 className="">Ingreso Total: ${ingresoTotalNegocio}</h6>
                    <h6 className="">Comisión de Kanguru: ${comisionDeKanguru}</h6>
                    <h6 className="">Ingreso Neto: ${ingresoNeto}</h6>
                </div>
                
            );
            
            ingresoNetoNegocio = (
                <h5 className="comisionVenta">${ingresoNeto}</h5>
            );

            if(venta.paqueteria != "" && venta.codigoRastreo != "" && !isDraft){
                descargarGuia = (
                    <h6 className="linkToPage" onClick={this.downloadGuide}>Descargar Guía</h6>
                );
            }
            else{
                descargarGuia = (
                    null
                );
            }
        }
        else{
            if(venta.tipoDePago === "enLinea"){
                let ingresoTotalSinComision = Math.round(Number(venta.ingresoTotal) / 1.04);
                let ingresoDelProducto = Number(venta.ingresoTotalNegocio) - Number(venta.ingresoEnvio);
                let comisionDelVendedor = Math.round(ingresoDelProducto * 0.01 * venta.comision);
                let comisionDeKanguru = Math.round(ingresoDelProducto * 0.07);
                
                if(dateCompare){
                    comisionDelVendedor = Math.round(Number(venta.ingresoTotalNegocio) * 0.01 * venta.comision);
                    comisionDeKanguru = Math.round(Number(venta.ingresoTotalNegocio) * 0.07);
                }
                
                let ingresoNeto = venta.ingresoTotalNegocio - comisionDelVendedor - comisionDeKanguru;
                //Creo que falta considerar la cantidad de unidades para restar la cantidad correct de ingresoEnvio. Ya que el ingreso total del negocio es envio + precio del producto por cantidad.
                detallesAdminNegocio = (
                    <div className="mb-3">
                        <h4 className="cuentaVendedorLabelForm">Detalles Administrativos</h4>
                        <h6 className="">Ingreso del Producto: ${venta.ingresoTotalNegocio - Number(venta.ingresoEnvio)}</h6> 
                        <h6 className="">Ingreso del Envio: ${venta.ingresoEnvio}</h6>
                        <h6 className="">Comisión del Vendedor: ${comisionDelVendedor}</h6>
                        <h6 className="">Comisión de Kanguru: ${comisionDeKanguru}</h6>
                        <h6 className="">Ingreso Neto: ${ingresoNeto}</h6>
                    </div>
                );

                ingresoNetoNegocio = (
                    <h5 className="comisionVenta">${ingresoNeto}</h5>
                );
    
                descargarGuia = (
                    null
                );
                
            }
            else{
                let ingresoTotalSinComision = Math.round(Number(venta.ingresoTotal) / 1.04);
                let precioOriginal = Number(venta.ingresoTotalNegocio) + Number(venta.ingresoEnvio);
                let comisionDelVendedor = Math.round(Number(venta.ingresoTotalNegocio)* 0.01 * venta.comision);
                let comisionDeKanguru = Math.round(Number(venta.ingresoTotalNegocio) * 0.07);
                
                if(dateCompare){
                    comisionDelVendedor = Math.round(precioOriginal * 0.01 * venta.comision);
                    comisionDeKanguru = Math.round(precioOriginal * 0.07);
                }

                let ingresoNeto = venta.ingresoTotalNegocio - comisionDelVendedor - comisionDeKanguru;
                
                detallesAdminNegocio = (
                    <div className="mb-3">
                        <h4 className="cuentaVendedorLabelForm">Detalles Administrativos</h4>
                        <h6 className="">Ingreso del Producto: ${venta.ingresoTotalNegocio}</h6>
                        <h6 className="">Comisión del Vendedor: ${comisionDelVendedor}</h6>
                        <h6 className="">Comisión de Kanguru: ${comisionDeKanguru}</h6>
                        <h6 className="">Ingreso Neto: ${ingresoNeto}</h6>
                    </div>
                    
                );
                
                ingresoNetoNegocio = (
                    <h5 className="comisionVenta">${ingresoNeto}</h5>
                );

                if(venta.paqueteria != "" && venta.codigoRastreo != ""){
                    descargarGuia = (
                        <h6 className="linkToPage" onClick={this.downloadGuide}>Descargar Guía</h6>
                    );
                }
                else{
                    descargarGuia = (
                        null
                    );
                }
            }
        }

        let direccionDelComprador;
        if(venta.enviarA === "punto99"){
            direccionDelComprador = (
                <div className="mb-3">
                    <h4 className="cuentaVendedorLabelForm">Dirección del Comprador</h4>
                    <h6 className="">El cliente recojerá su paquete en el punto 99 que aparece en la guía.</h6>
                </div>
            );
            
        }
        else if(venta.shippingInfo.address.address){
            direccionDelComprador = (
                <div className="mb-3">
                    <h4 className="cuentaVendedorLabelForm">Dirección del Comprador</h4>
                    <h6 className="">Nombre: {venta.shippingInfo.name}</h6>
                    <h6 className="">Dirección: {venta.shippingInfo.address.address}</h6>
                    <h6 className="">Longitud: {venta.shippingInfo.address.location.lat}</h6>
                    <h6 className="">Longitud: {venta.shippingInfo.address.location.lng}</h6>
                </div>
            );
        }
        else{
            direccionDelComprador = (
                <div className="mb-3">
                    <h4 className="cuentaVendedorLabelForm">Dirección del Comprador</h4>
                    <h6 className="">Nombre: {venta.shippingInfo.name}</h6>
                    <h6 className="">Calle: {venta.shippingInfo.address.line1}</h6>
                    <h6 className="">Ciudad: {venta.shippingInfo.address.city}</h6>
                    <h6 className="">Código Postal: {venta.shippingInfo.address.postal_code}</h6>
                    <h6 className="">Estado: {venta.shippingInfo.address.state}</h6>
                    <h6 className="">País: Mexico</h6>             
                </div>
            );
            
        }

        let ordenDeEnvioEsDraft;
        if(this.state.isDraft){
            ordenDeEnvioEsDraft = (
                <div>
                    <button className="btn btn-shippingLabelPending" data-bs-toggle="modal" data-bs-target={'#'+'c'+venta.objectID}>
                        <i class="bi bi-exclamation-triangle"></i>
                    </button>
                </div>
            )
        }
        else{
            ordenDeEnvioEsDraft = (
                null
            )
        }

        let detallesDeOrden;
        if(nuevoEsquemaDeComisiones || venta.tipoDePago === "contraEntrega"){
            detallesDeOrden = (
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <h4 className="cuentaVendedorLabelForm">Orden</h4>
                            <h6 className="">Producto: {venta.nombreProducto}</h6>
                            <h6 className="">Cantidad: {venta.cantidad}</h6>
                        {variacion}
                        {personalizado}
                        <h6 className="">Fecha: {venta.Fecha}</h6>
                        </div>

                        <div class="mb-3">
                            <h4 className="cuentaVendedorLabelForm">Forma de Pago</h4>
                            <h6 className="">Pago: {venta.tipoDePago}</h6>
                        </div>

                        <div class="mb-3">
                            <h4 className="cuentaVendedorLabelForm">Detalles de Envio</h4>
                            <h6 className="">Paquetería: {venta.paqueteria}</h6>
                            <h6 className="">Número de Guía: {venta.codigoRastreo}</h6>
                            <h6 className="">{descargarGuia}</h6>
                        </div>
                        
                        {detallesAdminNegocio}
                    </form>
                </div>   
            )
        }
        else{
            detallesDeOrden = (
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <h4 className="cuentaVendedorLabelForm">Orden</h4>
                            <h6 className="">Producto: {venta.nombreProducto}</h6>
                            <h6 className="">Cantidad: {venta.cantidad}</h6>
                        {variacion}
                        {personalizado}
                        <h6 className="">Fecha: {venta.Fecha}</h6>
                        </div>
                            
                        <div class="mb-3">
                            <h4 className="cuentaVendedorLabelForm">Detalles del Comprador</h4>
                            <h6 className="">Email: {venta.emailCliente}</h6>
                            <h6 className="">Teléfono: {venta.telefonoCliente != undefined ? venta.telefonoCliente : null}</h6>
                        </div>

                        <div class="mb-3">
                            <h4 className="cuentaVendedorLabelForm">Forma de Pago</h4>
                            <h6 className="">Pago: {venta.tipoDePago}</h6>
                        </div>

                        {direccionDelComprador}

                        <div class="mb-3">
                            <h4 className="cuentaVendedorLabelForm">Detalles de Envio</h4>
                            <h6 className="">Paquetería: {venta.paqueteria}</h6>
                            <h6 className="">Número de Guía: {venta.codigoRastreo}</h6>
                            <h6 className="">{descargarGuia}</h6>
                        </div>
                        
                        {detallesAdminNegocio}
                    </form>
                </div>  
            ) 
        }
            
        let modalVentaDetails; 
        modalVentaDetails = (
            <div class="modal fade" id={'n'+venta.objectID} tabindex="-1" aria-labelledby="ventaModal" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Detalles de Orden </h3>
                        </div>
                        {detallesDeOrden}
                        <div class="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        );

        let modalConfirmarOrden = (
            <div class="modal fade" id={'confirmarOrden'+venta.objectID} tabindex="-1" aria-labelledby="ventaModal" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title">Confirmar Orden</h3>
                        </div>
                        <div class="modal-body">
                            <p>Una vez que confirmes la orden, la paquetería pasará a tu punto de recolección a recojer el pedido.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={this.confirmOrder} data-bs-dismiss="modal">Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
        );

        let thumbnailImage;
        if(product !== "" && product.imagenesDeProducto[0]){
            thumbnailImage = (
                <img src={product.imagenesDeProducto[0]} alt="" className="imgThumbnailList"/>
            );
        }
        else{
            thumbnailImage = (
                null
            );
        }

        let estatusDeOrden = venta.estatusDeOrden;
        //Si el estatus de orden inicialmente es draft pero el usuario ya la confirmó, entonces avisar que el estatus de la venta es confirmed
        if(venta.estatusDeOrden === "DRAFT" && !isDraft){
            estatusDeOrden = "CONFIRMED"
        }

        return(
            <div>
                {/* Modals */}
                {modalVentaDetails}
                {modalConfirmarOrden}
                
                {/* Venta Summary */}
                <div className="d-flex">
                    <a data-bs-toggle="modal" data-bs-target={'#'+'n'+venta.objectID} className="list-group-item list-group-item-action">
                        <li class="d-flex row mt-2 mt-md-0">
                            <div className="col-1 me-0 me-md-2">
                                {thumbnailImage}
                            </div>
                            
                            <div className="me-5 mt-3 mt-md-0 col-12 col-md-8">
                                <div class="d-flex fw-bold">
                                    {venta.nombreProducto}
                                </div>
                                <div className="d-flex mt-3 mb-3 mt-md-0">
                                    <div>
                                        Fecha: {venta.Fecha}
                                    </div>

                                    <div className="ms-4">
                                        Estatus: {estatusDeOrden}
                                    </div> 

                                    <div className="ms-4">
                                        N.Rastreo: {venta.codigoRastreo}
                                    </div> 
                                </div>

                                {/* Se despliega > 600 px */}
                                <div className="col-2 no-display-mobile">
                                    {estatusEnviado}
                                </div>
                            </div>
                            <div className="col-1">
                                {ingresoNetoNegocio}
                            </div>
                        </li>
                        {/* En Mobile */}
                        <div className="mt-3 mt-mb-0">
                            <div className="no-display-md">
                                {estatusEnviado}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        );
    } 
}

const mapDispatchToProps = (dispatch) => {
    return {
      addCodigoRastreo: (codigoRastreo) => dispatch(addCodigoRastreo(codigoRastreo))
    }
}


export default connect(null, mapDispatchToProps)(VentaSummaryNegocio);