import React, { Component } from 'react';
import VentasList from '../ventas/VentasList';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import {addRetiro} from '../../store/actions/retiroActions';
import {addBankAccount} from '../../store/actions/retiroActions';
import '../../App.css';

class CuentaNegocio extends Component {
    state = {
        usuario_id: this.props.auth.uid,
        cantidad: 0,
        Banco:this.props.profile.Banco,
        Cuenta_de_Banco: this.props.profile.Cuenta_de_Banco,
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleChangeBankAccount = (e) => {
        this.setState({
            usuario_id: this.props.auth.uid
        }, () => {
            this.props.addBankAccount(this.state);
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            cantidad: this.props.dineroEnCartera,
            usuario_id: this.props.auth.uid,
            Cuenta_de_Banco: this.props.profile.Cuenta_de_Banco,
            Banco: this.props.profile.Banco
        }, () => {
            if(this.state.cantidad > 0 && this.state.Cuenta_de_Banco != '' && this.state.Banco != ''){
                this.props.addRetiro(this.state);
                alert(
                    'Retiro Completado. El dinero llegará a tu cuenta de banco en 3 días hábiles.'
                ); 
            }else if(this.state.Cuenta_de_Banco === '' || this.state.Banco === ''){
                alert(
                    'Necesitas agegar una cuenta de banco para retirar tu dinero. Aquí te depositaremos tu dinero.'
                ); 
            }else{
                alert(
                    'No tienes dinero para retirar.'
                ); 
            }
        });
    }

    render(){
        const { auth, ventas, dineroEnCartera, profile} = this.props;
        //Route Guard – Si el usuario no está signed in, mandarlo a página de sign in
        if (auth.isLoaded && !auth.uid) return <Redirect to ='/signin'/>
        if(auth.isLoaded && auth.uid && ventas && profile){
            return(
                <div className="container">
                    {/*Modal de Cuenta de Banco*/}
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Ajustes</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                            <form>
                                <div class="mb-3">
                                    
                                    <h6 className="cuentaVendedorLabelForm">Cuenta de Banco Actual: {profile.Cuenta_de_Banco}</h6>
                                    <h6 className="cuentaVendedorLabelForm">Banco: {profile.Banco}</h6>
                                    
                                    
                                    <label for="Cuenta_de_Banco" className="col-form-label cuentaVendedorLabelForm">Cuenta de Banco Nueva:</label>
                                    <input onChange={this.handleChange} type="text" className="form-control" id="Cuenta_de_Banco"/>
                                    
                                    <label for="Banco" className="col-form-label cuentaVendedorLabelForm">Banco:</label>
                                    <input onChange={this.handleChange} type="text" className="form-control" id="Banco"/>

                                </div>
                            </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                                <button onClick={this.handleChangeBankAccount} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Guardar Cambios</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    {/* Información y Cuenta de Vendedor */}
                    <div className="mt-3 cuentaContenido d-flex">
                        {/* Imagen de Perfil y Nombre*/}
                        <div>
                            <i className="bi bi-person-circle me-2 iconPerfil"/>
                        </div>
                        {/* Nombre, Dinero en Cuenta, Retirar, Ajustes*/}
                        <div className="infoCuenta">
                            <div className="mb-3">
                                <h6 className="nombreVendedor">{profile.Nombre_de_Negocio}</h6>
                            </div>
                            <div>
                                <h4 className="btn btn-secondary dineroCuenta mb-2">{'$' + dineroEnCartera}</h4>
                            </div>
                            <div className="">
                                {/* Ajustes*/}
                                <a href="" onClick={this.handleSetBankAccount} className="ajustesIconColor" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="bi bi-gear me-3 ajustesIcon"></i></a>
                                <h2 onClick={this.handleSubmit} className="btn btn-secondary retirarDinero">Retirar</h2>
                            </div>
                        </div>
                    </div>
                    {/* Ventas */}
                    <div className="mt-3 listaVentas">
                        <VentasList ventas={ventas} nombreNegocio={profile.Nombre_de_Negocio} />
                    </div>
                    
                </div>
            );
        }else{
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    
    const ventas = state.firestore.ordered.Ventas || state.venta.ventas;
    const retiros = state.firestore.ordered.Retiros || state.retiro.retiros;
    const auth = state.firebase.auth;
    
    //Ventas de este negocio
    const mySales = ventas.filter((sale) => {
      if (sale.negocio_id === auth.uid) return sale;
    });

    //Retiros de este Negocio
    const misRetiros = retiros.filter((retiro) => {
        if (retiro.usuario_id === auth.uid) return retiro;
    });

    //Dinero total que ha ganado el negocio
    let dineroTotal = 0;
    for (var i = 0; i < mySales.length; i++) {
        dineroTotal = dineroTotal + (Number(mySales[i].ingreso) + Number(mySales[i].ingresoEnvio)) - (mySales[i].comision * 0.01 * mySales[i].ingreso) - (mySales[i].ingreso*0.05);
    }

    //Dinero total que ha retirado el negocio
    let retiroTotal = 0;
    for (var i = 0; i < misRetiros.length; i++) {
        retiroTotal = retiroTotal + misRetiros[i].cantidad;
    }

    let dineroEnCartera = dineroTotal - retiroTotal;

    return{
        ventas: mySales,
        auth: auth,
        dineroEnCartera: dineroEnCartera,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addRetiro: (retiro) => dispatch(addRetiro(retiro)),
      addBankAccount: (account) => dispatch(addBankAccount(account))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {collection: 'Ventas'},
        {collection: 'Retiros'}
    ])
)(CuentaNegocio);