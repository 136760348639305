import React, { Component } from 'react';
import {connect} from 'react-redux';
import {signIn} from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import {Helmet} from "react-helmet";
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  triggerAnalyticEvents = () => {
    //Google Analytics
    gtag("event", "login", {});
  }


  handleSubmit = (e) => {
      e.preventDefault();
      this.triggerAnalyticEvents();
      this.props.signIn(this.state);
  }

  componentDidMount(){  
    window.scrollTo(0, 0);
  }

  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to ='/'/>
    return (
        <div>

          {/* DYNAMIC META INFORMATION */}
          <Helmet>
            <title>Iniciar Sesión</title>
            <meta name="description" content="¡Bienvenido! Inicia sesión con tus credenciales."/>
          </Helmet>
          
          {/* <h1 className="sign-in-title">KANGURU</h1> */}
          <div className="container position-relative start-50 translate-middle signInForm row">
              {/* Titulo */}
              <h3 className = "text-black signUpTitle">Iniciar Sesión</h3>
            
              <form className="mt-4 col-11">
                  {/* Email */}
                  <div className="mb-4">
                      <label for="email" class="form-label">Correo Electrónico</label>
                      <input onChange={this.handleChange} type="email" class="form-control" id="email"/>
                  </div>
                  
                  {/* Password */}
                  <div className="mb-4">
                      <label for="password" class="form-label">Contraseña</label>
                      <input onChange={this.handleChange} type="password" class="form-control" id="password"/>
                  </div>

                  {/* Forgot Password */}
                  <div className="mb-4">
                      <a href="/recuperar-contrasena"> Recuperar Contraseña </a>
                  </div>

                  {/* Submit Button */}
                  <div className="">
                      <button onClick={this.handleSubmit} type="button" class="btn btn-primary buttonSignUp">Iniciar Sesión</button>
                      <div className="signIn-error">
                        { authError ? <p>{authError}</p> : null}
                      </div>
                  </div>
              </form>
          </div>
        </div>
        
        
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => dispatch(signIn(credentials))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
