export const addRetiro = (retiro) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Retiros').add({
            usuario_id: retiro.usuario_id,
            cantidad: retiro.cantidad,
            cuenta_de_banco: retiro.Cuenta_de_Banco,
            banco: retiro.Banco,
            Fecha: new Date().toString().substring(3,new Date().toString().indexOf("2022")+4), 
            retiroEn: new Date()
        }).then(() => {
            dispatch({ type: 'ADD_RETIRO', retiro}); 
        }).catch((err) => {
            dispatch({ type: 'ADD_RETIRO_ERROR', err});
        }) 
    }
};

export const addBankAccount = (account) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Usuarios').doc(account.usuario_id).update({Cuenta_de_Banco: account.Cuenta_de_Banco, Banco: account.Banco})
        .then(() => {
            dispatch({ type: 'ADD_BANK_ACCOUNT', account}); 
        }).catch((err) => {
            dispatch({ type: 'ADD_BANK_ACCOUNT_ERROR', err});
        }) 
    }
};