import React from 'react';
import './ChatHeader.css';

const ChatHeader = (props) => {

    const {receiver, setMobileDisplayedContent} = props;

    const handleGoBack = (e) => {
        e.preventDefault();
        setMobileDisplayedContent("chatList");
    }
    
    let goBack;
    if(window.innerWidth < 600){
        goBack = (
            <i class="bi bi-chevron-left goBackIcon" onClick={(e) => handleGoBack(e)}></i>
        )
    }
    else{
        goBack = (
            null
        )
    }

    if(receiver){
        return(
            <div className="chatHeaderContainer">
                <div className="d-flex mt-2">
                    <div class="goBack">
                        {goBack}
                    </div>
                    <img src={(receiver.Logo && receiver.Logo[0]) !== '' ? receiver.Logo[0]: "/img/no-store.png"} className="chatLogoHeader me-2"/>
                    <div class="header-title">
                        <p>{receiver.Nombre_de_Negocio === "" ? receiver.Nombre_de_Vendedor: receiver.Nombre_de_Negocio}</p>
                    </div>
                </div>
                <div className="divider"></div>
            </div> 
        )
    }
    else{
        return(
            <div className="chatHeaderContainer">
                
            </div> 
        )
    }
    
}

export default ChatHeader;