import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from "react-helmet";
import Template1 from './Templates/Diseños/Template1';

const CatalogoCliente = (props) => {
    
    const {productsCatalogo, vendedorID, vendedor, oDisenoEnCatalogo} = props;
    let templateToDisplay;
    const sTemplateID = oDisenoEnCatalogo.objectID;

    if(sTemplateID === "UTfkRxk8ijvujKRYvzOy"){
        templateToDisplay = (
            <Template1 productsCatalogo={productsCatalogo} vendedorID={vendedorID} vendedor={vendedor} oDisenoEnCatalogo={oDisenoEnCatalogo}/>
        );
    }
    else{
        templateToDisplay = (
            <Template1 productsCatalogo={productsCatalogo} vendedorID={vendedorID} vendedor={vendedor} oDisenoEnCatalogo={oDisenoEnCatalogo}/>
        );
    }
    

    return (
        <div>
            {templateToDisplay}
        </div>
       
    );
}

export default CatalogoCliente;
