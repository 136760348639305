import React from 'react';
import {Link, useHistory} from 'react-router-dom';

const CategorySummary = (props) => {

    const {categoria} = props;
    let path = '/categoria' + '/' + categoria.categoria;
    

    if(categoria.categoria === "buscarPorTienda"){
        path = '/buscarPorTienda';
    }

    return(
        <div>
            <div className="card categoriasMobile mb-3">
                <Link to={path}>
                    <img src={categoria.img} alt="" className="card-img-top category-card-img"/>
                </Link>
                <div class="card-body w-100 categoria-card-body">
                    <p class="card-text categoria-card-text">{categoria.nombre}</p>
                </div>
            </div>
        </div>
    )
    
}

export default CategorySummary;



