import React, { useState, useEffect } from 'react';
import './MensajeSummary.css';

const MensajeSummary = (props) => {
    
    const {mensaje, auth} = props;
    //If message is from sender, then color orange, else color grey
    let mensajeConDiseño;
    if(mensaje.sSenderID === auth){
        mensajeConDiseño = (
            <p className="text sender-text">{mensaje.sMensaje}</p>  
        )
    }
    else{
        mensajeConDiseño = (
            <p className="text receiver-text">{mensaje.sMensaje}</p>  
        ) 
    }


    return(
        <div className="d-flex mensaje-container">
            {mensajeConDiseño}
        </div>
    )
    
}

export default MensajeSummary;