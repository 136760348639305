import React from 'react';
import ReseñaSummary from './ReseñaSummary';

{/* Despliega Lista de Ventas en CuentaVendedor */}
const ReseñasList = (props) => {

    const {reseñas} = props;

        return(
            <div className="reseñasList">
                {reseñas && reseñas.map(reseña => {
                    return (
                        <div key={reseña.objectID}>
                            <ReseñaSummary reseña={reseña}/>
                        </div>  
                    )
                })}
            </div> 
        )
}

export default ReseñasList;