import React, { Component } from 'react';
import AddIndividualProduct from './AddIndividualProduct';
import PricingExercise from './PricingExercise';
import ProductPendingList from './ProductPendingList';
import CSVReader from 'react-csv-reader';
import {connect} from 'react-redux';
import {addProductCSV} from '../../../store/actions/productActions';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';


class AddProduct extends Component {
    state = {
        content: "individual",
        dataCSV: [],
        negocio: this.props.profile.Nombre_de_Negocio,
        negocio_id: this.props.auth.uid,
        negocio_email: this.props.auth.email,
        showLoader: false,
        dataFromCSVHasError: false,
        errorImportingDataFromCSV: {}
        
    }

    notify = (type) => {
        if(type == "publicaronProductos"){
            toast.success("¡Excelente! Ya se publicaron tus productos. Puede tomar un poco en reflejarse, por favor refresca la página.");
        }
    }

    handleClickDisplayContent = (e) => {
        const oldContent = this.state.content;
        this.setState({
          content: [e.target.id]
        }, () => {
            if(oldContent != ''){
                const element = document.getElementById(oldContent);
                const element2 = document.getElementById(this.state.content);
                element.style.color = 'black';
                element2.style.color = '#E75B10';
            }else{
                const element2 = document.getElementById(this.state.content);
                element2.style.color = '#E75B10';
            }
            
        });
    };

    createArrayFromStringSeparatedByChar = (string, char) => {
        let baseString = string;
        let newArray = [];
        //Mientras siga habiendo una comma presente
        while(baseString.indexOf(char) > -1){
            let idxDeChar = baseString.indexOf(char);
            let element = baseString.substring(0, idxDeChar);
            newArray.push(element);
        
            //Borrar link de imagenesString
            baseString = baseString.replace(element+char, "");
        }
        newArray.push(baseString);

        return newArray;
    }

    uploadProducts = () => {
        //Iteramos Data CSV
        for(let i = 1; i < this.state.dataCSV.length; i++){
            if(this.state.dataCSV[i][0] !== ""){
                 //Creamos el arreglo de imagenesDeProducto
                let imagenesDeProducto = [];
                imagenesDeProducto.push(this.state.dataCSV[i][7]);
                
                let otrasImagenesDeProducto = this.createArrayFromStringSeparatedByChar(this.state.dataCSV[i][8], ",");
                imagenesDeProducto = imagenesDeProducto.concat(otrasImagenesDeProducto);

                //Creamos los arreglos de cantidades y variaciones
                let cantidades = [];
                let variaciones = [];
                
                //Si hay variaciones
                if(this.state.dataCSV[i][6] !== ""){
                    cantidades = this.createArrayFromStringSeparatedByChar(this.state.dataCSV[i][5], ";");
                    variaciones = this.createArrayFromStringSeparatedByChar(this.state.dataCSV[i][6], ",");
                }
                else{
                    cantidades.push(this.state.dataCSV[i][5]);
                }

                // Llamamos función para editar producto en base a los datos de la fila actual
                this.props.addProductCSV(this.state.dataCSV[i], this.state, imagenesDeProducto, cantidades, variaciones);
            }
        }
    }

    handleImportCSV = (data, fileInfo, originalFile) => {
        this.setState({
            dataCSV: data
        }, () => {
            //ERROR HANDLING
            const categoriasValidas = ["hogar", "cocina", "tecnologia", "moda", "joyería", "bebes", "mascotas", "calzado", "accesoriosParaVehiculos", "juegosYJuguetes", "belleza", "bienestar", "fitness", "optica", "sexToys"];
            for(let i = 1; i < this.state.dataCSV.length; i++){
                if(this.state.dataCSV[i][0] !== ""){
                    if(this.state.dataCSV[i][2].indexOf("$") > -1){
                        this.setState({
                            dataFromCSVHasError: true,
                            errorImportingDataFromCSV: {
                                error: "Precio tiene signo de $",
                                row: i+1
                            }
                        })
                        break;
                    }
                    else if(this.state.dataCSV[i][2].indexOf(",") > -1){
                        this.setState({
                            dataFromCSVHasError: true,
                            errorImportingDataFromCSV: {
                                error: "Precio tiene comma",
                                row: i+1
                            }
                        })
                        break;
                    }
                    else if(this.state.dataCSV[i][4].indexOf(".") > -1){
                        this.setState({
                            dataFromCSVHasError: true,
                            errorImportingDataFromCSV: {
                                error: "Peso es decimal",
                                row: i+1
                            }
                        })
                        break;
                    }
                    else if(!categoriasValidas.includes(this.state.dataCSV[i][3])){
                        this.setState({
                            dataFromCSVHasError: true,
                            errorImportingDataFromCSV: {
                                error: "La categoría no es válida",
                                row: i+1
                            }
                        })
                    }
                }
            }
        })
    }

    handleSubmit = () => {
        this.uploadProducts();
        this.setState({
            showLoader: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    showLoader: false
                })
                this.notify("publicaronProductos");
            }, "3000")
        })
    }


    componentDidMount(){
        const element = document.getElementById(this.state.content);
        element.style.color = '#E75B10';
    }

    
    render(){
        const pendingProducts = this.props.pendingProducts;
        
        let content;
        if(this.state.content == "individual"){
            content = (
                <AddIndividualProduct/>
            );
        }
        else if(this.state.content == "pricingExercise"){
            content = (
                <PricingExercise/>
            );
        }
        else if(this.state.content == "pending"){
            if(pendingProducts.length == 0){
                content = (
                    <div className="container spinner">
                        <p className="mt-3">No tienes productos pendientes a publicar.</p>
                    </div>
                );
            }
            else{
                content = (
                    <ProductPendingList products={pendingProducts}/>
                );
            }
            
        }

        let loader = (
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status"></div>
                <p className="mt-3">Cargando Productos.</p>
                <p className="mt-3">Al terminar, recarga la página.</p>
            </div>
        );

        let publishBtn;
        if(this.state.dataCSV[0] !== undefined){
            if(this.state.dataCSV[0].length == 9 && !this.state.dataFromCSVHasError){
                publishBtn = (
                    <button onClick={this.handleSubmit} type="button" className="btn btn-primary" data-bs-dismiss="modal">Importar</button>
                );
            }
            else{
                publishBtn = (
                    <button onClick={this.handleSubmit} type="button" className="btn btn-grey btn-no-import">Importar</button>
                );
            }
        }
        else{
            publishBtn = (
                <button onClick={this.handleSubmit} type="button" className="btn btn-grey btn-no-import">Importar</button>
            );
        }

        let error;
        if(this.state.dataFromCSVHasError){
            error = (
                <p className="mt-3 error">Error: {this.state.errorImportingDataFromCSV.error} en fila {this.state.errorImportingDataFromCSV.row}</p>
            );
        }
        else{
            error = (
                null
            );
        }

        let importModal = (
            <div class="modal fade" id="importModal" tabindex="-1" aria-labelledby="importModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Importa tus productos de archivo .csv</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p className="bold">Pasos</p>
                        <ol>
                            <li>Accede a la plantilla y descárgala a un archivo .csv para subir tus productos: <a target="_blank" href="https://docs.google.com/spreadsheets/d/1iMQE3NzKPevTFjipWGGkGiG-NKdWuGL4T92yvBneRoI/edit?usp=sharing">plantilla</a></li>
                            <li className="mt-4">Antes de editar el archivo, asegurate haber revisado el formato de los campos: <a target="_blank" href="https://docs.google.com/document/d/1vKiCdkohRY_GnaMXCie4dS1_0dvbrGwvCIfWD4yNotw/edit?usp=sharing">aquí</a></li>
                            <li className="mt-4">Borra los productos de muestra en la plantilla y agrega los tuyos.</li>
                            <li className="mt-4">
                                Importa tu archivo:
                                <div className="mt-2">
                                    <CSVReader onFileLoaded={this.handleImportCSV} />
                                    {error}
                                </div>
                            </li>
                        </ol>
                        {/* Divider Line */}
                        <div className="col-12 mt-3">
                            <div className="divider"></div>
                        </div>
                        <p className="bold mt-3">Recuerda</p>
                        <p>No cambies el nombre de las columnas.</p>
                        <p>Revisa el formato antes de editar la plantilla: <a target="_blank" href="https://docs.google.com/document/d/1PXBkbUxwtm0MxIrBprvMYLuta4P3ABGxmAgLHw54qvM/edit?usp=sharing">aquí</a>.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                        {publishBtn}
                    </div>
                    </div>
                </div>
            </div>
        );

        return(
            <div className="mt-4">
                {/* MODAL PARA IMPORTAR ARCHIVO CSV */}
                {importModal}

                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                <div>
                    <div className="d-flex">
                        <h3>Agrega Productos</h3>
                        <button className="btn btn-import ms-4" data-bs-toggle="modal" data-bs-target="#importModal">Carga Masiva</button>
                    </div>
                    <div className="d-flex mt-3">
                        <p onClick={this.handleClickDisplayContent} className="me-4 addProductOption" id="individual">Individual</p>
                        <p onClick={this.handleClickDisplayContent} className="me-4 addProductOption" id="pricingExercise">Calcula tu Ingreso Neto</p>
                        {/* <p onClick={this.handleClickDisplayContent} className="addProductOption" id="pending">Pendientes</p> */}
                    </div>
                </div>
                <div className="contentAddProduct">
                    {!this.state.showLoader ? content:loader}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addProductCSV: (product, state, imagenesDeProducto, cantidades, variaciones) => dispatch(addProductCSV(product, state, imagenesDeProducto, cantidades, variaciones))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
