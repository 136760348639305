import React, { useState, useEffect } from 'react';
import './ProductosActualizados.css'

const ProductoActualizadoSummary = (props) => {

    const {product} = props;
    const tipoDeCambio = product.oUltimaActualizacion.sTipoDeCambio;
    let cambioBtn;

    if(tipoDeCambio === "oculto"){
        cambioBtn = (
            <p className="btn btnNoDisponible">No Disponible</p>
        )
    }
    else if(tipoDeCambio === "precio"){
        cambioBtn = (
            <p className="btn btnCambioDePrecio">Cambio de Precio</p>
        )
    }
    else if(tipoDeCambio === "agotado"){
        cambioBtn = (
            <p className="btn btnAgotado">Agotado</p>
        )
    }
    else if(tipoDeCambio === "disponible"){
        cambioBtn = (
            <p className="btn btnDisponible">Disponible</p>
        )
    }

    return(
        <div className="list-group-item">
            <li class="d-md-flex justify-content-between align-items-center mt-3 mt-md-0">
                <div>
                    <div className="d-flex">
                        <div>
                            <img src={product.imagenesDeProducto[0]} alt="" className="imgThumbnailList"/>
                        </div>
                        
                        <div>
                            <div class="fw-bold mt-1 mt-md-3">
                                {product.nombre}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-4 mt-md-3">
                    {cambioBtn}
                </div>
            </li>  
        </div>
    )
    
}

export default ProductoActualizadoSummary