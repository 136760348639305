/*global google*/
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {addLogistics} from '../../store/actions/negocioActions';
import axios from 'axios';
import Geosuggest from 'react-geosuggest';
import toast, { Toaster } from 'react-hot-toast';

class NegocioLogisticsDashboard extends Component {
    state = {
        uid: this.props.uid,
        address: "",
        location: {lat: "", lng: ""},
        street_number: "",
        postal_code: "",
        reference: "",
        hasCoverage: true,
        suggestHasPostalCode: false,
        type: ""
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    notify = (type) => {
        if(type == "direccionIncompletaNumero"){
            toast.error('¡Rayos! Tu dirección no es válida, no tiene número. Si no aparece la dirección que quieres, comunicate al +52 8186058811');
        }
        else if(type == "direccionIncompletaCP"){
            toast.error('¡Uy! Tu dirección no es válida. Falta el código postal.');
        }
        else if(type == "direccionIncompletaDireccion"){
            toast.error('¡No tenemos tu dirección! Necesitas seleccionar la opción que aparece en el campo de dirección.');
        }
        else if(type == "codigoPostalInvalido"){
            toast.error('El código postal que pusiste no es válido, corrígelo y listo.');
        }
        else if(type == "sinCobertura"){
            toast.error('Disculpanos. Actualmente, no ofrecemos cobertura a esta dirección. Comunicate a +52 8117918821');
        }
        else if(type == "addDireccionDeRecoleccion"){
            toast.success('¡Buenísimo! Se agregó tu punto de recolección. Ya puedes publicar productos y aceptar ordenes.');
        }
    }

    handleSubmit= (e) => {
        e.preventDefault();
        if(this.state.street_number === ""){
            this.notify("direccionIncompletaNumero");
        }
        else if(this.state.postal_code === ""){
            this.notify("direccionIncompletaCP");
        }
        else if(this.state.address === ""){
            this.notify("direccionIncompletaDireccion");
        }
        else if(this.state.postal_code.length !== 5){
            this.notify("codigoPostalInvalido");
        }
        else if(!this.state.hasCoverage){
            this.notify("sinCobertura");
        }
        else{
            //Actualizar Datos
            this.notify("addDireccionDeRecoleccion");
            this.props.addLogistics(this.state);
        }
    }

    onSuggestSelect = (suggest) => {
        if(suggest && suggest.gmaps){
            let addressComponents = suggest.gmaps.address_components;
            let street_number = "";
            let postal_code = "";
            for (var i = 0; i < addressComponents.length; i++) {
                for (var j = 0; j < addressComponents[i].types.length; j++) {
                    if (addressComponents[i].types[j] == "street_number") {
                        street_number = addressComponents[i].long_name;
                    }
                    else if(addressComponents[i].types[j] == "postal_code"){
                        postal_code = addressComponents[i].long_name;
                    }
                }
            }

            if(postal_code){
                this.setState({
                    address: suggest.gmaps.formatted_address,
                    location: {lat: suggest.location.lat, lng: suggest.location.lng},
                    postal_code: postal_code,
                    street_number: street_number,
                    suggestHasPostalCode: true
                });
            }
            else{
                this.setState({
                    postal_code: "",
                    suggestHasPostalCode: false
                });
            }
        }
    }

    async componentDidUpdate(prevProps, prevState){
        if(this.state.postal_code.length === 5 && this.state.postal_code !== prevState.postal_code){
            const postalCodeDestination = this.state.postal_code;
            const postalCodeOrigin = "06700"; //código postal de ciudad de méxico 
            //Llamar función para validar código postal
            
            const validatePostalCode = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/coverageByZipCode99Minutos", {
                params: {
                    postalCodeDestination: postalCodeDestination,
                    postalCodeOrigin: postalCodeOrigin
                }
            })
            .then((response) => {
                //Update state que se validó el C.P
                return this.setState({hasCoverage: response.data.hasCoverage});
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }

    async componentDidMount(){
        const negocio = this.props.negocio;
        
        if(negocio.direccionDeRecoleccion !== undefined){
            this.setState({
                type: negocio.direccionDeRecoleccion.type,
                address: negocio.direccionDeRecoleccion.address,
                location: {lat: negocio.direccionDeRecoleccion.lat, lng: negocio.direccionDeRecoleccion.lng},
                street_number: negocio.direccionDeRecoleccion.street_number
            })
        }
    }
    
    render(){
        let typeSelect;
       
        if(this.state.type == ''){
            typeSelect = (
                <select onChange={this.handleChange} id="type" className="form-select" aria-label="No">
                    <option disabled selected value=''>Tipo</option>
                    <option value="Residencial">Residencial</option>
                    <option value="Comercial">Comercial</option>
                </select>
            );
        }
        else if(this.state.type == "Residencial"){
            typeSelect = (
                <select onChange={this.handleChange} id="type" className="form-select" aria-label="No">
                    <option disabled value=''>Tipo</option>
                    <option selected value="Residencial">Residencial</option>
                    <option value="Comercial">Comercial</option>
                </select>
            );
        }
        else{
            typeSelect = (
                <select onChange={this.handleChange} id="type" className="form-select" aria-label="No">
                    <option disabled value=''>Tipo</option>
                    <option value="Residencial">Residencial</option>
                    <option selected value="Comercial">Comercial</option>
                </select>
            );
        }

        let inputPostalCode;
            if(this.state.suggestHasPostalCode && this.state.postal_code !== ""){
                inputPostalCode = (
                     <input type="text" placeholder={this.state.postal_code} className="form-control" id="postal_code" disabled/>
                );
            }
            else{
                inputPostalCode = (
                    <input type="text" onChange={this.handleChange} placeholder={this.state.postal_code} className="form-control" id="postal_code"/>
                );
            }

        return(
            <div>
                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                <form className="row mt-4 mt-md-0">
                    {/* Dir de Recolección */}
                    <div>
                        <h3 className="mt-4">¿Dónde debemos recolectar tus paquetes?</h3>
                        <div className="mt-4">
                            {/* Address Google */}
                            <div className="col-12 mt-3">
                                <label for="address" className="form-label"><span className="addProductFormLabel">Dirreción</span> (elije la dirección <span className="bold">exacta</span>, inlcuir número)</label>
                                <Geosuggest
                                    placeholder={this.state.address}
                                    onSuggestSelect={this.onSuggestSelect}
                                    location={new google.maps.LatLng(25.654333, -100.3698572)}
                                    radius="20"
                                    country='mx'
                                    types={["geocode"]}
                                    maxFixtures={5}
                                    
                                />
                            </div>

                            {/* Codigo Postal */}
                            <div className="col-12 mt-3">
                                <label for="postal_code" className="form-label addProductFormLabel">Código Postal</label>
                                {inputPostalCode}
                            </div>

                            {/* Referencia */}
                            <div className="col-12 mt-3">
                                <label for="reference" className="form-label addProductFormLabel">Referencia</label>
                                <input type="text" onChange={this.handleChange}  className="form-control" placeholder="Ej. Entre Calles / Interior Casa #91 / Piso #1 / Dpt #301" id="reference"/>
                            </div>

                            {/* Tipo de Lugar */}
                            <div className="col-12 mt-4">
                                <label for="type" className="form-label addProductFormLabel">Tipo de Lugar</label>
                                {typeSelect}
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div class="col-12 mt-3">
                            <button onClick={this.handleSubmit} type="submit" className="btn btn-primary mt-3 mb-5">{this.props.negocio.direccionDeRecoleccion == undefined ? "Agregar Datos" : "Actualizar Datos"}</button>
                        </div>
                    </div>
                </form>
            </div>
        );    
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addLogistics: (state) => dispatch(addLogistics(state))
    }
}

export default connect(null, mapDispatchToProps)(NegocioLogisticsDashboard);
