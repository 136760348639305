import React, { useEffect, useState } from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import '../../App.css';
import {signOut} from '../../store/actions/authActions';
import {connect} from 'react-redux';
import Searchbar from './Searchbar';

const SignedInLinksMobile = (props) => {
    const {auth, profile} = props;
    const [showBanner, setShowBanner] = useState(false);
    const [showNav, setShowNav] = useState(true);
    let history = useHistory ();
    const location = useLocation();
    

   //If location changes, checks whether banner and navbar should be shown
   useEffect(() => {
        if(window.location.href.includes('chats') || (profile.bCompletoOnboarding !== undefined && profile.bCompletoOnboarding === false)){
            setShowNav(false);
            setShowBanner(false);
        }
        else if(window.location.href.includes('dashboard-negocio') || window.location.href.includes('capacitacion-negocios')){
            setShowBanner(false);
            setShowNav(true);
        }
        else{
            setShowBanner(true);
            setShowNav(true);
        }

    }, [location]); 

    const handleClick = () => {
        //Para refrescar la historia y que aparesca home y no la última vista categoría al momento de picarle al kanguru
        history.push('/', { from: '' } );
    }
    
    let banner;
    if(showBanner && profile.Nombre_de_Negocio != ''){
        banner = (
            <div className="banner">
                <p className="banner-text pt-2 pb-2 pt-md-0 pb-md-0">Esto es lo que ven los afiliados, puedes explorar qué productos y comisiones ofrecen otros proveedores.</p>
            </div>
        );
    }
    else{
        banner = (
            null
        );     
    }

    let logo;
    if(auth.isLoaded){
        logo = (
            <img src="/img/Kanguru_logo.png" alt="" className="logo"/>
        );
    }

    if(!showNav){
        return(
            null
        )
    }
    else if(logo){
        return(
            <div>
                <div>
                    {banner}
                </div>
                <div class="container-fluid">
                    {/* Logo and Title of Page */}
                    <Link to="/" className="me-md-5" onClick={handleClick}>
                        {logo}
                    </Link>
                    
                    {/* Search Bar*/}
                    <Searchbar profile={profile}/>
                </div>
            </div>
            
        );
    }else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        ); 
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinksMobile);