import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {addConnectAccount} from '../../store/actions/authActions';

class StripeConnect extends Component{
    
    handleToken = async token =>  {
    
        const profile = this.props.profile;
        const auth = this.props.auth;
        console.log(auth.uid);
        const response = await axios.post(
        "https://back-end-kilig.herokuapp.com/onboard-user",
            {auth, profile}
            // {headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded',
            //     'Authorization': 'Bearer pk_live_51JNgnVAKagnM0wdjxBybIm0YWedaHzuucbWoWEPkusDLfQZA6XUYkWSrx0T6i2i76B65cB3oEMjZ2p8OZXakopI400qyN7rfYC'

            // }}
            // {headers: 
            //     { Authorization: `Bearer pk_live_51JNgnVAKagnM0wdjxBybIm0YWedaHzuucbWoWEPkusDLfQZA6XUYkWSrx0T6i2i76B65cB3oEMjZ2p8OZXakopI400qyN7rfYC`,
            //     'Content-Type': 'application/x-www-form-urlencoded'}
            // }
        );

        // const connect = await axios.get(
        //     "https://back-end-kilig.herokuapp.com/v1/accounts/" + response.data.id
            
        // );
        // console.log(connect.data.id);
        console.log("Response:", response.data.url);
        console.log(response.data.id);
        if (response.data.url) {
            window.location = response.data.url;
            console.log('success')
            //Add account id to user and status = false because onboarding has not completed
            this.props.addConnectAccount(response.data.id, auth.uid);
        } else {
        console.log('No se completó el onboarding');
        }
    }

    //Si el usuario completó el onboarding, entonces escribirlo en base de datos y ya no aparece el botón
  
    render(){
        return (
            <button onClick={this.handleToken} className="btn btn-primary mt-3">
                Configurar Cuenta
            </button>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addConnectAccount: (accountID, userID) => dispatch(addConnectAccount(accountID, userID))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(StripeConnect);