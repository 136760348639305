import React from 'react';
import './LandingPricing.css';
import '../../../../App.css';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

const LandingPricing = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Precio</title>
                <meta name="description" content="¿Tienes una pregunta? Contáctanos :)"/>
            </Helmet>
            <div className="container center mb-5">
                <div className="title-section">
                    <h2 className="title bold">Vende productos, después elije si suscribírte</h2>
                    <h6 className="title-helper">Prueba Kanguru gratis por 7 días, sin meter tarjeta de crédito</h6>
                    <Link to="/signup-vendedor"><button className="btn btn-title-section mt-3 mt-md-5">Comienza Ahora</button></Link>
                </div>
                <div className="position-relative start-50 translate-middle pricing-plan">
                    <div className="">
                        <div>
                            <p className="pricing-plan-title">Suscripción Naranja</p>
                            <p>Todo lo que necesitas para empezar <br></br> tu negocio.</p>
                            <p className="mt-5 pricing-plan-price">$99 pesos al mes</p>
                        </div>
                        <div className="divider"></div>
                        <div className="mt-3 left">
                            <p className="bold">Incluye:</p>
                            <div className="mt-3">
                                <p><i class="bi bi-check-circle-fill me-2 pricing-plan-icon"></i>Miles de Productos para Vender</p>
                                <p><i class="bi bi-check-circle-fill me-2 pricing-plan-icon"></i>Catalogos Ilimitados</p>
                                <p><i class="bi bi-check-circle-fill me-2 pricing-plan-icon"></i>Cancela cuando quieras</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPricing;
