import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingFuncionamientoNegocios = () => {
    return(
        <div className="container">
            {/* Dynamic Description*/}
            <Helmet>
                <title>Cómo Funciona Kanguru para Proveedores</title>
                <meta name="description" content="Aprende cómo funcionan los envíos y cómo se cobran las comisiones."/>
            </Helmet>
            <h1 className="terminosCondicionesCatch">Cómo Funciona Kanguru para Proveedores</h1>
            
            <p className="mt-5">
                El presente documento tiene como objetivo explicar el funcionamiento de la plataforma Kanguru a el potencial proveedor, <span className="yellow-emphasis">PROVEEDOR</span>. A continuación se muestra un ejemplo de escenario de venta. Se asumen que <span className="yellow-emphasis">PROVEEDOR</span> publicó un producto llamado “Calcetines Rojos” en la plataforma. 
            </p>
            
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">Información sobre el producto “Calcetines Rojos” </h5>
                <p>Precio Base: $500 pesos</p>
            </div>

            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">Escenario 1: Se realizó una venta del producto “Calcetines Rojos”</h5>
                <div className="mt-4">
                    <img className="flow_de_compra" src="img/flow_compra_cpc.png" alt=""/>
                </div>
            </div>

            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">Información sobre el producto “Calcetines Rojos” </h5>
                <div>
                    <p>Tu Ingreso Total: $500 pesos</p>
                    <p>Comisión de Kanguru: $35 pesos</p>
                    <p>Tu Ingreso Neto = $465 pesos = 500 - 35</p>
                </div>
            </div>

            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">Notas Adicionales</h5>
                <p>
                    Kanguru le deposita al <span className="yellow-emphasis">PROVEEDOR</span> el acumulado del ingreso neto de sus ventas el día 16 o 1 de cada mes dependiendo de cuando el cliente pagó su orden. Siendo los días 15 y 30 de cada mes los días corte. 

                </p>
                
                <p>
                    En ventas con método de pago contra entrega y pago en línea, Kanguru generá la guía de envío. Podrás descargar dicha guía para enviar tu producto en la página de dashboard, seleccionando la respectiva venta y el botón para descargar la guía.
                </p>

                <p>Restrincciones de Peso: 0-25 kg</p>
                <p>Restrincciones de Productos: No se aceptan productos como medicamentos, o productos donde el cliente necesite ser mayor de edad para comprar como cigarros electrónicos, productos de tabaco, o alcohol.</p>
                <p>Comisión de la Kanguru: 7% sobre el ingreso del producto, que es el precio base del producto * cantidad que ordenó el cliente.</p>
            </div>
        </div>
    );
}

export default LandingFuncionamientoNegocios;
