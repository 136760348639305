import React from 'react';
import CatalogoSummary from './CatalogoSummary';

{/* Despliega Lista de Catalogos*/}
const CatalogosList = (props) => {

    const {catalogos, vendedorID, disenoEnCatalogo} = props;
        
        return(
            <div className="reseñasList">
                {catalogos && catalogos.map(catalogo => {
                    return (
                        <div key={catalogo.objectID}>
                            <CatalogoSummary catalogo={catalogo} vendedorID={vendedorID} disenoEnCatalogo={disenoEnCatalogo}/>
                        </div>  
                    )
                })}
            </div> 
        )
}

export default CatalogosList;