import React, {Component} from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import ProductGraficMaterial from './ProductGraficMaterial'
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import copy from 'copy-to-clipboard';
import axios from 'axios';
import LandingEnvios from '../layout/LandingPage/LandingEnvios';
import {addProductToCatalogo} from '../../store/actions/productActions';
import {addChat} from '../../store/actions/chatActions';
import algoliasearch from 'algoliasearch';
import Reseñas from '../reviews/Reseñas';
import {Helmet} from "react-helmet";
import toast, { Toaster} from 'react-hot-toast';
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";
const SEARCH_API_KEY_CATALOGOS = "465a5e3eb841eff90af7f9c36278dd44";
const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "37bbdced854246edc06c7fb3c072275c";
const SEARCH_API_KEY_CHATS = "95e6ac5a71881cf27f0a4566a7719b90";


//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";
// const SEARCH_API_KEY_CATALOGOS = "fc1440dbc9593332f235bbc14852b8a3";
// const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "a4785ad7a7f68b11da33cfcd839116ac";

const ALGOLIA_INDEX_PRODUCTOSENCATALOGO = 'Kanguru_ProductosEnCatalogo';
const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_RESENAS = 'Kanguru_Resenas';
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';
const ALGOLIA_INDEX_CATALOGOS = 'Kanguru_Catalogos';
const ALGOLIA_INDEX_CHATS = 'Kanguru_Chats';

const client_productosEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOSENCATALOGO);
const indexProductosEnCatalogo = client_productosEnCatalogo.initIndex(ALGOLIA_INDEX_PRODUCTOSENCATALOGO);
const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_resenas = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_RESENAS);
const indexResenas = client_resenas.initIndex(ALGOLIA_INDEX_RESENAS);
const client_catalogos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_CATALOGOS);
const indexCatalogos = client_catalogos.initIndex(ALGOLIA_INDEX_CATALOGOS);
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);
const client_Chats= algoliasearch(APPLICATION_ID, SEARCH_API_KEY_CHATS);
const indexChats = client_Chats.initIndex(ALGOLIA_INDEX_CHATS);


 
class ProductDetails extends Component{

    state = {
        index:0,
        precio:"",
        content: 0,
        variaciones: '0',
        product: "",
        reseñas: [],
        reseñasTotal: [],
        bExisteEnCatalogo: false,
        sCatalogoID: "",
        catalogos: [],
        negocio: "",
    }

    triggerAnalyticEvents = (eventType) => {
        gtag("event", "select_content", {
            content_type: eventType
        });
    }

    addViewAnalytics = (productoID) => {
        
        const view = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/actualizarVisitasEnTasaDeConversion", {
            params: {
                productoID: productoID
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleChangeImage = (e) => {
        this.setState({
          index: e.target.id
        })
    }

    download = (imgsProduct) => {
        // let arrayImgs = imgsProduct.concat(imgsGrafic);
        let arrayImgs = imgsProduct;
        for(let i = 0; i < arrayImgs.length; i++){
            axios.get(arrayImgs[i], {responseType: 'arraybuffer'}).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/image'}));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.jpg"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err);
            });
        }
        this.triggerAnalyticEvents("downloadImgs")

    };

    downloadOnlyImage = e => {
        
        axios.get(e.target.id, {responseType: 'arraybuffer'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/image'}));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.jpg"); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err);
        });

        this.triggerAnalyticEvents("downloadImgs")
    };

    copyInfo = (price, title, description) => {
        let info = title + "\n" + "\n" + "Precio: $"+ price + "\n" + "\n" + "Descripción: " + description;
        copy(info);
        this.notify("copioInfoProducto");
        this.triggerAnalyticEvents("copyInfo")
    }


    toHex = (input) => {
        //750 sería hmfkaz
        //Del 0-4 la letra es az,by,cx,dw,ev
        //Del 5-9 la letra es fk,gl,hm,in,jp
        let hash = "";
        let alphabet = "abcdefghijklmnopqrstuvwxyz";
        let temp = "";
        for(let i = 0; i < input.length; i++){
            if(Number(input[i]) < 5){
                temp = temp + alphabet[input[i]] + alphabet[25 - input[i]];
            }
            else{
                temp = temp + alphabet[input[i]] + alphabet[Number(input[i])+5];
            }
        }
        
        hash = temp;
      
        return hash;
    }

    toCheckout = (precioFinal, precioBase, comisionDelVendedor) => {
        const precioHash = this.toHex(precioFinal.toString());
        
        let text = '/checkout/' + this.props.productID + '/' + this.props.auth.uid + '/' + precioHash;
        if((precioBase + comisionDelVendedor) < this.state.product.precio){ //Si se edita el precio, sólo se puede copiar la liga si el precio nuevo es mayor o igual al original menos la comisión del vendedor
            this.notify("precioMenorAOriginal");
        }
        else{
            window.location.href = text;
        }

        this.triggerAnalyticEvents("toCheckout")
    }

    notify = (type) => {
        if(type == "agregoACatalogo"){
            toast.success('¡Excelente! Se agregó el producto a tu catálogo.');
        }
        else if(type == "yaExiste"){
            toast.error('No te apresures ;). Este producto ya está en tu catálogo.');
        }
        else if(type == "precioMenorAOriginal"){
            toast.error('¡Uy! No puedes hacer eso, tu ganancia es negativa.');
        }
        else if(type == "copioInfoProducto"){
            toast.success('¡Eso es todo! Se copió la info del producto.');
        }
        else if("chatUnavailable"){
            toast.error("¡Atento! Sólo los afiliados pueden hablar con otros proveedores.");
        }
    }

    chat = async (sNegocioID, sVendedorID) => {
        //Obtiene chats de este afiliado
        //Gets Chats of this Afiliado
        const filter = "aUserIDs:" + sVendedorID; 
        const chats = await indexChats.search("", {filters: filter, hitsPerPage: 100});

        //Si el ID del negocio se encuentra en uno de los chats, entonces no llamar función de add chat
        //Si el ID no se encuentra, entonces llamar función de addChat
        const tieneChat = chats.hits.filter((chat) => {
            if (chat.aUserIDs.includes(sNegocioID)) return chat;
        });

        if(tieneChat.length === 0){
            this.props.addChat(sNegocioID, sVendedorID);
        }
    }

    //Función que verifica si el producto ya está en tu catálogo
    addProductCatalogo = async (sCatalogoID, sProductoID, nIncremento, precioBase) => {
        
        const productosEnCatalogo = await indexProductosEnCatalogo.search(sCatalogoID, {filters: 'sProductoID:' + sProductoID, hitsPerPage: 200});
        const tieneCatalogo = productosEnCatalogo.hits.filter((producto) => {
            if (producto.bActivo === true) return producto;
        });

        if(precioBase < this.state.product.precio){
            this.notify("precioMenorAOriginal");
        }
        else if(tieneCatalogo.length > 0){
            this.notify("yaExiste");
        }
        else{
            this.props.addProductToCatalogo(sCatalogoID, sProductoID, nIncremento);
            this.notify("agregoACatalogo");
            this.triggerAnalyticEvents("addToCatalogo")
        }
    }

    async componentDidMount(){
        
        window.scrollTo(0, 0)

        const {productID, auth} = this.props;

        
        
        if(productID && auth){
            this.addViewAnalytics(productID);

            const product = await indexProductos.getObject(productID); 
            const negocio = await indexUsuarios.getObject(product.negocio_id);
            const reseñas = await indexResenas.search(productID, {filters: 'esVistoPor:afiliados', hitsPerPage: 50});
            const catalogos = await indexCatalogos.search(auth.uid, {filters: 'bActivo: true', hitsPerPage: 50});
            this.setState({
                product: product,
                reseñas: reseñas.hits.slice(0, 5),
                reseñasTotal: reseñas.hits,
                catalogos: catalogos.hits,
                negocio: negocio
            });
        }
    }

    render(){
        const {auth, profile} = this.props;
        const cantidades = [];
        const product = this.state.product;
        const reseñas = this.state.reseñas;
        const reseñasTotal = this.state.reseñasTotal;
        const negocio = this.state.negocio;
        
        if(product){
            //DEFINIMOS EL PRECIO FINAL
            let precioBase;
            let gananciaExtra;
            //Si se editó el precio
            if(this.state.precio != ""){
                precioBase = Number(this.state.precio);
                gananciaExtra = Number(this.state.precio) - Number(product.precio);
            }
            else{
                precioBase = Number(product.precio);
                gananciaExtra = 0;
            }
            let costoDeEnvio = Number(product.costoDeEnvio);
            let comisionDelVendedor =  Math.round((product.comision * 0.01 * Number(product.precio)) + 20);
            let comisionDelVendedorConExtra = comisionDelVendedor + gananciaExtra;
            let costoPorProcesarPorcentaje = 0.04;
            let precioFinal = Math.round((precioBase + costoDeEnvio + comisionDelVendedor) / (1 - costoPorProcesarPorcentaje));
            let costoPorProcesarCantidad = Math.round(precioFinal * costoPorProcesarPorcentaje);
            
    
            {/* Liga para Comprador, sólo se despliega si el usuario está signed in y es un vendedor */}
            let liga_para_comprador_title;
            if(window.innerWidth >= 600){
                liga_para_comprador_title = (
                    <h6 className="productDetails">Si tu Cliente está Listo para Comprar el Producto:</h6>
                );
                
            }
            else{
                liga_para_comprador_title = (
                    <h4 className="productDetails">Si tu Cliente está Listo para Comprar el Producto:</h4>
                );
            }

            let liga_para_comprador;
            if(auth.uid && profile.Nombre_de_Negocio === ''){
                liga_para_comprador = (
                    <div>
                        {liga_para_comprador_title}
                        <h6 id="copy-link" onClick={() => this.toCheckout(precioFinal, precioBase, comisionDelVendedor)} className="btn btn-primary mt-1">
                            Generar Pedido
                            <i class="bi bi-arrow-up-right-circle-fill ms-2"></i>
                        </h6>
                    </div>
                );
            }else if(!auth.uid){
                liga_para_comprador = (
                    <div>
                        {liga_para_comprador_title}
                        <h6 className="landingTextEnfasis">Necesitas ser un afiliado para ver esto.</h6>
                    </div>
                )
            }else{
                liga_para_comprador = (
                    null
                )
            }

            //CANTIDADES DISPONIBLES
            let cantMax = 0;

            for(let i = 0; i < product.cantidades.length; i++){
                if(product.cantidades[i] > cantMax)
                    cantMax = product.cantidades[i];
            }

            for(let i = 0; i < cantMax; i++){
                cantidades.push(i);
            }

            const cantidadOpciones = cantidades.map(i => {
                return (
                    <option value={i}>{i}</option>
                );
            })

            let cantidad;
            cantidad = (
                <div className="mt-3">
                    <h6 className="addProductFormLabel">Cantidad Disponible: {cantMax}</h6>
                </div>
            );

            
            let variaciones;
            if(product.variaciones[0] != '' && product.variaciones[0]){
                const variacionesOpciones = product.variaciones.map((valor, idx) => {
                        return (
                            <option value={idx}>{valor}</option>
                        );
                    })

                variaciones = (
                    <div className="col-6 mt-3">
                        <label for="variaciones" className="form-label addProductFormLabel">Opciones Disponibles</label>
                        <select onChange={this.handleChange} id="variaciones" className="form-select">
                            {variacionesOpciones}
                        </select>
                        
                    </div>
                    
                );   
            }
            else{
                variaciones = (
                    null
                );
            }

            //SE PUEDE PERSONALIZAR
            let personalizado;
            if(product.personalizado === '1'){
                personalizado = (
                    <h6 className="mt-3 addProductFormLabel">Personalizable</h6>
                );
            }
            else{
                personalizado = (
                null
                );
            }

            let precioMayoreo;
            if(product.precioMayoreo != "" && product.cantMinMayoreo != ""){
                
                let precioFinalMayoreo = Math.round((Number(product.precioMayoreo) + Number(product.costoDeEnvio))*1.04);
                
                precioMayoreo = (
                    <div className="mt-3">
                        <h6 className="addProductFormLabel">Precio de Mayoreo en Ordenes > {product.cantMinMayoreo} Unidades: ${precioFinalMayoreo}</h6>
                    </div>
                );
            }else{
                precioMayoreo = (
                    null
                );
            }

            const imagenesDeProducto = product.imagenesDeProducto.slice(0, 6).map(imagenDeProducto => {
                return(
                    <img onClick={this.handleChangeImage} id={product.imagenesDeProducto.indexOf(imagenDeProducto)} src={imagenDeProducto} alt="" className="productImages me-3"/>  
                );
            })
            
            let metodosDePagoTitle;
            if(window.innerWidth >= 600){
                metodosDePagoTitle = (
                    <h5 className="productDetails">Métodos de Pago</h5>
                );
            }
            else{
                metodosDePagoTitle = (
                    <h4 className="productDetails">Métodos de Pago</h4>
                );
            }

            //Solo se acepta págo contra entrega si el precio final de la orden es menor a 8,000 pesos
            let metodosDePago;
            const tiendasDeImpuls = ["0tskUsErRuVle7Xu8r7KOntoHLl2", "360tkOXiWdXFzI4woedRelbIuUo2", "BLl34HKMc1bcawzMHaALPqheuzx2", "XbHVCU07Wfh00uoa0dN8UZq7fn92", "agwthEEidxYcYIiIfM8P6Bmt8kp2", "hYcxg5jQDCb0mPh75Z05DwClAl23", "t8oLxehnOgNV2x3YO1mWaozlAAt1", "xRuldfzhFPVZsBpF9CDN1PQZT4K3"];
            const productoDeTiendaDeImpuls = tiendasDeImpuls.includes(product.negocio_id);
            if(this.state.negocio.direccionDeRecoleccion && precioFinal < 8000 && !productoDeTiendaDeImpuls){
                metodosDePago = (
                    <div className="mt-4 mb-4">
                        {metodosDePagoTitle}
                        <div className="mt-3 mt-md-0">
                            <p>Tarjetas de Débito y Crédito</p>
                            <div className="d-flex mb-4">
                                <img src="/img/AmericanExpress.png" alt="" className="paymentIcons"/>
                                <img src="/img/Mastercard.png" alt="" className="paymentIcons ms-2"/>
                                <img src="/img/visa.png" alt="" className="paymentIcons ms-2"/>
                            </div>
                        </div>
                        <div>
                            <p>Pago Contra Entrega</p>
                        </div>
                    </div> 
                );
            }
            else if(this.state.negocio.direccionDeRecoleccion){
                metodosDePago = (
                    <div className="mt-4 mb-4">
                        {metodosDePagoTitle}
                        <div className="mt-3 mt-md-0">
                            <p>Tarjetas de Débito y Crédito</p>
                            <div className="d-flex mb-4">
                                <img src="/img/AmericanExpress.png" alt="" className="paymentIcons"/>
                                <img src="/img/Mastercard.png" alt="" className="paymentIcons ms-2"/>
                                <img src="/img/visa.png" alt="" className="paymentIcons ms-2"/>
                            </div>
                        </div>
                    </div> 
                );
            }
            else{
                metodosDePago = (
                    <div className="mt-4 mb-4">
                        {metodosDePagoTitle}
                    </div> 
                );
            }
            

            let agregaCatalogo;
            if(window.innerWidth >= 600 && profile.Nombre_de_Negocio === ''){
                agregaCatalogo = (
                    <div clasName="mt-3">
                        <h6 className="productDetails">Agrega el Producto a tu Catálogo:</h6>
                        <h6 id="copy-link" data-bs-toggle="modal" data-bs-target="#agregarProductoACatalogoModal" className="btn btn-primary mt-1">
                            <i class="bi bi-box-arrow-up-right"></i>
                        </h6>
                    </div>
                );
            }
            else if(profile.Nombre_de_Negocio === ''){
                agregaCatalogo = (
                    <div clasName="mt-3">
                        <h4 className="productDetails">Agrega el Producto a tu Catálogo:</h4>
                        <h6 id="copy-link" data-bs-toggle="modal" data-bs-target="#agregarProductoACatalogoModal" className="btn btn-primary mt-1">
                            <i class="bi bi-box-arrow-up-right me-2"></i>
                                Agregar
                        </h6>
                    </div>
                );
            }
            else{
                agregaCatalogo = (
                    null
                );
            }

            let catalogos;
            if(this.state.catalogos.length > 0){
                catalogos = (
                    this.state.catalogos.map((catalogo, idx) => {
                        return (
                            <option value={catalogo.objectID}>{catalogo.sNombre}</option>
                        )
                    })
                );
            }
            else{
                catalogos = (
                    <div>
                        <p>No tienes catálogos. Crea uno para poder agregar este producto.</p>
                    </div>
                );
            }
            
            let formattedProductDescription;
            formattedProductDescription = (
                product.descripcion.split("\n").map((line, idx) => {
                    return(
                        <p className="">{line}</p>
                    )
                })
            );

            let chatBtn;
            if(profile.Nombre_de_Negocio !== ''){
                chatBtn = (
                    <button onClick={() => this.notify("chatUnavailable")} className="btn btn-primary">Chatea</button>
                )
            }
            else{
                chatBtn = (
                    <Link to={'/chats/' + product.negocio_id}>
                        <button onClick={() => this.chat(product.negocio_id, auth.uid)} className="btn btn-primary">Chatea</button>
                    </Link>
                )
            }
            
            let contentToDisplay;
            if(window.innerWidth >= 600 && this.state.content == 0){
                contentToDisplay = (
                    <div>
                        {/* Checkout */}
                        <div className="row mt-3 checkoutBackground">
                            {/* ProductImage */}
                            <div className="col-md-4">
                                <div className="graficMaterial">
                                    <img src={product.imagenesDeProducto[this.state.index]} alt="" className="productImageActive imageProductGraficMaterial"/>
                                    <div className="downloadGraficMaterial">
                                        <i onClick={this.downloadOnlyImage} id={product.imagenesDeProducto[this.state.index]} className="bi bi-download downloadIcon"></i>  
                                    </div>
                                </div>
                                <div className="productImagesMobile mt-3">
                                    {imagenesDeProducto}
                                </div>
                            </div>

                            <div className="col-md-4 productInfo">
                                {/* Social Proof #1 */}
                                <div className="mt-1">
                                    <h6 className="socialProof1">Nuevo</h6>
                                </div>

                                {/* Product Name */}
                                <div className="mt-1">
                                    <h2 className="productName">{product.nombre}</h2>
                                </div>
                            

                                {/* Rating */}
                                <div className="rating mt-1">
                                    <i className="bi bi-star-fill me-2"></i>
                                    <i className="bi bi-star-fill me-2"></i>
                                    <i className="bi bi-star-fill me-2"></i>
                                    <i className="bi bi-star-fill me-2"></i>
                                </div>

                                {/* Commision */}
                                <div className="mt-3">
                                    <h6 className="btn btn-primary comisionProduct">Tú ganas: ${comisionDelVendedorConExtra}</h6>
                                </div>
                                    
                                {/* Price */}
                                <div className="mt-2 d-flex">
                                    <h3 className="productPrice">MXN ${precioFinal}</h3>
                                    <a href="" className="ajustesIconColor" data-bs-toggle="modal" data-bs-target="#modalEditPrice"><i className="bi bi-pencil-fill ms-3 editIcon"></i></a>
                                </div>

                                {/* Precio de Mayoreo */}
                                {precioMayoreo}

                                {/* Cantidad */}
                                {cantidad}

                                {/* Extra */}
                                <form>
                                    {variaciones}
                                    {personalizado}
                                </form>

                                {/* Descargar Fotos y Info*/}
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <div>
                                            <p onClick={() => this.download(product.imagenesDeProducto)} className="btn btn-fotos-PD mt-1 me-3">
                                                <i class="bi bi-download"></i>
                                            </p>
                                            <p className="info">Fotos</p>
                                        </div>
                                        
                                        <div>
                                            <p onClick={() => this.copyInfo(precioFinal, product.nombre, product.descripcion)} className="btn btn-info-PD mt-1 me-3">
                                                <i class="bi bi-files"></i>    
                                            </p>
                                            <p className="info">Info</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Info */}
                            <div className="col-md-3 orderInfo">
                                {/* Info */}
                                <div className="mt-3">
                                    <p className="productStatus"><i class="bi bi-truck me-2 mt-2"></i>Envío gratis a todo el país</p>
                                    <p><i class="bi bi-hourglass-split me-2 mt-2"></i>Entrega en 5 - 7 días hábiles</p>
                                    <Link to={'/store/' + product.negocio_id} className="storeLink mt-2">
                                        <p className="linkToPage">Tienda Oficial de {product.negocio}</p>
                                    </Link>
                                    <Link to="/cambios-y-devoluciones" className="linkToPage"><i class="bi bi-arrow-left-right me-2 mt-3"></i>Consulta Política de Cambios y Devoluciones</Link>
                                </div>
                                
                                {/* Agregar a Catalogo */}
                                <div className="mt-3">
                                    {agregaCatalogo}
                                </div>
                                
                                {/* Enlace */}
                                <div className="mt-3">
                                    {liga_para_comprador}
                                </div>

                                {/* Métodos de Pago */}
                                {metodosDePago}
                            </div>

                            {/* Divider Line */}
                            <div className="col-8">
                                <div className="divider"></div>
                            </div>

                            {/* Chatea */}
                            <div className="mt-5 d-flex">
                                {/* Logo */}
                                <img src={(negocio.Logo && negocio.Logo[0]) !== '' ? negocio.Logo[0]: "/img/no-store.png"} className="chatBusinessLogoContainer"/>

                                {/* Chat */}
                                <div className="ms-3">
                                    <p className="chatBusinessTitle">{product.negocio}</p>
                                    {chatBtn}
                                </div>
                            </div>

                            {/* Divider Line */}
                            <div className="col-8 mt-5">
                                <div className="divider"></div>
                            </div>

                            {/* Description */}
                            <div className="col-8 mt-5">
                                <h4 className="material-titulo">Descripción</h4>
                                <div className="productDetailsPoints mt-3">
                                    <p className="justify-text">{formattedProductDescription}</p>
                                </div>
                            </div>

                            
                            {/* Divider Line */}
                            <div className="col-8 mt-5">
                                <div className="divider"></div>
                            </div>

                            {/* Reviews */}
                            <div className="row mt-5"> 
                                <Reseñas product={product} reseñas={reseñas} reseñasTotal={reseñasTotal} productoID={this.props.productID} vendedorID={auth.uid} location="productDetails" />
                            </div>
                        </div>
                    </div> 
                );
            }else if(this.state.content == 0){
                contentToDisplay = (
                    <div>
                        {/* topBeforeImage */}
                        <div className="mt-3">
                            {/* Social Proof #1 */}
                            <div>
                                <p className="socialProof1">Nuevo</p>
                            </div>

                            {/* Product Name */}
                            <div>
                                <h4 className="productName">{product.nombre}</h4>
                            </div>       

                            {/* Rating */}
                            <div className="rating mt-1">
                                <i className="bi bi-star-fill me-2"></i>
                                <i className="bi bi-star-fill me-2"></i>
                                <i className="bi bi-star-fill me-2"></i>
                                <i className="bi bi-star-fill me-2"></i>
                            </div>

                            {/* Tag */}
                            <div className="mt-2">
                                <p className="btn btn-primaryMobile noHover">Más Vendidos</p>
                            </div>
                        </div>

                        {/* ProductImage */}
                        <div className="mt-3">
                            <div className="graficMaterial">
                                <img src={product.imagenesDeProducto[this.state.index]} alt="" className="productImageActive imageProductGraficMaterial"/>
                                <div className="downloadGraficMaterial">
                                    <i onClick={this.downloadOnlyImage} id={product.imagenesDeProducto[this.state.index]} className="bi bi-download downloadIcon"></i>  
                                </div>
                            </div>
                            <div className="productImagesMobile mt-3">
                                {imagenesDeProducto}
                            </div>
                        </div>

                        

                        {/* Información de Compra */}
                        <div className="mt-3">
                            
                            {/* Commision */}
                            <div className="mt-3">
                                <h6 className="btn btn-primary comisionProduct">Tú ganas: ${comisionDelVendedorConExtra}</h6>
                            </div>
                                
                            {/* Price */}
                            <div className="mt-3 d-flex">
                                <h3 className="productPriceCheckout">MXN ${precioFinal}</h3>
                                <a href="" className="ajustesIconColor" data-bs-toggle="modal" data-bs-target="#modalEditPrice"><i className="bi bi-pencil-fill ms-3 editIcon"></i></a>
                            </div>

                            {/* Precio de Mayoreo */}
                            <div className="mt-3">
                                {precioMayoreo}
                            </div>
                            
                            {/* Cantidad */}
                            <div className="mt-3">
                                {cantidad}
                            </div>

                            {/* Extra */}
                            <form>
                                {variaciones}
                                {personalizado}
                            </form>

                            {/* Envio Gratis */}
                            <div className="mb-4">
                                <p className="productStatus mt-3"><i class="bi bi-truck me-2"></i>Envío gratis a todo el país</p>
                                <p className="mt-1"><i class="bi bi-hourglass-split me-2"></i>Entrega en 5 - 7 días hábiles</p>
                                <Link to={'/store/' + product.negocio_id} className="storeLink mt-2">
                                    <p className="linkToPage">Tienda Oficial de {product.negocio}</p>
                                </Link>
                                <Link to="/cambios-y-devoluciones" className="linkToPage"><i class="bi bi-arrow-left-right me-2 mt-3"></i>Consulta Política de Cambios y Devoluciones</Link>
                            </div>
                        </div>

                        {/* Divider */}
                        <div className="">
                            <div className="divider"></div>
                        </div>
                        
                        {/* Descargar Fotos y Info*/}
                        <div className="mt-4 mb-2">
                            <h4 className="productDetails">Descargar Fotos y Copiar Info</h4>
                            <div className="d-flex mt-3">
                                <p onClick={() => this.download(product.imagenesDeProducto)} className="btn btn-fotos-PD mt-1 me-3">
                                    <i class="bi bi-download"></i>
                                </p>
                                <p onClick={() => this.copyInfo(precioFinal, product.nombre, product.descripcion)} className="btn btn-info-PD mt-1 me-3">
                                    <i class="bi bi-files"></i>    
                                </p>
                            </div>
                        </div>

                        {/* Divider */}
                        <div className="">
                            <div className="divider"></div>
                        </div>

                        {/* Chatea */}
                        <div className="mt-4 mb-4 d-flex">
                            {/* Logo */}
                            <img src={(negocio.Logo && negocio.Logo[0]) !== '' ? negocio.Logo[0]: "/img/no-store.png"} className="chatBusinessLogoContainer"/>

                            {/* Chat */}
                            <div className="ms-3">
                                <p className="chatBusinessTitle">{product.negocio}</p>
                                {chatBtn}
                            </div>
                        </div>

                        {/* Divider */}
                        <div className="">
                            <div className="divider"></div>
                        </div>


                        {/* Description */}
                        <div className="mt-4 mb-4">
                            <h4 className="productDetails">Descripción</h4>
                            <div className="productDetailsPoints mt-3">
                                <p>{formattedProductDescription}</p>
                            </div>
                        </div>

                        {/* Divider */}
                        <div className="">
                            <div className="divider"></div>
                        </div>

                        {/* Agregar a Catalogo */}
                        <div className="mt-4">
                            {agregaCatalogo}
                        </div>
                        

                        {/* Enlace */}
                        <div className="mt-4 mb-4 ">
                            {liga_para_comprador}
                        </div>

                        {/* Divider */}
                        <div className="">
                            <div className="divider"></div>
                        </div>

                        {/* Métodos de Pago */}
                        <div className="mt-4 mb-4">
                            {metodosDePago}
                        </div>

                        {/* Divider Line */}
                        <div className="">
                            <div className="divider"></div>
                        </div>

                        {/* Reviews */}
                        <div className="row mt-4 mb-4"> 
                            <Reseñas product={product} reseñas={reseñas} reseñasTotal={reseñasTotal} productoID={this.props.productID} vendedorID={auth.uid} location="productDetails" />
                        </div>
                    </div>

                );
            }
            else{
                contentToDisplay = (
                    <LandingEnvios/>
                );
            }

            let agregarProductoACatalogoModal = (
                <div className="modal fade" id="agregarProductoACatalogoModal" tabindex="-1" aria-labelledby="agregarProductoACatalogoModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" id="agregarProductoACatalogoModal">Mis Catalogos</h3>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <label for="sCatalogoID" className="form-label addProductFormLabel">Selecciona el catálogo donde quieres agregar este producto</label>
                                    <select onChange={this.handleChange} id="sCatalogoID" className="form-select" aria-label="No">
                                        <option selected>Catalogos</option>
                                        {catalogos} 
                                    </select>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-grey" aria-label="Close" data-bs-dismiss="modal">Cerrar</button>
                                <button onClick={() => this.addProductCatalogo(this.state.sCatalogoID, product.objectID, gananciaExtra, precioBase)} type="button" className="btn btn-primary" data-bs-dismiss="modal">Agregar</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            
            return(
                <div className="container-fluid custom-container footer-spacing">
                    {/* Dynamic Description*/}
                    <Helmet>
                        <title>{product.nombre}</title>
                        <meta name="title" content={product.nombre} />
                        {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta> */}
                    </Helmet>

                    {/* Whats App Btn */}
                    <div>
                        <a href="https://api.whatsapp.com/send?phone=+524438011119&text=Hola, buenos días" className="floating" target="_blank">
                            <i className="bi bi-whatsapp float-button"></i>
                        </a>
                    </div>

                    <div>
                        {/*Modal Para Editar Precio de Producto*/}
                        <div class="modal fade" id="modalEditPrice" tabindex="-1" aria-labelledby="editPrice" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="editPrice">Edita el Precio Base del Producto</h5>
                                </div>
                                <div class="modal-body">
                                <p>Edita el precio base del producto y aumenta tu ganancia. ¡La diferencia del precio base original y el nuevo será tu ganancia extra!</p>
                                <form>
                                    <div class="mb-3">
                                        {/* Precio Incluye Comisión de Pago*/}
                                        <label for="precio" className="col-form-label">Precio Base del Producto: ${precioBase}</label> 
                                        <input onChange={this.handleChange} type="text" placeholder="Precio Nuevo" className="form-control" id="precio"/>
                                    </div>
                                    <div class="mb-2">                                    
                                        <p>Costo de Envio: ${Number(product.costoDeEnvio)}</p>
                                    </div>
                                    <div class="mb-2">                                    
                                        <p>Costo por Procesar Transacción: ${costoPorProcesarCantidad}</p>
                                    </div>
                                    <div>     
                                        {/* Si se cambia el precio, el extra se considera sobre el precio sin la comisión de transacción, ya que esta es sólo de Kanguru*/}                               
                                        {/* <label for="precio" className="col-form-label">Tú Ganas: ${comisionDelVendedor}</label> */}
                                        <p>Tu Ganancia ${comisionDelVendedorConExtra}</p>
                                        <p>Precio Final: ${precioFinal}</p>
                                    </div>
                                </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Guardar Cambios</button>
                                </div>
                                </div>
                            </div>
                        </div>
                        {/* Notifications Toast*/}
                        <Toaster 
                            toastOptions={{
                                duration: 2000,
                                position: 'top-center',
                            }}
                        />

                        {agregarProductoACatalogoModal}
                        {contentToDisplay}
                    </div>
                </div>
            );
        }
        else{
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const productID = ownProps.match.params.id; //id of product
    const auth = state.firebase.auth;

    return{
        productID: productID,
        auth: auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addProductToCatalogo: (sCatalogoID, sProductoID, nIncremento) => dispatch(addProductToCatalogo(sCatalogoID, sProductoID, nIncremento)),
        addChat: (sNegocioID, sVendedorID) => dispatch(addChat(sNegocioID, sVendedorID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);









