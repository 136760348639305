import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import './HomeMobileVendedor.css';
import '../../../App.css';
import ProductList from '../../products/ProductList';
import ProductSummary from '../../products/ProductSummary';

const HomeMobileVendedor = (props) => {
   
    const { bestSellers, trending, productoEstrella, productosNuevos} = props;
    const [bestSellersVisibles, setBestSellersVisibles] = useState(1);
    const [trendingVisibles, setTrendingVisibles] = useState(1);
    const [productosNuevosVisibles, setProductosNuevosVisibles] = useState(1);
    const history = useHistory();

    //Redirije al usuario al seleccionar algo del carrousel
    const carrouselClick = (e, termino) => {
        history.replace(`/?s=${termino}`)
        e.preventDefault()
    };


    //LOGICA PARA MOSTRAR EXPLORAR MAS/MENOS
    //1 es igual a 4 productos visibles; 0 es igual a todos.
    let showBestSellers;
    if(bestSellersVisibles === 1){
        showBestSellers = (
            <p className="explorar-ocultar" onClick={() => setBestSellersVisibles(0)}>Explorar Más</p>
        )
    }
    else{
        showBestSellers = (
            <p className="explorar-ocultar" onClick={() => setBestSellersVisibles(1)}>Mostrar Menos</p>
        )
    }
    
    let showTrending;
    if(trendingVisibles === 1){
        showTrending = (
            <p className="explorar-ocultar" onClick={() => setTrendingVisibles(0)}>Explorar Más</p>
        )
    }
    else{
        showTrending = (
            <p className="explorar-ocultar" onClick={() => setTrendingVisibles(1)}>Mostrar Menos</p>
        )
    }

    let showProductosNuevos;
    if(productosNuevosVisibles === 1){
        showProductosNuevos = (
            <p className="explorar-ocultar" onClick={() => setProductosNuevosVisibles(0)}>Explorar Más</p>
        )
    }
    else{
        showProductosNuevos = (
            <p className="explorar-ocultar" onClick={() => setProductosNuevosVisibles(1)}>Mostrar Menos</p>
        )
    }

    return(
        <div className="home-mobile">
            
            {/* Whats App Btn */}
            <div>
                <a href="https://api.whatsapp.com/send?phone=+524438011119&text=Hola, buenos días" className="floating" target="_blank">
                    <i className="bi bi-whatsapp float-button"></i>
                </a>
            </div>
            
            {/* Slide show of ads */}
            <div className="container-fluid mt-4">
                <Link to="/catalogos" className="banner-home-mobile d-flex">
                    <p className="banner-home-mobile-text" id="crearCatalogo">
                        ¿Ya creaste tu catálogo? 
                        <br></br> 
                        <span className="bold">¡Crea tu tienda en línea ahora!</span>
                    </p>
                    <i className="bi bi-chevron-right banner-home-mobile-icon"></i>
                </Link>
            </div>
            <div className="container-fluid">

                {/* Aprende a Vender */}
                <div className="section">
                    <p className="section-title">Aprende a Utilizar Kanguru</p>
                    
                    <div className="scroll-images">
                        {/* Cómo Funciona Kanguru */}
                        <Link to="/como-funciona">
                            <img src="img/como_funciona_kanguru.png"  className="img-scroll-section" alt="..."/>
                        </Link>

                        {/* Cómo Levantar un pedido */}
                        <a href="https://www.youtube.com/watch?v=urA7egjjwp8&t=15s" target="_blank">
                            <img src="img/como_hacer_pedido.png"  className="img-scroll-section" alt="..."/>
                        </a>

                        {/* Cómo Vender en Marketplace */}
                        <a href="https://www.youtube.com/watch?v=E1aGIQfmZDM&t=2s" target="_blank">
                            <img src="img/vende_marketplace.png"  className="img-scroll-section" alt="..."/>
                        </a>

                        {/* SERIE DE 0 A GURU */}
                        <a href="https://www.youtube.com/playlist?list=PLtA0CI7TNyFTXoCrv8GN0V8H5PckbOGBr" target="_blank">
                            <img src="img/serie-0-guru.png" className="img-scroll-section" alt="..."/>
                        </a>

                        {/* Taller con Experto */}
                        <a href="https://www.youtube.com/watch?v=EIlBitqJ1tc&t=1550s" target="_blank">
                            <img src="img/Taller_Experto.png"  className="img-scroll-section" alt="..."/>
                        </a>
                    </div>
                </div>

               
                {/* Promociones */}
                <div className="section">
                    <p className="section-title">Promociones</p>

                    <div className="scroll-images">
                        {/* <a href="https://www.youtube.com/watch?v=E2VgoZ3VU3o&t=9s" target="_blank">
                            <img src="img/Publicidad_Marketplace.png"  className="img-scroll-section" alt="..."/>
                        </a> */}
                        <Link to="/programa-de-referidos">
                            <img src="img/plan_referidos.png"  className="img-scroll-section" alt="..."/>
                        </Link>
                        <Link to="/reto-semanal">
                            <img src="img/reto_semanal_home_mobile.png"  className="img-scroll-section" alt="..."/>
                        </Link>
                    </div>
                </div>

                {/* Herramientas */}
                <div className="section">
                    <p className="section-title">Súper herramientas</p>
                    
                    <div className="scroll-images">

                        {/* Grupo de Whats app */}
                        <a href="https://chat.whatsapp.com/ENVJExa5kiP5MXJW8HljQk" target="_blank">
                            <img src="img/unete-al-grupo.png"  className="img-scroll-section" alt="..."/>
                        </a>

                        {/* Canal de Youtube */}
                        <a href="https://www.youtube.com/channel/UCqYbGgZB1vaD0f950RJZUmA" target="_blank">
                            <img src="img/canal_youtube.png"  className="img-scroll-section" alt="..."/>
                        </a>

                        {/* Cobertura */}
                        <Link to="/cobertura">
                            <img src="img/Cobertura.png"  className="img-scroll-section" alt="..."/>
                        </Link>

                        {/* Ayuda Ingresando una Dirección */}
                        <a href="https://www.youtube.com/channel/UCqYbGgZB1vaD0f950RJZUmA/featured" target="_blank">
                            <img src="img/ayuda_direccion.png"  className="img-scroll-section" alt="..."/>
                        </a>

                        {/* Fotos Reales */}
                        <a href="https://drive.google.com/drive/folders/1sJaV1nBAnF9UEX6f5ifyWQcFzVGP4A07?usp=sharing" target="_blank">
                            <img src="img/fotos_reales.png"  className="img-scroll-section" alt="..."/>
                        </a>

                        {/* Respuestas Automáticas */}
                        <a href="https://docs.google.com/document/d/1vIDXu7m3n4H-_Lw79uc2tWd18bYayEwcxrW2Z5haWEA/edit?usp=sharing" target="_blank">
                            <img src="img/auto_respuestas.png"  className="img-scroll-section" alt="..."/>
                        </a>

                        {/* Productos Actualizados */}
                        <Link to="/productos-actualizados">
                            <img src="img/productos_actualizados.png"  className="img-scroll-section" alt="..."/>
                        </Link>
                    </div>
                </div>

                {/* Más Vendidos */}
                <div className="section section-products">
                    <div>
                        <p className="section-title">Los Más Vendidos</p>
                        <ProductList numero={bestSellersVisibles} products={bestSellers} filter={""} mobile={true} catalogo={false}/>
                    </div>
                    <div className="exporar-mas">
                        {showBestSellers}
                    </div>
                </div>

                {/* Descubre artículos de cocina */}
                <Link to="/categoria/cocina">
                    <img src="img/mejores_productos_cocina.png"  className="mejores-productos" alt="..."/>
                </Link>

                {/* Producto Estrella */}
                <div className="section section-products">
                    <p className="section-title">Nuestro Producto Estrella</p>
                    <div className="producto-estrella">
                        <ProductSummary product={productoEstrella}/>
                    </div>
                </div>

                {/* Productos Nuevos */}
                <div className="section section-products">
                    <p className="section-title">Nuevos Productos</p>
                    <ProductList numero={productosNuevosVisibles} products={productosNuevos} filter={""} mobile={true} catalogo={false}/> 
                    <div className="exporar-mas">
                        {showProductosNuevos}
                    </div>
                </div>

                {/* Descubre artículos del hogar */}
                <Link to="/categoria/hogar">
                    <img src="img/mejores_productos_hogar.png"  className="mejores-productos" alt="..."/>
                </Link>
                
                {/* Trending */}
                <div className="section section-products">
                    <p className="section-title">Lo Más Trending </p>
                    <ProductList numero={trendingVisibles} products={trending} filter={""} mobile={true} catalogo={false}/> 
                    <div className="exporar-mas">
                        {showTrending}
                    </div>
                </div>
            </div>
        </div>
   );
    
}

export default HomeMobileVendedor