import React, { Component } from 'react'
import {connect} from 'react-redux';
import {signUp} from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

class SignUpNegocio extends Component {
  state = {
    nombre_de_negocio: '',
    nombre_de_vendedor: '',
    telefono: '+52',
    codigoReferidoExterno: '',
    codigoReferidoPersonal: '',
    email: '',
    password: '',
    aceptoTC: false,
    aceptoPrivacidad: false
  }

  

  notify = (type) => {
    if(type == "nombreDeNegocioInvalido"){
      toast.error('El nombre de tu marca es inválido.');
    }
    else if(type == "telefonoInvalido"){
        toast.error('Necesitas agregar un número de teléfono válido. Asegúrate de incluir +52');
    }
    else if(type == "noAceptoTC"){
        toast.error('Necesitas aceptar los Términos y Condiciones para continuar.');
    }
    else if(type == "noAceptoPrivacidad"){
        toast.error('Necesitas aceptar las Políticas de Privacidad para continuar.');
    }
    else if(type == "contraseñaMenorA6"){
        toast.error('La contraseña debe ser de 6 o más cáracteres.');
    }
    else if(type == "emailInvalido"){
      toast.error('Tu correo es inválido.');
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.state.nombre_de_negocio == ""){
      this.notify("nombreDeNegocioInvalido");
    }
    else if(!this.state.telefono.match(/^\+52(\s*[0-9]\s*){10}\s*$/)){
      this.notify("telefonoInvalido");
    }
    else if(!this.state.aceptoTC){
      this.notify("noAceptoTC");
    }
    else if(!this.state.aceptoPrivacidad){
      this.notify("noAceptoPrivacidad");
    }
    else if(this.state.password.length < 6){
      this.notify("contraseñaMenorA6");
    }
    else if(this.state.email == "" || !this.state.email.indexOf("@")){
      this.notify("emailInvalido");
    }
    else{
      this.props.signUp(this.state);
      ReactPixel.track('CompleteRegistrationProveedor', {
        content_name: 'SignUpNegocio'
      })
    }
  }

  componentDidMount(){  
    window.scrollTo(0, 0);
  }

  render() {
    console.log(this.state.aceptoTC);
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to ='/'/>
      return (
        <div>
          {/* DYNAMIC META INFORMATION */}
          <Helmet>
              <title>Regístrate como Negocio</title>
              <meta name="description" content="¡Es Gratis! Regístrate como negocio y publica tus productos sin costo en Kanguru."/>
          </Helmet>

          {/* Notifications Toast*/}
          <Toaster 
              toastOptions={{
                  duration: 5000,
                  position: 'top-center',
              }}
          />


          {/* <h1 className="sign-up-title">KANGURU</h1> */}
          <div className="container position-relative start-50 translate-middle signUpForm1 row">
            {/* Titulo */}
            <h3 className = "text-black signUpTitle">Registrate Como Proveedor</h3>
            
            <form className="mt-4 col-11">
              {/* Nombre de Negocio */}
              <div className="mb-4">
                <label for="nombre_de_negocio" class="form-label">Nombre de tu Marca</label>
                <input onChange={this.handleChange} type="text" class="form-control" id="nombre_de_negocio"/>
              </div>
              {/* Email */}
              <div className="mb-4">
                <label for="email" class="form-label">Correo Electrónico</label>
                <input onChange={this.handleChange} type="email" class="form-control" id="email"/>
              </div>

              {/* Teléfono */}
              <div className="mb-4">
                <label for="email" class="form-label">Teléfono (+52 seguido por 10 números)</label>
                <input onChange={this.handleChange} value={this.state.telefono} type="text" class="form-control" id="telefono"/>
              </div>
                
              {/* Password */}
              <div className="mb-4">
                <label for="password" class="form-label">Contraseña</label>
                <input onChange={this.handleChange} type="password"  placeholder="6 Caracteres o Más" class="form-control" id="password"/>
              </div>

              {/* Submit Button */}
              <div className="">
                <button onClick={this.handleSubmit} type="button" class="btn btn-primary buttonSignUp">Crear Cuenta</button>
              </div>

              <div class="form-check mt-4">
                <div>
                  <input class="form-check-input" type="checkbox" value={true} id="aceptoTC" onClick={this.handleChange}/>
                  <label class="form-check-label" for="aceptoTC">
                    Acepto los <Link to="https://kanguru.mx/terminos-y-condiciones">Términos y Condiciones</Link>
                  </label>
                </div>
                
                <div className="mt-2">
                  <input class="form-check-input" type="checkbox" value={true} id="aceptoPrivacidad" onClick={this.handleChange}/>
                  <label class="form-check-label" for="aceptoPrivacidad">
                    Acepto las <Link to="/terminos-y-condiciones">Políticas de Privacidad</Link>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
        
      )
  } 
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpNegocio);