import React from 'react';
import '../../../../App.css';
import './LandingPageNegocio.css';
import ReactPixel from 'react-facebook-pixel';
import { Link } from 'react-router-dom';

const LandingNegocios = () => {

    ReactPixel.init('908315683105799', {}, { debug: true, autoConfig: false });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');

    return(
       <div className="landingPage-content">
            <div className="landingPage-section-1">
                <div className="row">
        
                    {/* Right Content */}
                    <div className="landing-section-1-left col-md-6">
                        <h1 className="landing-section-1-left-text signUpTitle">Aumenta las ventas<br></br> de tu negocio</h1>
                        <p className="landing-section-1-left-text-pg-proveedores">Te ofrecemos nuestra red de miles de vendedores para vender tus productos en todo México. </p>
                        <Link to="/solicitud-proveedor" className="btn btn-landing-section-1">Quiero ser proveedor</Link>
                    </div>

                    {/* Catch Image */}
                    <div className="landing-section-1-center-mobile col-md-6">
                        <img src="img/landing-1-proveedor-mobile.png" alt="" className="landing-section-1-center-img"/>
                    </div>

                    {/* Catch Image */}
                    <div className="landing-section-1-right col-md-6">
                        <img src="img/landing-1-proveedor-right.png" alt="" className="landing-section-1-right-img"/>
                    </div>
                </div>
                <div className="landing-section-1-center">
                    <h1 className="landing-section-1-center-text">Más de 2,000 vendedores para tu marca</h1>
                    <p className="landing-section-1-center-text-pg">Esto aumentará tus ventas</p>
                </div>
            </div>
            <div className="landingPage-section-2">
                <div className="row">
                    {/* Mobile Image */}
                    <div className="landing-section-2-center-image col-md-6">
                        <img src="img/landing-section-2.png" alt="" className="landing-section-2-center-img"/>
                    </div>

                    {/* Right Content */}
                    <div className="landing-section-2-left col-md-6">
                        <h1 className="landing-section-2-left-text">¿Cómo funciona?</h1>
                        <div className="landing-section-bulletpoints">
                            <div className="row landing-section-bulletpoints-row">
                                <div className="col-md-6 d-flex">
                                    <p className="landing-section-number">1</p>
                                    <p className="landing-section-bulletpoints-text">Publica tus productos</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <p className="landing-section-number">2</p>
                                    <p className="landing-section-bulletpoints-text">Los vendedores ofrezen tus producto y registran pedidos en la app</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 d-flex">
                                    <p className="landing-section-number">3</p>
                                    <p className="landing-section-bulletpoints-text">Gestionamos la recolección y entrega del pedido</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <p className="landing-section-number">4</p>
                                    <p className="landing-section-bulletpoints-text">Te depositamos tus ganancias</p>
                                </div>
                            </div>
                        </div>
                        <Link to="/solicitud-proveedor" className="btn btn-landing-section-1">Quiero ser proveedor</Link>
                        
                    </div>

                    {/* Catch Image */}
                    <div className="landing-section-2-right col-md-6">
                        <img src="img/landing-section-2.png" alt="" className="landing-section-2-right-img-proveedores"/>
                    </div>
                </div>
            </div>
            <div className="landingPage-section-3">
                <div className="row">
                    {/* Catch Image */}
                    <div className="landing-section-3-left col-md-6">
                        <img src="img/landing-section-3-img-proveedores.png" alt="" className="landing-section-3-left-img"/>
                    </div>

                    {/* Left Content */}
                    <div className="landing-section-3-right col-md-6">
                        <h1 className="landing-section-3-right-text">Crece tu negocio sin riesgo</h1>
                        <div className="landing-section-bulletpoints">
                            <div className="row landing-section-bulletpoints-row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Publicar tus productos <br className="no-display-mobile"></br> no tiene costo</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Sólo pagas una comisión al hacer <br></br> una venta</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Nosotros nos encargamos del envío y cobro</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Asesoramiento Personal</p>
                                </div>
                            </div>
                        </div>
                        <Link to="/solicitud-proveedor" className="btn btn-landing-section-1">Quiero ser proveedor</Link>
                    </div>
                </div>
            </div>
            {/* <div className="landingPage-section-5">
                Center Text
                <div className="landing-section-5-center">
                    <h1 className="landing-section-5-center-text">Testimonio</h1>
                </div>
                Testimony
                <div className="row">
                    Catch Image
                    <div className="landing-section-5-left col-md-6">
                        <iframe className="landing-section-5-left-video" frameborder="0" allowfullscreen
                            src="https://www.youtube.com/embed/g2aWyQGk32E">
                        </iframe>
                    </div>

                    Left Content
                    <div className="landing-section-5-right col-md-6">
                        <p className="landing-section-5-right-text">“Kanguru me ha permitido vender más <br></br>significativos de forma sencilla.”</p>
                        <p className="landing-section-5-right-text-name">Comercializadora Rodinsa</p>
                        <Link to="/solicitud-proveedor" className="btn btn-landing-section-1 right-btn">Quiero ser proveedor</Link>
                    </div>
                </div>
            </div> */}
            <div className="landingPage-section-5">
                {/* Center Text */}
                <div className="landing-section-5-center">
                    <h1 className="landing-section-5-center-text">Preguntas Frecuentes</h1>
                </div>
                {/* Preguntas Frecuentes */}
                <div className="landing-section-5-accordion">
                    <div className="accordion accordion-flush mt-5" id="accordionFlushPF">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                ¿Cómo me paga Kanguru?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Cada quincena. Los días corte son el 15 y 30 de cada mes, y los días de pago son el 16 y 1 de cada mes, respectivamente. Se paga sobre lo completado en el periodo.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    ¿Aparte de la comisión para el vendedor, qué comisión cobra Kanguru?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Nosotros tomamos una comisión fija del 7%. Ambas comisiones se cobran sobre el ingreso del producto. Por ejemplo, si tu producto tiene un precio de $100 pesos más $80 pesos de envío, las comisiones sólo se cobran sobre los $100 pesos.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    ¿Cómo elijo la comisión para el vendedor?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Al publicar un producto escoges qué porcentaje de comisión le ofreces al vendedor por vender tu producto.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    ¿Con qué paqueterías trabajan?
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">99 minutos, Estafeta, y Fedex.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    ¿Qué otros proveedores reconocidos trabajan con Kanguru?
                                </button>
                            </h2>
                            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Kocare Beauty, Myrva Shop, Just Click, y Comercializadora Rodinsa.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landingPage-section-6">
                {/* Center Text */}
                <div className="landing-section-6-center">
                    <h1 className="landing-section-6-center-text">¡Listo para Crecer tu Negocio!</h1>
                    <Link to="/solicitud-proveedor" className="btn btn-landing-section-6">Registrate Ahora</Link>
                </div>
            </div>
       </div>
    );
}

export default LandingNegocios;
