import React from 'react';
import RetiroSummary from './RetiroSummary';

{/* Despliega Lista de Ventas en CuentaVendedor */}
const RetirosList = (props) => {

    const {retiros} = props;

    if(retiros.length != 0){
        return(
            <div>
                <ul className="list-group">
                    {retiros && retiros.map(retiro => {
                        return (
                            <div key={retiro.objectID}>
                                <RetiroSummary retiro={retiro}/>
                            </div>  
                        )
                    })}
                </ul>
            </div> 
        )
    }
    else{
        return(
            <div className="noProducts">
                <h2>No hay retiros.</h2>
            </div>
        ); 
    }
    
}

export default RetirosList;