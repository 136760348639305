import React, { useState, useEffect } from 'react';
import axios from 'axios';


{/* Despliega Lista de Ventas en CuentaVendedor */}
const VentaSummaryVendedor = (props) => {

    const {venta, numero} = props;

    const [ordenCancelada, setOrdenCancelada] = useState(false);
    
    const cancelarOrden = (e) => {
        e.preventDefault();
        
        const cancelar = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/cancelOrder99Minutos", {
            params: {
                codigoRastreo: venta.codigoRastreo,
            }
        })
        .then((response) => {
            //Order is Cancelled
            console.log('cancelled');
            setOrdenCancelada(true);
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    // useEffect(() => {
    //     if(venta.codigoRastreo != "" && (venta.paqueteria === "99 Minutos" || venta.paqueteria === "99minutos") && !venta.entregado && !checkedStatus && venta.estatusDeOrden != "CANCELLED"){
    //         const account = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/getTrackingStatus99Minutos", {
    //             params: {
    //                 codigoRastreo: venta.codigoRastreo,
    //                 ventaID: venta.objectID
    //             }
    //         })
    //         .then((response) => {
    //             return setCheckedStatus(true);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         })
    //     }
    // }, []); 

    let entregado;
    let pagado;
    let estatusDeEnvio;
    let avisoCancelacion;
    let vendedorComisionClass;
    let estatusDeOrden;
    let cancelar;
   

    //COLOR CODE DE COMSION
    if(venta.pagado === true){
        vendedorComisionClass = "comisionVenta";
    }
    else{
        vendedorComisionClass = "comisionVentaNoPagada";
    }

    //MENSAJE DE ESTATUS DE ORDEN
    if(ordenCancelada || venta.estatusDeOrden === "CANCELLED" || venta.estatusDeOrden === "ON_ROAD_TO_RETURN" || venta.estatusDeOrden === "ONROADTORETURN" || venta.estatusDeOrden === "RETURNED"){
        estatusDeOrden = (
            <p className="canceladaEstatus">Cancelado <i class="bi bi-x-octagon-fill"></i></p>
        );
        avisoCancelacion = (
            <h6 className="redText">IMPORTANTE: Tu orden fue cancelada. Envíamos una notificación al email ingresado al generar el pedido.</h6>
       );
    }
    else if(venta.estatusDeOrden === "DELIVERED"){
        estatusDeOrden = (
            <p className="entregadaEstatus">¡Entregado! <i class="bi bi-patch-check-fill"></i></p>
        );
        avisoCancelacion = (
            null
        );
    }
    else if(venta.estatusDeOrden === "ON_ROAD_TO_DELIVERY" || venta.estatusDeOrden === "ONROADTODELIVERY"){
        estatusDeOrden = (
            <p className="enCaminoEstatus">¡En Camino, notifica a tu cliente!<i class="bi bi-truck ms-2"></i></p>
        );
        avisoCancelacion = (
            null
        );
    }
    else if(venta.estatusDeOrden === "LOST" || venta.estatusDeOrden === "STOLEN"){
        estatusDeOrden = (
            <p className="canceladaEstatus">Pérdida <i class="bi bi-x-octagon-fill"></i></p>
        );
        avisoCancelacion = (
            <h6 className="redText">IMPORTANTE: La paquetería perdió tu orden. Comunícate con el equipo de Kanguru lo antes posible.</h6>
       );
    }
    else if(venta.estatusDeOrden === "CONFIRMED" || venta.estatusDeOrden === "DRAFT" || venta.estatusDeOrden === ""){
        estatusDeOrden = (
            <p className="collectedEstatus">Orden Creada<i class="bi bi-bag-check-fill ms-2"></i></p>
        );
        avisoCancelacion = (
            null
        );
    }
    else if(venta.estatusDeOrden === "ON_ROAD_TO_PICKUP" || venta.estatusDeOrden === "ONROADTOPICKUP"){
        estatusDeOrden = (
            <p className="enCaminoRecolectarEstatus">¡La paquetería está en camino a recoger el producto de la bodega del proveedor! <i class="bi bi-truck ms-2"></i></p>
        );
        avisoCancelacion = (
            null
        );
    }
    else if(venta.estatusDeOrden === "DEMAGED"){
        estatusDeOrden = (
            <p className="canceladaEstatus">Dañada <i class="bi bi-x-octagon-fill"></i></p>
        );
        avisoCancelacion = (
            <h6 className="redText">IMPORTANTE: La paquetería daño tu orden. Comunícate con el equipo de Kanguru lo antes posible.</h6>
       );
    }
    else if(venta.estatusDeOrden === "COLLECTED"){
        estatusDeOrden = (
            <p className="collectedEstatus">¡Orden Recolectada por Paquetería! <i class="bi bi-patch-check-fill"></i></p>
        );
        avisoCancelacion = (
            null
        );
    }
    else{
        estatusDeOrden = (
            null
        );
        avisoCancelacion = (
            null
        );
    }

    //PAQUETERÍA
    if(venta.estatusDeOrden === "DRAFT"){
        estatusDeEnvio = (
            <h6 className="">Una vez que el proveedor acepte el pedido podrás revisar el estatus de envío <a href={"https://tracking.99minutos.com/search/" + venta.codigoRastreo} target="_blank">Aquí</a></h6>
        );
    }
    else if((venta.paqueteria === "99 minutos" || venta.paqueteria === "99minutos" || venta.paqueteria === "99 Minutos") && venta.codigoRastreo !== ""){
        estatusDeEnvio = (
            <h6 className="">Revisa el Estatus de Envío <a href={"https://tracking.99minutos.com/search/" + venta.codigoRastreo} target="_blank">Aquí</a></h6>
        );
    }
    else if(venta.paqueteria === "Estafeta" || venta.paqueteria === "estafeta"){
        estatusDeEnvio = (
            <h6 className="">Revisa el Estatus de Envío <a href={"https://www.estafeta.com/Herramientas/Rastreo"} target="_blank">Aquí</a></h6>
        );
    }
    else if(venta.paqueteria === "DHL" || venta.paqueteria === "dhl"){
        estatusDeEnvio = (
            <h6 className="">Revisa el Estatus de Envío <a href={"https://www.dhl.com/mx-es/home/rastreo.html"} target="_blank">Aquí</a></h6>
        );
    }
    else if(venta.paqueteria === "Fedex" || venta.paqueteria === "FEDEX"){
        estatusDeEnvio = (
            <h6 className="">Revisa el Estatus de Envío <a href={"https://www.fedex.com/es-mx/tracking.html"} target="_blank">Aquí</a></h6>
        );
    }
    else{
        estatusDeEnvio= (
            null
        );
    }

    //Si la venta fue entregada o ya se canceló o se perdió o está en proceso de devolución, entonces no se puede cancelar
    if(!ordenCancelada && venta.codigoRastreo !== "" && venta.estatusDeOrden !== "DELIVERED" && venta.estatusDeOrden !== "CANCELLED" && venta.estatusDeOrden !== "DEMAGED" && venta.estatusDeOrden !== "LOST" && venta.estatusDeOrden !== "ON_ROAD_TO_RETURN" && venta.estatusDeOrden !== "ONROADTORETURN" && venta.estatusDeOrden !== "RETURNED" && venta.estatusDeOrden !== "UNSUCCESSFUL_RETURN" && venta.estatusDeOrden !== "UNSUCCESSFULRETURN" && venta.estatusDeOrden !== "STOLEN"){
        // cancelar = (
        //     <p data-bs-toggle="modal" data-bs-target={'#'+'cancelarOrden'+venta.objectID}  className="btn btn-cancel mt-4">Cancelar</p>
        // )
        cancelar = (
            null
        )
    }
    else{
        cancelar = (
            null
        )
    }

    //PAGADO y ENTREGADO PARA MODAL
    if(venta.pagado === true){
        pagado = (
            <h6>Pagado: Sí</h6>
        );
    }
    else{
        pagado = (
            <h6>Pagado: No</h6>
        );
    }

    if(venta.entregado === true){
        entregado = (
            <h6>Entregado: Sí</h6>
        );
    }
    else{
        entregado = (
            <h6>Entregado: No</h6>
        );
    }

    let codigoRastreo;
    if(venta.codigoRastreo === ""){
        codigoRastreo = (
            <h6>Código de Rastreo: Aún no se genera la guía de envío.</h6>
        );
    }
    else{
        codigoRastreo = (
           <h6>Código de Rastreo: {venta.codigoRastreo}</h6>
        );
        
    }

    let paqueteria;
    if(venta.paqueteria === ""){
        paqueteria = (
            <h6>Paquetería: Aún no se genera la guía de envío.</h6>
        );
    }
    else{
        paqueteria = (
            <h6>Paquetería: {venta.paqueteria}</h6>
        );
    }

    
    let nuevoEsquemaDeComisiones = venta.createdAt >= new Date('07/15/2022');
    let dateCompare = venta.createdAt < new Date('01/26/2022');
    let ingresoTotalSinComision;
    let comisionDelVendedorConExtra;
    let comisionDelVendedor;
    let gananciaExtra;
    let ingresoTotalNegocio = Number(venta.ingresoTotalNegocio);
    let costoDeEnvio = Number(venta.ingresoEnvio);
    let vendedorComision;
    let vendedorComisionModal;
    
    if(nuevoEsquemaDeComisiones){
        ingresoTotalSinComision = Math.round(Number(venta.ingresoTotal) * 0.96);
        comisionDelVendedor = Math.round(ingresoTotalNegocio * 0.01 * venta.comision);
        //––––––––––––––––––––––––––––––
        //Ganancia Extra es igual al precioBase original - el precioBase nuevo
        //Donde el ingreso del precioBase original está representado por el ingresoTotalNegocio
        //En efecto, hay que restar el ingresoTotalNegocio del precioBase que se aplicó al hacer la venta
        //Para encontrar la ganancia extra.
        let precioBase = ingresoTotalSinComision - costoDeEnvio - comisionDelVendedor;
        gananciaExtra = precioBase - ingresoTotalNegocio;
        comisionDelVendedorConExtra = Math.round(comisionDelVendedor + gananciaExtra);
        //––––––––––––––––––––––––––––––
        vendedorComisionModal = comisionDelVendedorConExtra;
        vendedorComision = comisionDelVendedorConExtra;
    }
    else{
        ingresoTotalSinComision = Math.round(Number(venta.ingresoTotal) / 1.04);
        if(venta.tipoDePago === "enLinea"){
            let ingresoDeProducto = Number(venta.ingresoTotalNegocio) - Number(venta.ingresoEnvio);
            comisionDelVendedor = Math.round(ingresoDeProducto * 0.01 * venta.comision);
            if(dateCompare){
                comisionDelVendedor = Math.round(Number(venta.ingresoTotalNegocio) * 0.01 * venta.comision);
            }
            gananciaExtra = ingresoTotalSinComision - venta.ingresoTotalNegocio;
            comisionDelVendedorConExtra = comisionDelVendedor + gananciaExtra;
            vendedorComisionModal = comisionDelVendedorConExtra;
            vendedorComision = comisionDelVendedorConExtra;
        }
        else{
            let precioOriginal = Number(venta.ingresoTotalNegocio) + Number(venta.ingresoEnvio);
            let comisionDelVendedor = Math.round(Number(venta.ingresoTotalNegocio)* 0.01 * venta.comision);
            if(dateCompare){
                comisionDelVendedor = Math.round(precioOriginal * 0.01 * venta.comision);
            }
            gananciaExtra = ingresoTotalSinComision - precioOriginal;
            comisionDelVendedorConExtra = comisionDelVendedor + gananciaExtra;
            vendedorComisionModal = comisionDelVendedorConExtra;
            vendedorComision = comisionDelVendedorConExtra;
        }
    }
    
    
    
    let modalVentaDetails;
    modalVentaDetails = (
        <div class="modal fade" id={'k'+venta.objectID} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Seguimiento de Orden</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="mb-4">
                                <h6 className="cuentaVendedorLabelForm">Resumen de Venta</h6>
                                <h6 className="">Producto: {venta.nombreProducto}</h6>
                                <h6 className="">Cantidad: {venta.cantidad}</h6>
                                <h6 className="">Monto que Paga el Cliente: {venta.ingresoTotal}</h6>
                                <h6 className="">Tu Comisión: {vendedorComisionModal}</h6>
                                <h6 className="">Fecha: {venta.Fecha}</h6>
                                <h6 className="">Tipo de Pago: {venta.tipoDePago}</h6>
                            </div>
                                
                            <div class="mb-4">
                                <h6 className="cuentaVendedorLabelForm">Información del Cliente</h6>
                                <h6 className="">Nombre: {venta.shippingInfo.name}</h6>
                                <h6 className="">Teléfono: {venta.telefonoCliente}</h6>
                            </div>

                            <div class="mb-4">
                                <h6 className="cuentaVendedorLabelForm">Información de Envío</h6>
                                {codigoRastreo}
                                {paqueteria}
                                {estatusDeEnvio}
                            </div>

                            <div class="mb-4">
                                <h6 className="cuentaVendedorLabelForm">Estatus de Orden</h6>
                                {avisoCancelacion}
                                {pagado}
                                {entregado}
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );

    let modalCancelarOrden = (
        <div class="modal fade" id={'cancelarOrden'+venta.objectID} tabindex="-1" aria-labelledby="ventaModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">Cancelar Orden</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>¿Estás seguro que quieres cancelar la orden?</p>
                        <p>Esta acción no es reversible.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => cancelarOrden(e)} data-bs-dismiss="modal">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    );
    
    //Donde número ===0 significa que la venta no es de un referido
    let ventaSummary;
    if(numero === 0){
        ventaSummary = (
            <div>
                <a data-bs-toggle="modal" data-bs-target={'#'+'k'+venta.objectID} className="list-group-item list-group-item-action pointer">
                    <li class="d-flex justify-content-between align-items-left mt-1">
                        <div>
                            <div class="fw-bold">
                                {venta.nombreProducto}
                            </div>
                            <div className="d-flex bottomInfoComision">
                                <div className="me-3">
                                    {venta.Fecha}
                                </div>
                                <div>
                                    Cliente: {venta.shippingInfo.name}
                                </div>
                            </div>
                            {estatusDeOrden}
                            {cancelar}
                        </div>
                        <h5 className={vendedorComisionClass}>${vendedorComision}</h5>
                    </li>  
                    
                </a>
            </div>
        );
    }
    else{
        ventaSummary = (
            <div>
                <a className="list-group-item list-group-item-action noHover">
                    <li class="d-flex justify-content-between align-items-center">
                        <div>
                            <div class="fw-bold d-flex">
                                Venta de Referido
                            </div>
                            <div>
                                {venta.Fecha}
                            </div>
                        </div>
                        <h5 className="comisionVenta">$50</h5>
                    </li>  
                </a>
            </div>
        );
    }

    return (
        <div>
            {modalVentaDetails}
            {modalCancelarOrden}
            {ventaSummary}
        </div>
        
    );
    
}

export default VentaSummaryVendedor;