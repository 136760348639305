import React, { useState, useEffect, useRef} from 'react';
import MensajesList from './MensajesList';
import ChatHeader from './ChatHeader';
import ChatSendForm from './ChatSendForm';
import './Chat.css';
import firebase from "../../config/fbConfig";
import {useCollectionData} from 'react-firebase-hooks/firestore';

const Chat = (props) => {

    const {auth, receiver, sendMessage, chat, handleWarning, setMobileDisplayedContent} = props;
    const [query, setQuery] = useState("");
    const [mensajes] = useCollectionData(query);
    
    useEffect(() => { 
        const query = firebase.firestore().collection('Chats').doc(chat.objectID).collection('Mensajes').orderBy('tCreatedAt');
        setQuery(query);
    }, [chat])

    if(chat && mensajes){

        return(
            <div >
                {/* CHAT HEADER, enviar reciever para ver nombre */}
                <ChatHeader receiver={receiver} setMobileDisplayedContent={setMobileDisplayedContent}/>

                {/* CHAT CONTENT */}
                <div className="messages">
                    <MensajesList mensajes={mensajes} auth={auth}/>
                </div>
                
                
                {/* CHAT SEND FORM */}
                <ChatSendForm sendMessage={sendMessage} handleWarning={handleWarning}/>

            </div> 
        )
    }
    else{
        return(
            <div>
                {/* CHAT HEADER, enviar reciever para ver nombre */}
                <ChatHeader receiver={receiver}/>
                
                {/* CHAT SEND FORM */}
                <ChatSendForm sendMessage={sendMessage}/>
            </div> 
        )
    }
}

export default Chat;