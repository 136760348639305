import authReducer from './authReducer';
import productReducer from './productReducer';
import ventaReducer from './ventaReducer';
import retiroReducer from './retiroReducer';
import negocioReducer from './negocioReducer';
import resenaReducer from './resenaReducer';
import retoReducer from './retoReducer';
import catalogoReducer from './catalogoReducer';
import chatReducer from './chatReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";


const rootReducer = combineReducers({
  auth: authReducer,
  product: productReducer,
  retiro: retiroReducer,
  venta: ventaReducer,
  negocio: negocioReducer,
  resena: resenaReducer,
  reto: retoReducer,
  catalogo: catalogoReducer,
  chat: chatReducer,
  firestore: firestoreReducer, //synced firestore data with app
  firebase: firebaseReducer //synced auth status with app
});

export default rootReducer;