import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingProgramaReferidos = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Programa de Referidos</title>
                <meta name="description" content="¡Refiere y gana! Conoce acerca del programa de referidos y aumenta tus ingresos."/>
            </Helmet>
            <div>
                <div className="landingClabeImgVendedor">
                    <div className="textNosotros">
                        <h4 className="textNosotros2">Programa de Referidos</h4>
                    </div>
                    <img src="img/programa_referidos.png" className="landingClabeImgVendedor2" alt=""/>
                </div>
            </div>
            <div className="landingPage2-Contacto">
                <div className="container-fluid custom-container mb-5 row footer-spacing">
                    <div className="col-md-6">
                        <div className="mt-5 mt-md-0">
                            <h2 className="bold">Cómo Funciona</h2>
                            <div className="mt-3">
                                <h4>¡Gana $50 pesos por referido!</h4>
                                <div className="mt-3">
                                    <ol type="1">
                                        <li>Aplica en la primera venta completada de tu referido.</li>
                                        <li>Una orden completada es una orden que ya se entregó y pagó.</li>
                                        <li>Este dinero te lo damos nosotros, no lo tomamos de la comisión de tu referido.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5">
                            <h2 className="bold">Cómo Agregar a un Referido</h2>
                            <div className="mt-3">
                                <div className="mt-6">
                                    {/* <div>
                                        <h4>Opción 1: Compartiendo tu Código para Referidos:</h4>
                                        <ol type="1" className="mt-3">
                                            <li>Encuentra tu código para referidos en la página de "Cuenta"</li>
                                            <li>Comparte tu código para referidos con quien quieras</li>
                                            <li>Esta persona al momento de registrarse en la app deberá ponerlo en la casilla correspondiente</li>
                                        </ol>
                                    </div> */}
                                    <div>
                                        <h4>Compartiendo el Enlace para que se Registren:</h4>
                                        <ol type="1" className="mt-3">
                                            <li>Encuentra tu enlace para referidos en la página de “Cuenta”</li>
                                            <li>Comparte el enlace con quien quieras</li>
                                            <li>Una vez que esta persona seleccione tu enlace, será redirigido a la página para registrarse y automáticamente será asignado como tu referido</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img src="img/girl-digital-1.png" className="landingCatchImgReferidos" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingProgramaReferidos;
