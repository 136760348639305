import React, {Component} from 'react';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import Navbar from './components/layout/Navbar';
import Home from './components/dashboard/Home/Home';
import StoreNegocio from './components/dashboard/StoreNegocio';
import CuentaNegocio from './components/dashboard/CuentaNegocio';
import DashboardNegocio from './components/dashboard/DashboardNegocio/DashboardNegocio';
import CuentaVendedor from './components/dashboard/CuentaVendedor';
import ProductDetails from './components/products/ProductDetails';
import SignIn from './components/auth/SignIn';
import ForgotPassword from './components/auth/ForgotPassword';
import SignUpVendedor from './components/auth/SignUpVendedor';
import SignUpNegocio from './components/auth/SignUpNegocio';
import solicitudSignUpNegocio from './components/auth/solicitudSignUpNegocio';
import ProductCheckout from './components/products/ProductCheckout';
import ProductCheckoutKangupago from './components/products/ProductCheckoutKangupago';
import ProductCheckoutCatalogo from './components/products/ProductCheckoutCatalogo';
import VentaSuccess from './components/ventas/VentaSuccess';
import VentaSuccessPCE from './components/ventas/VentaSuccessPCE';
import LandingVendedores from './components/layout/LandingPage/LandingPageVendedores/LandingVendedores';
import Envios from './components/layout/LandingPage/LandingEnvios';
import Nosotros from './components/layout/LandingPage/LandingNosotros';
import CoberturaPCE from './components/layout/LandingPage/LandingCobertura';
import ProgramaReferidos from './components/layout/LandingPage/LandingProgramaReferidos';
import RetoSemanal from './components/layout/LandingPage/LandingRetoSemanal';
import Contacto from './components/layout/LandingPage/LandingContacto';
import PreguntasFrecuentes from './components/layout/LandingPage/LandingPF';
import LandingCatalogoEnLinea from './components/layout/LandingPage/LandingCatalogoEnLinea';
import LandingKanguPago from './components/layout/LandingPage/LandingKanguPago';
import LandingNegocios from './components/layout/LandingPage//LandingPageNegocios/LandingPageNegocios';
import Terminos from './components/layout/LandingPage/LandingTC';
import AvisoDePrivacidad from './components/layout/LandingPage/LandingPoliticasDePrivacidad';
import Footer from './components/layout/Footer';
import Catalogo from './components/dashboard/Catalogo/Catalogo';
import Catalogos from './components/dashboard/Catalogo/Catalogos';
import CatalogoConfiguracion from './components/dashboard/Catalogo/CatalogoConfiguracion';
import CatalogoAgregaProductos from './components/dashboard/Catalogo/CatalogoAgregaProductos';
import Templates from './components/dashboard/Catalogo/Templates/Templates';
import LandingCapacitacionNegocios from './components/layout/LandingPage/LandingCapacitacionNegocios';
import LandingFuncionamientoNegocios from './components/layout/LandingPage/LandingFuncionamientoNegocios';
import LandingFuncionamientoVendedores from './components/layout/LandingPage/LandingFuncionamientoVendedores';
import RevisarCoberturaPCE from './components/dashboard/RevisarCoberturaPCE';
import Categorias from './components/categorias/Categorias';
import ProductosEnCategoria from './components/categorias/ProductosEnCategoria';
import BuscarPorTienda from './components/categorias/BuscarPorTienda';
import BottomNavAfiliado from './components/layout/BottomNavAfiliado';
import BottomNavNegocio from './components/layout/BottomNavNegocio';
import Chats from './components/chat/Chats';
import LandingPricing from './components/layout/LandingPage/LandingPagePricing/LandingPricing';
import ProductosActualizados from './components/products/ProductosActualizados/ProductosActualizados';
import {Helmet} from "react-helmet";



class App extends Component {
  
  
  render(){
    const {auth, profile} = this.props;

    let Dashboard;
    let unknownPage;
    if(auth.uid){
      Dashboard = (
        <Route exact path="/" component={Home} />
      );

      unknownPage = (
         <Route exact path="/:random" component={Home} />
      );
      
    }
    else if(!auth.uid && window.location.href.indexOf("catalogoenlinea.mx") > -1){
      Dashboard = (
        <Route exact path="/" component={LandingCatalogoEnLinea} />
      );

      unknownPage = (
        <Route exact path="/:random" component={LandingCatalogoEnLinea} />
      );
    }
    else if(!auth.uid && window.location.href.indexOf("kangupago.com") > -1){
      Dashboard = (
        <Route exact path="/" component={LandingKanguPago} />
      );

      unknownPage = (
        <Route exact path="/:random" component={LandingKanguPago} />
      );
    }
    else{
      unknownPage = (
        <Route exact path="/:random" component={LandingVendedores} />
      );

      Dashboard = (
        <Route exact path="/" component={LandingVendedores} />
      );
    }

    let checkoutPage;
    if(window.location.href.indexOf("catalogoenlinea.mx") > -1){
      checkoutPage = (
        <Route path="/checkout/:productID/:vendedorID/:sDisenoEnCatalogoID/:precio" component={ProductCheckoutCatalogo} />
      );
    }
    else if(window.location.href.indexOf("kangupago.com") > -1){
      checkoutPage = (
        <Route path="/checkout/:productID/:vendedorID/:precio" component={ProductCheckoutKangupago} />
      );
    }
    else{
      checkoutPage = (
          <Route path="/checkout/:productID/:vendedorID/:precio" component={ProductCheckout} />
        );
    }
      
    let footer;
    if(window.location.href.indexOf("signin") > -1 || window.location.href.indexOf("signup") > -1 || window.location.href.indexOf("checkout") > -1 || window.location.href.indexOf("recuperar-contrasena") > -1){
      footer = null;
    }
    else if(auth.uid && window.innerWidth < 600){
      footer = null;
    }
    else{
      footer = <Footer/>;
    }

    let BottomNav;
    if(auth.uid && window.innerWidth < 600 && profile.isLoaded && profile.Nombre_de_Negocio == '' && window.location.href.indexOf("catalogoenlinea.mx") === -1 && window.location.href.indexOf("kangupago.com") === -1){
      BottomNav = <BottomNavAfiliado profile={profile}/>
    }
    else if(auth.uid && window.innerWidth < 600 && profile.isLoaded && profile.Nombre_de_Negocio != '' && window.location.href.indexOf("catalogoenlinea.mx") === -1 && window.location.href.indexOf("kangupago.com") === -1){
      BottomNav = <BottomNavNegocio/>
    }
    else{
      BottomNav = null
    }
    
    return(
      <BrowserRouter>
        <Helmet>
          <title>Kanguru</title>
          <meta name="title" content="Kanguru" />
          <meta name="description" content="¡Conviértete en Afiliado con Kanguru! Comparte productos de marcas reconocidas y recibe comisiones por cada venta realizada. ¡Seguro y simple!"/>
          <link rel="icon" href="https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/images%2F499e4386-36d0-40cc-bd92-13017033b2c0_576x691.jpeg?alt=media&token=cf5e129d-0365-477c-acfb-57a787a71346"/>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://kanguru.mx/" />
          <meta property="og:title" content="Kanguru" />
          <meta property="og:description" content="¡Conviértete en Afiliado con Kanguru! Comparte productos de marcas reconocidas y recibe comisiones por cada venta realizada. ¡Seguro y simple!" />

          <link rel="canonical" href="https://kanguru.mx/" />
        </Helmet>
        <div className="App notranslate" lang="es" translate="no">
          <Navbar auth={auth} profile={profile}/>
          <Switch>
            {Dashboard}
            <Route path="/signin" component={SignIn} />
            <Route path="/recuperar-contrasena" component={ForgotPassword} />
            <Route path={"/signup-vendedor/:codigoReferido?"} component={SignUpVendedor} />
            <Route path="/signup-proveedor" component={SignUpNegocio} />
            <Route path="/solicitud-proveedor" component={solicitudSignUpNegocio} />
            <Route path="/product/:id" component={ProductDetails} />
            <Route path={"/chats/:chatID?"} component={Chats} />
            <Route path="/store/:id" component={StoreNegocio} />
            <Route path="/cuenta-negocio/:id" component={CuentaNegocio} />
            <Route path="/dashboard-negocio/:id" component={DashboardNegocio} />
            <Route path="/cuenta-vendedor/:id" component={CuentaVendedor} />
            {checkoutPage}
            <Route path="/success" component={VentaSuccess} />
            <Route path="/success-pce/:sVentaID/:sProductoID/:precio" component={VentaSuccessPCE} />
            <Route path="/nosotros" component={Nosotros} />
            {/* <Route path="/cobertura" component={CoberturaPCE} /> */}
            <Route path="/reto-semanal" component={RetoSemanal} />
            <Route path="/programa-de-referidos" component={ProgramaReferidos} />
            <Route path="/cambios-y-devoluciones" component={Envios} />
            <Route path="/terminos-y-condiciones" component={Terminos} />
            <Route path="/aviso-de-privacidad" component={AvisoDePrivacidad} />
            <Route path="/preguntas-frecuentes" component={PreguntasFrecuentes} />
            <Route path="/contacto" component={Contacto} />
            <Route path="/proveedores" component={LandingNegocios} />
            <Route path="/catalogos" component={Catalogos} />
            <Route path="/catalogo/:vendedorID/:catalogoID/:sDisenoEnCatalogoID/:checkout" component={Catalogo} />
            <Route path="/templates/:sCatalogoID/:sDisenoEnCatalogoID/:sIsNew?" component={Templates} />
            <Route path="/catalogo/agrega-productos/:sCatalogoID/:sDisenoEnCatalogoID" component={CatalogoAgregaProductos} />
            <Route path="/configuracion/:sCatalogoID/:sDisenoEnCatalogoID" component={CatalogoConfiguracion} />
            <Route path="/capacitacion-negocios" component={LandingCapacitacionNegocios} />
            <Route path="/funcionamiento-negocios" component={LandingFuncionamientoNegocios} />
            <Route path="/como-funciona" component={LandingFuncionamientoVendedores} />
            <Route path="/cobertura" component={RevisarCoberturaPCE} />
            <Route path="/categorias" component={Categorias} />
            <Route path="/categoria/:categoria" component={ProductosEnCategoria} />
            <Route path="/BuscarPorTienda" component={BuscarPorTienda} />
            <Route path="/precio" component={LandingPricing} />
            <Route path="/productos-actualizados" component={ProductosActualizados} />
            <Route path="/?s=" component={Home} />
            {unknownPage}
          </Switch>
          {footer}
          {BottomNav}
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(App);

