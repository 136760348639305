import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch';
import './ChatSummary.css';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);

const ChatSummary = (props) => {
    //State Variables
    const [receiver, setReceiever] = useState("");
    const {chat, auth, getChat} = props;

    const getReceiverId = () => {
        const receiverID = chat.aUserIDs.filter((id) => {
            if(id !== auth){
                return id;
            }
        })

        return receiverID;
    }

    //Obtiene Nombre del Otro Usuario
    useEffect(async () => {
        if(receiver === ""){
            const receiverID = getReceiverId()
            const receiverTemp = await indexUsuarios.getObject(receiverID[0]);
            setReceiever(receiverTemp);
        }
    }, []);

    if(receiver !== ""){
        return(
            <div>
                <div className="chat-summary-container mt-3">
                    <div className="d-flex pointer" onClick={() => getChat(chat.objectID)}>
                        <img src={(receiver.Logo && receiver.Logo[0]) !== '' ? receiver.Logo[0]: "/img/no-store.png"} className="chatLogo me-2"/>
                        <div class="chat-summary-title">
                            {receiver.Nombre_de_Negocio === "" ? receiver.Nombre_de_Vendedor: receiver.Nombre_de_Negocio}
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
        )
    }
    else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status"></div>
                <p className="mt-3">Cargando...</p>
            </div>
        )
        
    }

    


    
    
}

export default ChatSummary;