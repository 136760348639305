
export const addChat= (sNegocioID, sVendedorID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Chats').add({
            aUserIDs: [sNegocioID, sVendedorID],
            tCreatedAt: new Date(),
            tLastOpenedAt: new Date()
        }).then(() => {
            dispatch({ type: 'ADDED_CHAT'}); 
        }).catch((err) => {
            dispatch({ type: 'ADDED_CHAT_ERROR', err});
        }) 
    }
};

export const updateLastChat = (sChatID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Chats').doc(sChatID).set({
            tLastChat: new Date()
        },{ merge: true }).then(() => {
            dispatch({ type: 'UPDATED_LAST_SENT_MESSAGE'});
        }).catch((err) => {
            dispatch({ type: 'UPDATED_LAST_SENT_MESSAGE_ERROR', err});
        }) 
    }
};

export const updateLastOpened = (sChatID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Chats').doc(sChatID).set({
            tLastOpenedAt: new Date()
        },{ merge: true }).then(() => {
            dispatch({ type: 'UPDATED_LAST_OPENED'});
        }).catch((err) => {
            dispatch({ type: 'UPDATED_LAST_OPENED_ERROR', err});
        }) 
    }
};

export const addMessage= (sChatID, sMensaje, sSenderID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Chats').doc(sChatID).collection('Mensajes').add({
            sMensaje: sMensaje,
            sSenderID: sSenderID,
            tCreatedAt: new Date()
        }).then(() => {
            //Update tLastChat
            dispatch(updateLastChat(sChatID));
            dispatch({ type: 'SEND_MESSAGE'});
        }).catch((err) => {
            dispatch({ type: 'SEND_MESSAGE_ERROR', err});
        }) 
    }
};

export const addChatWarning = (sUserID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Usuarios').doc(sUserID).get().then(doc => {
            let nWarnings = doc.data().nChatWarnings;
            if(nWarnings == undefined){
                nWarnings = 0;
            }
            nWarnings = nWarnings + 1;

            doc.ref.set({
                nChatWarnings: nWarnings
            },{ merge: true });

        }).then(() => {
            dispatch({ type: 'ADDED_CHAT_WARNING'});
        }).catch((err) => {
            dispatch({ type: 'ADDED_CHAT_WARNING_ERROR', err});
        }) 
    }
};