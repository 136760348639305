import React, { Component } from 'react'
import {connect} from 'react-redux';
import {signUp} from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import gtag, { install } from 'ga-gtag';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';

ReactPixel.init('908315683105799', {}, { debug: true, autoConfig: false });
ReactPixel.pageView();
install('G-GQ8M7NNRJ7');

class SignUpVendedor extends Component {
  state = {
    nombre_de_negocio: '',
    nombre_de_vendedor: '',
    telefono: '+52',
    codigoReferidoExterno: '',
    codigoReferidoPersonal: '',
    email: '',
    password: '',
    aceptoTC: false,
    aceptoPrivacidad: false
  }

  
  notify = (type) => {
    if(type == "nombreCompletoInvalido"){
      toast.error('Te falta agregar tu nombre completo.');
    }
    else if(type == "emailInvalido"){
      toast.error('Tu correo es inválido.');
    }
    else if(type == "telefonoInvalido"){
      toast.error('Necesitas agregar un número de teléfono válido. Asegúrate de incluir +52');
    }
    else if(type == "contraseñaMenorA6"){
      toast.error('La contraseña debe ser de 6 o más cáracteres.');
    }
    else if(type == "codigoReferidoInvalido"){
      toast.error('El código de referido ingresado no es válido. Si no tienes un código de referido, puedes dejar en blanco.');
    }
    else if(type == "noAceptoTC"){
      toast.error('Necesitas aceptar los Términos y Condiciones para continuar.');
    }
    else if(type == "noAceptoPrivacidad"){
        toast.error('Necesitas aceptar las Políticas de Privacidad para continuar.');
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  makeReferalCode = (input) => {
    let name = input.substring(0,3);
    let randomNumber = Math.floor(Math.random() * 10000);
    let randomIdx = Math.floor(Math.random() * 25);
    let randomLetter = "abcdefghijklmnopqrstuvwxyz"[randomIdx];
    return name+randomNumber+randomLetter;
  }

  triggerAnalyticEvents = () => {
    //Facebook Pixel
    ReactPixel.trackCustom('CompleteRegistrationVendedor', {
      content_name: 'SignUpVendedor'
    });

    //Google Analytics
    gtag("event", "sign_up", {});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const validacionCodigoRef = this.state.codigoReferidoExterno.match(/^[A-zÀ-ú]{3}[0-9]{1,5}[a-z]$/i) || this.state.codigoReferidoExterno === '';

    if(this.state.nombre_de_vendedor == ""){
      this.notify("nombreCompletoInvalido");
    }
    else if(this.state.email == "" || !this.state.email.indexOf("@")){
      this.notify("emailInvalido");
    }
    else if(!this.state.telefono.match(/^\+52(\s*[0-9]\s*){10}\s*$/)){
      this.notify("telefonoInvalido");
    }
    else if(!validacionCodigoRef){
      this.notify("codigoReferidoInvalido");
    }
    else if(this.state.password.length < 6){
      this.notify("contraseñaMenorA6");
    }
    else if(!this.state.aceptoTC){
      this.notify("noAceptoTC");
    }
    else if(!this.state.aceptoPrivacidad){
      this.notify("noAceptoPrivacidad");
    }
    else{
      this.setState({
        codigoReferidoPersonal: this.makeReferalCode(this.state.nombre_de_vendedor)
      }, () => {
        this.triggerAnalyticEvents();
        this.props.signUp(this.state);
      })
    }
  }

  componentDidMount(){  
    window.scrollTo(0, 0);
    const {codigoReferidoExterno} = this.props;
    this.setState({
      codigoReferidoExterno: codigoReferidoExterno
    })
  }


  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to ='/'/>

    return (
      <div>
        
        {/* DYNAMIC META INFORMATION */}
        <Helmet>
            <title>Regístrate como Afiliado</title>
            <meta name="description" content="¡Es Gratis! Regístrate como afiliado y descubre todos los productos que puedes vender a cambio de comisiones."/>
        </Helmet>

        {/* Notifications Toast*/}
        <Toaster 
            toastOptions={{
                duration: 5000,
                position: 'top-center',
            }}
        />

        {/* <h1 className="sign-up-title">KANGURU</h1> */}
        <div className="container position-relative start-50 translate-middle signUpForm1 row">
          {/* Titulo */}
          <h3 className = "text-black signUpTitle">Registrate Como Afiliado</h3>  
            
          <form className="mt-3 col-11">
            {/* Nombre */}
            <div className="mb-4">
              <label for="nombre_de_vendedor" class="form-label">Nombre Completo</label>
              <input onChange={this.handleChange} type="text" placeholder="Nombre y Apellido" class="form-control" id="nombre_de_vendedor"/>
            </div>
            {/* Email */}
            <div className="mb-4">
              <label for="email" class="form-label">Correo Electrónico</label>
              <input onChange={this.handleChange} type="email" class="form-control" id="email"/>
            </div>

            {/* Teléfono */}
            <div className="mb-4">
                <label for="email" class="form-label">Teléfono (+52 seguido por 10 números)</label>
                <input onChange={this.handleChange} value={this.state.telefono} type="text" class="form-control" id="telefono"/>
            </div>

            {/* Código de Referido */}
            {/* <div className="mb-4">
                <label for="email" class="form-label">Código de Referido (Opcional)</label>
                <input onChange={this.handleChange} type="text" class="form-control" id="codigoReferidoExterno"/>
            </div> */}
                
            {/* Password */}
            <div className="mb-4">
              <label for="password" class="form-label">Contraseña</label>
              <input onChange={this.handleChange} type="password" placeholder="6 Caracteres o Más" class="form-control" id="password"/>
            </div>

            {/* Submit Button */}
            <div className="mb-4">
              <button onClick={this.handleSubmit} type="button" class="btn btn-primary buttonSignUp">Crear Cuenta</button>
            </div>

            <div class="form-check mt-4">
              <div>
                <input class="form-check-input" type="checkbox" value={true} id="aceptoTC" onClick={this.handleChange}/>
                <label class="form-check-label" for="aceptoTC">
                  Acepto los <Link to="https://kanguru.mx/terminos-y-condiciones">Términos y Condiciones</Link>
                </label>
              </div>
              
              <div className="mt-2">
                <input class="form-check-input" type="checkbox" value={true} id="aceptoPrivacidad" onClick={this.handleChange}/>
                <label class="form-check-label" for="aceptoPrivacidad">
                  Acepto las <Link to="/terminos-y-condiciones">Políticas de Privacidad</Link>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      
    )
  } 
}

const mapStateToProps = (state, ownProps) => {

  let codigoReferidoExterno = ownProps.match.params.codigoReferido;
  
  if(codigoReferidoExterno == undefined){
    codigoReferidoExterno = "";
  }
  
  return {
    auth: state.firebase.auth,
    codigoReferidoExterno: codigoReferidoExterno

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpVendedor);