import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../../App.css';

const Searchbar = (props) => {
    const {profile} = props;
    const [searchQuery, setSearchQuery] = useState('');
    const history = useHistory();
    
    const onSubmit = e => {
        e.preventDefault();
        history.replace(`/?s=${searchQuery}`)
    };

    let placeholder;
    //Es un afiliado
    if(profile.Nombre_de_Negocio == ''){
        placeholder = "¿Qué producto quieres vender?"
    }
    //Es un negocio
    else{
        placeholder = "¿Qué producto quieres buscar?"
    }

    let searchBar;
    if(window.innerWidth < 600){
        searchBar = (
             <form id="searchForm" onSubmit={onSubmit} autoComplete="off" className="d-flex col-md-7 ms-md-5 search-bar" action={"/"} method="get">
                <input onInput={e => setSearchQuery(e.target.value)} className="form-control me-2 input-search-bar" name="s" placeholder={placeholder} aria-label="Search"/>
                <button type="submit" class="search-bar-button"><i class="bi bi-search"></i></button>           
            </form>     
        )
    }
    else{
        searchBar = (
            <form id="searchForm" onSubmit={onSubmit} autoComplete="off" className="d-flex col-md-7 ms-md-5 search-bar" action="/" method="get">
                <input onInput={e => setSearchQuery(e.target.value)} className="form-control me-2 input-search-bar" name="s" placeholder="¿Qué Producto Buscas?" aria-label="Search"/>
               <button type="submit" class="btn btn-primary search-bar-button"><i class="bi bi-search"></i></button>           
           </form>     
        )
    }

    return(
       searchBar
    );
}

export default Searchbar;