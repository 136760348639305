import React, {Component} from 'react';
import copy from 'copy-to-clipboard';
import {Link} from 'react-router-dom';
import firebase from 'firebase';
import {connect} from 'react-redux';
import FileUploader from 'react-firebase-file-uploader';
import {editInfoDisenoCatalogo, editNombreCatalogo, deleteCatalogo} from '../../../store/actions/catalogoActions';
import toast, { Toaster } from 'react-hot-toast';
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');

{/* Despliega Catalogo */}
class CatalogoSummary extends Component {
    state = {
        disenoEnCatalogo: {},
        sNombre: ""
    }

    triggerAnalyticEvents = (eventType) => {
        if(eventType == "share"){
            gtag("event", "share", {
                content_type: "catalogo"
            });
        }
    }

    notify = (type) => {
        if(type == "copioEnlaceCatalogo"){
            toast.success('¡Un paso más cerca a vender! Se copió el enlace de tu catálogo digital, compárte el enlace con tus clientes.');
        }
        else if(type == "editoNombreCatalogo"){
            toast.success('¡Que buen nombre! Se personalizó tu catálogo.');
        }
        else if(type == "informacionIncompletaNombre"){
            toast.error('¡Uy! No se te olvide escribir el nombre de tu gran catálogo.');
        }
        else if(type == "deletedCatalogo"){
            toast.success('Se borró tu catálogo con éxito. Puede tomar un poco en reflejarse, por favor refresca la página.');
        }
    }

    copyLink = () => {
        const {catalogo, vendedorID} = this.props;
        const sDisenoEnCatalogoID = this.state.disenoEnCatalogo.objectID;
        const linkToCatalogoCliente = 'http://catalogoenlinea.mx/' + "catalogo" + '/' + vendedorID + '/' + catalogo.objectID + '/' + sDisenoEnCatalogoID + '/' + "checkout";
        copy(linkToCatalogoCliente);
        this.notify("copioEnlaceCatalogo");
        this.triggerAnalyticEvents("share");
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    borrarCatalogo = (e) => {
        e.preventDefault();
        const {catalogo} = this.props;
        this.props.deleteCatalogo(catalogo.objectID);
        this.notify("deletedCatalogo")
    }

    handleSubmit = () => {
        if(this.state.sNombre !== ""){
            console.log(this.state.disenoEnCatalogo.sCatalogoID);
            this.props.editInfoDisenoCatalogo(this.state.disenoEnCatalogo.objectID, this.state.sNombre);
            this.props.editNombreCatalogo(this.state.disenoEnCatalogo.sCatalogoID, this.state.sNombre);
            this.notify("editoNombreCatalogo")
        }
        else{
            this.notify("informacionIncompletaNombre");
        }
        
    }

    componentDidMount(){
        const disenosDeCatalogos = this.props.disenoEnCatalogo;
        const catalogoID = this.props.catalogo.objectID;
        
        

        //Obtiene el disenoEnCatalogo de este catálogo
        if(disenosDeCatalogos && catalogoID){
            const disenoEnCatalogo = disenosDeCatalogos.find((diseno) => {
                if (diseno.sCatalogoID === catalogoID){
                    return diseno;
                }
            });

            if(disenoEnCatalogo){
                this.setState({
                    disenoEnCatalogo: disenoEnCatalogo,
                    sNombre: disenoEnCatalogo.sNombre
                });
            }
        }
    }

    

    render(){
        const {catalogo, vendedorID} = this.props;
        const sDisenoEnCatalogoID = this.state.disenoEnCatalogo.objectID;
        const sTemplateID = this.state.disenoEnCatalogo.sTemplateID;
        let sNombre = this.state.sNombre;
        const linkToCatalogoPersonal = '/catalogo/' + vendedorID + '/' + catalogo.objectID + '/' + sDisenoEnCatalogoID + '/' + 'personal';
        
        if(sNombre == ""){
            sNombre = "El nombre de tu tienda";
        }

        //Modal para Editar Nombre
        let modalEditarInfoCatalogo = (
            <div class="modal fade" id={'z'+catalogo.objectID} tabindex="-1" aria-labelledby="modalEditarInfoCatalogo" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Personaliza tu Catalogo</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="mb-3">  
                                    <div>
                                        <label for="sNombre" className="col-form-label cuentaVendedorLabelForm">Nombre</label>
                                        <input onChange={this.handleChange} placeholder="Así reconozerán tus clientes tu catálogo" type="text" className="form-control" id="sNombre"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        );

        //Modal para Editar Nombre
        let modalBorrarCatalogo = (
            <div class="modal fade" id={'w'+catalogo.objectID} tabindex="-1" aria-labelledby="modalBorrarInfoCatalogo" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">¿Deseas Borrar tu Catalogo?</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body mt-3">
                            <p>Una vez que borres el catálogo no lo podrás recuperar.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                            <button onClick={this.borrarCatalogo} type="button" className="btn btn-primary" data-bs-dismiss="modal">Borrar</button>
                        </div>
                    </div>
                </div>
            </div>
        );

        let templateThumbnailImg = "https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fthumbnails%2Fthumbnail_template1_712x408.jpeg?alt=media&token=1dde3a5e-1265-486f-b1f6-a131d9964cd6";
        
    
        return(
            <div>
                {modalEditarInfoCatalogo}
                {modalBorrarCatalogo}
                
                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                <div className="mt-4">
                    <div className="catalogo d-flex">
                        {/* Image of Template */}
                        <div className="col-md-4">
                            <Link to={linkToCatalogoPersonal}>
                                <img src={templateThumbnailImg} className="imgCatalogo"></img>
                            </Link>
                        </div>
                        {/* Name and Share BTN */}
                        <div className="col-md-8">
                            <div className="infoCatalogo">
                                <div className="">
                                    <div className="dropdown d-flex">
                                        <i className="bi bi-pencil-square edit-icon" id="dropdownMenuEditIcon" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuEditIcon">
                                            <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target={'#'+'z'+catalogo.objectID}>Nombre</a></li>
                                            {/* <li><Link class="dropdown-item" to={"/configuracion/" + catalogo.objectID + '/' + sDisenoEnCatalogoID}>Nombre</Link></li> */}
                                            <li><Link class="dropdown-item" to={"/templates/" + catalogo.objectID + '/' + sDisenoEnCatalogoID}>Diseño</Link></li>
                                        </ul>
                                        <i class="bi bi-trash trash-icon" data-bs-target={'#'+'w'+catalogo.objectID} data-bs-toggle="modal" aria-expanded="false"></i>
                                    </div>
                                    
                                </div>
                                
                                <p className="catalogo-nombre">{sNombre}</p>
                                <button className="btn btn-compartir" onClick={() => this.copyLink()}>COMPARTIR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        editInfoDisenoCatalogo: (sDisenoEnCatalogoID, sNombre) => dispatch(editInfoDisenoCatalogo(sDisenoEnCatalogoID, sNombre)),
        editNombreCatalogo: (sCatalogoID, sNombre) => dispatch(editNombreCatalogo(sCatalogoID, sNombre)),
        deleteCatalogo: (sCatalogoID) => dispatch(deleteCatalogo(sCatalogoID))
    }
}

export default connect(null, mapDispatchToProps)(CatalogoSummary);