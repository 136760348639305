import React, { Component } from 'react';
import ProductList from '../products/ProductList';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import algoliasearch from 'algoliasearch';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {addChat, updateLastOpened} from '../../store/actions/chatActions';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_CHATS = "95e6ac5a71881cf27f0a4566a7719b90";

//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";

const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';
const ALGOLIA_INDEX_CHATS = 'Kanguru_Chats';

const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);
const client_Chats= algoliasearch(APPLICATION_ID, SEARCH_API_KEY_CHATS);
const indexChats = client_Chats.initIndex(ALGOLIA_INDEX_CHATS);

class StoreNegocios extends Component {
    state = {
        products: [],
        negocio: "",
        user: "",
        pageAlgolia: 0
    }

    notify = (type) => {
        if(type == "noProducts"){
            toast.error("¡Uy! Ya no hay más productos.");
        }
        else if("chatUnavailable"){
            toast.error("¡Atento! Sólo los afiliados pueden hablar con otros proveedores.");
        }
    }

    showVerMasBtn = (action) => {
        const verMasBtn = document.getElementById("verMasBtn");
        
        if(verMasBtn){
            if(this.state.products.length < 48 || action === "hide"){
                verMasBtn.style.display = 'none';
                
            }
            else{
                verMasBtn.style.display = 'inline';
            }
        }
    }

    loadMoreProducts = async (e) => {
        e.preventDefault();
        const {negocio_id} = this.props;
        const pageAlgolia = this.state.pageAlgolia + 1;
        const products = await indexProductos.search(negocio_id, {filters: 'isDisplayed:true', page: pageAlgolia, hitsPerPage: 48});
        const productsNew = this.state.products.concat(products.hits);

        if(products.hits.length === 0){
            this.notify("noProducts");
            this.showVerMasBtn("hide");
        }
        else{
            this.setState({
                pageAlgolia: pageAlgolia,
                products: productsNew
            })
            this.showVerMasBtn("show");
        }
    }

    chat = async (sNegocioID, sVendedorID) => {
        //Obtiene chats de este afiliado
        //Gets Chats of this Afiliado
        const filter = "aUserIDs:" + sVendedorID; 
        const chats = await indexChats.search("", {filters: filter, hitsPerPage: 100});

        //Si el ID del negocio se encuentra en uno de los chats, entonces no llamar función de add chat
        //Si el ID no se encuentra, entonces llamar función de addChat
        const tieneChat = chats.hits.filter((chat) => {
            if(chat.aUserIDs.includes(sNegocioID)){
                this.props.updateLastOpened(chat.objectID);
                return chat;
            }
        });

        if(tieneChat.length === 0){
            this.props.addChat(sNegocioID, sVendedorID);
        }
    }


    async componentDidMount(){
        const {negocio_id, auth} = this.props;
        
        if(negocio_id){
            const products = await indexProductos.search(negocio_id, {filters: 'isDisplayed:true', page: 0, hitsPerPage: 48});
            const negocio = await indexUsuarios.getObject(negocio_id);
            const user = await indexUsuarios.getObject(auth.uid);
            
            this.setState({
                products: products.hits,
                negocio: negocio,
                user: user
            }, async () => {
                this.showVerMasBtn();
            });
        }
    }
    
    render(){
        const products = this.state.products;
        const negocio = this.state.negocio;
        const user = this.state.user;
        const auth = this.props.auth;
        
        let logo;

        let mobile;
        if(window.innerWidth < 600){
            mobile = true;
        }
        else{
            mobile = false;
        }
        
        let storeImages;
        if(negocio){
            if(negocio.Logo[0] && negocio.Logo[0] != ''){
                logo = (
                    <img src={negocio.Logo[0]} alt="" className="logo-shape"/>
                );
            }
            else{
                logo = (
                    <img src="/img/no-store.png" alt="" className="logo-shape"/>
                ); 
            }
            if(negocio.Imagenes_de_Store[0] && negocio.Imagenes_de_Store[1] && negocio.Imagenes_de_Store[0] != ''){
                storeImages = (
                    <div className="mt-4 storeImages">
                        <div className="row row-cols-md-4 g-4">
                            <div className="col">
                                <img src={negocio.Imagenes_de_Store[0]} alt="" className="storeImage"/>
                            </div>
                            <div className="col">
                                <img src={negocio.Imagenes_de_Store[1]} alt="" className="storeImage"/>
                            </div>
                            <div className="col">
                                <img src={negocio.Imagenes_de_Store[2]} alt="" className="storeImage"/>
                            </div>
                            <div className="col">
                                <img src={negocio.Imagenes_de_Store[3]} alt="" className="storeImage"/>
                            </div>
                        </div>
                    </div>
                );
            }
            else{
                storeImages = (
                    <div className="mt-4 storeImages">
                        <div className="row row-cols-md-4 g-4">
                            <div className="col">
                                <img src="/img/no-store.png" alt="" className="storeImage"/>
                            </div>
                            <div className="col">
                                <img src="/img/no-store.png" alt="" className="storeImage"/>
                            </div>
                            <div className="col">
                                <img src="/img/no-store.png" alt="" className="storeImage"/>
                            </div>
                            <div className="col">
                                <img src="/img/no-store.png" alt="" className="storeImage"/>
                            </div>
                        </div>
                    </div>
                );
            }

            let chatBtn;
            if(user.Nombre_de_Negocio !== ""){
                chatBtn = (
                   <button onClick={() => this.notify("chatUnavailable")} className="btn btn-primary mt-2">Chatea</button>
                )
            }
            else{
                chatBtn = (
                    <button onClick={() => this.chat(negocio.objectID, auth.uid)} className="btn btn-primary">Chatea</button>
                )
            }

            {/* <Link to={'/chats/'} className="mt-2">
                <button onClick={() => this.chat(negocio.objectID, auth.uid)} className="btn btn-primary">Chatea</button>
            </Link> */}

            return(
                <div className="container-fluid custom-container footer-spacing-catalogos">
                    {/* Notifications Toast*/}
                    <Toaster 
                        toastOptions={{
                            duration: 5000,
                            position: 'top-center',
                        }}
                    />

                    {/* Store Images. Only Displayed in Tablet and Up */}
                    {storeImages}

                    {/* Store Logo and Info */}
                    <div className="mt-md-5 d-flex storeInfoMobile">
                        <div className="">
                        {logo}
                        </div>
                        <div className="ms-3 storeInfo">
                            {/* Store Name */}
                            <h3 className="storeNameLogo mt-2">{negocio.Nombre_de_Negocio}</h3>

                            {chatBtn}
                        </div>
                    </div>
                    
                    {/* Store Products */}
                    <div className="mt-5 mb-4">
                        {/* Products Title*/}
                        <div>
                            <h2 className="storeProducts">Productos</h2>
                        </div>
                        {/* Productos */}
                        <div className="">
                            <ProductList numero={0} products={products} filter='' mobile={mobile} catalogo={false} />
                        </div>
                        {/* Botón para ver más */}
                        <div className="ver-mas-parent-home">
                            <p onClick={this.loadMoreProducts} id="verMasBtn" className="btn btn-ver-mas">Ver Más</p>
                        </div>
                    </div>
                </div>
            );
        }else{
            return(
                <div className="container spinner footer-spacing">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    
    const negocio_id = ownProps.match.params.id; //id de negocio
    const auth = state.firebase.auth;
    
    return{
        negocio_id: negocio_id,
        auth: auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addChat: (sNegocioID, sVendedorID) => dispatch(addChat(sNegocioID, sVendedorID)),
        updateLastOpened: (sChatID) => dispatch(updateLastOpened(sChatID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreNegocios);
