const initState = {
    retiros: [
        {id: '1', usuario_id:'5', cantidad:200}
    ]
};

const retiroReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_RETIRO':
            console.log('Added retiro', action.retiro);
            return state;
        case 'ADD_RETIRO_ERROR':
            console.log('Added Retiro Error', action.err);
            return state;
        case 'ADD_BANK_ACCOUNT':
            console.log('Added Bank Account', action.account);
            return state;
        case 'ADD_BANK_ACCOUNT_ERROR':
            console.log('Added Bank Account Error', action.err);
            return state;
        default:
            return state;
    }
}


export default retiroReducer;