import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingContacto = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Contacto</title>
                <meta name="description" content="¿Tienes una pregunta? Contáctanos :)"/>
            </Helmet>
            <div>
                <div className="landingClabeImgVendedor">
                    <div className="textNosotros">
                        <h4 className="textNosotros2">Contáctanos</h4>
                    </div>
                    <img src="img/landing-secure.png" className="landingClabeImgVendedor2" alt=""/>
                </div>
            </div>
            <div className="landingPage2-Contacto">
                <div className="container-fluid custom-container row">
                    <div className="col-md-5">
                        <div>
                            <h1 className="landingText-Contacto mt-5">Correo Electónico</h1>
                            <h4 className="">contacto@kanguru.mx</h4>
                            <div className="divider"></div>
                        </div>
                        <div>
                            <h1 className="landingText-Contacto mt-5">Teléfono</h1>
                            <h4 className="">+52 8115443495</h4>
                            <div className="divider"></div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <img src="img/contact-img.png" className="landingCatchImgContacto" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingContacto;
