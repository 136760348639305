import React from 'react';
import TemplateSummary from './TemplateSummary';

{/* Despliega Lista de Catalogos*/}
const TemplatesList = (props) => {

    const {templates, sDisenoEnCatalogoID, sCatalogoID, sIsNew} = props;

        return(
            <div className="row row-cols-2 gx-5 overflow-hidden">
                {templates && templates.map(template => {
                    return (
                        <div key={template.objectID}>
                            <TemplateSummary template={template}  sCatalogoID={sCatalogoID} sDisenoEnCatalogoID={sDisenoEnCatalogoID} sIsNew={sIsNew}/>
                        </div>  
                    )
                })}
            </div> 
        )
}

export default TemplatesList;