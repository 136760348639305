import React, {Component} from 'react';
import copy from 'copy-to-clipboard';
import firebase from 'firebase';
import {connect} from 'react-redux';
import FileUploader from 'react-firebase-file-uploader';
import {editTemplateDisenoCatalogo} from '../../../../store/actions/catalogoActions';
import { useHistory } from 'react-router-dom';

{/* Despliega Catalogo */}
const TemplateSummary = (props) => {
    const {template, sDisenoEnCatalogoID, sCatalogoID, sIsNew} = props;
    let imgTemplate = "https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fbanners%2Fthumbnail_template1_2200x1500.jpeg?alt=media&token=dd7ba8e1-2d53-4682-ac46-9e2b2ff79631";
    const history = useHistory();

    const handleSumbit = async (sTemplateID, sDisenoEnCatalogoID) => {
        const editTemplate = await props.editTemplateDisenoCatalogo(sTemplateID, sDisenoEnCatalogoID);
        if(editTemplate){
            let link;

            if(sIsNew !== undefined && sIsNew == "new"){
                link = "/configuracion/" + sCatalogoID + '/' + sDisenoEnCatalogoID;
            }
            else{
                link = "/catalogos";
            }
        
            history.push(link);
        }
    };
    
    return(
        <div>
            <div className="col">
                <div className="">
                    <img src={imgTemplate} className="templateImg" onClick={() => handleSumbit(template.objectID, sDisenoEnCatalogoID)}/>
                </div>
                <div className="mt-3">
                    <p className="template-name">{template.sNombre}</p>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return{
        editTemplateDisenoCatalogo: (sTemplateID, sDisenoEnCatalogoID) => dispatch(editTemplateDisenoCatalogo(sTemplateID, sDisenoEnCatalogoID))
    }
}

export default connect(null, mapDispatchToProps)(TemplateSummary);