import React, { Component} from 'react';
import ProductList from '../../products/ProductList';
import { Link } from 'react-router-dom';
import StoreList from '../../stores/StoreList';
import HomeMobileVendedor from './HomeMobileVendedor';
import Filtro from '../../filtro/Filtro';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import { Redirect, withRouter} from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import {Helmet} from "react-helmet";
import gtag, { install } from 'ga-gtag';
import './Home.css';
import toast, { Toaster } from 'react-hot-toast';
import OnBoarding from '../../onBoarding/OnBoarding';

install('G-GQ8M7NNRJ7');

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";

//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";

const ALGOLIA_INDEX = 'Kanguru_Productos';
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';

const client = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const index = client.initIndex(ALGOLIA_INDEX);
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);

class Home extends Component {

    state={
        filter: '',
        productListNum: 1,
        products: [],
        setFilteredResults: [],
        bestSellers: [],
        trending: [],
        productosNuevos: [],
        productoEstrella: "",
        negocio: false,
        stores: [],
        pageAlgolia: 0,
        filtro: {
            categoria: "",
            busqueda: "",
            envioSiguienteDia: "",
        }
    }

    notify = (type) => {
        if(type == "noProducts"){
            toast.error("¡Uy! Ya no hay más productos de esta categoría.");
        }
    }

    triggerAnalyticEvents = (eventType, content) => {
        if(eventType == "select_content"){
            gtag("event", "select_content", {
                content_type: content
            });
        }
        else if(eventType == "search"){
            gtag("event", "search", {
                search_term: content
            });
        }
        
    }

    //Function that checks if product is sold out
    isSoldOut = (product) => {
        let cantMax = 0;

        for(let i = 0; i < product.cantidades.length; i++){
            if(product.cantidades[i] > cantMax){
                cantMax = product.cantidades[i];
            } 
        }

        if(cantMax === 0){
            return true;
        }
        else{
            return false;
        }
    }

    displayFilteredProducts = async (filtro) => {
        //User selects a filter, then show first 48 filtered products
        //Only available products are displayed
        let query;
        let products;
        let busqueda;
        
        //Si hay filtro y categoria
        if(filtro.envioSiguienteDia !== "" && filtro.categoria !== ""){
            query = "categoria: " + filtro.categoria + " AND " + "isDisplayed:true AND sEnvioSiguienteDia: " + filtro.envioSiguienteDia;
            products = await index.search("", {filters: query, page: 0, hitsPerPage: 48});
            this.props.history.push('/', { filtro: filtro });
        }
        //Si hay filtro y búsqueda
        else if(filtro.envioSiguienteDia !== "" && filtro.busqueda){
            query = "isDisplayed:true AND sEnvioSiguienteDia: " + filtro.envioSiguienteDia;
            busqueda = filtro.busqueda;
            products = await index.search(busqueda, {filters: query, page: 0, hitsPerPage: 48});
        }
        //Si hay búsqueda y categoria, o sólo categoria
        else if((filtro.busqueda !== "" && filtro.categoria !== "") || filtro.categoria !== ""){
            query = "categoria: " + filtro.categoria + " AND " + "isDisplayed:true";
            products = await index.search("", {filters: query, page: this.state.pageAlgolia, hitsPerPage: 48});
            this.props.history.push('/', { filtro: filtro });
        }
        //Si hay búsqueda
        else if(filtro.busqueda !== ""){
            busqueda = filtro.busqueda;
            products = await index.search(busqueda, {filters: 'isDisplayed:true', page: 0, hitsPerPage: 48});
        }

        if(products){
            // const availableProducts = products.hits.filter((product) => {
            //     if(!this.isSoldOut(product)){
            //         return product;
            //     }
            // })
    
            this.setState({
                products: products.hits,
            })
        }
        
        
        
    }

    //Función que activa o desactiva botón de filtro
    handleBtnFiltro = () => {
        const button = document.getElementById("btnFiltro");

        if(button){
            if(this.state.filtro.envioSiguienteDia === ""){
                button.style.color = 'black';
                button.style.backgroundColor = 'white';
                button.style.border = '2px solid black';
            }
            else{
                button.style.color = 'white';
                button.style.backgroundColor = '#E75B10';
                button.style.border = '0px solid #E75B10';
            }
        }
    }

    handleClickFiltro = (filtro) => {

        //Actualiza Filtro actual
        this.setState({
            filtro: filtro
        }, async () => {
            this.displayFilteredProducts(this.state.filtro);
            this.handleBtnFiltro();
        });

        
    }

    handleClickCategoria = (e) => {
        e.preventDefault();
        // this.props.history.push('/', { categoria: e.target.id });
        const oldFilter = this.state.filtro.categoria;
        
        this.setState((prevState) => ({
          filtro: {
              ...prevState.filtro,
              categoria: e.target.id
          },
          pageAlgolia: 0

        }), async () => {
            if(oldFilter != ''){
                const element = document.getElementById(oldFilter);
                const element2 = document.getElementById(this.state.filtro.categoria);
                element.style.color = 'black';
                element2.style.color = '#E75B10';
            }else{
                const element2 = document.getElementById(this.state.filtro.categoria);
                if(element2){
                    element2.style.color = '#E75B10';
                }
            }
            this.displayFilteredProducts(this.state.filtro);
        });

        this.triggerAnalyticEvents("select_content", toString(e.target.id));
    };

    handleShowAllProducts = () => {
        const nextState = this.state.productListNum == 1 ? 0 : 1;
        this.setState({
            productListNum: nextState
        });
    }

    loadMoreProducts = async (e) => {
        e.preventDefault();
        const filter = this.state.filtro.categoria;
        const pageAlgolia = this.state.pageAlgolia + 1;
        const products = await index.search(filter, {filters: 'isDisplayed:true', page: pageAlgolia, hitsPerPage: 48});
        // const availableProducts = products.hits.filter((product) => {
        //     if(!this.isSoldOut(product)){
        //         return product;
        //     }
        // })
        
        const productsNew = this.state.products.concat(products.hits);
    
        if(products.hits.length === 0){
            this.notify("noProducts");
            this.showVerMasBtn("hide");
        }
        else{
            this.setState({
                pageAlgolia: pageAlgolia,
                products: productsNew
            })
            this.showVerMasBtn("show");
        }
    }

    getProductosNuevos = async () => {
        const productosNuevos = await index.search("", {filters: 'isDisplayed:true', hitsPerPage: 24});
        const productosNuevosSorted = productosNuevos.hits.sort((a,b) => {
            if(a.publicoEn < b.publicoEn ){
                return -1;
            }
            else if(a.publicoEn > b.publicoEn ){
                return 1;
            }
            else{
                return 0;
            }
        });
        return productosNuevosSorted;
    }

    showVerMasBtn = (action) => {
        const verMasBtn = document.getElementById("verMasBtn");
        console.log(this.state.products.length);
        if(verMasBtn){
            if(this.state.products.length < 48 || action === "hide"){
                verMasBtn.style.display = 'none';
                
            }
            else{
                verMasBtn.style.display = 'inline';
            }
        }
    }

    async componentDidUpdate(prevProps, prevState){

        const { search } = window.location;
        let busqueda = new URLSearchParams(search).get('s');
        this.showVerMasBtn();

        if(!busqueda){
            busqueda = "";
        }
        //Si cambió la búsqueda
        if(busqueda !== this.state.filtro.busqueda){
            //Si hay búsqueda
            if(busqueda !== ""){
                //Changes filter color to black
                if(window.innerWidth > 600 && this.state.filtro.categoria !== ''){
                    const element = document.getElementById(this.state.filtro.categoria);
                    element.style.color = 'black';
                }
            }

            this.setState((prevState) => ({
                filtro: {
                    ...prevState.filtro,
                    busqueda: busqueda,
                    categoria: ""
                }
            }), async () => {
                this.displayFilteredProducts(this.state.filtro);
            });
        }
    }
    
    async componentDidMount(){
        const {profile} = this.props;

        let prevFiltro;
        if(this.props.history.location.state !== undefined){
            prevFiltro = this.props.history.location.state.filtro;
        }
        else{
            prevFiltro = this.state.filtro;
        }

        const bestSellers = await index.search("bestSeller", {filters: 'isDisplayed:true', hitsPerPage: 32});
        const trending = await index.search("trending", {filters: 'isDisplayed:true', hitsPerPage: 32});
        const productoEstrella = await index.getObject("olfYKDt0eQudWNc5cBFK");
        const stores = await indexUsuarios.search("", {filters: 'isBusiness: true', hitsPerPage: 100});
        
        let negocio = false;
        let productosNuevos = await this.getProductosNuevos();

        if(profile.Nombre_de_Negocio != ""){
            negocio = true;
        }

        this.setState({
            bestSellers: bestSellers.hits,
            trending: trending.hits,
            productoEstrella: productoEstrella,
            productosNuevos: productosNuevos,
            negocio: negocio,
            stores: stores.hits
        });

        if(prevFiltro.categoria !== "" || prevFiltro.busqueda !== ""){
            this.setState({
                filtro: prevFiltro
            }, async () => {
                this.displayFilteredProducts(this.state.filtro);
                this.handleBtnFiltro();
            });
        }
        
    }

    render(){
        const {auth, profile} = this.props;
        let popularProducts;
        let trendingProducts;
        let firstContent;
        let popularStores;
        const bestSellers = this.state.bestSellers;
        const trending = this.state.trending;
        const products = this.state.products;
        const stores = this.state.stores;
        const productosNuevos = this.state.productosNuevos;
        const productoEstrella = this.state.productoEstrella;
        const firstTimeLoggingIn = auth.createdAt === auth.lastLoginAt ? true : false;
        const completedOnboarding = profile.bCompletoOnboarding !== undefined && profile.bCompletoOnboarding === false;
        const showOnboarding = firstTimeLoggingIn && completedOnboarding;
        
        if(this.state.filtro.categoria === "" && this.state.filtro.busqueda === "" ){
            //Lo que ven los vendedores en al lado de las categorías al ingresar a Home
            firstContent = (
                <div className="col-md-7 mt-4 ms-md-1 ">
                    <div className="row">
                        <div className="col-md-10 row">
                            <div className="col-6">
                                <div className="mb-4 home_img_right2">
                                    <a href="https://www.youtube.com/channel/UCqYbGgZB1vaD0f950RJZUmA">
                                        <img onClick={() => this.triggerAnalyticEvents("select_content","tutoriales")} src="img/tutoriales_home.png" className="home_img_size" alt=""/>
                                    </a>
                                </div>
                                <div className="home_img_right2">
                                    <Link to="/programa-de-referidos">
                                        <img onClick={() => this.triggerAnalyticEvents("select_content", "programaDeReferidos")} src="img/programa_referidos_home.png" className="home_img_size" alt=""/>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-4 home_img_right">
                                    <Link to="/reto-semanal">
                                        <img onClick={() => this.triggerAnalyticEvents("select_content", "retoSemanal")} src="img/reto_semanal_home.png" className="home_img_size2" alt=""/>
                                    </Link>
                                </div>
                                <div className="home_img_right">
                                    <Link to="/cobertura">
                                        <img onClick={() => this.triggerAnalyticEvents("select_content", "coberturaPCE")} src="img/pce_cobertura_home.png" className="home_img_size2" alt=""/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <a href="https://www.youtube.com/watch?v=urA7egjjwp8&t=3s" target="_blank">
                                <img src="img/eres-nuevo.png" className="novedades2" alt=""/> 
                            </a>
                        </div>
                    </div>
                </div>
            );
            
            popularProducts = (
                <div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h3 className="contentText">
                                Más Vendidos
                            </h3>
                            <div className="homeContent">
                                <ProductList numero={this.state.productListNum} products={bestSellers} filter={this.state.filtro.categoria} catalogo={false}/> 
                            </div>
                        </div>
                    </div>
                </div>
            );

            trendingProducts = (
                <div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <h3 className="contentText">
                                Trending
                            </h3>
                            <div className="homeContent">
                                <ProductList numero={this.state.productListNum} products={trending} filter={this.state.filtro.categoria} catalogo={false}/> 
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* Ver más
                        <button className="btnVerMas" type="button" onClick={this.handleShowAllProducts}> {this.state.productListNum == 1 ? "Ver Más" : "Ver Menos"} </button> */}
                    </div>
                </div>
            );


            popularStores = (
                <div className="mt-5 videosHome">
                    <h3 className="contentTextTiendas">
                        Aprende a Vender con Kanguru
                    </h3>

                    <div className="row gx-3">
                        <div className="col-md-3 mt-4 videoSpacing col-12">
                            <iframe className="video" frameborder="0" allowfullscreen
                                src="https://www.youtube.com/embed/zRdxrNKOkjc">
                            </iframe>
                            <p className="videoCaption">Cómo Funciona Kanguru</p>
                        </div>
                        <div className="col-md-3 mt-4 videoSpacing col-12">
                            <iframe className="video" frameborder="0" allowfullscreen
                                src="https://www.youtube.com/embed/urA7egjjwp8">
                            </iframe>
                            <p className="videoCaption">Cómo Levantar un Pedido de Pago Contra Entrega</p>
                        </div>
                        <div className="col-md-3 mt-4 videoSpacing col-12">
                            <iframe className="video" frameborder="0" allowfullscreen
                                src="https://www.youtube.com/embed/E1aGIQfmZDM">
                            </iframe>
                            <p className="videoCaption">Cómo Publicar en Facebook Marketplace.</p>
                        </div>
                        
                        <div className="col-md-3 mt-4 mb-2 col-12">
                            <iframe className="video" frameborder="0" allowfullscreen
                                src="https://www.youtube.com/embed/jGbYuDVXj_4">
                                
                                
                            </iframe>
                            <p className="videoCaption">Cómo Editar el Precio para Aumentar tu Ganancia</p>
                        </div>
                    </div>
                    
                    
                    {/* <div className="col-md-2 me-md-4 mt-4 col-6">
                        <Link to="/store/hYcxg5jQDCb0mPh75Z05DwClAl23">
                            <img src="img/vans_home.png" className="storesHome" alt=""/>
                        </Link>
                    </div>
                    <div className="col-md-2 me-md-4 mt-4 col-6">
                        <Link to="/store/0tskUsErRuVle7Xu8r7KOntoHLl2">
                            <img src="img/fila_home.png" className="storesHome" alt=""/>
                        </Link>
                    </div>
                    <div className="col-md-2 mt-4 col-6 noDispHomeMobile">
                        <Link to="/store/XbHVCU07Wfh00uoa0dN8UZq7fn92" className="storesHome" alt="">
                            <img src="img/puma_home.png" className="storesHome" alt=""/>
                        </Link>
                    </div> */}
                </div>
            );

        }
        else if(this.state.filtro.busqueda !== "" || this.state.filtro.categoria !== ""){
            if(this.state.filtro.categoria == 'marcas'){
                firstContent = (
                    <div className="col-md-9 mt-5 ms-md-3 productsHome">
                        <StoreList stores={stores}/> 
                    </div>
                    
                );
                
                popularStores = (
                    null
                );
    
                popularProducts = (
                    null
                );
            }
            else{
                //Si hay categoria y también query, entonces gana filtro
                if(this.state.filtro.categoria !== "" && this.state.filtro.busqueda !== ""){
                    firstContent = (
                        <div className="col-md-9 mt-3 ms-md-3 productsHome" id="firstContent">
                            {/* Filtro */}
                            <div>
                                <p id="btnFiltro" className="btn btn-filtro mt-3" data-bs-toggle="modal" data-bs-target="#filtroModal"><i className="bi bi-funnel-fill me-2"></i>Filtro</p>
                            </div>
                            
                            <div>
                                <ProductList numero={0} products={products} filter={this.state.filtro.categoria} catalogo={false}/> 
                            </div>
                            
                            {/* Ver Más */}
                            <div className="ver-mas-parent-home">
                                <p id="verMasBtn" id="verMasBtn" className="btn btn-ver-mas">Ver Más</p>
                            </div>
                        </div>
                    );
                }
                //Si hay categoria
                else if(this.state.filtro.categoria !== ""){
                    firstContent = (
                        <div className="col-md-9 mt-3 ms-md-3 productsHome" id="firstContent">
                            {/* Filtro */}
                            <div>
                                <p id="btnFiltro" className="btn btn-filtro mt-3" data-bs-toggle="modal" data-bs-target="#filtroModal"><i className="bi bi-funnel-fill me-2"></i>Filtro</p>
                            </div>
                            
                            <div>
                                <ProductList numero={0} products={products} filter={this.state.filtro.categoria} catalogo={false}/> 
                            </div>
                            
                            {/* Ver Más */}
                            <div className="ver-mas-parent-home">
                                <p onClick={this.loadMoreProducts} id="verMasBtn" className="btn btn-ver-mas">Ver Más</p>
                            </div>
                        </div>
                    );
                }
                //Si hay query pero no se encontró ningun resultado
                else if(products.length === 0){
                    firstContent = (
                        <div className="col-md-9 ms-md-3">
                            {/* Filtro */}
                            <div>
                                <p id="btnFiltro" className="btn btn-filtro sin-resultados-btn" data-bs-toggle="modal" data-bs-target="#filtroModal"><i className="bi bi-funnel-fill me-2"></i>Filtro</p>
                            </div>

                            <div className="sin-resultados">
                                
                                <div>
                                    <h4>Lo sentimos, no encontramos productos en base a tu búsqueda.</h4>
                                    <h4 className="mt-3">Por favor intenta nuevamente con una búsqueda o filtro diferente.</h4>
                                </div>
                            </div>
                        </div>
                        
                    )
                    
                }
                //Si hay query
                else{
                    firstContent = (
                        <div className="col-md-9 mt-3 ms-md-3 productsHome" id="firstContent">
                            {/* Filtro */}
                            <div>
                                <p id="btnFiltro" className="btn btn-filtro mt-3" data-bs-toggle="modal" data-bs-target="#filtroModal"><i className="bi bi-funnel-fill me-2"></i>Filtro</p>
                            </div>

                            {/* Productos */}
                            <div>
                                <ProductList numero={0} products={products} filter={''} catalogo={false}/>
                            </div>
                            
                            {/* Ver Más
                            <div className="ver-mas-parent-home">
                                <p className="btn btn-ver-mas">Ver Más</p>
                            </div> */}
                        </div>
                    );
                }
                
                popularStores = (
                    null
                );
    
                popularProducts = (
                    null
                );
            }
        }
        else{
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            ); 
        }
        
        //Si eres un negocio, no te sale HomePara que sólo te salga tu dashboard si eres negocio
        // if(auth.isLoaded && auth.uid && profile.isLoaded && profile.Nombre_de_Negocio != ''){
        //     return(
        //         <Redirect to ={'/dashboard-negocio/' + auth.uid}/>  
        //     );
        // }

        
        if(stores && productoEstrella !== "" && productosNuevos.length !== 0 && profile && auth.isLoaded && auth.uid && profile.isLoaded){

            //HOME EN MOBILE
            if(window.innerWidth < 600){
                if(showOnboarding){
                     return (
                        <OnBoarding auth={auth} firstTimeLoggingIn={firstTimeLoggingIn}/>
                     )
                }
                else if(this.state.filtro.busqueda === ""){
                    return(
                        <HomeMobileVendedor bestSellers={bestSellers} trending={trending} productoEstrella={productoEstrella} productosNuevos={productosNuevos}/>
                    )
                }
                else if(products.length === 0){
                    return(
                        <div lang="es" className="notranslate" translate="no">
                            {/* Dynamic Description*/}
                            <Helmet>
                                <title>Inicio</title>
                                <meta name="title" content="Inicio" />
                            </Helmet>

                            {/* Notifications Toast*/}
                            <Toaster 
                                toastOptions={{
                                    duration: 5000,
                                    position: 'top-center',
                                }}
                            />

                            {/* Modal de Filtro */}
                            <Filtro filtro={this.state.filtro} handleClickFiltro={this.handleClickFiltro}/>
                            
                            <div className="container">
                                {/* Filtro */}
                                <div className="">
                                    <p id="btnFiltro" className="btn btn-filtro mt-3" data-bs-toggle="modal" data-bs-target="#filtroModal"><i className="bi bi-funnel-fill me-2"></i>Filtro</p>
                                </div>

                                <div className="sin-resultados">
                                    <h4>Lo sentimos, no encontramos productos en base a tu búsqueda.</h4>
                                    <h4 className="mt-4">Por favor intenta nuevamente con una búsqueda o filtro diferente.</h4>
                                </div>
                            </div>
                        </div>
                    ); 
                }
                else if(products.length > 0){
                    return(
                        <div lang="es" className="notranslate search-mobile" translate="no">
                            {/* Dynamic Description*/}
                            <Helmet>
                                <title>Inicio</title>
                                <meta name="title" content="Inicio" />
                            </Helmet>

                            {/* Notifications Toast*/}
                            <Toaster 
                                toastOptions={{
                                    duration: 5000,
                                    position: 'top-center',
                                }}
                            />

                            {/* Modal de Filtro */}
                            <Filtro filtro={this.state.filtro} handleClickFiltro={this.handleClickFiltro}/>
                            
                            <div className="container-fluid">
                                {/* Filtro */}
                                <div className="">
                                    <p id="btnFiltro" className="btn btn-filtro mt-3" data-bs-toggle="modal" data-bs-target="#filtroModal"><i className="bi bi-funnel-fill me-2"></i>Filtro</p>
                                </div>

                                <ProductList numero={0} products={products} filter={''} mobile={true} catalogo={false}/>
                            </div>
                        </div>
                    )
                }
                else{
                    return(
                        <div className="container spinner">
                            <div class="spinner-border spinner-color" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ); 
                }
            }
            else{
                return(
                    <div lang="es" className="notranslate" translate="no">
                        {/* Dynamic Description*/}
                        <Helmet>
                            <title>Inicio</title>
                            <meta name="title" content="Inicio" />
                        </Helmet>

                        {/* Notifications Toast*/}
                        <Toaster 
                            toastOptions={{
                                duration: 5000,
                                position: 'top-center',
                            }}
                        />

                        {/* Modal de Filtro */}
                        <Filtro filtro={this.state.filtro} handleClickFiltro={this.handleClickFiltro}/>
                        
                        <div className="container-fluid custom-container footer-spacing" id="container">
                            {/* Whats App Btn */}
                            <a href="https://api.whatsapp.com/send?phone=+524438011119&text=Hola, buenos días" className="floating" target="_blank">
                                <i className="bi bi-whatsapp float-button"></i>
                            </a>

                            <div className="row">
                                {/* Side Menu */}
                                <div className="col-md-3 side-menu-home mt-4">
                                    {/* Titulo de Filtro */}
                                    <p className="side-menu-filter-title"><i class="bi bi-filter me-2"></i>Categorias</p>
                                            
                                    {/* Marcas */}
                                    <p onClick={this.handleClickCategoria} className="side-menu-home-text" id="marcas"><i class="bi bi-shop me-2"></i>Buscar por Tienda</p>
    
                                    {/* Accesorios para Vehiculos */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="accesoriosParaVehiculos"><i class="bi bi-gear-wide-connected me-2"></i>Accesorios para Vehículos</a></p>
                                    
                                    {/* Belleza */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="belleza"><i class="bi bi-emoji-heart-eyes me-2"></i>Belleza</a></p>
                                    
                                    {/* Bienestar */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="bienestar"><i class="bi bi-tsunami me-2"></i>Bienestar</a></p>
                                    
                                    {/* Bebes */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="bebes"><i class="bi bi-flower2 me-2"></i>Bebés</a></p>
                                    
                                    {/* Calzado */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="calzado"><i class="bi bi-tag me-2"></i>Calzado</a></p>
    
                                    {/* Cocina */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="cocina"><i class="bi bi-egg-fried me-2"></i>Cocina</a></p>
    
                                    {/* Fitness */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="fitness"><i class="bi bi-bicycle me-2"></i>Fitness</a></p>
    
                                    {/* Hogar */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="hogar"><i class="bi bi-house me-2"></i>Hogar</a></p>
                                       
                                    {/* Tecnología */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria } id="tecnologia"><i class="bi bi-laptop me-2"></i>Tecnología</a></p>

                                    {/* Sex Toys */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria } id="sexToys"><i class="bi bi-gender-ambiguous me-2"></i>Sex Toys</a></p>
    
                                    {/* Juegos y Juguetes */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="juegosYJuguetes"><i class="bi bi-joystick me-2"></i>Juguetes Infantiles</a></p>
    
                                    {/* Joyería */}
                                    <p><a className="side-menu-home-text"  onClick={this.handleClickCategoria} id="joyería"><i class="bi bi-gem me-2"></i>Joyería</a></p>
    
                                    {/* Mascotas */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="mascotas"><i class="bi bi-piggy-bank me-2"></i>Mascotas</a></p>
                                    
                                    {/* Moda */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="moda"><i class="bi bi-handbag me-2"></i>Moda</a></p>

                                    {/* Optica */}
                                    <p><a className="side-menu-home-text" onClick={this.handleClickCategoria} id="optica"><i class="bi bi-sunglasses me-2"></i>Lentes y Óptica</a></p>
                                </div>
                                
                                {/*First Content*/}
                                {firstContent}
                            </div>
                            
                            {/*Mejores Tiendas*/}
                            {popularStores}
    
                            {/* Más Vendidos */}
                            {popularProducts}
                            
                            {/* Trending */}
                            {trendingProducts}
                        </div>
                    </div> 
                );
            }
        }
        else{
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            ); 
        }
    }
}

const mapStateToProps = (state) => {
    
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

export default connect(mapStateToProps, null)(Home);