import React, { Component } from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import {addImages} from '../../store/actions/negocioActions';
import {addLogo} from '../../store/actions/negocioActions';

class NegocioStoreDashboard extends Component {
    state={
        negocio_id: this.props.auth.uid,

        //Imagenes de Store
        filenames: [],
        downloadURLs: [],
        isUploading: false,
        uploadProgress: 0,

         //Logo
         filenames2: [],
         downloadURLs2: [],
         isUploading2: false,
         uploadProgress2: 0,
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleUploadStart2 = () => this.setState({ isUploading2: true, progress: 0 });
    
    handleProgress = progress => this.setState({ progress });
    handleProgress2 = progress => this.setState({ progress });
    
    
    handleDownload = async newFileFinal=> {
        const downloadURL = await firebase
          .storage()
          .ref("images")
          .child(newFileFinal)
          .getDownloadURL();

        this.setState(oldState => ({
            filenames: [...oldState.filenames, newFileFinal],
            downloadURLs: [...oldState.downloadURLs, downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
    }

    handleUploadSuccess = async filename => {
        //New File URL
        let newFile;
        let newFileFinal;
        if(filename.substring(filename.length - 5, filename.length) === ".jpeg"){
            //JPEG
            newFile = filename.substring(0,filename.length - 5);
            newFileFinal = newFile + '_576x691.jpeg';
        }
        else{
            //PNG O JPG
            newFile = filename.substring(0,filename.length - 4);
            newFileFinal = newFile + '_576x691.jpeg';
        }

        setTimeout(() => {
            this.handleDownload(newFileFinal);
        }, 5000);
    };

    handleDownload2 = async newFileFinal=> {
        const downloadURL = await firebase
          .storage()
          .ref("images")
          .child(newFileFinal)
          .getDownloadURL();

        this.setState(oldState => ({
            filenames2: [...oldState.filenames2, newFileFinal],
            downloadURLs2: [...oldState.downloadURLs2, downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
    }

    handleUploadSuccess2 = async filename => {
        //New File URL
        let newFile;
        let newFileFinal;
        if(filename.substring(filename.length - 5, filename.length) === ".jpeg"){
            //JPEG
            newFile = filename.substring(0,filename.length - 5);
            newFileFinal = newFile + '_576x691.jpeg';
        }
        else{
            //PNG O JPG
            newFile = filename.substring(0,filename.length - 4);
            newFileFinal = newFile + '_576x691.jpeg';
        }
        

        setTimeout(() => {
            this.handleDownload2(newFileFinal);
        }, 5000);
    };

    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadError2 = error => {
        this.setState({ isUploading2: false });
        console.error(error);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        //Checa si hay logo y imagenes de store
        if(this.state.downloadURLs2[0] && this.state.downloadURLs[0]){
            if(this.state.downloadURLs.length != 4){
                alert('Asegurate de subir 4 fotos para decorar tu tienda. Si ya subiste 4 fotos, entonces esperate 10 segundos, y después vuelve a seleccionar el botón "Agregar".')
            }
            else{
                this.props.addImages(this.state); 
                this.props.addLogo(this.state.downloadURLs2, this.state.negocio_id); 
            }
        }
        else if(this.state.downloadURLs[0]){
            if(this.state.downloadURLs.length != 4){
                alert('Asegurate de subir 4 fotos para decorar tu tienda. Si sí subiste 4 fotos, entonces esperate 10 segundos, y después vuelve a seleccionar el botón "Agregar".')
            }
            else{
                this.props.addImages(this.state); 
            }
        }
        else if(this.state.downloadURLs2[0]){
            this.props.addLogo(this.state.downloadURLs2, this.state.negocio_id); 
        }
        else{
            alert( 'Se están processando tus imagenes. Esperate 10 segundos, y después vuelve a seleccionar el botón "Agregar".')
        }
    };
    
    render(){

        return(
            <form className="row g-3 mt-1 mt-md-0">
                
                {/* Imagenes para Decorar Tienda */}
                <div className="col-12">
                    {this.state.avatarURL && <img src={this.state.avatarURL} />}
                    <h6 for="imagenes" className="form-label addProductFormLabel">Agrega Cuatro Imágenes para Decorar tu Tienda</h6>
                    <p>*Selecciona las 4 imagenes al mismo tiempo. Sólo se aceptan formatos: .jpg o .jpeg o .png </p>
                    <FileUploader
                        accept="image/*"
                        name="image-uploader-multiple"
                        randomizeFilename
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        multiple
                    />
                    {this.state.isUploading && <p className="mt-3">Se están cargando las imágenes...</p>}
                </div>

                 {/* Logo */}
                 <div className="col-12">
                    {this.state.avatarURL && <img src={this.state.avatarURL} />}
                    <h6 for="imagenes" className="form-label addProductFormLabel mt-3">Logo (.jpg o .jpeg o .png)</h6>
                    <FileUploader
                        accept="image/*"
                        name="image-uploader"
                        randomizeFilename
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError2}
                        onUploadSuccess={this.handleUploadSuccess2}
                        onProgress={this.handleProgress2}
                    />
                    {this.state.isUploading2 && <p className="mt-3">Se están cargando las imágenes...</p>}
                </div>
                
                {/* Submit Button */}
                <div class="col-12">
                    <button onClick={this.handleSubmit} type="submit" className="btn btn-primary mt-3">Agregar</button>
                </div>

                <div className="mt-4">
                    <a href={'/store/' + this.props.auth.uid}>Tu Tienda</a>
                </div>
                
            </form>
        );
    }
}

//Get user data so we can edit the images in his store
const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addImages: (images) => dispatch(addImages(images)),
        addLogo: (images, negocio) => dispatch(addLogo(images, negocio)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NegocioStoreDashboard);
