import React from 'react'
import '../../App.css';


const CheckoutNav = (props) => {
    let checkoutNav;

    //El navbar está dentro de un catálogo es null
    if(window.location.href.indexOf("catalogoenlinea.mx") > -1){
        checkoutNav = (
            null
        );
    }
    else if(window.location.href.indexOf("kangupago.com") > -1){
        checkoutNav = (
            <div>
                <nav class="navbar navbar-expand-md navbar-light fixed">
                    <div className="logoCheckout logoCheckout-kangupago">
                        <img src="/img/Kangupago_Logo.png" alt="" className="logo_kangupago"/>
                    </div>
                </nav>
            </div> 
        );
    }
    else{
        checkoutNav = (
                <div>
                    <nav class="navbar navbar-expand-md navbar-light fixed">
                        <div className="logoCheckout">
                            <img src="/img/Kanguru_logo.png" alt="" className="logo"/>
                        </div>
                    </nav>
                </div> 
        );
    }
    return(
        <div>
            {checkoutNav}
        </div>
        
    );

    
}


export default (CheckoutNav);