import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import algoliasearch from 'algoliasearch';
import {addMessage, updateLastOpened, addChatWarning} from '../../store/actions/chatActions';
import firebase from "../../config/fbConfig";
import {useCollectionData} from 'react-firebase-hooks/firestore';
import ChatsList from './ChatsList';
import Chat from './Chat';
import './Chat.css';
import { Link } from 'react-router-dom';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_CHATS = "95e6ac5a71881cf27f0a4566a7719b90";
const ALGOLIA_INDEX_CHATS = 'Kanguru_Chats';
const client_Chats= algoliasearch(APPLICATION_ID, SEARCH_API_KEY_CHATS);
const indexChats = client_Chats.initIndex(ALGOLIA_INDEX_CHATS);

const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);


const Chats = (props) => {

    //State Variables
    const [chats, setChats] = useState("");
    const [chat, setChat] = useState("");
    const [receiver, setReceiever] = useState("");
    const [showLoader, setShowLoader] = useState(true);
    const [mobileDisplayedContent, setMobileDisplayedContent] = useState("chatList");
    const {auth, addMessage, addChatWarning, updateLastOpened} = props;

    
    //Función de para obtener Chats de este Afiliado
    const getChats = async () => {
        //Gets Chats of this Afiliado
        const filter = "aUserIDs:" + auth; 
        const chats = await indexChats.search("", {filters: filter, hitsPerPage: 50});
        
        //Se consideran los chats que no tienen tLastOpenedAt en el else
        const chatsToDisplay = chats.hits.filter((chat) => {
            //Fecha de Creación de Cuenta
            let tLastOpenedAt = Number(chat.tLastOpenedAt);
        
            //Fecha del Presente Menos 1 día
            let presentDate = new Date();
            let presentDateMinusOneDay = presentDate.setDate(presentDate.getDate() - 1);
        
            //No ha pasado más de un día desde que abrió el chat
            const lessThanOneDay = tLastOpenedAt > presentDateMinusOneDay ?  true : false;
        
            if(chat.tLastOpenedAt){
                if(lessThanOneDay || chat.tLastChat !== undefined){
                    return chat;
                }
            }
        })

        setChats(chatsToDisplay);
    }

    //Función para Obtener Chat Seleccionado
    const getChat = async (id) => {
        const chat = await indexChats.getObject(id);
        
        if(chat){
            setChat(chat);
            getReceiver(chat);
        }
        
        //Muestra el Chat Obtenido en Mobile
        setMobileDisplayedContent("chat");

        //Se actualiza la última vez que se abrió el chat
        updateLastOpened(id);
    }


    const getReceiver = async (chat) => {
        const receiverID = getReceiverId(chat);
        const receiverTemp = await indexUsuarios.getObject(receiverID[0]);
        setReceiever(receiverTemp);
    }

    const getReceiverId = (chat) => {
        const receiverID = chat.aUserIDs.filter((id) => {
            if(id !== auth){
                return id;
            }
        })

        return receiverID;
    }

    const sendMessage = (e, message) => {
        e.preventDefault();
        addMessage(chat.objectID, message, auth);
    }

    //Función que registra los warnings de usuarios para monitorear quiénes nos están intentando saltar
    const handleWarning = (e) => {
        e.preventDefault();
        addChatWarning(auth);
    }
    
    //useEffect
    useEffect(() => {
        if(chats === ""){
            setTimeout(() => {
                setShowLoader(false);
                getChats();
            }, "2000")
        }
    }, []);

    let loader = (
        <div className="container spinner">
            <div class="spinner-border spinner-color" role="status"></div>
            <p className="mt-3">Cargando Chats.</p>
            <p className="mt-3">Si al terminar no ves el chat, recarga la página.</p>
        </div>
    );

    let goBack;
    if(window.innerWidth < 600){
        goBack = (
            <Link to="/"><i class="bi bi-chevron-left goBackIcon"></i></Link>
        )
    }
    else{
        goBack = (
            null
        )
    }


    let content; 
    //MOBILE
    if(window.innerWidth < 600){
        //Lista de Chats
        if(mobileDisplayedContent == "chatList"){
            content = (
                <div className="d-flex center-content mt-3">
                    <div className="col-12 ">
                        {/* CHATS */}
                        <div className="d-flex mb-4 ms-3">
                            <div class="goBack">
                                {goBack}
                            </div>
                            <h3 className="chats-title ms-3">Chats</h3>
                        </div>
                        <div className="divider"></div>
                        <ChatsList auth={auth} chats={chats} getChat={getChat}/>
                    </div>
                </div>
            )
        }
        //Chat
        else{
            content = (
                <div className="d-flex center-content">
                    <div className="col-12 chat">
                        {/* CHAT */}
                        <Chat auth={auth} receiver={receiver} sendMessage={sendMessage} chat={chat} handleWarning={handleWarning} setMobileDisplayedContent={setMobileDisplayedContent}/>
                    </div>
                </div>
            )
        }
    }
    //DESKTOP
    else{
        //Si no hay un chat seleccionado
        if(mobileDisplayedContent == "chatList"){
            content = (
                <div className="container footer-spacing">
                    <div className="d-flex center-content mt-5">
                        <div className="col-3 chat-side-menu">
                            {/* CHATS */}
                            <h3 className="chats-title ms-0 ms-md-3 mt-2">Chats</h3>
                            <ChatsList auth={auth} chats={chats} getChat={getChat}/>
                        </div>
                        <div className="col-8 chat-list">
                            <p>Selecciona un chat</p>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            content = (
                <div className="container footer-spacing">
                    <div className="d-flex center-content mt-5">
                        <div className="col-3 chat-side-menu">
                            {/* CHATS */}
                            <h3 className="chats-title ms-0 ms-md-3 mt-2">Chats</h3>
                            <ChatsList auth={auth} chats={chats} getChat={getChat}/>
                        </div>
                        <div className="col-8 chat">
                            {/* CHAT */}
                            <Chat auth={auth} receiver={receiver} sendMessage={sendMessage} chat={chat} handleWarning={handleWarning}/>
                        </div>
                    </div>
                </div>
            )
        }
        
    }

    
    return(
        <div>
            {!showLoader ? content:loader}
        </div>
    )
    
    
}

const mapStateToProps = (state, ownProps) => {
    const auth = state.firebase.auth;
    
    if(auth){
        return{
            auth: auth.uid
         }
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addMessage: (sChatID, sMensaje, sSenderID) => dispatch(addMessage(sChatID, sMensaje, sSenderID)),
        addChatWarning: (sUserID) => dispatch(addChatWarning(sUserID)),
        updateLastOpened: (sChatID) => dispatch(updateLastOpened(sChatID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chats);