import React from 'react'
import '../../App.css';
import {signOut} from '../../store/actions/authActions';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

const SignedOutLinks = (props) => {
    const { auth} = props;

    let Navbar_Menu;
    if(window.location.href.indexOf("catalogoenlinea.mx") > -1){
        Navbar_Menu = (
            <div className="container-fluid custom-container">
                {/* Toggle Botton for mobile nav*/}
                <button class="navbar-toggler mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                
                {/* Logo and Title of Page */}
                <a href="/" className="me-md-5 catalogo_center">
                    <img src="/img/Catalogo_en_linea_logo.png" alt="" className="logo_catalogo"/>
                </a>

                {/* Main Nav */}
                <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
                    <ul className="navbar-nav nav-black-text">
                        <li className="nav-item">
                            <a href='/signin/' className="nav-link"><span className="nav-black-text">Iniciar Sesión</span></a>
                        </li>
                    </ul>
                </div>
            </div> 
        );
    }
    else if(window.location.href.indexOf("kangupago.com") > -1){
        Navbar_Menu = (
            <div className="container-fluid custom-container">
                {/* Toggle Botton for mobile nav*/}
                <button class="navbar-toggler mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                
                {/* Logo and Title of Page */}
                <Link to="/" className="me-md-5">
                    <img src="/img/Kangupago_Logo.png" alt="" className="logo_kangupago"/>
                </Link>

                {/* Main Nav */}
                <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
                    <ul className="navbar-nav nav-black-text">
                        <li className="nav-item">
                            <a href='/signin/' className="nav-link"><span className="nav-black-text">Iniciar Sesión</span></a>
                        </li>
                    </ul>
                </div>
            </div> 
        );
    }
    else{
        Navbar_Menu = (
            <div className="container-fluid custom-container">

                {/* <div className="landing-section-navbar-decor">
                    <img src="img/navbar-landingpage-decor.png" alt="" className="landing-section-navbar-decor-img"/>
                </div> */}
                
                {/* Toggle Botton for mobile nav*/}
                <button class="navbar-toggler mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                
                {/* Logo and Title of Page */}
                <Link to="/" className="me-md-5">
                    <img src="/img/Kanguru_logo.png" alt="" className="logo"/>
                </Link>

                {/* Main Nav */}
                <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
                    <ul className="navbar-nav nav-black-text">
                        <li className="nav-item ms-0 ms-md-3">
                            <Link to='/proveedores' className="nav-link"><span className="nav-black-text">Proveedores</span></Link>
                        </li>
                        <li className="nav-item ms-0 ms-md-3">
                            <Link to='/signin/' className="nav-link"><span className="nav-black-text">Iniciar Sesión</span></Link>
                        </li>
                        
                        <li className="nav-item ms-0 ms-md-3">
                            <Link to='/signup-vendedor' className="nav-link"><span className="nav-black-text bold">Regístrate</span></Link>
                        </li>
                    </ul>
                </div>
            </div> 
        );
        
    }
    

    return(
         <div>
                <nav className="navbar navbar-expand-md navbar-light fixed landing-section-navbar">
                    {Navbar_Menu}
                </nav>
            </div> 
    );
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedOutLinks);