import React from 'react';
import axios from 'axios';
import ReseñasEstrellas from '../reviews/ReseñasEstrellas';

{/* Despliega Lista de Ventas en CuentaVendedor */}
const ReseñaSummary = (props) => {

    const {reseña} = props;

    const download = (urlImagen) => {
        
        axios.get(urlImagen, {responseType: 'arraybuffer'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/image'}));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.jpg"); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err);
        });

    };

    let fecha = new Date(reseña.createdAt).toString().substring(3,new Date().toString().indexOf("2022")+4);

    let imagen;
    if(reseña.imagen !== undefined && reseña.imagen[0]){
        imagen = (
            <div className="mt-3 imagenContainer">
                <div className="graficMaterial">
                    <img src={reseña.imagen} alt="" className="resenaImagen imageProductGraficMaterial"/>
                    <div className="downloadGraficMaterial">
                        <i onClick={() => download(reseña.imagen[0])} className="bi bi-download downloadIconResena"></i>  
                    </div>
                </div>
            </div>
            
            
        );
    }
    else{
        imagen = (
            null
       );
    }
    

    return(
        <div className="mt-4 mt-md-3 d-md-flex">
            <div className="">
                <ReseñasEstrellas rating={reseña.calificacion}/>
                <h4 className="reseñaTitulo">{reseña.titulo}</h4>
                <p className="reseñaDescripcion">{reseña.descripcion}</p>
                <p className="reseñaFecha">{fecha}</p>
            </div>
            {imagen}
        </div>
    )
    
}

export default ReseñaSummary;