import React from 'react';
import ProductInventorySummary from './ProductInventorySummary';

{/* Despliega Inventario en Dashboard de Negocio */}
const ProductInventoryList = ({products}) => {
    return(
        <div>
            <ul className="list-group">
                {products && products.map(product => {
                    return (
                        <div key={product.objectID}>
                            <ProductInventorySummary product={product}/>
                        </div>  
                    )
                })}
            </ul>
        </div> 
    )
}

export default ProductInventoryList;