import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import '../../../App.css';
import {editInfoDisenoCatalogo, editNombreCatalogo} from '../../../store/actions/catalogoActions';
import toast, { Toaster } from 'react-hot-toast';

const CatalogoConfiguracion = (props) => {
    //State Variables
    const [nombre, setNombre] = useState("");
    const {sCatalogoID, sDisenoEnCatalogoID} = props;
    const history = useHistory();

    const notify = (type) => {
        if(type == "informacionIncompletaNombre"){
            toast.error('¡Uy! No se te olvide escribir el nombre de tu gran catálogo.');
        }
    }

    const editCatalogue = async (e) => {
        e.preventDefault();
        if(nombre !== ""){
            //Crear nuevas funciones que tomen otro tipo de parámetro
            const editInfoDisenoCatalogo = await props.editInfoDisenoCatalogo(sDisenoEnCatalogoID, nombre);
            const editNombre = await props.editNombreCatalogo(sCatalogoID, nombre);
           
            if(editNombre && editInfoDisenoCatalogo){
                const link = "/catalogo/agrega-productos/" + sCatalogoID + '/' + sDisenoEnCatalogoID
                history.push(link);
            }
        }
        else{
            notify("informacionIncompletaNombre");
        }


        
    }

    return(
        <div className="container-fluid custom-container footer-spacing">
            {/* Notifications Toast*/}
            <Toaster 
                toastOptions={{
                    duration: 5000,
                    position: 'top-center',
                }}
            />

            <div className="mt-4 d-flex">
                <p className="catalogoTitle me-3">Configura tu Tienda</p>
            </div>
            <form className="mt-5">
                {/* Nombre */}
                <div className="col-12 col-md-6">
                    <label for="sNombre" className="form-label addProductFormLabel">Escoge el titulo de tu tienda</label>
                    <input type="text" onChange={(e) => setNombre(e.target.value)}  className="form-control" placeholder="Los Mejores Regalos, Electronica y Más, etc.." id="sNombre"/>
                </div>

                {/* Submit Button */}
                <div class="col-6 mt-3">
                    <button onClick={(e) => editCatalogue(e)} type="submit" className="btn btn-primary mt-3">Continuar</button>
                </div>
            </form>
        </div>

        
    )
        
}

const mapStateToProps = (state, ownProps) => {
    const sCatalogoID= ownProps.match.params.sCatalogoID; //id of product
    const sDisenoEnCatalogoID = ownProps.match.params.sDisenoEnCatalogoID;

    return{
        sCatalogoID: sCatalogoID,
        sDisenoEnCatalogoID: sDisenoEnCatalogoID,
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return{
        editInfoDisenoCatalogo: (sDisenoEnCatalogoID, sNombre) => dispatch(editInfoDisenoCatalogo(sDisenoEnCatalogoID, sNombre)),
        editNombreCatalogo: (sCatalogoID, sNombre) => dispatch(editNombreCatalogo(sCatalogoID, sNombre))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoConfiguracion);