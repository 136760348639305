export const addProduct = (product) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const envio = product.peso === "de2a3" || product.peso === "de3a5" ? 110 : product.peso === "de5a25" ? 150 : 80;
        const imagenes = product.downloadURLs;
        const comision = 15;

        //Formateamos el nombre y descripción para evitar que haya double quotations
        const nombre = product.nombre.replace(/['"]+/g, '');
        const descripcion = product.descripcion.replace(/['"]+/g, '');
        const precio = Number(product.precio.replace(/,/g, ''));
        

        const firestore = getFirestore();
        firestore.collection('Productos').add({
            nombre: nombre,
            descripcion: descripcion,
            negocio: product.negocio,
            negocio_id: product.negocio_id,
            negocio_email: product.negocio_email,
            precio: precio,
            precioMayoreo: product.precioMayoreo,
            cantMinMayoreo: product.cantMinMayoreo,
            costoDeEnvio: envio,
            comision: comision,
            loyaltyBonus: product.loyaltyBonus,
            categoria:product.categoria,
            variaciones: product.variaciones,
            cantidades: product.cantidades,
            personalizado: product.personalizado,
            imagenesDeProducto: imagenes,
            imagenesDeMaterialGraficoDeProducto: [],
            peso: product.peso,
            nVisitas: 0,
            nVentas: 0, 
            nTasaDeConversionVentasVisitas: 0,
            isDisplayed: true,
            tCreatedAt: new Date()
        }).then(() => {
            dispatch({ type: 'ADD_PRODUCT', product});
        }).then(() => {
            window.location.reload(false); 
        }).catch((err) => {
            dispatch({ type: 'ADD_PRODUCT_ERROR', err});
        })
        
    }
};

export const addProductCSV = (product, state, imagenesDeProducto, cantidades, variaciones) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //Change peso to valid value
        let peso = Number(product[4]);
        if(peso < 1){
            peso = "menosDe1";
        }
        else if(peso <= 2){
            peso = "de1A2";
        }
        else if(peso <= 3){
            peso = "de2a3";
        }
        else if(peso < 5){
            peso = "de3a5";
        }
        else{
            peso = "de5a25";
        }

        const envio = peso === "de2a3" || peso === "de3a5" ? 110 : peso === "de5a25" ? 150 : 80;

        //Formateamos el nombre y descripción para evitar que haya double quotations
        let nombre = product[0].replace(/['"]+/g, '');
        let descripcion = product[1].replace(/['"]+/g, '');
        
        const firestore = getFirestore();
        firestore.collection('Productos').add({
            nombre: nombre,
            descripcion: descripcion,
            precio: Number(product[2]),
            categoria: product[3],
            comision: 15,
            cantidades: cantidades,
            variaciones: variaciones,
            peso: peso,
            costoDeEnvio: envio,
            precioMayoreo: "",
            cantMinMayoreo: "",
            personalizado: "0",
            loyaltyBonus: 0,
            negocio: state.negocio,
            negocio_id: state.negocio_id,
            negocio_email: state.negocio_email,
            imagenesDeProducto: imagenesDeProducto,
            imagenesDeMaterialGraficoDeProducto: [],
            isDisplayed: true,
            nVisitas: 0,
            nVentas: 0, 
            nTasaDeConversionVentasVisitas: 0,
            pending: false,
            tCreatedAt: new Date()
        }).then(() => {
            dispatch({ type: 'ADD_PRODUCT_CSV', product});
        }).catch((err) => {
            dispatch({ type: 'ADD_PRODUCT_CSV_ERROR', err});
        })
    }
};

export const editProductCSV = (product, imagenesDeProducto, cantidades, variaciones) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //Change peso to valid value
        let productID = product[0];
        let peso = Number(product[5]);
        if(peso < 1){
            peso = "menosDe1";
        }
        else if(peso <= 2){
            peso = "de1A2";
        }
        else if(peso <= 3){
            peso = "de2a3";
        }
        else if(peso < 5){
            peso = "de3a5";
        }
        else{
            peso = "de5a25";
        }

        const envio = peso === "de2a3" || peso === "de3a5" ? 150 : peso === "de5a25" ? 200 : 100;
    
        const firestore = getFirestore();
        firestore.collection('Productos').doc(productID).update({
            nombre: product[1],
            descripcion: product[2],
            precio: Number(product[3]),
            categoria: product[4],
            cantidades: cantidades,
            variaciones: variaciones,
            peso: peso,
            costoDeEnvio: envio,
            imagenesDeProducto: imagenesDeProducto,
        }).then(() => {
            dispatch({ type: 'EDIT_PRODUCT_CSV', product});
        }).catch((err) => {
            dispatch({ type: 'EDIT_PRODUCT_CSV_ERROR', err});
        })
    }
};

export const publishPendingProduct = (productID, images) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //make async call to database
        const firestore = getFirestore();
        firestore.collection('Productos').doc(productID).set({
            imagenesDeProducto: images,
            isDisplayed: true,
            pending: false
          },{ merge: true })
        .then(() => {
            dispatch({ type: 'PUBLISH_PENDING_PRODUCT', productID}); 
        }).catch((err) => {
            dispatch({ type: 'PUBLISH_PENDING_PRODUCT_ERROR', err});
        }) 
    }
}

export const deleteProduct = (product) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //make async call to database
        const firestore = getFirestore();
        firestore.collection('Productos').doc(product.objectID).set({
            isDisplayed: false
          },{ merge: true })
        .then(() => {
            dispatch({ type: 'DELETE_PRODUCT', product}); 
        }).catch((err) => {
            dispatch({ type: 'DELETE_ERROR', err});
        }) 
    }
};

export const displayProduct = (product) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //make async call to database
        const firestore = getFirestore();
        firestore.collection('Productos').doc(product.objectID).set({
            isDisplayed: true
          },{ merge: true })
        .then(() => {
            dispatch({ type: 'DISPLAY_PRODUCT', product}); 
        }).catch((err) => {
            dispatch({ type: 'DISPLAY_PRODUCT_ERROR', err});
        }) 
    }
};

export const deletePendingProduct = (productID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //make async call to database
        const firestore = getFirestore();
        firestore.collection('Productos').doc(productID).set({
            pending: false
          },{ merge: true })
        .then(() => {
            dispatch({ type: 'DELETE_PENDING_PRODUCT', productID}); 
        }).catch((err) => {
            dispatch({ type: 'DELETE__PENDING_PRODUCT_ERROR', err});
        }) 
    }
};

export const editProduct = (state, product) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        const envio = state.peso === "de2a3" || state.peso === "de3a5" ? 110 : state.peso === "de5a25" ? 150 : 80;
        
        let peso = state.peso;
        if(!state.peso){
            peso = "";
        }

        let imagenesDeProducto = state.downloadURLs;
        if(imagenesDeProducto.length === 0 || imagenesDeProducto === undefined){
            imagenesDeProducto = product.imagenesDeProducto;
        }
        

        firestore.collection('Productos').doc(product.objectID).set({
            nombre: state.nombre,
            descripcion: state.descripcion,
            variaciones: state.variaciones,
            cantidades: state.cantidades,
            costoDeEnvio:envio,
            precio: state.precio,
            precioMayoreo: state.precioMayoreo,
            cantMinMayoreo: state.cantMinMayoreo,
            loyaltyBonus: state.loyaltyBonus,
            categoria: state.categoria,
            peso: peso,
            imagenesDeProducto: imagenesDeProducto
        },{ merge: true })
        .then(() => {
            dispatch({ type: 'EDIT_PRODUCT', product}); 
        }).then(() => {
            window.location.reload(false);
        }).catch((err) => {
            dispatch({ type: 'EDIT_PRODUCT_ERROR', err});
        }) 
    }
};

export const addProductToCatalogo = (sCatalogoID, sProductoID, nIncremento) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
      const firebase = getFirebase();
      const firestore = getFirestore();
      firestore.collection('ProductosEnCatalogo').add({
            sCatalogoID: sCatalogoID,
            sProductoID: sProductoID,
            nIncremento: nIncremento,
            tCreatedAt: new Date(),
            bActivo: true
        })
        .then(() => {
            dispatch({ type: 'ADD_PRODUCT_CATALOGO' })
        })
        .catch(err => {
            dispatch({ type: 'ADD_PRODUCT_CATALOGO_ERROR', err })
        })
    }
  };

export const deleteProductFromCatalogue = (sProductoEnCatalogoID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
      const firebase = getFirebase();
      const firestore = getFirestore();
      console.log(sProductoEnCatalogoID);
      firestore.collection('ProductosEnCatalogo').doc(sProductoEnCatalogoID).update({
          bActivo: false
        })
        .then(() => {
            dispatch({ type: 'DELETE_PRODUCT_CATALOGO' })
        })
        .catch(err => {
            dispatch({ type: 'DELETE_PRODUCT_CATALOGO_ERROR', err })
        })
    }
};


export const editarEnvio = (product) => {
     return (dispatch, getState, {getFirebase, getFirestore}) => {
       const firebase = getFirebase();
       const firestore = getFirestore();
       firestore.collection('Productos').doc(product.id).set({
          costoDeEnvio: "100"
         },{ merge: true })
         .then(() => {
         dispatch({ type: 'UPDATE_COSTO_ENVIO_SUCCESS' })
         })
         .catch(err => {
         dispatch({ type: 'UPDATE_COSTO_ENVIO_ERROR', err })
         })
     }
};

export const editarPrecio = (product, precioNuevo) => {
     return (dispatch, getState, {getFirebase, getFirestore}) => {
       const firebase = getFirebase();
       const firestore = getFirestore();
       firestore.collection('Productos').doc(product.id).set({
          precio: precioNuevo
         },{ merge: true })
         .then(() => {
         dispatch({ type: 'UPDATE_PRECIO_SUCCESS' })
         })
         .catch(err => {
         dispatch({ type: 'UPDATE_PRECIO_ERROR', err })
         })
     }
};

export const editarComision = (product, comision) => {
     return (dispatch, getState, {getFirebase, getFirestore}) => {
       const firebase = getFirebase();
       const firestore = getFirestore();
       firestore.collection('Productos').doc(product.id).set({
          comision: comision
         },{ merge: true })
         .then(() => {
         dispatch({ type: 'UPDATE_COMISION_SUCCESS' })
         })
         .catch(err => {
         dispatch({ type: 'UPDATE_COMISION_ERROR', err })
         })
     }
};

export const editarProductLinksCatalogo = (linkProductsCatalogoNuevo, usuarioID) => {
     return (dispatch, getState, {getFirebase, getFirestore}) => {
       const firebase = getFirebase();
       const firestore = getFirestore();
       firestore.collection('Usuarios').doc(usuarioID).set({
          linkProductsCatalogo: linkProductsCatalogoNuevo
         },{ merge: true })
         .then(() => {
         dispatch({ type: 'UPDATE_LINKS_CATALOGO_SUCCESS' })
         })
         .catch(err => {
         dispatch({ type: 'UPDATE_LINKS_CATALOGO_ERROR', err })
         })
     }
};
    