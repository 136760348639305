export const addDisenoCatalogo = (sCatalogoID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        return firestore.collection('DisenoEnCatalogo').add({
            bActivo: true,
            sCatalogoID: sCatalogoID,
            sNombre: "",
            sTemplateID: "",
            tCreatedAt: new Date()
        }).then((docRef) => {
            dispatch({ type: 'ADD_DISENO_CATALOGO'}); 
            return docRef.id;
        }).catch((err) => {
            dispatch({ type: 'ADD_DISENO_CATALOGO_ERROR', err});
        }) 
    }
};

export const editInfoDisenoCatalogo = (sDisenoEnCatalogoID, sNombre) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        return firestore.collection('DisenoEnCatalogo').doc(sDisenoEnCatalogoID).update({
            sNombre: sNombre
        }).then(() => {
            dispatch({ type: 'EDIT_INFO_CATALOGO'}); 
            return sDisenoEnCatalogoID;
        }).catch((err) => {
            dispatch({ type: 'EDIT_INFO_CATALOGO_ERROR', err});
        }) 
    }
};

export const editNombreCatalogo = (sCatalogoID, nombre) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        return firestore.collection('Catalogos').doc(sCatalogoID).update({
            sNombre: nombre
        }).then(() => {
            dispatch({ type: 'EDIT_NOMBRE_CATALOGO'});
            return sCatalogoID;
        }).catch((err) => {
            dispatch({ type: 'EDIT_NOMBRE_CATALOGO_ERROR', err});
        }) 
    }
};

export const editTemplateDisenoCatalogo = (sTemplateID, sDisenoEnCatalogoID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        return firestore.collection('DisenoEnCatalogo').doc(sDisenoEnCatalogoID).update({
            sTemplateID: sTemplateID,
        }).then(() => {
            dispatch({ type: 'EDIT_TEMPLATE_CATALOGO'}); 
            return sTemplateID;
        }).catch((err) => {
            dispatch({ type: 'EDIT_TEMPLATE_CATALOGO_ERROR', err});
        }) 
    }
};

export const addCatalogo = (vendedorID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        return firestore.collection('Catalogos').add({
            bActivo: true,
            sVendedorID: vendedorID,
            sNombre: "",
            tCreatedAt: new Date()
        }).then(async (docRef) => {
            const sDisenoEnCatalogoID = await dispatch(addDisenoCatalogo(docRef.id));
            const catalogo = {
                sCatalogoID: docRef.id,
                sDisenoEnCatalogoID: sDisenoEnCatalogoID
            }
            return catalogo;
        }).catch((err) => {
            dispatch({ type: 'ADD_CATALOGO_ERROR', err});
        }) 
    }
};

export const deleteCatalogo = (sCatalogoID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Catalogos').doc(sCatalogoID).update({
            bActivo: false
        }).then(() => {
            dispatch({ type: 'DELETE_CATALOGO'}); 
        }).catch((err) => {
            dispatch({ type: 'DELETE_CATALOGO_ERROR', err});
        }) 
    }
};





