import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingRetoSemanal = () => {
    return(
        <div className="footer-spacing mb-5">
            {/* Dynamic Description*/}
            <Helmet>
                <title>Reto Semanal</title>
                <meta name="description" content="¡Conoce el reto semanal y aumenta tus ingresos!"/>
            </Helmet>
            <div>
                <div className="landingClabeImgVendedor">
                    <div className="textNosotros">
                        <h4 className="textRetoSemanal">Reto Semanal</h4>
                    </div>
                    <img src="img/retoSemanal-bg.png" className="landingClabeImgVendedor2" alt=""/>
                </div>
            </div>
            <div className="landingPage2-Nosotros">
                <div className="container-fluid custom-container row">
                    <div className="retoSemanalImg">
                        <img src="img/reto_semanal_jul26_2.png" className="landingCatchImgRS" alt=""/>
                    </div>
                </div>
            </div>
            <div className="landingPageVendedores1">
                <div className="estadosCoberturaPCE">
                    <p className="estadosCoberturaPCEText">Condiciones</p>
                </div>
                <div className="estadosCoberturaPCE">
                    <p className="municipiosCoberturaPCEText">1. Si tu venta se realizó dentro del periodo pero el cliente no recibió su orden antes de Julio 10 por temas de envío, aún se tomará en cuenta la venta para el reto.</p>
                    <p className="municipiosCoberturaPCEText">2. Esta promoción no aplica para productos de Squareshop y Kanguru.</p>
                    <p className="municipiosCoberturaPCEText">3. Esta promoción aplica del 25 al 30 de Julio.</p>
                </div>
            </div> 
        </div>
    );
}

export default LandingRetoSemanal;
