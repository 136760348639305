import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingEnvios = () => {
    return(
        <div className="container footer-spacing">
            {/* Dynamic Description*/}
            <Helmet>
                <title>Envíos y Devoluciones</title>
                <meta name="description" content="Conoce todo acerca de nuestros envíos y devoluciones."/>
            </Helmet>
            <h1 className="terminosCondicionesCatch">ENVÍOS Y DEVOLUCIONES</h1>
            <div className="mt-5">
                <h5 className="enviosCatch">Entregas</h5>
                <h5 className="mt-5 terminosCondicionesEnfasis">¿Cuánto tardaré en recibir mi producto?</h5>
                <p>
                    Tu pedido te llegará en un plazo de 3-5 días hábiles, dependiendo de la distancia que debe recorrer el pedido. 
                </p>
                <h5 className="mt-5 terminosCondicionesEnfasis">¿Puedo seguir el estado de mi pedido?</h5>
                <p>
                    Se te proporcionará un número de seguimiento para que puedas seguir tu pedido. Te enviaremos este número 1-3 días hábiles después de hacer tu pedido al correo que ingresaste al realizar tu compra.
                </p>
            </div>
            <div className="mt-5">
                <h5 className="enviosCatch">Cambios y Devoluciones</h5>
                <h5 className="mt-5 terminosCondicionesEnfasis">¿Se aceptan devoluciones?</h5>
                <p>
                    Sólo se aceptan devoluciones si el método de pago fue pago en línea y ya pasaron 15 días hábiles desde la fecha cuando realizaste el pedido y aún no lo recibes. El proceso para solicitar una devolución es el siguiente:
                </p>
                <ol type="1">
                    <li>Notificanos a devoluciones@kanguru.mx con la descripción de tu pedido y el número de orden.</li>
                    <li>Kanguru verificará esta información.</li>
                    <li>Una vez aprobado, Kanguru te reembolsará el monto total de la orden directamente a la tarjeta que usaste para pagar dentro de Kanguru.</li>
                </ol>

                <h5 className="mt-5 terminosCondicionesEnfasis">¿Se aceptan cambios?</h5>
                <p>
                    Sólo se aceptan cambios si el artículo tiene un error o defecto. El proceso para solicitar un cambio es el siguiente:
                </p>
                <ol type="1">
                    <li>Notificanos a devoluciones@kanguru.mx con la descripción del pedido y el problema, el número de guía de tu orden, y fotos de evidencia.</li>
                    <li>Kanguru verificará esta información.</li>
                    <li>Una vez aprobado, Kanguru te enviará una guía de envío para devolver el producto defectuoso.</li>
                    <li>Recuerda que es necesario que el producto defectuoso se envíe en su empaque original.</li>
                    <li>Una vez recibido el producto erroneo o defectuoso, te enviaremos el nuevo producto y el nuevo número de guía de tu pedido.</li>
                </ol>

                <h5 className="mt-5 terminosCondicionesEnfasis">¿Cuánto tiempo tengo para poder realizar un cambio?</h5>
                <p className="mb-5">
                    Tienes hasta 3 días después de recibir tu pedido para solicitar un cambio.
                </p>
            </div>
        </div>
    );
}

export default LandingEnvios;
