import React from 'react';

{/* Despliega Lista de Referidos en CuentaVendedor */}
const ReferidoSummary = (props) => {

    const {referido} = props;
    let nombre = referido.Nombre_de_Vendedor;
    let telefono = referido.Telefono;

    return(
        <div>
            <a className="list-group-item list-group-item-action noHover">
                <li class="d-flex justify-content-between align-items-center">
                    <div>
                        <div class="fw-bold d-flex">
                            {nombre}
                        </div>
                        <div>
                            {telefono}
                        </div>
                    </div>
                </li>  
            </a>
        </div>
    )
    
}

export default ReferidoSummary;