import React, { Component } from 'react';
import DashboardNegocioContent from './DashboardNegocioContent';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import algoliasearch from 'algoliasearch';


//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_PROMOCIONES = "5895f21d82547ab167e3faed6868a6e4";

//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";

const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_VENTAS = 'Kanguru_Ventas';
const ALGOLIA_INDEX_PROMOCIONES = 'Kanguru_Promociones';

const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_ventas = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_VENTAS);
const indexVentas = client_ventas.initIndex(ALGOLIA_INDEX_VENTAS);
const client_promociones = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PROMOCIONES);
const indexPromociones = client_promociones.initIndex(ALGOLIA_INDEX_PROMOCIONES);



class DashboardNegocio extends Component {
    state = {
        nombreNegocio: "dola",
        status: null,
        products: [],
        pendingProducts:[],
        ventas: [],
        promociones: [],
        loaded: false
    };

    async componentDidMount(){
        const {auth} = this.props;
        
        if(auth.uid){
            //Primeros 48 Productos de este Negocio
            const products = await indexProductos.search(auth.uid, {page: 0, hitsPerPage: 48});
            const pendingProducts = await indexProductos.search(auth.uid, {filters: 'pending:true', hitsPerPage: 10});

            //Primeras 48 Ventas de Este Negocio
            const ventas = await indexVentas.search(auth.uid, {page: 0, hitsPerPage: 48});

            //Primeras 48 Promociones de este Negocio
            const promociones = await indexPromociones.search(auth.uid, {page: 0, hitsPerPage: 48});

            this.setState({
                products: products.hits,
                pendingProducts: pendingProducts.hits,
                ventas: ventas.hits,
                promociones: promociones.hits,
                loaded:true
            });
        }
    }



    render(){
        const {auth, profile} = this.props;

        //Route Guard – Si el usuario no está signed in, mandarlo a página de sign in
        if (auth.isLoaded && !auth.uid){
            return(
                <Redirect to ='/signin'/>  
            );
        }
        else if(auth.isLoaded && auth.uid && profile.isLoaded && this.state.loaded){
        
            if(profile.Connect_Account_ID && this.state.status === null){ //si el usuario ya tiene connect id y no se ha checado el estado de su cuenta
                const account = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/connectAccountStatus", {  
                    params: {
                        id: profile.Connect_Account_ID
                    }
                })
                .then((response) => {
                    return this.setState({status: response.data.accountStatus});
                })
                .catch(function (error) {
                    console.log(error);
                })
            }
            
            return(
                <DashboardNegocioContent auth={auth} profile={profile} state={this.state}/>
            );

        }
        else{
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }
    }
}


const mapStateToProps = (state) => {
    
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps, null)(DashboardNegocio);
