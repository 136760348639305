const initState = {
    resenas: [
        {id: '1', productoID:'5', vendedorID:'45', titulo:'excelente', descripcion:'super bueno'}
    ]
};

const resenaReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_RESENA':
            console.log('Added resena', action.retiro);
            return state;
        case 'ADD_RESENA_ERROR':
            console.log('Added Resena Error', action.err);
            return state;
        default:
            return state;
    }
}


export default resenaReducer;