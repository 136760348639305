import React, { useState, useEffect} from 'react';
import './Filtro.css';
import { async } from '@firebase/util';

const Filtro = (props) => {
    
    const {filtro, handleClickFiltro} = props;
    const [nuevoFiltro, setNuevoFiltro] = useState({
        categoria: "",
        busqueda: "",
        envioSiguienteDia: ""
    });
    
    //Función para cambiar el color del botón al seleccionarse
    const seleccionarCiudad = (ciudad) => {
        const nuevoBtn = document.getElementById(ciudad);
        nuevoBtn.style.color = 'white';
        nuevoBtn.style.backgroundColor = '#E75B10';
        nuevoBtn.style.border = '0px solid #E75B10';
    }

    const deseleccionarCiudad = (ciudad) => {
        const prevBtn = document.getElementById(ciudad);
        prevBtn.style.color = 'black';
        prevBtn.style.backgroundColor = 'white';
        prevBtn.style.border = '1px solid black';
    }

    const limpiarModal = (e) => {
        e.preventDefault();
        deseleccionarCiudad(nuevoFiltro.envioSiguienteDia);

        setNuevoFiltro(prevNuevoFiltro => ({
            ...prevNuevoFiltro,
            envioSiguienteDia: ""
        }));
    }
    
    const handleEnvioSiguienteDia = (e) => {
        
        const prevCiudad = nuevoFiltro.envioSiguienteDia;
       
        if(nuevoFiltro.envioSiguienteDia != ""){
            deseleccionarCiudad(prevCiudad);
        }

        seleccionarCiudad(e.target.id);

        setNuevoFiltro(prevNuevoFiltro => ({
            ...prevNuevoFiltro,
            envioSiguienteDia: e.target.id
        }));
        
    }

   

    const handleSubmit = (e) => {
        e.preventDefault();
        handleClickFiltro(nuevoFiltro)
    }

    useEffect(() => {
        //Filtro es igual a atributo de parent
        if((filtro.categoria !== nuevoFiltro.categoria) || (filtro.busqueda !== nuevoFiltro.busqueda)){
            setNuevoFiltro(filtro);
        }

        
        //No aparece botón de limpiar si no se necesita
        const limpiarBtn = document.getElementById("limpiarBtn");
        
        if(filtro.envioSiguienteDia === ""){
            limpiarBtn.style.display = 'none';
            
        }
        else{
            limpiarBtn.style.display = 'block';
        }

    });



    return(
         <div className="modal fade " id="filtroModal" tabindex="-1" aria-labelledby="filtroLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div className="modal-header text-center">
                        <h6 className="modal-title w-100">Filtros</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body ms-3">

                        {/* Tipo de envío */}
                        <div className="">
                            <p className="mt-4 sub-titulo bold">Envío al Siguiente Día</p>
                            <p className="descripcion">Productos que pueden llegar al siguiente día a la ciudad que elijas</p>
                            
                            {/* Ciudades */}
                            <div className="d-flex mt-4">
                                <p onClick={(e) => handleEnvioSiguienteDia(e)} className="btn btn-ciudad me-3" id="cdmx">CDMX</p>
                                <p onClick={(e) => handleEnvioSiguienteDia(e)} className="btn btn-ciudad me-3" id="monterrey">Monterrey</p>
                                <p onClick={(e) => handleEnvioSiguienteDia(e)} className="btn btn-ciudad" id="acapulco">Acapulco</p>
                            </div>
                            

                            {/* Divider */}
                            {/* <div className="mt-4">
                                <div className="divider"></div>
                            </div> */}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="limpiarBtn" onClick={(e) => limpiarModal(e)} type="button" className="btn btn-grey">Limpiar</button>
                        <button type="button" className="btn btn-primary btn-submit" aria-label="Close" data-bs-dismiss="modal" onClick={(e) => handleSubmit(e)}>Aplicar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Filtro;