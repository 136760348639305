import React from 'react';
import RetoSummary from './RetoSummary';

const RetoList = (props) => {

    const {retos} = props;

    if(retos.length != 0){
        return(
            <div>
                <ul className="list-group">
                    {retos && retos.map(reto => {
                        return (
                            <div key={reto.objectID}>
                                <RetoSummary reto={reto}/>
                            </div>  
                        )
                    })}
                </ul>
            </div> 
        )
    }
    else{
        return(
            <div className="noProducts">
                <h2>Aquí verás tus retos completados :)</h2>
            </div>
        ); 
    }
    
}

export default RetoList;