import React, { Component} from 'react';
import ProductList from '../products/ProductList';
import Filtro from '../filtro/Filtro';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import './Categorias.css';
import { Redirect, withRouter} from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import {Helmet} from "react-helmet";
import gtag, { install } from 'ga-gtag';
import toast, { Toaster } from 'react-hot-toast';

install('G-GQ8M7NNRJ7');

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";

//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";

const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';

const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);

class ProductosEnCategoria extends Component {

    state={
        products: [],
        pageAlgolia: 0,
        filtro: {
            categoria: "",
            busqueda: "",
            envioSiguienteDia: "",
        }
    }

    notify = (type) => {
        if(type == "noProducts"){
            toast.error("¡Uy! Ya no hay más productos de esta categoría.");
        }
    }

    //Function that checks if product is sold out
    isSoldOut = (product) => {
        let cantMax = 0;

        for(let i = 0; i < product.cantidades.length; i++){
            if(product.cantidades[i] > cantMax){
                cantMax = product.cantidades[i];
            } 
        }

        if(cantMax === 0){
            return true;
        }
        else{
            return false;
        }
    }

    showVerMasBtn = (action) => {
        const verMasBtn = document.getElementById("verMasBtn");
        
        if(verMasBtn){
            if(this.state.products.length < 48 || action === "hide"){
                verMasBtn.style.display = 'none';
                
            }
            else{
                verMasBtn.style.display = 'inline';
            }
        }
    }

    loadMoreProducts = async (e) => {
        e.preventDefault();
        const filtro = this.state.filtro;
        const pageAlgolia = this.state.pageAlgolia + 1;
        let query;

        //Si hay filtro y categoria
        if(filtro.envioSiguienteDia !== "" && filtro.categoria !== ""){
            query = "categoria: " + filtro.categoria + " AND " + "isDisplayed:true AND sEnvioSiguienteDia: " + filtro.envioSiguienteDia;
        }
        //Si hay categoria
        else if(filtro.categoria !== ""){
            query = "categoria: " + filtro.categoria + " AND " + "isDisplayed:true";
        }

        const products = await indexProductos.search("", {filters: query, page: pageAlgolia, hitsPerPage: 48});
        
        // const availableProducts = products.hits.filter((product) => {
        //     if(!this.isSoldOut(product)){
        //         return product;
        //     }
        // })

        const productsNew = this.state.products.concat(products.hits);
        
        if(products.hits.length === 0){
            this.notify("noProducts");
            this.showVerMasBtn("hide");
        }
        else{
            this.setState({
                pageAlgolia: pageAlgolia,
                products: productsNew
            })
            this.showVerMasBtn("show");
        }
    }

    //Función que activa o desactiva botón de filtro
    handleBtnFiltro = () => {
        const button = document.getElementById("btnFiltro");

        if(button){
            if(this.state.filtro.envioSiguienteDia === ""){
                button.style.color = 'black';
                button.style.backgroundColor = 'white';
                button.style.border = '2px solid black';
            }
            else{
                button.style.color = 'white';
                button.style.backgroundColor = '#E75B10';
                button.style.border = '0px solid #E75B10';
            }
        }
    }

    displayFilteredProducts = async (filtro) => {
        let query;
        let products;
        this.props.history.push('/categoria/' + filtro.categoria, { filtro: filtro });

        //Si hay filtro y categoria
        if(filtro.envioSiguienteDia !== "" && filtro.categoria !== ""){
            query = "categoria: " + filtro.categoria + " AND " + "isDisplayed:true AND sEnvioSiguienteDia: " + filtro.envioSiguienteDia;
            products = await indexProductos.search("", {filters: query, page: 0, hitsPerPage: 48});
            
        }
        //Si hay categoria
        else if(filtro.categoria !== ""){
            query = "categoria: " + filtro.categoria + " AND " + "isDisplayed:true";
            products = await indexProductos.search("", {filters: query, page: 0, hitsPerPage: 48});
        }
            
        if(products){
            // const availableProducts = products.hits.filter((product) => {
            //     if(!this.isSoldOut(product)){
            //         return product;
            //     }
            // })
    
            this.setState({
                products: products.hits,
            }, async () => {
                this.showVerMasBtn();
            })
        }
    }

    handleClickFiltro = (filtro) => {
        //Actualiza Filtro actual
        this.setState({
            filtro: filtro
        }, async () => {
            this.displayFilteredProducts(this.state.filtro);
            this.handleBtnFiltro();
        });
    }

    
    async componentDidMount(){
        const {categoria} = this.props;
        
        let prevEnvioSiguienteDia;
        if(this.props.history.location.state !== undefined){
            prevEnvioSiguienteDia = this.props.history.location.state.filtro.envioSiguienteDia;
        }
        else{
            prevEnvioSiguienteDia = "";
        }

        //Actualizar filtro
        this.setState({
            filtro: {
                categoria: categoria,
                busqueda: "",
                envioSiguienteDia: prevEnvioSiguienteDia,
            }
        }, async () => {
            this.displayFilteredProducts(this.state.filtro);
            this.handleBtnFiltro();
        });
    }

    render(){
        const {categoria} = this.props;
        const products = this.state.products;

        return(
            <div className="container-fluid categorias">
                
                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                {/* Modal de Filtro */}
                <Filtro filtro={this.state.filtro} handleClickFiltro={this.handleClickFiltro}/>
                
                {/* Filtro */}
                <div>
                    <p id="btnFiltro" className="btn btn-filtro mt-3" data-bs-toggle="modal" data-bs-target="#filtroModal"><i className="bi bi-funnel-fill me-2"></i>Filtro</p>
                </div>

                <h3 className="mt-3">{categoria.charAt(0).toUpperCase() + categoria.substring(1)}</h3>
                
                
                {/* Productos de Categoría */}
                <ProductList numero={0} products={products} filter={''} mobile={true} catalogo={false}/> 
                
                {/* Botón para ver más */}
                <div className="ver-mas-parent-home">
                    <p onClick={this.loadMoreProducts} id="verMasBtn" className="btn btn-ver-mas">Ver Más</p>
                </div>
            </div>
        )  
    }
}

const mapStateToProps = (state, ownProps) => {
    
    return{
        categoria: ownProps.match.params.categoria
    }
}

export default connect(mapStateToProps, null)(ProductosEnCategoria);