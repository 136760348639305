const initState = {
    resenas: [
        {id: '1', vendedorID:'5', descripcion:'Vende 3000 pesos o más y gana 200 pesos', createdAt:'December 20, 2021 00:00:00', inicio: 'December 20, 2021 00:00:00', fin:'December 20, 2021 00:00:00'}
    ]
};

const resenaReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_RETO':
            console.log('Added reto', action.retiro);
            return state;
        case 'ADD_RETO_ERROR':
            console.log('Added Reto Error', action.err);
            return state;
        default:
            return state;
    }
}


export default resenaReducer;