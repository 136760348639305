/*global google*/
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import StripeCheckoutButton from '../stripe/StripeCheckoutButton';
import { Link } from 'react-router-dom';
import '../../Stripe.css';
import '../../App.css';
import '../dashboard/Catalogo/Templates/Diseños/Template1.css'
import ReactPixel from 'react-facebook-pixel';
import {addVenta} from '../../store/actions/ventaActions';
import {Helmet} from "react-helmet";
import algoliasearch from 'algoliasearch';
import ReviewList from '../reviews/ReseñasList';
import axios from 'axios';
import Reseñas from '../reviews/Reseñas';
import Geosuggest from 'react-geosuggest';
import toast, { Toaster } from 'react-hot-toast';
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";
const SEARCH_API_KEY_CATALOGOS = "465a5e3eb841eff90af7f9c36278dd44";
const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "37bbdced854246edc06c7fb3c072275c";
const SEARCH_API_KEY_DISENOENCATALOGO = "7fdb8d87725c540857fc680054155691";

//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";
// const SEARCH_API_KEY_DISENOENCATALOGO = "ceb37b088c17d1ae94ffc81222bc2736";

const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_VENTAS = 'Kanguru_Ventas';
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';
const ALGOLIA_INDEX_RESENAS = 'Kanguru_Resenas';
const ALGOLIA_INDEX_DISENOENCATALOGO = 'Kanguru_DisenoEnCatalogo';

const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_ventas = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_VENTAS);
const indexVentas = client_ventas.initIndex(ALGOLIA_INDEX_VENTAS);
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);
const client_resenas = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_RESENAS);
const indexResenas = client_resenas.initIndex(ALGOLIA_INDEX_RESENAS);
const client_disenoEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_DISENOENCATALOGO);
const indexDisenoEnCatalogo = client_disenoEnCatalogo.initIndex(ALGOLIA_INDEX_DISENOENCATALOGO);


class ProductCheckoutCatalogo extends Component {
    state = {
        variaciones: '0',
        personalizado:'',
        index:0,
        cantidad:1,
        content:0,
        tipoDePago: "enLinea",
        nombreCliente: "",
        emailCliente: "",
        telefonoCliente: "+52",
        line1: "",
        city: "",
        country: "Mexico",
        postal_code: "",
        municipio: "",
        state: "", 
        estados: [], 
        municipios: [],
        product: "",
        codigoReferidoAfiliado: "",
        negocio: "",
        reseñasTotal: [],
        reseñas: [],
        enviarA: "direccionCliente",
        puntosDeRecoleccion: [],
        locationIdP99: "",
        direccion: "",
        reference: "",
        location: {lat: "", lng: ""},
        hasCoverage: true,
        suggestHasPostalCode: false,
        street_number: "",
        oDisenoEnCatalogo: "",
        sTipoDeDireccion: "coordBased",
        vendedor: ""
    }

    triggerAnalyticEvents = (eventType, product, precio) => {
        gtag("event", "begin_checkout", {
            currency: "MXN",
            value: Number(precio),
            items: [
              {
                item_id: product.objectID,
                item_name: product.nombre,
                currency: "MXN",
                item_brand: product.negocio,
                item_category: product.categoria,
                price: Number(precio)
              }
            ]
        });
       
    }

    addVentaAnalytics = (productoID) => {
        const venta = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/actualizarVentasEnTasaDeConversion", {
            params: {
                productoID: productoID
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeImage = (e) => {
        this.setState({
          index: e.target.id
        })
    }

    puntosDeRecoleccion = () => {
        const address = this.state.line1 + ", " + this.state.city + ", " + this.state.postal_code + " " + this.state.municipio + ", " + this.state.state + ", " + "México";
        const account = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/getNearestLocations99Minutos", {
            params: {
                address: address
            }
        })
        .then((response) => {
            return this.setState({puntosDeRecoleccion: response.data.puntos99});
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    onSuggestSelect = (suggest) => {
        if(suggest && suggest.gmaps){
            let addressComponents = suggest.gmaps.address_components;
            let street_number = "";
            let postal_code = "";
            for (var i = 0; i < addressComponents.length; i++) {
                for (var j = 0; j < addressComponents[i].types.length; j++) {
                    if (addressComponents[i].types[j] == "street_number") {
                        street_number = addressComponents[i].long_name;
                    }
                    else if(addressComponents[i].types[j] == "postal_code"){
                        postal_code = addressComponents[i].long_name;
                    }
                }
            }

            this.setState({
                direccion: suggest.gmaps.formatted_address,
                location: {lat: suggest.location.lat, lng: suggest.location.lng},
                postal_code: postal_code,
                street_number: street_number,
            });

            if(postal_code !== ""){
                this.setState({
                    suggestHasPostalCode: true
                });
            }
            else{
                this.setState({
                    suggestHasPostalCode: false
                });
            }
        }
    }

    notify = (type) => {
        if(type == "seleccionaMetodoDePago"){
            toast.error('¡Por favor selecciona un método de pago!');
        }
        else if(type == "copioEnlaceDePago"){
            toast.success('¡Excelente Guru! Se copió el enlace de pago, ahora compártelo con tu cliente.');
        }
        else if(type == "informacionIncompleta"){
            toast.error('La información está incompleta. Revisa el nombre y correo electrónico.');
        }
        else if(type == "direccionIncompletaNumero"){
            toast.error('¡Rayos! La dirección está incompleta, no tiene número. Si no aparece la dirección que quieres, comunicate al +52 8186058811');
        }
        else if(type == "direccionIncompletaCP"){
            toast.error('¡Uy! La dirección no es válida. Falta el código postal.');
        }
        else if(type == "direccionIncompletaDireccion"){
            toast.error('Necesitas seleccionar la opción correcta que aparece en el campo de dirección.');
        }
        else if(type == "telefonoIncompleto"){
            toast.error('¡Necesitas agregar un número de teléfono válido. +52 seguido por 10 digitos.');
        }
        else if(type == "punto99Incompleto"){
            toast.error('¡Te falta escoger el punto de recolección!');
        }
        else if(type == "codigoPostalInvalido"){
            toast.error('El código postal que pusiste no es válido, corrígelo y listo.')
        }
        else if(type == "sinCobertura"){
            toast.error('Disculpanos. Actualmente, no ofrecemos pago contra entrega a esta dirección. Sin embargo, puedes hacer tu pedido con pago en línea.')
        }
    }

    async componentDidUpdate(prevProps, prevState){
        if(this.state.postal_code.length === 5 && this.state.postal_code !== prevState.postal_code){
            const postalCodeDestination = this.state.postal_code;
            const postalCodeOrigin = this.state.negocio.direccionDeRecoleccion.postal_code;
            //Llamar función para validar código postal
            const validatePostalCode = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/coverageByZipCode99Minutos", {
                params: {
                    postalCodeDestination: postalCodeDestination,
                    postalCodeOrigin: postalCodeOrigin
                }
            })
            .then((response) => {
                //Update state que se validó el C.P
                return this.setState({hasCoverage: response.data.hasCoverage});
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }

    async componentDidMount(){

        const {productID, vendedorID, sDisenoEnCatalogoID} = this.props;
        
        if(productID && vendedorID && sDisenoEnCatalogoID){
            const product = await indexProductos.getObject(productID);
            const {hits} = await indexVentas.search(vendedorID, {hitsPerPage: 100}); //ventas de este vendedor
            const vendedor = await indexUsuarios.getObject(vendedorID);
            const negocio = await indexUsuarios.getObject(product.negocio_id);
            const oDisenoEnCatalogo = await indexDisenoEnCatalogo.getObject(sDisenoEnCatalogoID);
            
            //GANANCIAS POR VENTA DE REFERIDOS
            let ventasDeVendedor = hits;
            let codigoReferidoAfiliado = "";
            
            //Dentro del mes
            // let today = new Date();
            // let thirtyDays = 1000*60*60*24*30;
            // let referidoVencido = (today - user.CreatedAt) > thirtyDays;
            
            //Si es la primera venta del afiliado y tiene código de referido
            if(ventasDeVendedor.length == 0 && vendedor.codigoReferidoExterno != ""){
                codigoReferidoAfiliado = vendedor.codigoReferidoExterno;
            }

            const reseñas = await indexResenas.search(productID, {filters: 'esVistoPor:clientes', hitsPerPage: 50});
        
            if(reseñas){
                this.setState({
                    product: product,
                    reseñas: reseñas.hits.slice(0, 5),
                    reseñasTotal: reseñas.hits,
                    codigoReferidoAfiliado: codigoReferidoAfiliado,
                    negocio: negocio,
                    oDisenoEnCatalogo: oDisenoEnCatalogo,
                    vendedor: vendedor
                });
            }
        }
    }

    render(){
        const {productID, vendedorID, precio} = this.props;
        const product = this.state.product;
        const negocio = this.state.negocio;
        const oDisenoEnCatalogo = this.state.oDisenoEnCatalogo;
        const reseñas = this.state.reseñas;
        const reseñasTotal = this.state.reseñasTotal;
        const vendedor = this.state.vendedor;
        const codigoReferidoAfiliado = this.state.codigoReferidoAfiliado;
        const comision = product.comision;
        const cantidades = [];
        let precioFinalMayoreo = 0;

        const handleSubmit = () => {
            if(this.state.nombreCliente === "" || this.state.emailCliente === ""){
                this.notify("informacionIncompleta");
            }
            else if(!this.state.telefonoCliente.match(/^\+52(\s*[0-9]\s*){10}\s*$/)){
                this.notify("telefonoIncompleto");
            }
            else if(this.state.street_number === ""){
                this.notify("direccionIncompletaNumero");
            }
            else if(this.state.postal_code === ""){
                this.notify("direccionIncompletaCP")
            }
            else if(this.state.direccion === ""){
               this.notify("direccionIncompletaDireccion");
            }
            else if(this.state.locationIdP99 === "" && this.state.enviarA === "punto99"){
                this.notify("punto99Incompleto");
            }
            else if(this.state.postal_code.length !== 5){
                this.notify("codigoPostalInvalido");
            }
            else if(!this.state.hasCoverage){
                this.notifiy("sinCobertura");
            }
            else{
                //Limita el error humano a duplicar venta
                const element = document.getElementById("realizarPedidoBtn");
                element.style.pointerEvents = "none";
 
                //Hace la venta minorista
                if(product.precioMayoreo === "" || product.cantMinMayoreo === "" || this.state.cantidad < Number(product.cantMinMayoreo)){
                    this.addVentaAnalytics(this.props.productID);
                    this.props.addVenta(this.state, precio, this.props.vendedorID, this.props.productID);
                }
                //Hace la venta mayoreo
                else if(this.state.cantidad >= Number(product.cantMinMayoreo)){
                    this.addVentaAnalytics(this.props.productID);
                    this.props.addVenta(this.state, precioFinalMayoreo, this.props.vendedorID, this.props.productID);
                }
            }
        }

        if(product && negocio){
            
            let cantMax = 0;

            for(let i = 0; i < product.cantidades.length; i++){
                if(product.cantidades[i] > cantMax)
                    cantMax = product.cantidades[i];
            }

            for(let i = 1; i <= cantMax; i++){
                cantidades.push(i);
            }

            const cantidadOpciones = cantidades.map(i => {
                return (
                    <option value={i}>{i}</option>
                );
            })

            let cantidad;
            cantidad = (
                <div className="col-6 mt-3">
                    <label for="cantidad" className="form-label addProductFormLabel">Cantidad</label>
                    <select onChange={this.handleChange} id="cantidad" className="form-select">
                        {cantidadOpciones}
                    </select>
                </div>
            );

            let variaciones;
            if(product.variaciones[0] != '' && product.variaciones[0]){
                const variacionesOpciones = product.variaciones.map((valor, idx) => {
                        return (
                            <option value={idx}>{valor}</option>
                        );
                    })

                variaciones = (
                    <div className="col-6 mt-3">
                        <label for="variaciones" className="form-label addProductFormLabel">Opción</label>
                        <select onChange={this.handleChange} id="variaciones" className="form-select">
                            {variacionesOpciones}
                        </select>
                        
                    </div>
                    
                );   
            }
            else{
                variaciones = (
                    null
                );
            }

            let personalizado;
            if(product.personalizado === '1'){
                personalizado = (
                <div className="col-12 mt-3">
                    <label for="personalizado" className="form-label addProductFormLabel">Personaliza el Producto (Ej. Tus iniciales)</label>
                    <textarea onChange={this.handleChange} maxLength="10" className="form-control" id="personalizado" rows="1"></textarea>
                </div>
                );
            }
            else{
                personalizado = (
                null
                );
            }

            let costoEnvio;
            if(product.costoDeEnvio === 'shr_1JXcnKAKagnM0wdjeVEnk4Ji'){
                costoEnvio = 200;
            }else{
                costoEnvio = 0;
            }

            const imagenesDeProducto = product.imagenesDeProducto.slice(0, 6).map(imagenDeProducto => {
                return(
                    <img onClick={this.handleChangeImage} id={product.imagenesDeProducto.indexOf(imagenDeProducto)} src={imagenDeProducto} alt="" className="productImages me-3"/>  
                );
            })

            let precioMayoreo;
            if(product.precioMayoreo !== "" && product.cantMinMayoreo !== ""){
                precioFinalMayoreo = Math.round((Number(product.precioMayoreo) + Number(product.costoDeEnvio))*1.04);

                precioMayoreo = (
                    <div className="mt-3">
                        <h6 className="addProductFormLabel">Precio de Mayoreo en Ordenes > {product.cantMinMayoreo} Unidades: ${precioFinalMayoreo}</h6>
                    </div>
                );
            }else{
                precioMayoreo = (
                    null
                );
            }

            let totalDeOrden;
            if(product.precioMayoreo === "" || product.precioMayoreo === "" || this.state.cantidad < Number(product.cantMinMayoreo)){
                totalDeOrden = (
                    <h6 className="">Total: MXN ${Math.round(precio * this.state.cantidad)}</h6>
                )
            }
            //Hace la venta mayoreo
            else if(this.state.cantidad >= Number(product.cantMinMayoreo)){
                totalDeOrden = (
                    <h6 className="">Total: MXN ${Math.round(precioFinalMayoreo * this.state.cantidad)}</h6>
                )
            }

            let metodosDePagoTitle;
            if(window.innerWidth >= 600){
                metodosDePagoTitle = (
                    <h6 className="productDetails">Método de Pago</h6>
                );
            }
            else{
                metodosDePagoTitle = (
                    <h4 className="productDetails">Método de Pago</h4>
                );
            }

            let metodosDePago;
            const tiendasDeImpuls = ["0tskUsErRuVle7Xu8r7KOntoHLl2", "360tkOXiWdXFzI4woedRelbIuUo2", "BLl34HKMc1bcawzMHaALPqheuzx2", "XbHVCU07Wfh00uoa0dN8UZq7fn92", "agwthEEidxYcYIiIfM8P6Bmt8kp2", "hYcxg5jQDCb0mPh75Z05DwClAl23", "t8oLxehnOgNV2x3YO1mWaozlAAt1", "xRuldfzhFPVZsBpF9CDN1PQZT4K3"];
            const productoDeTiendaDeImpuls = tiendasDeImpuls.includes(product.negocio_id);
             //Solo se acepta pago contra entrega si el precio final es menor a 8000
            if(this.state.negocio.direccionDeRecoleccion && Number(precio * this.state.cantidad) < 8000 && !productoDeTiendaDeImpuls){
                metodosDePago = (
                    <div className="mt-4 mb-4">
                        {metodosDePagoTitle}
                        <div className="form-check mt-4 mt-md-0">
                            <input className="form-check-input" type="radio" onClick={this.handleChange} name="flexRadioDefault" value="enLinea" id="tipoDePago"/>
                            <label className="form-check-label ms-2 ms-md-0" for="flexRadioDefault1">
                                <p className="">Tarjetas de Débito y Crédito</p>
                                <div className="d-flex mb-4">
                                    <img src="/img/AmericanExpress.png" alt="" className="paymentIcons"/>
                                    <img src="/img/Mastercard.png" alt="" className="paymentIcons ms-2"/>
                                    <img src="/img/visa.png" alt="" className="paymentIcons ms-2"/>
                                </div>
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" onClick={this.handleChange} type="radio" name="flexRadioDefault" value="contraEntrega" id="tipoDePago"/>
                            <label class="form-check-label ms-2 ms-md-0" for="flexRadioDefault2">
                                Pago Contra Entrega
                            </label>
                        </div>
                    </div> 
                );
            }
            else if(this.state.negocio.direccionDeRecoleccion){
                metodosDePago = (
                    <div className="mt-4 mb-4">
                        {metodosDePagoTitle}
                        <div className="form-check mt-4 mt-md-0">
                            <input className="form-check-input" type="radio" onClick={this.handleChange} name="flexRadioDefault" value="enLinea" id="tipoDePago"/>
                            <label className="form-check-label ms-2 ms-md-0" for="flexRadioDefault1">
                                <p>Tarjetas de Débito y Crédito</p>
                                <div className="d-flex mb-4">
                                    <img src="/img/AmericanExpress.png" alt="" className="paymentIcons"/>
                                    <img src="/img/Mastercard.png" alt="" className="paymentIcons ms-2"/>
                                    <img src="/img/visa.png" alt="" className="paymentIcons ms-2"/>
                                </div>
                            </label>
                        </div>
                    </div> 
                );
            }
            else{
                metodosDePago = (
                    <div className="mt-4 mb-4">
                        {metodosDePagoTitle}
                    </div> 
                );
            }
            
            

            let botonDeCompra;
            //Contra entrega
            {/* <button onClick={() => this.triggerAnalyticEvents("begin_checkout", product, precio)} data-bs-toggle="modal" data-bs-target={"#pedidoModal"} className="btn btn-secondary checkout-button mt-2">
                    Realizar Pedido
                </button> */}

            //Selecciona método de pago
            {/* <button onClick={() => this.notify("seleccionaMetodoDePago")} className="btn btn-secondary checkout-button mt-2">
                Realizar Pedido
            </button> */}
            
            if(product.cantidades[this.state.variaciones] < 0){
                botonDeCompra = (
                    <button className="btn btn-secondary checkout-button-agotado mt-2">
                        Agotado
                    </button>
                );
            }
            else if(this.state.tipoDePago === "enLinea"){
                //Hace la venta con precio minorista
                if(product.precioMayoreo === "" || product.cantMinMayoreo === "" || this.state.cantidad < Number(product.cantMinMayoreo)){
                    // botonDeCompra = (
                    //     <StripeCheckoutButton product={product} negocio={negocio} precio={precio} accountID={negocio.Connect_Account_ID} vendedorID={vendedorID} productID={productID} comision={comision} personalizado={this.state.personalizado} variacion={this.state.variaciones} cantidad={this.state.cantidad} codigoReferidoAfiliado={codigoReferidoAfiliado}/>
                    // );
                    botonDeCompra = (
                        <button className="btn btn-secondary checkout-button-agotado mt-2">
                            No Disponible
                        </button>
                    );   
                }
                //Hace la venta mayoreo
                else if(this.state.cantidad >= Number(product.cantMinMayoreo)){
                    // botonDeCompra = (
                    //     <StripeCheckoutButton product={product} negocio={negocio} precio={precioFinalMayoreo} accountID={negocio.Connect_Account_ID} vendedorID={vendedorID} productID={productID} comision={comision} personalizado={this.state.personalizado} variacion={this.state.variaciones} cantidad={this.state.cantidad} codigoReferidoAfiliado={codigoReferidoAfiliado}/>
                    // );
                    botonDeCompra = (
                        <button className="btn btn-secondary checkout-button-agotado mt-2">
                            No Disponible
                        </button>
                    );   
                }  
            }
            else if(this.state.tipoDePago === "contraEntrega"){
                botonDeCompra = (
                    <button className="btn btn-secondary checkout-button-agotado mt-2">
                        No Disponible
                    </button>
                );
            }
            else{
                botonDeCompra = (
                    <button className="btn btn-secondary checkout-button-agotado mt-2">
                        No Disponible
                    </button>
                );
            }

            let opcion;
            if(product.variaciones[this.state.variaciones]){
                opcion = (
                   <h6 className="">Opción: {product.variaciones[this.state.variaciones]}</h6>
                );
            }
            else{
                opcion = (
                    null
                );
            }

            let rigthButtonModalRealizarPedido;
            let avisoParaContinuar = null;
            if(this.state.enviarA === "direccionCliente"){
                rigthButtonModalRealizarPedido = (
                    window.location.href.indexOf("catalogoenlinea.mx") > -1 ? <button onClick={() => handleSubmit()} type="button" className="btn btn-primary" id="realizarPedidoBtn">Realizar Pedido</button>: <button onClick={() => handleSubmit()} type="button" className="btn btn-primary-cliente" id="realizarPedidoBtn">Realizar Pedido</button>
                );
            }
            else if(this.state.enviarA === "punto99" && (this.state.nombreCliente === "" || this.state.emailCliente === "" || this.state.line1 === "" || this.state.city === "" ||  this.state.postal_code === "" || this.state.state === "")){
                rigthButtonModalRealizarPedido = (
                    window.location.href.indexOf("catalogoenlinea.mx") > -1 ? <button type="button" className="btn btn-primary no-pointer">Continuar</button>: <button type="button" className="btn btn-primary-cliente no-pointer">Continuar</button>
                );

                avisoParaContinuar = (
                    <p className="mt-3">*Necesitas llenar todos los datos para continuar.</p>
                );
            }
            else{
                rigthButtonModalRealizarPedido = (
                    window.location.href.indexOf("catalogoenlinea.mx") > -1 ? <button onClick={this.puntosDeRecoleccion} data-bs-toggle="modal" data-bs-target={"#puntoDeRecoleccionModal"} data-bs-dismiss="modal" type="button" className="btn btn-primary">Continuar</button>:<button onClick={this.puntosDeRecoleccion} data-bs-toggle="modal" data-bs-target={"#puntoDeRecoleccionModal"} data-bs-dismiss="modal" type="button" className="btn btn-primary-cliente">Continuar</button>
                );
            }

            let puntosDeRecoleccion;
            if(this.state.puntosDeRecoleccion.length > 0){
                puntosDeRecoleccion = (
                    this.state.puntosDeRecoleccion.map((punto99, idx) => {
                        return (
                            <div className="form-check mt-3">
                                <input onClick={this.handleChange} className="form-check-input" type="radio" name="puntoDeRecoleccion" value={punto99.locationId} id="locationIdP99" />
                                <label className="form-check-label" for={punto99.locationId}>
                                    <p>{punto99.description}</p>
                                    <p>{punto99.address}</p>
                                </label>
                            </div>
                        )
                    })
                );
            }
            else{
                puntosDeRecoleccion = (
                    <div className="container spinner">
                        <div class="spinner-border spinner-color" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                );
            }

            let inputPostalCode;
            if(this.state.suggestHasPostalCode && this.state.postal_code !== ""){
                inputPostalCode = (
                     <input type="text" placeholder={this.state.postal_code} className="form-control" id="postal_code" disabled/>
                );
            }
            else{
                inputPostalCode = (
                    <input type="text" onChange={this.handleChange} placeholder={this.state.postal_code} className="form-control" id="postal_code"/>
                );
            }

            let formattedProductDescription;
            formattedProductDescription = (
                product.descripcion.split("\n").map((line, idx) => {
                    return(
                        <p className="">{line}</p>
                    )
                })
            );

            let contentToDisplay;
            if(window.innerWidth >= 600){
                contentToDisplay = (
                    <div>
                        {/* Checkout */}
                        <div className="row mt-3 checkoutBackground">
                            
                            {/* ProductImage */}
                            <div className="col-md-4">
                                <div className="graficMaterial">
                                    <img src={product.imagenesDeProducto[this.state.index]} alt="" className="productImageActive"/>
                                </div>
                                <div className="productImagesMobile mt-3">
                                    {imagenesDeProducto}
                                </div>
                            </div>

                            <div className="col-md-4 productInfo">
                                {/* Social Proof #1 */}
                                <div className="mt-1">
                                    <h6 className="socialProof1">Nuevo</h6>
                                </div>

                                {/* Product Name */}
                                <div className="mt-1">
                                    <h2 className="productName">{product.nombre}</h2>
                                </div>

                                {/* Rating */}
                                <div className="rating mt-1">
                                    <i className="bi bi-star-fill me-2"></i>
                                    <i className="bi bi-star-fill me-2"></i>
                                    <i className="bi bi-star-fill me-2"></i>
                                    <i className="bi bi-star-fill me-2"></i>
                                </div>

                                {/* Tag */}
                                <div className="mt-3">
                                    {window.location.href.indexOf("catalogoenlinea.mx") > -1 ? <p className="btn btn-primary noHover">Más Vendidos</p> : <p className="btn btn-primary-cliente noHover">Más Vendidos</p>}
                                </div>
                                

                                {/* Price */}
                                <div className="mt-2">
                                    <h2 className="productPrice">MXN ${precio}</h2>
                                </div>

                                {/* Precio de Mayoreo */}
                                {precioMayoreo}

                                {/* Cantidad */}
                                {cantidad}

                                {/* Extra */}
                                <form>
                                    {variaciones}
                                    {personalizado}
                                </form>
                            </div>

                            {/* Comprar Ahora */}
                            <div className="col-md-3 orderInfo">
                                {/* Info */}
                                <div className="mt-3">
                                    <p className="productStatus"><i class="bi bi-truck me-2 mt-2"></i>Envío gratis a todo el país</p>
                                    <p><i class="bi bi-hourglass-split me-2 mt-2"></i>Entrega en 5 - 7 días hábiles</p>
                                    <Link to="/cambios-y-devoluciones" className="linkToPage"><i class="bi bi-arrow-left-right me-2 mt-3"></i>Consulta Política de Cambios y Devoluciones</Link>
                                </div>
                                
                                {/* Comprar */}
                                <div className="mt-3">
                                    {botonDeCompra}
                                </div>
                                
                                {/* Métodos de Pago */}
                                {metodosDePago}
                            </div>

                            {/* Divider Line */}
                            <div className="col-8 mt-5">
                                <div className="divider"></div>
                            </div>

                            {/* Description */}
                            <div className="col-8 mt-5">
                                <h4 className="material-titulo">Descripción</h4>
                                <div className="productDetailsPoints mt-3">
                                    <p className="justify-text">{formattedProductDescription}</p>
                                </div>
                            </div>

                            {/* Divider Line */}
                            <div className="col-8 mt-5">
                                <div className="divider"></div>
                            </div>

                            {/* Reviews */}
                            <div className="row mt-5"> 
                                <Reseñas product={product} reseñas={reseñas} reseñasTotal={reseñasTotal} location="checkout"/>
                            </div>
                        </div>  
                    </div>
                );
            }else{
                contentToDisplay = (
                    <div>
                        {/* topBeforeImage */}
                        <div className="mt-3">
                            {/* Social Proof #1 */}
                            <div>
                                <p className="socialProof1">Nuevo | 62 Vendidos</p>
                            </div>

                            {/* Product Name */}
                            <div>
                                <h5 className="productName">{product.nombre}</h5>
                            </div>       

                            {/* Rating */}
                            <div className="rating mt-1">
                                <i className="bi bi-star-fill me-2"></i>
                                <i className="bi bi-star-fill me-2"></i>
                                <i className="bi bi-star-fill me-2"></i>
                                <i className="bi bi-star-fill me-2"></i>
                            </div>

                            {/* Tag */}
                            <div className="mt-2">
                                {window.location.href.indexOf("catalogoenlinea.mx") > -1 ? <p className="btn btn-primaryMobile noHover">Más Vendidos</p> : <p className="btn btn-primary-cliente-mobile noHover">Más Vendidos</p>}
                            </div>
                        </div>

                        {/* ProductImage */}
                        <div className="mt-3">
                            <div className="graficMaterial">
                                <img src={product.imagenesDeProducto[this.state.index]} alt="" className="productImageActive"/>
                            </div>
                            <div className="productImagesMobile mt-3">
                                {imagenesDeProducto}
                            </div>
                        </div>

                        {/* Información de Compra */}
                        <div className="mt-3">
                            <div>
                                <h1 className="productPriceCheckout">MXN ${precio}</h1>
                                {/* Precio de Mayoreo */}
                                {precioMayoreo}
                            </div>

                            {/* Envio Gratis */}
                            <div>
                                <p className="productStatus mt-3"><i class="bi bi-truck me-2"></i>Envío gratis a todo el país</p>
                                <p className="mt-1"><i class="bi bi-hourglass-split me-2"></i>Entrega en 5 - 7 días hábiles</p>
                                <Link to="/cambios-y-devoluciones" className="linkToPage"><i class="bi bi-arrow-left-right me-2 mt-3"></i>Consulta Política de Cambios y Devoluciones</Link>
                            </div>
                            
                            {/* Cantidad */}
                            <div className="mt-4">
                                {cantidad}
                            </div>

                            {/* Extra */}
                            <div className="mt-4">
                                <form>
                                    {variaciones}
                                    {personalizado}
                                </form>
                            </div>

                            {/* Divider */}
                            <div className="mt-4">
                                <div className="divider"></div>
                            </div>

                            {/* Description */}
                            <div className="mt-4 mb-4">
                                <h4 className="productDetails">Descripción</h4>
                                <div className="productDetailsPoints mt-3">
                                    <p>{formattedProductDescription}</p>
                                </div>
                            </div>

                            {/* Divider */}
                            <div className="">
                                <div className="divider"></div>
                            </div>
                        </div>

                        {/* Métodos de Pago */}
                        <div className="mt-4">
                            {metodosDePago}
                        </div>
                        
                       {/* Comprar */}
                       <div className="mt-4 mb-4">
                            {botonDeCompra}
                        </div>
                        
                        {/* Divider Line */}
                        <div className="mt-4">
                            <div className="divider"></div>
                        </div>

                        {/* Reviews */}
                        <div className="row mt-4"> 
                            <Reseñas product={product} reseñas={reseñas} reseñasTotal={reseñasTotal} location="checkout"/>
                        </div>
                    </div>

                );
            }

            let reactHelmet;
            if(window.location.href.indexOf("catalogoenlinea.mx") > -1){
                reactHelmet = (
                    <Helmet>
                        <title>Completa tu Compra</title>
                        <meta name="title" content="Completa tu Compra" />
                        <meta name="description" content="Paga en Línea o Contra Entrega. Seguro y Simple."/>
                        <link rel="canonical" href="https://catalogoenlinea.mx/" />
                        <link rel="icon" href="https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/images%2F372f2ea8-e2e1-4552-b928-ff24b8d860bc_576x691.jpeg?alt=media&token=6497cd5b-b907-427a-921d-7cd02ba685f4" />

                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://catalogoenlinea.mx/" />
                        <meta property="og:title" content="Completa tu Compra" />
                        <meta property="og:description" content="Paga en Línea o Contra Entrega. Seguro y Simple." />
                        <meta property="og:image" content={product.imagenesDeProducto[0]} />
                    </Helmet>
                );
            }
            else{
                reactHelmet = (
                    <Helmet>
                        <title>Kangupago</title>
                        <meta name="title" content="Kangupago" />
                        <meta name="description" content="Paga en Línea o Contra Entrega. Seguro y Simple."/>
                        <link rel="canonical" href="https://kangupago.com/" />
                        <link rel="icon" href="https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/images%2F81ece66a-1ea1-4ef1-bc79-b212e2086ce4_576x691.jpeg?alt=media&token=513150c2-3c84-4c3d-bf9b-8db2f98d4a9b" />

                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://kangupago.com/" />
                        <meta property="og:title" content="Completa tu Compra" />
                        <meta property="og:description" content="Paga en Línea o Contra Entrega. Seguro y Simple." />
                        <meta property="og:image" content={product.imagenesDeProducto[0]} />
                    </Helmet>
                );
            }
            
            return(
                <div className="notranslate template1-en-catalogo" lang="es" translate="no">
                    {/* Dynamic Description*/}
                    {reactHelmet}

                    {/* Notifications Toast*/}
                    <Toaster 
                        toastOptions={{
                            duration: 5000,
                            position: 'top-center',
                        }}
                    />

                   {/* Whats App Btn */}
                   <div>
                        <a href={"https://api.whatsapp.com/send?phone=" + vendedor.Telefono + "&text=Hola, buenos días"} className="floating" target="_blank">
                            <i className="bi bi-whatsapp float-button"></i>
                        </a>
                    </div>
                    
                    {/*Modal para Realizar Pedido*/}
                    <div className="modal fade" id="pedidoModal" tabindex="-1" aria-labelledby="pedidoModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title" id="pedidoModal">Realizar Pedido</h3>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-4">
                                        <div>
                                            <h5 className="cuentaVendedorLabelForm">Detalles de Orden</h5>
                                            {totalDeOrden}
                                            <h6 className="">Envío: Gratis</h6>
                                            {opcion}
                                            <h6 className="">Cantidad: {this.state.cantidad}</h6>
                                        </div>
                                        <form className="row mt-3">
                                            <h5 className="addProductFormLabel mt-2">Información de Envío</h5>
                                            {/* Nombre del Cliente */}
                                            <div className="col-12">
                                                <label for="nombreCliente" className="form-label">Nombre Completo</label>
                                                <input type="text" onChange={this.handleChange} className="form-control" id="nombreCliente"/>
                                            </div>

                                            {/* Correo Electrónico */}
                                            <div className="col-12 mt-3">
                                                <label for="emailCliente" className="form-label">Correo Electrónico</label>
                                                <input type="text" onChange={this.handleChange} className="form-control" id="emailCliente"/>
                                            </div>

                                            {/* Teléfono */}
                                            <div className="col-12 mt-3">
                                                <label for="telefonoCliente" className="form-label">Teléfono</label>
                                                <input type="text" onChange={this.handleChange} value={this.state.telefonoCliente} className="form-control" id="telefonoCliente"/>
                                            </div>

                                            {/* Calle */}
                                            {/* <div className="col-12 mt-3">
                                                <label for="line1" className="form-label">Calle y Número</label>
                                                <input type="text" onChange={this.handleChange} className="form-control" id="line1"/>
                                            </div> */}

                                            {/* Address Google */}
                                            <div className="col-12 mt-3">
                                                <label for="direccion" className="form-label">¿Dónde debemos <span className="landingTextEnfasis bold">enviar</span> el paquete? Elije la opción <span className="bold">correcta</span>, asegurate de inlcuir el número.</label>
                                                <Geosuggest
                                                    placeholder=""
                                                    onSuggestSelect={this.onSuggestSelect}
                                                    location={new google.maps.LatLng(25.654333, -100.3698572)}
                                                    radius="20"
                                                    country='mx'
                                                    types={["geocode"]}
                                                    maxFixtures={5}
                                                />
                                            </div>

                                            {/* Municipio */}
                                            {/* <div className="col-md-12 mt-3">
                                                <label for="municipio" className="form-label">Municipio</label>
                                                <input onChange={this.handleChange} class="form-control" list="datalistOptionsMunicipios" id="municipio"/>
                                                <datalist id="datalistOptionsMunicipios">
                                                    {municipiosOpciones}
                                                </datalist>
                                                {errorMunicipio}
                                            </div> */}

                                            {/* Ciudad */}
                                            {/* <div className="col-12 mt-3">
                                                <label for="city" className="form-label">Ciudad</label>
                                                <input type="text" onChange={this.handleChange} className="form-control" id="city"/>
                                            </div> */}

                                            {/* Codigo Postal */}
                                            <div className="col-12 mt-3">
                                                <label for="postal_code" className="form-label">Código Postal</label>
                                                {inputPostalCode}
                                            </div>

                                            {/* Referencia */}
                                            <div className="col-12 mt-3">
                                                <label for="reference" className="form-label">Referencia</label>
                                                <input type="text" onChange={this.handleChange}  className="form-control" placeholder="Ej. Entre Calles / Interior Casa #91 / Piso #1 / Dpt #301" id="reference"/>
                                            </div>

                                            {/* Estado */}
                                            {/* <div className="col-md-12 mt-3 mb-3">
                                                <label for="state" className="form-label">Estado</label>
                                                <input onChange={this.handleChange} class="form-control" list="datalistOptionsEstados" id="state"/>
                                                <datalist id="datalistOptionsEstados">
                                                    {estadosOpciones}
                                                </datalist>
                                                {errorMunicipio}
                                            </div> */}
                                        </form>
                                        {/* Opción de Punto 99 como Lugar de Recolección */}
                                        {/* <div className="mt-3">
                                            <h5 for="enviarA" className="addProductFormLabel">A Dónde Quieres que Enviemos el Paquete</h5>
                                            <select onChange={this.handleChange} id="enviarA" className="form-select" aria-label="No">
                                                <option value="direccionCliente" selected>Mi Dirección</option>
                                                <option value="punto99">Punto de Recolección (Recoges tu pedido aquí)</option>
                                            </select>
                                            {avisoParaContinuar}
                                        </div> */}
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                                    {rigthButtonModalRealizarPedido}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal Para Escoger Punto de Recolección P99 */}
                    <div className="modal fade" id="puntoDeRecoleccionModal" tabindex="-1" aria-labelledby="puntoDeRecoleccionModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title" id="puntoDeRecoleccionModal">Punto de Recolección</h3>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        {puntosDeRecoleccion} 
                                    </div>
                                    <p>*Selecciona el punto de recolección más conveniente para recojer tu paquete.</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" className="btn btn-grey" data-bs-toggle="modal" data-bs-target={"#pedidoModal"} data-bs-dismiss="modal">Atrás</button>
                                    {window.location.href.indexOf("catalogoenlinea.mx") > -1 ? <button onClick={() => handleSubmit()} type="button" className="btn btn-primary" id="realizarPedidoBtn">Realizar Pedido</button>: <button onClick={() => handleSubmit()} type="button" className="btn btn-primary-cliente" id="realizarPedidoBtn">Realizar Pedido</button>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Modal Para Ver Todas las Reseñas*/}
                    <div class="modal fade" id="modalShowReviews" tabindex="-1" aria-labelledby="showReviews" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="editPrice">Opiniones de este Producto</h4>
                            </div>
                            <div class="modal-body modalReseñas">
                                <ReviewList reseñas={reseñasTotal}/> 
                            </div>
                            <div class="modal-footer">
                                {window.location.href.indexOf("catalogoenlinea.mx") > -1 ? <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button> : <button type="button" className="btn btn-primary-cliente-mobile noHover" data-bs-dismiss="modal">Cerrar</button>}
                            </div>
                            </div>
                        </div>
                    </div>

                    {/* Navbar */}
                    <div className="navbar-template template1">
                        <p className="nombreCatalogo">{oDisenoEnCatalogo.sNombre}</p>
                    </div>

                    <div className="container-fluid custom-container">
                        {contentToDisplay}
                    </div>

                    <div className="fifth-content-template1 template1 mt-5">
                        <div className="container">
                            <p className="footer-name-template1">{oDisenoEnCatalogo.sNombre}</p>
                            <div className="d-flex">
                                <p className="footer-social-template1 me-3"><i class="bi bi-instagram"></i></p>
                                <p className="footer-social-template1"><i class="bi bi-facebook"></i></p>
                            </div>
                            <p className="footer-derechos-template1">Todos los derechos reservados 2022.</p>
                        </div>
                    </div>
                    
                    
                </div>
            );
        }else{
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }
        
    }
}

const mapStateToProps = (state, ownProps) => {
    const productID = ownProps.match.params.productID; //id of product
    const vendedorID = ownProps.match.params.vendedorID; //id of vendedor
    const sDisenoEnCatalogoID = ownProps.match.params.sDisenoEnCatalogoID; //diseño del catálogo
    const precioHash = ownProps.match.params.precio; //precioDelProducto
    
    let alphabet = "abcdefghijklmnopqrstuvwxyz";
    let precioSinHash = "";
    
    
    for(let i = 0; i < precioHash.length; i=i+2){
        precioSinHash = precioSinHash + alphabet.indexOf(precioHash[i]);
    }

    return{
        productID: productID,
        vendedorID: vendedorID,
        sDisenoEnCatalogoID: sDisenoEnCatalogoID,
        precio: precioSinHash
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addVenta: (state, precio, vendedorID, productID) => dispatch(addVenta(state, precio, vendedorID, productID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCheckoutCatalogo);