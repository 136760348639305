import React from 'react'
import '../../App.css';
import SignedInLinks from './SignedInLinks';
import SignedInLinksMobile from './SignedInLinksMobile';
import SignedInLinksNegocio from './SignedInLinksNegocio';
import SignedOutLinks from './SignedOutLinks';
import CheckoutNav from './CheckoutNav';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';

const Navbar = (props) => {
    const {auth, profile} = props;
    let Links;
    
    if(window.location.href.indexOf("catalogoenlinea.mx") > -1 && window.location.href.indexOf("checkout") > -1){
        Links = <CheckoutNav/>
    }
    else if(window.location.href.indexOf("kangupago.com") > -1 && window.location.href.indexOf("checkout") > -1){
        Links = <CheckoutNav/>
    }
    else if(auth.uid && window.innerWidth < 600){
        Links = <SignedInLinksMobile auth={auth} profile={profile}/>
    }
    else if(auth.uid && profile.Nombre_de_Negocio === ''){
        Links = <SignedInLinks auth={auth} profile={profile}/>
    }
    else if(auth.uid && profile.Nombre_de_Negocio != ''){
        Links = <SignedInLinksNegocio auth={auth} profile={profile}/>
    }
    else{
        Links = <SignedOutLinks/>
    }

    return(
        <div>
            {auth.isLoaded && profile.isLoaded && Links}
        </div> 
    );
}



export default Navbar;
