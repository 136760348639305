import React, { Component } from 'react';
import {connect} from 'react-redux';
import {addProduct} from '../../../store/actions/productActions';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import ReactPixel from 'react-facebook-pixel';

class AddIndividualProduct extends Component {
    state = {
        nombre: '',
        descripcion: '',
        negocio: this.props.profile.Nombre_de_Negocio,
        negocio_id: this.props.auth.uid,
        negocio_email:this.props.auth.email,
        precio: 0,
        categoria:'',
        personalizado: "0",
        variaciones: [],
        cantidades: [],
        precioMayoreo: "",
        cantMinMayoreo: "",
        loyaltyBonus:0,
        peso: "",
        
        //Imagenes de Producto
        filenames: [],
        downloadURLs: [],
        isUploading: false,
        uploadProgress: 0,
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    
    handleProgress = progress => this.setState({ progress });
    
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleDownload = async (newFileFinal) => {
        const downloadURL = await firebase
          .storage()
          .ref("images")
          .child(newFileFinal)
          .getDownloadURL();

        this.setState(oldState => ({
            filenames: [...oldState.filenames, newFileFinal],
            downloadURLs: [...oldState.downloadURLs, downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
        
    }

    handleUploadSuccess = async (filename, tipo) => {
        //New File URL
        let newFile;
        let newFileFinal;
        if(filename.substring(filename.length - 5, filename.length) === ".jpeg"){
            //JPEG
            newFile = filename.substring(0,filename.length - 5);
            newFileFinal = newFile + '_576x691.jpeg';
        }
        else{
            //PNG O JPG
            newFile = filename.substring(0,filename.length - 4);
            newFileFinal = newFile + '_576x691.jpeg';
        }

        setTimeout(() => {
            this.handleDownload(newFileFinal, tipo);
        }, 5000);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeVariaciones = (e, index) => {
        const list = [...this.state.variaciones];
        list[index] = e.target.value;
        this.setState({
            variaciones: list
        })
    }

    handleChangeCantidad = (e) => {
        const list = [e.target.value];
                        
        this.setState({
            cantidades: list
        })
    }

    handleChangeCantidades = (e, index) => {
        const list = [...this.state.cantidades];
        list[index] = e.target.value;
        this.setState({
            cantidades: list
        })
    }

    handleAddVariacion = () => {
        const listV = [...this.state.variaciones];
        let listC = [...this.state.cantidades];
        if(listV.length === 0)
            listC = []
        listV.push("");
        listC.push("");
        this.setState({
            variaciones: listV,
            cantidades: listC
        })
    }

    handleRemoveVariacion = index => {
        const listV = [...this.state.variaciones], listC = [...this.state.cantidades];
        listV.splice(index, 1);
        listC.splice(index, 1);
        this.setState({
            variaciones: listV,
            cantidades: listC
        })
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
       
        if(this.state.downloadURLs[0] && this.state.nombre != '' && this.state.descripcion != '' && this.state.precio != 0 && this.state.peso != ""){
            this.props.addProduct(this.state);
        }
        else if(this.state.nombre === '' || this.state.descripcion === '' || this.state.precio === 0 || this.state.comision === 0 || this.state.peso === ""){
            alert(
                'La información es incompleta.'
            ); 
        }
        else{
            alert(
                'Se están processando tus imagenes. Esperate 10 segundos, y después vuelve a seleccionar el botón "agregar producto".'
            );  
        }
    };

    render(){
        let peso = this.state.peso;
        let costoDeEnvio = peso === "de2a3" || peso === "de3a5" ? 110 : peso === "de5a25" ? 150 : 80;
        let precioBase = Number(this.state.precio);
        let comisionDelVendedor = Math.round(precioBase*0.15);
        let costoPorProcesarPorcentaje = 0.04;
        let precioFinal = Math.round((precioBase + costoDeEnvio + comisionDelVendedor) / (1 - costoPorProcesarPorcentaje));
        let costoPorProcesarCantidad = Math.round(precioFinal * 0.04);
        
        let modalCalculoPrecioFinal = (
            <div class="modal fade" id="modalCalculoIngresoNeto" tabindex="-1" aria-labelledby="modalCalculoIngresoNeto" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Cálculo de Precio Final</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <p>El precio final en Kanguru es diferente a tu precio. Nosotros generamos un precio sugerido de venta, siendo el precio final. Ya que este incluye el costo de envío, la comisión del vendedor, y el costo por procesar la transacción.</p>
                            <p>Es importante recordar que Kanguru cobra su comisión de 7% sobre tu precio, el precio base.</p>
                        </div>
                        <div>
                            <p className="addProductFormLabel">Parámetros</p>
                            <p>Precio Base: {precioBase}</p>
                            <p>Costo de Envío: {costoDeEnvio}</p>
                            <p>Comision Del Vendedor: {comisionDelVendedor}, igual a 15% del Precio Base</p>
                            <p>Costo por Procesar Transacción: {costoPorProcesarCantidad}</p>
                        </div>
                        <div className="mt-4">
                            <p className="addProductFormLabel">Formula para Calcular Precio Final</p>
                            <p>Precio Final = (precioBase + costoDeEnvio + comisionDelVendedor) / 0.96</p>
                        </div>
                        <div className="mt-4">
                            <p className="addProductFormLabel">Precio Final en Kanguru: {precioFinal}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                    </div>
                </div>
            </div>
        );

        
        return(
            <div>
                {/* Modals */}
                {modalCalculoPrecioFinal}

                <form className="row g-3">
                    {/* Nombre del Producto */}
                    <div className="col-12">
                        <label for="nombreProducto" className="form-label addProductFormLabel">Nombre del Producto</label>
                        <input type="text" onChange={this.handleChange} className="form-control" id="nombre"/>
                    </div>

                    {/* Descripción */}
                    <div className="col-12">
                        <label for="descripcion" className="form-label addProductFormLabel">Descripción</label>
                        <textarea maxLength="3000" onChange={this.handleChange} className="form-control" id="descripcion" rows="16"></textarea>
                    </div>

                    {/* Precio */}
                    <div className="col-md-6">
                        <label for="precio" className="form-label addProductFormLabel">Precio</label>
                        <input type="text" onChange={this.handleChange} className="form-control" id="precio"/>
                    </div>

                    {/* Peso*/}
                    <div className="col-6">
                        <label for="peso" className="form-label addProductFormLabel">Peso del Producto</label>
                        <select onChange={this.handleChange} id="peso" className="form-select" aria-label="No">
                            <option disabled selected>Peso</option>
                            <option value="menosDe1">Menos de 1 Kg</option>
                            <option value="de1A2">De 1 a 2 Kg</option>
                            <option value="de2a3">De 2 a 3 Kg</option>
                            <option value="de3a5">De 3 a 5 Kg</option>
                            <option value="de5a25">De 5 a 25 Kg</option>
                        </select>
                    </div>

                    {/* Precio Final en Kanguru*/}
                    <div className="col-md-6">
                        <div className="d-flex">
                            <label for="precio" className="form-label addProductFormLabel me-2">Precio Final en Kanguru</label>
                            <i data-bs-toggle="modal" data-bs-target="#modalCalculoIngresoNeto" class="bi bi-question-circle pointer"></i>
                        </div>
                        <input type="text" placeholder={precioFinal} className="form-control" id="precioFinal" disabled/>
                    </div>

                    {/* Categoría */}
                    <div className="col-md-6">
                        <label for="categoria" className="form-label addProductFormLabel">Categoría</label>
                        <select onChange={this.handleChange} id="categoria" className="form-select" aria-label="No">
                            <option disabled selected>Categoría</option>
                            <option value="accesoriosParaVehiculos">Accesorio para Vehículos</option>
                            <option value="belleza">Belleza</option>
                            <option value="bienestar">Bienestar</option>
                            <option value="fitness">Fitness</option>
                            <option value="joyería">Joyería</option>
                            <option value="hogar">Hogar</option>
                            <option value="cocina">Cocina</option>
                            <option value="moda">Moda</option>
                            <option value="calzado">Calzado</option>
                            <option value="juegosYJuguetes">Juegos y Juguetes</option>
                            <option value="mascotas">Mascotas</option>
                            <option value="tecnologia">Tecnología</option>
                            <option value="bebes">Bebés</option>
                            <option value="optica">Lentes y Óptica</option>
                            <option value="sexToys">Sex Toys</option>
                        </select>
                    </div>

                    {/* Variaciones */}
                    <div className="col-12 mt-3">
                        <label for="variaciones" className="form-label addProductFormLabel">¿Este producto diferentes opciones? (Ej. Talla S, M, L)</label>
                        <i type="button" onClick={this.handleAddVariacion} className=" ms-3 addIconVariacion bi bi-plus-lg"></i>
                        
                        {this.state.variaciones.map((valor, idx) => {
                            return (
                                <div className="d-flex mb-2 mt-2" key={idx}>
                                    <input type="text" placeholder={"Ej. Talla S "} value={valor} onChange={e => this.handleChangeVariaciones(e, idx)} id="variaciones" className="form-control me-2"/>
                                    <input type="number" placeholder={"Cantidad Disponible"} value={this.state.cantidades[idx]} onChange={e => this.handleChangeCantidades(e, idx)} className="form-control" id="cantidades"/>
                                    <i type="button" id="borrarVar" onClick={() => this.handleRemoveVariacion(idx)} className="ms-3 addIcon2Variacion bi bi-trash"></i>
                                </div> 
                            )
                        })}
                    </div>

                    {/* Cantidad */}
                    {this.state.variaciones.length === 0 &&
                        <div className="col-12">
                            <label for="cantidades" className="form-label addProductFormLabel">Cantidad Disponible</label>
                            <input type="number" onChange={this.handleChangeCantidad} className="form-control" id="cantidades"/>
                        </div>
                    }
                    
                    {/* Imagen Principal */}
                    <div className="col-12">
                        {this.state.avatarURL && <img src={this.state.avatarURL} />}
                        <h6 for="imagenes" className="form-label addProductFormLabel mt-3">Imagen Principal del Producto (.jpg o .jpeg o .png)</h6>
                        <FileUploader
                            accept="image/*"
                            name="image-uploader"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                        />
                    </div>

                    {/* Imagenes de Producto */}
                    <div className="col-12">
                        {this.state.avatarURL && <img src={this.state.avatarURL} />}
                        <h6 for="imagenes" className="form-label addProductFormLabel mt-3">Otras Imagenes del Producto (.jpg o .jpeg o .png)</h6>
                        <FileUploader
                            accept="image/*"
                            name="image-uploader-multiple"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            multiple
                        />
                    </div>
                    <p className="mt-4">*Carga la imagen principal primero. Para las otras imagenes, puedes seleccionar multiples imagenes al mismo tiempo.*</p>
                    {this.state.isUploading && <p className="mt-3">Se están cargando las imágenes...</p>}
                    
                    {/* Submit Button */}
                    <div class="col-12 ">
                        <button onClick={this.handleSubmit} type="submit" className="btn btn-primary mt-3 mb-5">Agregar Producto</button>
                    </div>
                </form>
            </div>
        );
    }
}

//Get user data so we can add the product to him
const mapStateToProps = (state) => {
    return{
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        addProduct: (product) => dispatch(addProduct(product))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIndividualProduct);
