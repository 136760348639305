const initState = {
    products: [
        {id: '', nombre: '', negocio: '', negocio_id: '' ,imagenesDeProducto:['img/oro.png'], imagenesDeMaterialGraficoDeProducto:['img/oro.png'], descripcion: '', comision: '', precio: '', cantidades: []}
    ]
};

const productReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_PRODUCT':
            console.log('Added Product', action.product);
            return state;
        case 'ADD_PRODUCT_ERROR':
            console.log('Added Product Error', action.err);
            return state;
        case 'DELETE_PRODUCT':
            console.log('Deleted Product', action.product);
            return state;
        case 'DELETE_ERROR':
            console.log('Deleted Product Error', action.err);
            return state;
        case 'DISPLAY_PRODUCT':
            console.log('Display Product', action.product);
            return state;
        case 'DISPLAY_PRODUCT_ERROR':
            console.log('Display Product Error', action.err);
            return state;
        case 'EDIT_PRODUCT':
            console.log('Edited Product', action.product);
            return state;
        case 'EDIT_PRODUCT_ERROR':
            console.log('Edit Product Error', action.err);
            return state;
        case 'ADD_PRODUCT_CATALOGO':
            console.log('Added Product to Catalogo');
            return state;
        case 'ADD_PRODUCT_CATALOGO_ERROR':
            console.log('Added Product to Catalogo Error', action.err);
            return state;
        case 'DELETE_PRODUCT_CATALOGO':
            console.log('Deleted Product from Catalogo');
            return state;
        case 'DELETE_PRODUCT_CATALOGO_ERROR':
            console.log('Added Product to Catalogo Error', action.err);
            return state;
        case 'UPDATE_COSTO_ENVIO_SUCCESS':
            console.log('se editó costo de envío');
            return state;
        case 'UPDATE_COSTO_ENVIO_ERROR':
            console.log('No se editó costo de envío', action.err);
            return state;
        case 'UPDATE_PRECIO_SUCCESS':
            console.log('se editó precio');
            return state;
        case 'UPDATE_PRECIO_ERROR':
            console.log('No se editó el precio', action.err);
            return state;
        case 'UPDATE_LINKS_CATALOGO_SUCCESS':
            console.log('se editó el link');
            return state;
        case 'UPDATE_LINKS_CATALOGO_ERROR':
            console.log('No se editó el link', action.err);
            return state;
        case 'UPDATE_COMISION_SUCCESS':
            console.log('se editó la comisión');
            return state;
        case 'UPDATE_COMISION_ERROR':
            console.log('No se editó la comisión', action.err);
            return state;
        case 'ADD_PRODUCT_CSV':
            console.log('Se agregó un producto con csv', action.err);
            return state;
        case 'ADD_PRODUCT_CSV_ERROR':
            console.log('No se agregó un producto de csv', action.err);
            return state;
        case 'EDIT_PRODUCT_CSV':
            console.log('Se editó un producto con csv', action.err);
            return state;
        case 'EDIT_PRODUCT_CSV_ERROR':
            console.log('No se editó un producto de csv', action.err);
            return state;
        case 'PUBLISH_PENDING_PRODUCT':
            console.log('Se publicó el producto pendiente', action.err);
            return state;
        case 'PUBLISH_PENDING_PRODUCT_ERROR':
            console.log('No se publicó el producto pendiente', action.err);
            return state;
        case 'DELETE_PENDING_PRODUCT':
            console.log('Se borró el producto pendiente', action.err);
            return state;
        case 'DELETE_PENDING_PRODUCT_ERROR':
            console.log('Se borró el producto pendiente', action.err);
            return state;
        default:
            return state;
    }
}

export default productReducer;