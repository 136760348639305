import React, { useState } from 'react';
import {connect} from 'react-redux';
import {addReto} from '../../store/actions/retoActions';

const RetoActual = (props) => {
    
    const {ventas, retos, auth} = props;
    const [checkedReto, setCheckedReto] = useState(false);

    const CompletoRetoAntes = (retos, inicio, fin) => {
        //iterar retos
        for(let i = 0; i < retos.length; i++){
            if(retos[i].createdAt > inicio &&  retos[i].createdAt < fin){
                console.log("Ya completo el reto antes.")
                return true;
            }
        }
        return false;
    }

    const descripcion = "Vende 2,000 pesos o más y gana un bono de 100 pesos. Aplica para todas las tiendas menos Kanguru y Squareshop."
    const periodo = "Periodo del reto: Julio 25 2022 a Julio 30 2022";
    const cantidad = 300;
    const cantidadParaCumplirReto = 2000;
    const inicio = new Date('July 25, 2022 00:00:00');
    const fin = new Date('July 31, 2022 00:00:00');
    const negociosValidosParaReto = ["t8oLxehnOgNV2x3YO1mWaozlAAt1", "rHzReJm4p8TZccEg0srSgRrDeVC3", "p8DjDs3r7AUdYhPgFkC0F0At8Lf2", "hYcxg5jQDCb0mPh75Z05DwClAl23", "d8qcskWNioam0TRajVU3yYQCXiN2", "ZSZRT2zDKkc72WthH9jbFNutDtF3", "KdapLIGuTESaIfDmmVWyXvKNbj02", "EG80TpociEb6YT6Rkv1gRercmHI2", "BLl34HKMc1bcawzMHaALPqheuzx2", "360tkOXiWdXFzI4woedRelbIuUo2", "0tskUsErRuVle7Xu8r7KOntoHLl2", "zizJ0iaNx7cULOy0K88eeZUSXIz2", "pUpcy3D7yMQZZ24QWxLU4LnfePB3", "AzVweFmitmYXfowdujlK2vlkCTr1", "6x5fHqx7xVdmGifV7GkET726uzC2", "pLNMNZvaZUTxeTji3522DaXYQCC2", "lVkQJUEKnvTOgwVQZobenXdlrhD3", "WtjLRIH4pqOFmlAjleOuLmFdK2Q2", "Nk25ODT9MlMFgS9KS0GyawylKF52"];
    
    //Calcula las ventas pagadas totales del afiliado en el periodo
    const ventasDelAfiliadoEnPeriodo = ventas.filter((venta) => {
        if(venta.pagado && (venta.createdAt > inicio &&  venta.createdAt < fin) && negociosValidosParaReto.includes(venta.negocio_id)){
            return venta;
        }
    });

    //Calcula el monto total que ha vendido el afiliado en el periodo
    let montoTotalEnPeriodo = 0;
    for(let i = 0; i < ventasDelAfiliadoEnPeriodo.length; i++){
        montoTotalEnPeriodo = montoTotalEnPeriodo + ventasDelAfiliadoEnPeriodo[i].ingresoTotal;
    }

    //Calcula el porcentaje del reto cumplido
    let porcentajeFaltante = (montoTotalEnPeriodo / cantidadParaCumplirReto) * 100;
    porcentajeFaltante = porcentajeFaltante.toString() + "%";

    //Mensaje de Motivación
    let mensajeDeMotivacion;
    if(montoTotalEnPeriodo < cantidadParaCumplirReto){
        mensajeDeMotivacion = (
            <p>¡Tú puedes, <span className="reto-falta">sólo te faltan {cantidadParaCumplirReto - montoTotalEnPeriodo} pesos</span> para completar el reto! 💸💸</p>
        );
    }
    else{
        mensajeDeMotivacion = (
            <p>¡FELICIDADES! Cumpliste el reto y ganaste <span className="reto-falta">${cantidad} pesos</span> 🎉🎉</p>
        );
    }

    //Si el vendedor cumple con el reto y aún no lo completa
    if(montoTotalEnPeriodo >= cantidadParaCumplirReto && !CompletoRetoAntes(retos, inicio, fin) && !checkedReto){
        //Llamar action para agregar reto
        props.addReto(auth.uid, cantidad, fin, inicio, descripcion);
        setCheckedReto(true);
    }

    return(
        <div>
            <ul className="list-group">
                <a className="list-group-item list-group-item-action reto-actual">
                    <li class="d-flex justify-content-between align-items-left mt-3">
                        <div>
                            <div class="fw-bold">
                                Reto Activo: {descripcion}
                            </div>
                            <div className="bottomInfoComision">
                                {periodo}
                            </div>
                            <div className="bottomInfoComision">
                                {mensajeDeMotivacion}
                            </div>
                        </div>
                    </li>
                    <div className="progress mb-3">
                        <div className="progress-bar  progress-bar-striped progress-bar-animated progress-bar-reto" style={{width: porcentajeFaltante}} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </ul>
           
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addReto: (vendedorID, cantidad, fin, inicio, descripcion) => dispatch(addReto(vendedorID, cantidad, fin, inicio, descripcion))
    }
}



export default connect(null, mapDispatchToProps)(RetoActual);