
const toHex = (input) => {
    //750 sería hmfkaz
    //Del 0-4 la letra es az,by,cx,dw,ev
    //Del 5-9 la letra es fk,gl,hm,in,jp
    let hash = "";
    let alphabet = "abcdefghijklmnopqrstuvwxyz";
    let temp = "";
    for(let i = 0; i < input.length; i++){
        if(Number(input[i]) < 5){
            temp = temp + alphabet[input[i]] + alphabet[25 - input[i]];
        }
        else{
            temp = temp + alphabet[input[i]] + alphabet[Number(input[i])+5];
        }
    }
    
    hash = temp;
  
    return hash;
}

export const addVenta = (state, precio, vendedorID, productID) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        let opcion;
        let precioFinal;
        let precioViejo;
        let direccionDeRecoleccion;
        let direccionCliente;
        let peso;
        const product = state.product;
        const codigoReferidoAfiliado = state.codigoReferidoAfiliado;
        const accountID = state.negocio.Connect_Account_ID;
        const comision = product.comision;

        if(state.negocio.direccionDeRecoleccion){
            direccionDeRecoleccion = state.negocio.direccionDeRecoleccion;
        }
        else{
            direccionDeRecoleccion = {line1: "", city: "", postal_code: "", state: "", type: ""};
        }

        if(state.sTipoDeDireccion === "coordBased"){
            direccionCliente = {address: {address: state.direccion, location: state.location, postal_code: state.postal_code, reference: state.reference}, name:state.nombreCliente, type: state.sTipoDeDireccion};
        }
        else{
            direccionCliente = {address: {line1: state.line1, numExterior: state.numExterior, postal_code: state.postal_code, neighborhood: state.neighborhood, municipality: state.municipality, city: state.city, state: state.state, reference: state.reference}, name:state.nombreCliente, type: state.sTipoDeDireccion};
        }

        if(product.variaciones[0] != '' && product.variaciones[0]){
            opcion = product.variaciones[state.variaciones];
        }else{
            opcion = null;
        }

        precioFinal = Number(precio) * state.cantidad; //Lo que se le cobra al cliente
        
        const firestore = getFirestore();

        //Ingreso total debe de ser el precio (producto más envío) más comisión por cantidad de unidades
        
        firestore.collection('Ventas').add({
            tipoDePago: "contraEntrega",
            direccionDeRecoleccion: direccionDeRecoleccion,
            shippingInfo: direccionCliente,
            ingresoEnvio: Number(product.costoDeEnvio) * state.cantidad,
            ingresoTotal: precioFinal,
            ingresoTotalNegocio: Number(product.precio) * state.cantidad, //el negocio no recibe el ingreso del envio
            emailCliente: state.emailCliente,
            telefonoCliente: state.telefonoCliente,
            comision: comision,
            product_id: productID,
            negocio_id: product.negocio_id,
            vendedorID: vendedorID,
            negocio_email: product.negocio_email,
            personalizado: state.personalizado,
            nombreProducto: product.nombre,
            variacion: opcion,
            codigoReferidoAfiliado: codigoReferidoAfiliado,
            codigoRastreo: '',
            paqueteria: '',
            pagado: false,
            entregado: false,
            estatusDeOrden: "",
            ordenDeEnvioID: "",
            cantidad: state.cantidad,
            peso: product.peso,
            locationIdP99: state.locationIdP99,
            enviarA: state.enviarA,
            Fecha: new Date().toString().substring(3,new Date().toString().indexOf("2022")+4),
            createdAt: new Date(),
        }).then((docRef) => {
            dispatch({ type: 'ADD_VENTA', product});
            const sVentaID = docRef.id;
            const precioHash = toHex(precioFinal.toString());
            window.location.assign("/success-pce/" + sVentaID + "/" + productID + "/" + precioHash);
        })
        .catch((err) => {
            dispatch({ type: 'ADD_VENTA_ERROR', err});
        }) 
    }
};

export const addCodigoRastreo = (codigoRastreo) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Ventas').doc(codigoRastreo.venta_id).update({codigoRastreo: codigoRastreo.codigoRastreo, paqueteria: codigoRastreo.paqueteria })
        .then(() => {
            dispatch({ type: 'ADD_CODIGO_RASTREO', codigoRastreo}); 
        }).catch((err) => {
            dispatch({ type: 'ADD_CODIGO_RASTREO_ERROR', err});
        }) 
    }
};



