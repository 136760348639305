import React, { Component } from 'react';
import axios from 'axios';

{/* Despliega Material Gráfico en Product Details */}
class ProductGraficMaterial extends Component {
    state = {
        url: ''
    }

    handleChange = (e) => {
        this.setState({
          url: e.target.id
        })
    }

    
    download = e => {
        //IMPLEMENTATION 5
        axios.get(e.target.id, {responseType: 'arraybuffer'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/image'}));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.png"); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err);
        });

    };

    render(){
        const {product} = this.props;
        if(product){
            const imagenesDeMaterialGrafico = product.imagenesDeMaterialGraficoDeProducto.map(imagenDeMaterialGraficoDeProducto => {
                return(
                    <div className="col">
                        <div className="graficMaterial">
                            <img src={imagenDeMaterialGraficoDeProducto} alt="" className="card-img-top imageProductGraficMaterial"/>
                            <div className="downloadGraficMaterial">
                                <i onClick={this.download} id={imagenDeMaterialGraficoDeProducto} className="bi bi-download downloadIcon"></i>  
                            </div>
                        </div>
                    </div>
                );
            })
    
            return(
                <div className="row row-cols-1 row-cols-md-4 g-4 productGraficMaterial">
                    {imagenesDeMaterialGrafico}
                </div>
            );
        }else{
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }
        
    }
}

export default ProductGraficMaterial;