import React, { useState, useEffect, useRef} from 'react';
import '../../App.css';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import {Link, useHistory, useLocation} from 'react-router-dom';

const BottomNavAfiliado = (props) => {
    const {auth, profile} = props;
    const [showNav, setShowNav] = useState(true);
    let history = useHistory ();
    const location = useLocation();
    
    
    //If location changes, check if navbar should be shown
    useEffect(() => {
        if(window.location.href.includes('chats') || (profile.bCompletoOnboarding !== undefined && profile.bCompletoOnboarding === false)){
            setShowNav(false);
        }
        else{
            setShowNav(true);
        }
        
    }, [location]); 

    

    //No se muestra bottom nav en componente de chats
    if(showNav){
        return(
            <nav className="navbar fixed-bottom bottom-navbar" id="bottomNav">
                <div className="container-fluid d-flex navbar-icons">
                    <Link to="/" className="btn btn-bottom-navbar">
                        <i className="bi bi-house-door icon-bottom-navbar" id="inicio"></i>
                        <br></br>
                        Inicio
                    </Link>
                    <Link to="/categorias" className="btn btn-bottom-navbar">
                        <i className="bi bi-grid icon-bottom-navbar" id="categorias"></i>
                        <br></br>
                        Productos
                    </Link>
                    <Link to="/chats" className="btn btn-bottom-navbar">
                        <i className="bi bi-chat-square-text icon-bottom-navbar" id="chats"></i>
                        <br></br>
                        Chats
                    </Link>
                    <Link to="/catalogos" className="btn btn-bottom-navbar">
                        <i className="bi bi-shop icon-bottom-navbar" id="catalogos"></i>
                        <br></br>
                        Catálogos
                    </Link>
                    <Link to={'/cuenta-vendedor/' + auth.uid} className="btn btn-bottom-navbar">
                        <i className="bi bi-person-circle icon-bottom-navbar" id="cuenta"></i>
                        <br></br>
                        Cuenta
                    </Link>
                </div>
            </nav>
        );
    }
    else{
        return(
            null
        )
    }
    
    
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth
    }
}

export default connect(mapStateToProps)(BottomNavAfiliado);

