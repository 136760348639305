import React, {Component} from 'react';
import FileUploader from 'react-firebase-file-uploader';
import {publishPendingProduct} from '../../../store/actions/productActions';
import {deletePendingProduct} from '../../../store/actions/productActions';
import {connect} from 'react-redux';
import firebase from 'firebase';
import toast, { Toaster } from 'react-hot-toast';

class ProductPendingSummary extends Component{
    state = {
        //Imagenes de Producto
        filenames: [],
        downloadURLs: [],
        isUploading: false,
        uploadProgress: 0
    }

    notify = (type) => {
        if(type == "publishedPendingProduct"){
            toast.success('¡Excelente! Se publicó el producto. Puede tardar unos segundos en reflejarse. Refesca la página para ver el cambio.');
        }
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    
    handleProgress = progress => this.setState({ progress });
    
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    
    handleDownload = async newFileFinal=> {
        const downloadURL = await firebase
          .storage()
          .ref("images")
          .child(newFileFinal)
          .getDownloadURL();

        this.setState(oldState => ({
            filenames: [...oldState.filenames, newFileFinal],
            downloadURLs: [...oldState.downloadURLs, downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
    }
    
    handleUploadSuccess = async filename => {
        //New File URL
        let newFile;
        let newFileFinal;
        if(filename.substring(filename.length - 5, filename.length) === ".jpeg"){
            //JPEG
            newFile = filename.substring(0,filename.length - 5);
            newFileFinal = newFile + '_576x691.jpeg';
        }
        else{
            //PNG O JPG
            newFile = filename.substring(0,filename.length - 4);
            newFileFinal = newFile + '_576x691.jpeg';
        }

        setTimeout(() => {
            this.handleDownload(newFileFinal);
        }, 5000);
    };

    handleSubmit = () => {
        if(this.state.downloadURLs[0]){
            this.props.publishPendingProduct(this.props.product.objectID, this.state.downloadURLs);
        }
        this.notify("publishedPendingProduct");
    };

    handleDeletePendingProduct = (e) => {
        e.preventDefault();
        this.props.deletePendingProduct(this.props.product.objectID);
        alert("Se borró el producto pendiente a publicar. Puede tomar unos segundos en reflejarse. Por favor recarga la página.");
    };

   
    render(){
        const {product} = this.props;
        let publish;
        if(this.state.downloadURLs[0]){
            publish = (
                <button onClick={this.handleSubmit} type="button" className="btn btn-primary" data-bs-dismiss="modal">Publicar</button>
            );
        }
        else{
            publish = (
                <button onClick={this.handleSubmit} type="button" className="btn btn-grey btn-no-import">Publicar</button>
            );
        }
        let imagesModal = (
            <div class="modal fade" id={'n'+product.objectID} tabindex="-1" aria-labelledby="imagesModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Selecciona las fotos de este producto</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <h6 for="imagenes" className="form-label addProductFormLabel mt-3">Imagen Principal del Producto (.jpg o .jpeg o .png)</h6>
                            <FileUploader
                                accept="image/*"
                                name="image-uploader"
                                randomizeFilename
                                storageRef={firebase.storage().ref("images")}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                            />
                        </div>
                        <div className="mt-4">
                            <h6 for="imagenes" className="form-label addProductFormLabel mt-3">Otras Imagenes del Producto (.jpg o .jpeg o .png)</h6>
                            <FileUploader
                                accept="image/*"
                                name="image-uploader-multiple"
                                randomizeFilename
                                storageRef={firebase.storage().ref("images")}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                                multiple
                            />
                        </div>
            
                        <div className="mt-4">
                            {this.state.isUploading && <p>Se están cargando las imágenes...</p>}
                            <p className="">*Carga la imagen principal primero. Una vez publicado el producto, puede tomar unos segundos en reflejarse. Por favor recarga la página.*</p>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                        {publish}
                    </div>
                    </div>
                </div>
            </div>
        );

        return(
            <div>

                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                {/* MODAL PARA SUBIR FOTOS */}
                {imagesModal}

                {/* Esto ya no es parte del modal */}
                <div className="list-group-item">
                    <li class="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <div>
                                <img src="../img/base_img.png" alt=""/>
                            </div>
                            <div class="ms-3 name_pending_product">
                                {product.nombre}
                            </div>
                        </div>
                        <div class='d-flex'>
                            <h5 data-bs-toggle="modal" data-bs-target={'#n'+product.objectID} className="btn btn-primary">Publicar</h5>
                            <i onClick={this.handleDeletePendingProduct} className="bi bi-trash ms-3 iconTrash"></i>
                        </div>
                    </li>  
                </div>
            </div> 
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        publishPendingProduct: (productID, images) => dispatch(publishPendingProduct(productID, images)),
        deletePendingProduct: (productID) => dispatch(deletePendingProduct(productID))
    }
}


export default connect(null, mapDispatchToProps)(ProductPendingSummary);