export const addImages = (images) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Usuarios').doc(images.negocio_id).update({Imagenes_de_Store: images.downloadURLs
        }).then(() => {
            dispatch({ type: 'ADD_IMAGES', images}); 
        }).then(() => {
            window.location.reload(false);
        }).catch((err) => {
            dispatch({ type: 'ADD_IMAGES_ERROR', err});
        }) 
    }
};

export const addLogo = (images, negocio) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Usuarios').doc(negocio).update({Logo: images
        }).then(() => {
            dispatch({ type: 'ADD_LOGO_ERROR', images}); 
        }).then(() => {
            window.location.reload(false);
        }).catch((err) => {
            dispatch({ type: 'ADD_LOGO_ERROR', err});
        }) 
    }
};

export const addLogistics = (state) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();

        firestore.collection('Usuarios').doc(state.uid).set({
            direccionDeRecoleccion: {address: state.address, location: state.location, postal_code: state.postal_code, reference: state.reference, type: state.type}
        },{ merge: true })
        .then(() => {
            dispatch({ type: 'ADD_LOGISTICS', state}); 
        }).catch((err) => {
            dispatch({ type: 'LOGISTICS_ERROR', err});
        }) 
    }
}