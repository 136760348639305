import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';

const LandingPF = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Preguntas Frecuentes</title>
                <meta name="description" content="Conoce las respuestas a las preguntas más frecuentes sobre Kanguru."/>
            </Helmet>
            <div className="landingPage2">
                <div className="container">
                    <div>
                        <h1 className="landingText2">Afiliados</h1>
                        <div className="mt-5">
                            <div>
                                <h3>¿Cómo funciona?</h3>
                                <p className="landingPFText">Comparte los productos disponibles en Kanguru con tu red de conocidos y en redes sociales. Gana comisiones atractivas por cada venta que hagas. No necesitas invertir un sólo peso en inventario. Kanguru se encarga del envío.</p>
                            </div>

                            <div className="mt-5">
                                <h3>¿Cómo se le envía el producto a mi cliente?</h3>
                                <p className="landingPFText">Una vez que levantes un pedido, el proveedor recibirá la orden y le enviará el producto al cliente.</p>
                            </div>
                            
                            <div className="mt-5">
                                <h3>¿Cuándo gano una comisión?</h3>
                                <p className="landingPFText">Las comisiones de tus ventas se verán reflejadas en la página de "Cuenta" cuando el cliente pague su pedido. Para retirar este dinero sólo agrega un número de tarjeta y selecciona el botón de retirar. Te depositaremos el dinero los días 16 y 1 de cada mes, siendo los días 15 y 30 del mes los días corte.</p>
                            </div>

                            <div className="mt-5">
                                <h3>¿Cual es el porcentaje de comisión?</h3>
                                <p className="landingPFText">La comisión varía por producto. Para ver los productos disponibles y sus respectivas comisiones puedes crearte una cuenta, sin costo.</p>
                            </div>

                            <div className="mt-5">
                                <h3>¿Ofrecen pago contra entrega?</h3>
                                <p className="landingPFText">Sí, ofrecemos pago contra entrega.</p>
                            </div>

                            <div className="mt-5">
                                <h3>¿Cómo empiezo?</h3>
                                <p className="landingPFText">Empieza creando una cuenta <a href="https://kanguru.mx/signup-vendedor">aquí</a></p>
                            </div>
                        </div>
                            
                    </div>
                    <div>
                        <h1 className="landingText2">Proveedores</h1>
                        <div className="mt-5">
                            <div className="">
                                <h3>¿Cómo funciona?</h3>
                                <p className="landingPFText">Kanguru te da accesso a una red de miles de vendedores para vender tus productos. Para comenzar, publica tus productos sin costo. Una vez que publiques tus productos, nuestra red de vendedores podrá verlos y promocionarlos en sus redes. Al recibir un pedido, Kanguru generará la guía de envío y la podrás descargar en tu dashboard. Después de descargar la guía, la paquetería pasará a tu bodega a recojer el pedido y enviarselo al cliente.</p>
                            </div>
                            <div className="mt-5">
                                <h3>¿Qué comisión cobra Kanguru?</h3>
                                <p className="landingPFText">Kanguru te cobra una comisión del 7%. Para más información lee el siguiente documento: <Link to="/funcionamiento-negocios">aquí</Link></p>
                            </div>
                            <div className="mt-5">
                                <h3>¿Cómo funcionan los envios?</h3>
                                <p className="landingPFText">Kanguru genera y paga la guía de envío. Una vez que recibas una orden, podrás descargar la guía para la respectiva venta en tu dashboard, tú eres el encargado de despechar el pedido. La paquetería pasará por él a la dirección que definas en la sección de Logistica, dentro de tu dashboard.</p>
                            </div>
                            <div className="mt-5">
                                <h3>¿Cómo me paga Kanguru?</h3>
                                <p>Kanguru te deposita el acumulado del ingreso neto de tus ventas el día 16 o 1 de cada mes dependiendo de cuando el cliente pagó su orden. Siendo los días 15 y 30 de cada mes los días corte.</p>
                            </div>
                            <div className="mt-5">
                                <h3>¿Cómo empiezo?</h3>
                                <p className="landingPFText">Empieza creando una cuenta <a href="https://kanguru.mx/signup-negocio">aquí</a></p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default LandingPF;
