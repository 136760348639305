import React from 'react';
import ReferidoSummary from './ReferidoSummary';

const ReferidosList = (props) => {
    
    const {referidos} = props;
    
    if(referidos.length != 0){
        return(
            <div>
                <ul className="list-group">
                    {referidos && referidos.map(referido => {
                        return (
                            <div key={referido.objectID}>
                                <ReferidoSummary referido={referido}/>
                            </div>  
                        )
                    })}
                </ul>
            </div> 
        )
    }
    else{
        return(
            <div className="noProducts">
                <h2>No tienes referidos.</h2>
            </div>
        ); 
    }
    
}

export default ReferidosList;