import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingNosotros = () => {
    return(
        <div className="footer-spacing mb-5">
            {/* Dynamic Description*/}
            <Helmet>
                <title>Nosotros</title>
                <meta name="description" content="Digitalizamos las ventas por catálogo en México"/>
            </Helmet>
            <div>
                <div className="landingClabeImgVendedor">
                    <div className="textNosotros">
                        <h4 className="textNosotros2">¿Quiénes Somos?</h4>
                    </div>
                    <img src="img/landing-secure.png" className="landingClabeImgVendedor2" alt=""/>
                </div>
            </div>
            <div className="landingPage2-Nosotros">
                <div className="container-fluid custom-container row">
                    <div className="col-md-5">
                        <h1 className="landingText-Nosotros">Digitalizamos las ventas por catálogo en México</h1>
                        <h4 className="text-nosotros">Con nuestra aplicación, cualquier persona puede vender productos desde su casa sin inversión.</h4>
                        <a href="/signup-vendedor" className="btn btn-primaryLanding">Pruebalo Gratis</a>
                    </div>
                    <div className="col-md-7">
                        <img src="img/girl-digital-1.png" className="landingCatchImgNosotros" alt=""/>
                    </div>
                </div>
            </div>
            <div className="stats-nosotros">
                <div className="">
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="stats-nosotros-text">+3100</h2>
                            <h4 className="stats-nosotros-text2">Usuarios Registrados</h4>
                        </div>
                        <div className="col-md-4">
                            <h2 className="stats-nosotros-text">+50</h2>
                            <h4 className="stats-nosotros-text2">Proveedores</h4>
                        </div>
                        <div className="col-md-4">
                            <h2 className="stats-nosotros-text">+2300</h2>
                            <h4 className="stats-nosotros-text2">Productos Disponibles</h4>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="landingPageVendedores1">
                <div className="landingText2-Nosotros">
                    <p>Conoce al Equipo</p>
                </div>
                <div className="equipo row">
                    <div className="col-md-12">
                        <div className="equipo-text center">
                            <img src="img/ferrucho-nosotros.png" alt="" className="equipo-img"/>
                            <h4 className="mt-4">Fernando Doddoli</h4>
                            <h6 className="mt-4">CEO de Kanguru</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingNosotros;
