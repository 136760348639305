import React from 'react';
import ChatSummary from './ChatSummary';

const ChatsList = (props) => {

    const {chats, auth, getChat} = props;

    if(chats.length != 0){
        return(
            <div>
                {chats && chats.map(chat => {
                    return (
                        <div key={chat.objectID}>
                            <ChatSummary chat={chat} auth={auth} getChat={getChat}/>
                        </div>  
                    )
                })}
            </div> 
        )
    }
    else{
        return(
            <div className="">
                <p>No tienes chats</p>
            </div>
        ); 
        
    }
}

export default ChatsList;