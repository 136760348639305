import React, { useState, useEffect, useRef} from 'react';
import '../../App.css';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import {Link, useHistory, useLocation} from 'react-router-dom';

const BottomNavNegocio = (props) => {
    const {auth} = props;
    const [showNav, setShowNav] = useState(true);
    let history = useHistory ();
    const location = useLocation();

    //If location changes, check if navbar should be shown
    useEffect(() => {
        if(window.location.href.includes('chats')){
            setShowNav(false);
        }
        else{
            setShowNav(true);
        }
        console.log(showNav);
    }, [location]); 


    if(showNav){
        return(
            <nav class="navbar fixed-bottom bottom-navbar" id="bottomNav">
                <div class="container-fluid d-flex navbar-icons">
                    <Link to="/" className="btn btn-bottom-navbar">
                        <i class="bi bi-house-door icon-bottom-navbar"></i>
                        <br></br>
                        Inicio
                    </Link>
                    <Link to="/chats" className="btn btn-bottom-navbar">
                        <i className="bi bi-chat-square-text icon-bottom-navbar"></i>
                        <br></br>
                        Chats
                    </Link>
                    <Link to="/capacitacion-negocios" className="btn btn-bottom-navbar">
                        <i class="bi bi-info-circle icon-bottom-navbar"></i>
                        <br></br>
                        Ayuda
                    </Link>
                    <Link to={'/dashboard-negocio/' + auth.uid} className="btn btn-bottom-navbar">
                        <i class="bi bi-bar-chart-fill icon-bottom-navbar"></i>
                        <br></br>
                        Dashboard
                    </Link>
                </div>
            </nav>
        );
    }
    else{
        return(
            null
        )
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth
    }
}

export default connect(mapStateToProps)(BottomNavNegocio);

