import React from 'react';

const RetiroSummary = (props) => {

    const {retiro} = props;
    let createdAt = retiro.retiroEn.toString();
    console.log(retiro);

    return(
        <div>
            <a className="list-group-item list-group-item-action noHover">
                <li class="d-flex justify-content-between align-items-center">
                    <div>
                        <div class="fw-bold d-flex">
                        {retiro.Fecha}
                        </div>
                    </div>
                    <h5 className="retiro">${retiro.cantidad}</h5>
                </li>  
            </a>
        </div>
    )
    
}

export default RetiroSummary;