import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import {connect} from 'react-redux';
import {deleteProductFromCatalogue} from '../../store/actions/productActions';
import toast, { Toaster } from 'react-hot-toast';
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');


const ProductSummary = (props) => {
    const {product, key, catalogo, auth, vendedorID, sDisenoEnCatalogoID} = props;
    
    let precioBase = Number(product.precio);
    let costoDeEnvio = Number(product.costoDeEnvio);
    let comisionDelVendedor =  Math.round((product.comision * 0.01 * Number(product.precio)) + 20);
    let costoPorProcesarPorcentaje = 0.04;
    let precioFinal = Math.round((precioBase + costoDeEnvio + comisionDelVendedor) / (1 - costoPorProcesarPorcentaje));

    let cantMax = 0;

    for(let i = 0; i < product.cantidades.length; i++){
        if(product.cantidades[i] > cantMax)
            cantMax = product.cantidades[i];
    }

    let image;
    if(cantMax == 0){
        image = (
            <div>
                <div className="graficMaterial">
                    <img src={product.imagenesDeProducto[0]} alt="" className="card-img-top imageSizeCardDesktopSoldOut"/>
                    <div className="soldOut">
                        <h4 className="ajustesIconColor">Agotado</h4>
                    </div>
                </div>
            </div>
        );
    }
    else if(product.isDisplayed === false){
        image = (
            <div>
                <div className="graficMaterial">
                    <img src={product.imagenesDeProducto[0]} alt="" className="card-img-top imageSizeCardDesktopSoldOut"/>
                    <div className="soldOut">
                        <h4 className="ajustesIconColor">Oculto</h4>
                    </div>
                </div>
            </div>
        );
    }
    else{
        image = (
            <img src={product.imagenesDeProducto[0]} alt="" className="card-img-top imageSizeCardDesktop"/>
        );
        
    }

    const toHex = (input) => {
        //750 sería hmfkaz
        //Del 0-4 la letra es az,by,cx,dw,ev
        //Del 5-9 la letra es fk,gl,hm,in,jp
        let hash = "";
        let alphabet = "abcdefghijklmnopqrstuvwxyz";
        let temp = "";
        for(let i = 0; i < input.length; i++){
            if(Number(input[i]) < 5){
                temp = temp + alphabet[input[i]] + alphabet[25 - input[i]];
            }
            else{
                temp = temp + alphabet[input[i]] + alphabet[Number(input[i])+5];
            }
        }
        
        hash = temp;
      
        return hash;
    }

    const notify = (type) => {
        if(type == "borrarProductoDeCatalogo"){
            toast.success('Se borró el producto de tu catálogo. Puede tomar unos segundos en que vea el cambio. Por favor recarga la página.');
        }
    }

    const deleteProductFromCatalogue = (sProductoEnCatalogoID) => {
        props.deleteProductFromCatalogue(sProductoEnCatalogoID);
        notify("borrarProductoDeCatalogo");
    }

    const triggerAnalyticEvents = (precio) => {
        //Google Analytics
        gtag("event", "select_item", {
            items: [
              {
                item_id: product.objectID,
                item_name: product.nombre,
                currency: "MXN",
                item_brand: product.negocio,
                item_category: product.categoria,
                price: Number(precio)
              }
            ]
          });
    }

    //PRODUCT SUMMARY EN CATÁLOGO
    if(catalogo && product){
        let comisionDelVendedorConExtra = comisionDelVendedor + product.nIncremento;
        precioBase = Number(product.precio) + product.nIncremento; //Le tenemos que agregar el incremento para llegar al precioBase nuevo
        precioFinal = Math.round((precioBase + costoDeEnvio + comisionDelVendedor) / (1 - costoPorProcesarPorcentaje));
        let precioHash = toHex(precioFinal.toString());

        //Solo aparece el botón de borrar producto y lo que ganas si el usuario se encuentra dentro de su catálogo
        let borrarProducto;
        let tuGanas;
        if(window.location.href.indexOf("personal") != -1){
            borrarProducto = (
                <i type="button" id="borrarVar" onClick={() => deleteProductFromCatalogue(product.sProductoEnCatalogoID)} className="addIcon3Variacion bi bi-trash"></i>
            );
            tuGanas = (
                <h6 className="card-text me-3">Tú ganas: <span className="comision">${comisionDelVendedorConExtra}</span></h6>
            );
        }
        else{
            borrarProducto = (
                null
            );
            tuGanas = (
                null
            );

        }

        let linkToCatalogo;
        if(window.location.href.indexOf("catalogoenlinea.mx") > -1){
            linkToCatalogo = "/checkout" + "/" + product.objectID + "/" + vendedorID + "/" + sDisenoEnCatalogoID + '/' + precioHash;
        }
        else{
            linkToCatalogo = "/checkout" + "/" + product.objectID + "/" + vendedorID + '/' + precioHash;
        }

        
        if(product.isDisplayed === false){
            return(
                null
            )
        }
        else{
            return(
                <div onClick={() => triggerAnalyticEvents(precioFinal)} className="me-4 mb-3 mt-5">
                    {/* Notifications Toast*/}
                    <Toaster 
                        toastOptions={{
                            duration: 5000,
                            position: 'top-center',
                        }}
                    />

                    <div class="card">
                        <Link to={linkToCatalogo}>
                            {image}
                        </Link>
                        <div class="mt-3">
                            <div className="row">
                                <div className="col-9">
                                    <h6 className="card-title">{product.nombre}</h6>
                                    {tuGanas}
                                    <h6 className="product-price">${precioFinal}</h6>
                                </div>
                                <div className="col-3">
                                    {borrarProducto}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
    }
    else{
        return(
            <div onClick={() => triggerAnalyticEvents(precioFinal)} className="me-4 mb-3 mt-5">
                <div className="card">
                    <Link to={'/product/' + product.objectID}>
                        {image}
                    </Link>
                    <div class="mt-3">
                        <h6 className="card-title">{product.nombre}</h6>
                        <h6 className="card-text me-3">Tú ganas: <span className="comision">${comisionDelVendedor}</span></h6>
                        {/* Precio y Comisión */}
                        <h6 className="product-price">${precioFinal}</h6>
                    </div>
                </div>
            </div>
        );
    }
    
}

const mapStateToProps = (state, ownProps) => {

    return{
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteProductFromCatalogue: (sProductoEnCatalogoID) => dispatch(deleteProductFromCatalogue(sProductoEnCatalogoID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSummary);