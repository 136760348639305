import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import VentasList from '../ventas/VentasList';
import ReferidosList from '../referidos/ReferidosList';
import RetirosList from '../retiros/RetirosList';
import RetosList from '../retos/RetosList';
import RetoActual from '../retos/RetoActual';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import {addRetiro} from '../../store/actions/retiroActions';
import {addBankAccount} from '../../store/actions/retiroActions';
import {signOut} from '../../store/actions/authActions';
import '../../App.css';
import algoliasearch from 'algoliasearch'
import axios from 'axios';
import copy from 'copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import {Helmet} from "react-helmet";
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";
const SEARCH_API_KEY_CATALOGOS = "465a5e3eb841eff90af7f9c36278dd44";
const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "37bbdced854246edc06c7fb3c072275c";


//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";

const ALGOLIA_INDEX_VENTAS = 'Kanguru_Ventas';
const ALGOLIA_INDEX_RETIROS = 'Kanguru_Retiros';
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';
const ALGOLIA_INDEX_RETOS= 'Kanguru_Retos';

const client_ventas = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_VENTAS);
const indexVentas = client_ventas.initIndex(ALGOLIA_INDEX_VENTAS);
const client_retiros = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_RETIROS);
const indexRetiros = client_retiros.initIndex(ALGOLIA_INDEX_RETIROS);
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);
const client_retos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_RETOS);
const indexRetos = client_retos.initIndex(ALGOLIA_INDEX_RETOS);

class CuentaVendedor extends Component {
    state = {
        usuario_id: this.props.auth.uid,
        cantidad: 0,
        Banco:this.props.profile.Banco,
        Cuenta_de_Banco: this.props.profile.Cuenta_de_Banco,
        summaryListContent: "ventas",
        ventas: [],
        retiros: [],
        referidos: [],
        ventasReferidos: [],
        dineroEnCartera: 0,
        seRetiro: false,
        showLoader: false,
        fetchedData: false
    }

    triggerAnalyticEvents = (eventType, content_type) => {
        if(eventType == "select_content"){
            gtag("event", "select_content", {
                content_type: content_type
            });
        }
        else if(content_type == "share"){
            gtag("event", "share", {
                content_type: content_type,
                item_id: this.state.usuario_id, //ID del vendedor
            });
        }
        
    }

    notify = (type) => {
        if(type == "faltaTarjeta"){
            toast.error("¡Uy! Tu información bancaria es incompleta.");
        }
        else if(type == "numeroTarjetaIncompleto"){
            toast.error("Asegurate de que tu Número de Tarjeta sea de 16 dígitos :)");
        }
        else if(type == "balanceInsuficiente"){
            toast.error("!No tienes dinero para retirar! Pero no te preocupes, vendiendo ganarás :)");
        }
        else if(type == "copioEnlaceParaReferidos"){
            toast.success("¡Eso es todo! Se copió tu enlace para registrar referidos. Ahora comparte este enlace con quien quieras. Una vez que la persona se registre con tu enlace será tu referido.");
        }
        else if(type == "actualizoInformacionBancaria"){
            toast.success("¡Buenísimo! Se actualizó tu info bancaria.");
        }
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleChangeListContent = (e) => {
        this.setState({
            summaryListContent: [e.target.id]
        })
    }

    handleChangeBankAccount = (e) => {
        if(!this.state.Banco){
            this.setState({
                Banco:this.props.profile.Banco,
                usuario_id: this.props.auth.uid
            }, () => {
                this.props.addBankAccount(this.state);
                this.notify("actualizoInformacionBancaria");
            });
        }
        else if(!this.state.Cuenta_de_Banco){
            this.setState({
                Cuenta_de_Banco: this.props.profile.Cuenta_de_Banco,
                usuario_id: this.props.auth.uid
            }, () => {
                this.props.addBankAccount(this.state);
                this.notify("actualizoInformacionBancaria");
            });
        }
        else{
            this.setState({
                usuario_id: this.props.auth.uid
            }, () => {
                this.props.addBankAccount(this.state);
                this.notify("actualizoInformacionBancaria");
                this.triggerAnalyticEvents("select_content", "changedBankAccount")
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            cantidad: this.state.dineroEnCartera,
            usuario_id: this.props.auth.uid,
            Cuenta_de_Banco: this.props.profile.Cuenta_de_Banco,
            Banco: this.props.profile.Banco,
        }, () => {
            if(!this.state.seRetiro && this.state.cantidad > 0 && this.state.Cuenta_de_Banco.length === 16 && this.state.Banco != ''){
                this.props.addRetiro(this.state);
                this.setState({
                    dineroEnCartera: 0,
                    showLoader: true,
                    seRetiro: true
                }, () => {
                    setTimeout( () => {
                        this.setState({
                            showLoader: false
                        });
                      }, 5000);
                })
                this.triggerAnalyticEvents("select_content","withdrewFunds")
            }else if(this.state.Cuenta_de_Banco === '' || this.state.Banco === ''){
                this.notify("faltaTarjeta");
            }else if(this.state.Cuenta_de_Banco.length != 16){
                this.notify("numeroTarjetaIncompleto");
            }else{
                this.notify("balanceInsuficiente");
            }
        });
    }

    copyEnlaceParaReferidos = (e) => {
        let link = "¿Te gustaría trabajar desde casa? 💰" + "\n" + "Emprender con Kanguru es muy facil. Kanguru es una página de ventas que te permite vender miles de productos a cambio de comisiones, no necesitas invertir ni salir de casa. 🎉" + "\n" + "\n" + "*Si te interesa saber más, te invito registrarte, es gratis*" + "\n" + 'https://kanguru.mx/signup-vendedor/' + this.props.profile.codigoReferidoPersonal; 
        copy(link);
        this.notify("copioEnlaceParaReferidos");
        this.triggerAnalyticEvents("share","copiedReferralCode");
    }

    fetchData = async () => {
        const auth = this.props.auth;
        const codigoReferidoPersonal = this.props.profile.codigoReferidoPersonal;
        
        //Ventas de este Vendedor
        const ventas = await indexVentas.search(auth.uid, {hitsPerPage: 500});
        
        //Retiros de este Vendedor
        const retiros = await indexRetiros.search(auth.uid, {hitsPerPage: 500});
    
        //Dinero total que ha retirado este Vendedor
        let retiroTotal = 0;
        for (let i = 0; i < retiros.hits.length; i++) {
            retiroTotal = retiroTotal + retiros.hits[i].cantidad;
        }

        //Referidos de este vendedor
        const referidos = await indexUsuarios.search("", {filters: 'codigoReferidoExterno:' + codigoReferidoPersonal, hitsPerPage: 100});
        
        //Ingreso por Ventas de Referidos
        //Funciona porque sólo se agrega el código de referido de este vendedor a la primera venta de sus referidos
        const ventasDeMisReferidos = await indexVentas.search("", {filters: 'codigoReferidoAfiliado:' + codigoReferidoPersonal, hitsPerPage: 500});
        
        const ventasPagadasDeMisReferidos = ventasDeMisReferidos.hits.filter((venta) => {
            if (venta.pagado === true && venta.entregado === true) return venta;
        });

        //Se le abonan 50 pesos por cada venta de referidos
        let dineroPorReferidosTotal = ventasPagadasDeMisReferidos.length*50;

        //Retos Cumplidos de este Vendedor
        const retos = await indexRetos.search(auth.uid, {hitsPerPage: 100});
        //Dinero total que este vendedor ha ganado por retos
        let bonosTotal = 0;
        for (let i = 0; i < retos.hits.length; i++) {
            bonosTotal = bonosTotal + Number(retos.hits[i].cantidad);
        }

        //BALANCE NETO
        //Ingreso total por Comisiones de este Vendedor
        let dineroTotal = 0;
        
       
        

        for (var i = 0; i < ventas.hits.length; i++) {
            let nuevoEsquemaDeComisiones = ventas.hits[i].createdAt >= new Date('07/15/2022');
            let ingresoTotalSinComision;
            //NUEVO ESQUEMA DE COMISIONES
            if(nuevoEsquemaDeComisiones){
                if(ventas.hits[i].pagado === true){
                    ingresoTotalSinComision = Math.round(Number(ventas.hits[i].ingresoTotal) * 0.96);
                    let ingresoTotalNegocio = Number(ventas.hits[i].ingresoTotalNegocio);
                    let costoDeEnvio = ventas.hits[i].ingresoEnvio;
                    let comisionDelVendedor = Math.round(ingresoTotalNegocio * 0.01 * Number(ventas.hits[i].comision));
                    //––––––––––––––––––––––––––––––
                    //Ganancia Extra es igual al precioBase original - el precioBase nuevo
                    //Donde el ingreso del precioBase original está representado por el ingresoTotalNegocio
                    //En efecto, hay que restar el ingresoTotalNegocio del precioBase que se aplicó al hacer la venta
                    //Para encontrar la ganancia extra.
                    let precioBase = ingresoTotalSinComision - costoDeEnvio - comisionDelVendedor;
                    let gananciaExtra = precioBase - ingresoTotalNegocio;
                    let comisionDelVendedorConExtra = comisionDelVendedor + gananciaExtra;
                    dineroTotal = dineroTotal + comisionDelVendedorConExtra;
                }
            }
            else{
                let dateCompare = ventas.hits[i].createdAt < new Date('01/26/2022'); 
                ingresoTotalSinComision = Math.round(Number(ventas.hits[i].ingresoTotal) / 1.04);
                let precioOriginal = Number(ventas.hits[i].ingresoTotalNegocio) + Number(ventas.hits[i].ingresoEnvio);
                 //La venta está pagada y se hizo antes del 26 de Enero
                if(ventas.hits[i].pagado === true && dateCompare){
                    if(ventas.hits[i].tipoDePago === "enLinea"){
                        dineroTotal = dineroTotal + Math.round((ventas.hits[i].comision * 0.01 * Number(ventas.hits[i].ingresoTotalNegocio)) + (ingresoTotalSinComision - ventas.hits[i].ingresoTotalNegocio));
                    }
                    else{
                        dineroTotal = dineroTotal + Math.round((ventas.hits[i].comision * 0.01 * precioOriginal) + (ingresoTotalSinComision - precioOriginal));
                    }
                    
                }
                //La venta está pagada y se hizo después del 26 de Enero
                else if(ventas.hits[i].pagado === true && !dateCompare){
                    if(ventas.hits[i].tipoDePago === "enLinea"){
                        let ingresoDeProducto = Number(ventas.hits[i].ingresoTotalNegocio) - Number(ventas.hits[i].ingresoEnvio);
                        dineroTotal = dineroTotal + Math.round((ventas.hits[i].comision * 0.01 * ingresoDeProducto) + (ingresoTotalSinComision - ventas.hits[i].ingresoTotalNegocio));
                    }
                    else{
                        dineroTotal = dineroTotal + Math.round((ventas.hits[i].comision * 0.01 * Number(ventas.hits[i].ingresoTotalNegocio)) + (ingresoTotalSinComision - precioOriginal));
                    }
                }
            }
        }

        //BALANCE NETO
        const dineroEnCartera = (dineroTotal + dineroPorReferidosTotal + bonosTotal) - retiroTotal;
        
        this.setState({
            ventas: ventas.hits,
            referidos: referidos.hits,
            retiros: retiros.hits,
            ventasReferidos: ventasPagadasDeMisReferidos,
            dineroEnCartera: dineroEnCartera,
            retos: retos.hits,
            fetchedData: true
        });
        
    }
    

    async componentDidMount(){
        this.fetchData();
    }

    render(){
        const {auth, profile} = this.props;
        const ventas = this.state.ventas;
        const retiros = this.state.retiros;
        const referidos = this.state.referidos;
        const ventasReferidos = this.state.ventasReferidos;
        const dineroEnCartera = this.state.dineroEnCartera;
        const retos = this.state.retos;
        let fetchedData = this.state.fetchedData;
        
        //Route Guard – Si el usuario no está signed in, mandarlo a página de sign in
        if(auth.isLoaded && !auth.uid) return <Redirect to ='/signin'/>
        if(this.state.showLoader){
            return(
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status"></div>
                    <p className="mt-3">Realizando Retiro...</p>
                </div>
            )
        }
        else if(auth.isLoaded && auth.uid && profile.isLoaded && fetchedData){
            
            let summaryList;
            if(this.state.summaryListContent == "ventas"){
                summaryList = (
                    <VentasList ventas={ventas} numero={0} tipo={"vendedor"}/>
                );
            }
            else if(this.state.summaryListContent == "ventas_de_referidos"){
                summaryList = (
                    <VentasList ventas={ventasReferidos} numero={1} tipo={"vendedor"}/>
                );
            }
            else if(this.state.summaryListContent == "retiros"){
                summaryList = (
                    <RetirosList retiros={retiros} />
                ); 
            }
            else if(this.state.summaryListContent == "referidos"){
                summaryList = (
                    <ReferidosList referidos={referidos} />
                ); 
            }
            else if(this.state.summaryListContent == "retos"){
                summaryList = (
                    <div>
                        <div>
                            <RetoActual ventas={ventas} retos={retos} auth={auth}/>
                        </div>
                        <div className="retos-completados">
                            <h6 className="cuentaVendedorLabelForm mb-3">Retos Completados</h6>
                            <RetosList retos={retos} />
                        </div>
                        
                        
                    </div>
                    
                ); 
            }

            return(
                <div className="container-fluid custom-container ctaVendedorBackground notranslate mt-3" lang="es" translate="no">
                    {/* Dynamic Description*/}
                    <Helmet>
                        <title>Cuenta</title>
                        <meta name="title" content="Cuenta" />
                    </Helmet>

                    {/* Whats App Btn */}
                    <div>
                        <a href="https://api.whatsapp.com/send?phone=+524438011119&text=Hola, buenos días" className="floating" target="_blank">
                            <i className="bi bi-whatsapp float-button"></i>
                        </a>
                    </div>

                    {/*Modal de Cuenta de Banco*/}
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Ajustes</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                            <form>
                                <div class="mb-3">  
                                    <h6 className="cuentaVendedorLabelForm">Número de Tarjeta: {profile.Cuenta_de_Banco}</h6>
                                    <h6 className="cuentaVendedorLabelForm">Banco: {profile.Banco}</h6>
                                    
                                    <label for="Cuenta_de_Banco" className="col-form-label cuentaVendedorLabelForm">Número de Tarjeta Nuevo:</label>
                                    <input onChange={this.handleChange} type="text" className="form-control" id="Cuenta_de_Banco"/>
                                    
                                    <label for="Banco" className="col-form-label cuentaVendedorLabelForm">Banco:</label>
                                    <input onChange={this.handleChange} type="text" className="form-control" id="Banco"/>
                                </div>
                            </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                                <button onClick={this.handleChangeBankAccount} type="button" className="btn btn-primary" data-bs-dismiss="modal">Guardar Cambios</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    {/* Notifications Toast*/}
                    <Toaster 
                        toastOptions={{
                            duration: 6000,
                            position: 'top-center',
                        }}
                    />

                    {/* Información y Cuenta de Vendedor */}
                    <div className="mt-md-3 cuentaContenido d-flex">
                        {/* Imagen de Perfil y Nombre*/}
                        <div>
                            <div>
                                <i className="bi bi-person-circle me-2 iconPerfil"/>
                            </div>
                        </div>
                        {/* Nombre, Dinero en Cuenta, Retirar, Ajustes*/}
                        <div className="infoCuenta">
                            <div className="mb-3 d-flex w-100">
                                <h6 className="nombreVendedor">{profile.Nombre_de_Vendedor}</h6>
                                <Link to="/" onClick={() => this.props.signOut()} className="logout nav-black-text"><i  className="bi bi-arrow-bar-right"></i></Link>
                            </div>
                            <div>
                                <h4 className="btn btn-secondary dineroCuenta mb-2 ms-2">{'$' + dineroEnCartera}</h4>
                            </div>
                            <div className="">
                                {/* Ajustes*/}
                                <a href="" onClick={this.handleSetBankAccount} className="ajustesIconColor" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="bi bi-gear me-3 ajustesIcon"></i></a>
                                <h2 onClick={this.handleSubmit} className="btn btn-secondary retirarDinero">Retirar</h2>
                            </div>
                        </div>
                    </div>
                    <div className="listaVentas mt-3 mt-md-5">
                        <div className="d-flex">
                            <h6 className="cuentaVendedorLabelForm">Tu Enlace para Registrar Referidos:</h6>
                            <p onClick={this.copyEnlaceParaReferidos} className="ms-2 btn copyIcon">
                                <i class="bi bi-files"></i><span className="iconText ms-0 ms-md-2">Copiar</span>
                            </p>
                        </div>
                        <p className="mt-3 infoComisiones">*Tus comisiones de ventas con pago contra entrega se verán reflejadas cuando tu cliente reciba y pague su orden.</p>
                    </div>
                    {/*VENTAS, RETIROS, REFERIDOS, Y VENTAS DE REFERIDOS*/}
                    <div className="mt-3 mt-md-5 mb-5 listaVentas">
                        {/* <div className="d-flex">
                            <p onClick={this.handleChangeListContent} className="ventaListContentText" id="ventas">Mis Ventas</p>
                            <p onClick={this.handleChangeListContent} className="ventaListContentText ms-4" id="referidos">Ventas de Referidos</p>
                            <p onClick={this.handleChangeListContent} className="ventaListContentText ms-4" id="retiros">Mis Retiros</p>
                        </div> */}
                        <select onChange={this.handleChange} id="summaryListContent" className="form-select mb-3 cuentaVendedorSelect" aria-label="No">
                            <option selected value="ventas">Mis Ventas</option>
                            <option value="ventas_de_referidos">Ventas de Referidos</option>
                            <option value="retiros">Mis Retiros</option>
                            <option value="referidos">Mis Referidos</option>
                            <option value="retos">Retos</option>
                        </select>
                        {summaryList}
                    </div>
                </div>
            );
        }else{
            return(
                <div className="container spinner">
                <div class="spinner-border spinner-color" role="status"></div>
                    <p className="mt-3">Cargando...</p>
                    <p className="mt-3">Te deseamos un excelente día</p>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    
    const auth = state.firebase.auth;
    const profile = state.firebase.profile;
    
    return{
        auth: auth,
        profile: profile,
        codigoReferidoPersonal: profile.codigoReferidoPersonal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      addRetiro: (retiro) => dispatch(addRetiro(retiro)),
      addBankAccount: (account) => dispatch(addBankAccount(account)),
      signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CuentaVendedor);