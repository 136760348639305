/*global google*/
import React, { Component } from 'react';
import '../../App.css';
import {Helmet} from "react-helmet";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');

class RevisarCoberturaPCE extends Component {
    state = {
        postal_code: "",
        hasCoverage: true,
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
      }


    notify = (type) => {
        if(type == "hayCobertura"){
            toast.success('¡Sí hay cobertura de pago contra entrega a este código postal');
        }
        else if("sinCobertura"){
            toast.error('¡Lo sentimos, actualmente no contamos cobertura a este código postal!');
        }
        else if("invalidoCP"){
            toast.error("El código postal no está bien escrito, tienen que ser 5 dígitos.")
        }
        
    }

    handleSubmit = async () => {
        if(this.state.postal_code.length === 5){
            const postalCodeDestination = this.state.postal_code;
            const postalCodeOrigin = "06700";
            //Llamar función para validar código postal
            const validatePostalCode = axios.get("https://us-central1-kilig-cac58.cloudfunctions.net/coverageByZipCode99Minutos", {
                params: {
                    postalCodeDestination: postalCodeDestination,
                    postalCodeOrigin: postalCodeOrigin
                }
            })
            .then((response) => {
                //Update state que se validó el C.P
                if(response.data.hasCoverage){
                    this.notify("hayCobertura");
                }
                else{
                    this.notify("sinCobertura");
                }
                return this.setState({hasCoverage: response.data.hasCoverage});

            })
            .catch(function (error) {
                console.log(error);
            })
        }
        else{
            this.notify("invalidoCP");
        }
    }


    componentDidMount(){
        //Goes to the top of the page when the page first loads
        window.scrollTo(0, 0);
    }

    
    render(){
        return(
            <div>
                {/* DYNAMIC META INFORMATION */}
                <Helmet>
                    <title>Revisa si hay Cobertura</title>
                    <meta name="description" content="Revisa si hay cobertura de pago contra entrega a un código postal."/>
                </Helmet>

                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                {/* <h1 className="sign-in-title">KANGURU</h1> */}
                <div className="container position-relative start-50 translate-middle signInForm row">
                    {/* Titulo */}
                    <h3 className = "text-black signUpTitle">Revisa si hay Cobertura de Pago Contra Entrega</h3>
                
                    <form className="mt-4 col-11">
                        
                        {/* Cobertura */}
                        <div className="mb-4">
                            <label for="postal_code" class="form-label">Código Postal</label>
                            <input onChange={this.handleChange} type="text" class="form-control" id="postal_code"/>
                        </div>

                        {/* Submit Button */}
                        <div className="">
                            <button onClick={this.handleSubmit} type="button" class="btn btn-primary buttonSignUp">Enviar</button>
                        </div>
                    </form>
                </div>

                <div className="landingPage2-Cobertura">
                    <div>
                        <div className="estadosCoberturaPCE">
                            <p className="estadosCoberturaPCEText">Estados</p>
                        </div>
                        <div className="estadosCoberturaPCE">
                            <p className="municipiosCoberturaPCEText">Nuevo León, Coahuila, Sinaloa, Jalisco, Ciudad de México, Estado de México, Tabasco, Puebla, Oaxaca, Campeche, 
                            Veracruz, San Luis Potosí, Hidalgo, Morelos, Tlaxcala, Michoacán, Querétaro, Durango, Aguascalientes, Yucatán.</p>
                        </div>
                    </div>
                    <div>
                        <div className="estadosCoberturaPCE mt-5">
                            <p className="estadosCoberturaPCEText">Municipios</p>
                        </div>
                        <div className="estadosCoberturaPCE">
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Michoacán:</span> Morelia, Uruapan, Apatzingán</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Sinaloa:</span> Culiacán</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Coahuila:</span> Ramoz Arizpe, Saltillo, Torreón, Matamoros</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Nuevo León:</span> Apodaca, García, General Escobedo, Guadalupe, Monterrey, San Nicolás de los Garza, San Pedro Garza García, Santa Catarina</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">San Luis Potosí:</span> San Luis Potosí, Soledad de Graciano Sánchez</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Guanajuato:</span> León, Celaya</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Jalisco:</span> Guadalajara, San Pedro Tlaquepaque, Tlajomulco de Zúñiga, Tonalá, Zapopan, Puerto Vallarta</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Querétaro:</span> Corregidora, El Marqués, Huimilpan, Querétaro, San Juan del Rio</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Hidalgo:</span> Pachuca de Soto, Mineral de la Reforma, Actopan, Mixquiahuala de Juárez, Tula de Allende, Tepeji del Río de Ocampo, Atitalaquia, Atotonilco de Tula, Tulancingo de Bravo, Cuautepec de Hinojosa, Tizayuca, Apan, Tepeapulco</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Estado de México:</span> Atizapán,Atizapán de Zaragoza, Chalco, Chicoloapan, Chimalhuacán, Coacalco de Berriozábal, Cuautitlán, Cuautitlan Izcalli, Ecatepec de Morelos, Huehuetoca, Huixquilucan, Ixtapaluca, La Paz, Lerma, Melchor Ocampo, Metepec, Naucalpan de Juárez, Nezahualcóyotl, Nicolás Romero, San Mateo Atenco, Tecámac, Tepotzotlán, Texcoco, Tlalnepantla de Baz, Toluca, Tultitlán, Valle de Chalco Solidaridad, Zumpango </p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Veracruz:</span> Alvarado, Boca del Río, Camerino Z. Mendoza, Coatzacoalcos, Córdoba, Cosoleacaque, Fortín, Ixhuatlán del Sureste, La Antigua, Minatitlán, Orizaba, Río Blanco, Veracruz, Xalapa</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Oaxaca:</span> Oaxaca de Juárez, Ocotlán de Morelos, San Jacinto Amilpas, San Pablo Etla, Santa Cruz Xoxocotlán, Santa Lucía del Camino, Villa de Zaachila</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Tabasco:</span> Centro</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Campeche:</span> Campeche</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Puebla:</span> Atlixco, Coronango, Cuautlancingo, Huejotzingo, Izúcar de Matamoros, Ocoyucan, Puebla, San Andrés Cholula, San Martín Texmelucan, San Pedro Cholula, Teziutlán</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Ciudad de México:</span> Álvaro Obregón, Azcapotzalco, Benito Juárez, Coyoacán, Cuajimalpa de Morelos, Cuauhtémoc, Gustavo A. Madero, Iztacalco, Iztapalapa, La Magdalena Contreras, Miguel Hidalgo, Milpa Alta, Tláhuac, Tlalpan, Venustiano Carranza, Xochimilco</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Tlaxcala:</span> Tlaxcala, Apizaco, Apetatitlán de Antonio Carvajal, Amaxac de Guerrero, Santa Cruz Tlaxcala, Contla de Juan Cuamatzi, Chiautempan</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Morelos:</span> Cuautla, Curnavaca, Jiutepec, Tepoztlán, Temixco</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Aguascalientes:</span> Aguascalientes, Jesús María</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Durango:</span> Gómez Palacios, Lerdo</p>
                            <p className="municipiosCoberturaPCEText"><span className="municipio">Yucatán:</span> Kanasín, Mérida</p>
                        </div>
                    </div>
                </div> 
            </div>
        );
        
    }
}


export default RevisarCoberturaPCE;