import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingCatalogoEnLinea = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Catalogo en Linea</title>
                <meta name="description" content="¡Compra productos de tus catálogos digitales favoritos!"/>
            </Helmet>
            <div>
                <div className="landingImgCatalogoEnLinea">
                    <div>
                        <div className="textCatalogo">
                            <div className="d-flex">
                                <div className="rectangle_catalogo"></div>
                                <h4 className="textCatalogo2">COMPRA PRODUCTOS DE TUS CATÁLOGOS FAVORITOS</h4>
                            </div>
                            <h6 className="textCatalogo3">Descubre miles de catálogos digitales en un solo lugar.</h6>
                        </div>
                        
                        <img src="img/landing_catalogo_bg.png" className="landingImgCatalogoEnLinea2" alt=""/>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="squareLandingImgCatalogo">
                                <h4 className="textCatalogo4">Compras Seguras</h4>
                                <img src="img/first_sq_catalogo.png" className="first_sq_catalogo" alt=""/>
                                <h6 className="textCatalogo5">Compra de forma <span className="landingTextEnfasis">segura</span> los productos que quieras. Ofrecemos <span className="landingTextEnfasis">garantía</span> y <span className="landingTextEnfasis">devoluciones</span> sin costo.</h6>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="squareLandingImgCatalogo2">
                                <h4 className="textCatalogo4">Envíos Gratis</h4>
                                <img src="img/second_sq_catalogo.png" className="second_sq_catalogo" alt=""/>
                                <h6 className="textCatalogo5">Envío <span className="landingTextEnfasis">gratis</span> a toda la república de 5 a 7 días hábiles. Ofrecemos <span className="landingTextEnfasis">pago contra entrega.</span> </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="landingCatalogo2"></div>
        </div>
    );
}

export default LandingCatalogoEnLinea;
