import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';


const StoreSummary = ({store, hasLogo}) => {
    let storeImg;
    if(hasLogo){
        storeImg = (
            <img src={store.Logo[0]} alt="" className="card-img-top imageSizeCardDesktopLogo"/>
        );
    }
    else{
        storeImg = (
             <img src="/img/no-store.png" alt="" className="card-img-top imageSizeCardDesktopLogo"/>
        )
    }

    return(
        <div class="">
            <div class="card store-logos">
                <Link to={'/store/' + store.objectID}>
                    {storeImg}
                </Link>
                <div class="mt-3">
                    <h4 className="card-title">{store.Nombre_de_Negocio}</h4>
                </div>
            </div>
        </div>
    );
    
}


export default StoreSummary;
