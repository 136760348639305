import React, { useState, useEffect } from 'react';
import ProductSummary from './ProductSummary';
import {connect} from 'react-redux';

{/* Despliega Lista de Productos en Home */}
const ProductList = (props) => {
    
    const {products, mobile, numero, catalogo, vendedorID, oDisenoEnCatalogo} = props;
    const [show, setShow] = useState(true);
    
    useEffect(() => {
        setTimeout(() => setShow(false), 10000);
    }, []); 

    let load;
    if(show){
        load = (
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    else{
        load = (
            <div className="noProducts">
                <h1>No hay productos.</h1>
            </div>
        );
    }

    if(products.length != 0 && numero === 1 && !mobile){
        return(
            <div className="card-group notranslate" lang="es" translate="no">
                {products && products.slice(0, 28).map(product => {
                    return (
                        <div key={product.objectID}> 
                            <ProductSummary product={product}/>
                        </div>  
                    )
                })}
            </div>
        )
    }
    //HOME en Mobile
    else if(products.length != 0 && numero === 1 && mobile && !catalogo){
        return(
            <div className="row row-cols-2 gx-4 overflow-hidden card-catalogo products-dashboard notranslate" lang="es" translate="no">
                {products && products.slice(0, 4).map((product,idx) => {
                    return (
                        <div key={idx}> 
                            <ProductSummary product={product}/>
                        </div>  
                    )
                })}
            </div>
        )
    }
    //Productos de Categoria en Mobile
    else if(products.length != 0 && numero === 0 && mobile && !catalogo){
        return(
            <div className="row row-cols-2 gx-4 overflow-hidden card-catalogo products-dashboard notranslate" lang="es" translate="no">
                {products && products.map((product,idx) => {
                    return (
                        <div key={idx}> 
                            <ProductSummary product={product}/>
                        </div>  
                    )
                })}
            </div>
        )
    }
    //HOME
    else if(products.length != 0 && numero === 0 && !catalogo){
        return(
            <div className="card-group products-dashboard notranslate" lang="es" translate="no">
                {products && products.map((product,idx) => {
                    return (
                        <div key={idx}> 
                            <ProductSummary product={product}/>
                        </div>  
                    )
                })}
            </div>
        )
    }
    //CATALOGO
    else if(products.length != 0 && !mobile && numero === 0 && catalogo && oDisenoEnCatalogo ){
        return(
            <div className="card-group products-dashboard notranslate" lang="es" translate="no">
                {products && products.map((product,idx) => {
                    return (
                        <div key={idx}> 
                            <ProductSummary product={product} catalogo={catalogo} vendedorID={vendedorID} sDisenoEnCatalogoID={oDisenoEnCatalogo.objectID}/>
                        </div>  
                    )
                })}
            </div>
        );
    }
    //CATALOGO EN MOBILE
    else if(products.length != 0 && mobile && numero === 0 && catalogo && oDisenoEnCatalogo){
        return(
            <div className="row row-cols-2 gx-0 overflow-hidden card-catalogo products-dashboard notranslate" lang="es" translate="no">
                {products && products.map((product,idx) => {
                    return (
                        <div key={idx}> 
                            <ProductSummary product={product} catalogo={catalogo} vendedorID={vendedorID} sDisenoEnCatalogoID={oDisenoEnCatalogo.objectID}/>
                        </div>  
                    )
                })}
            </div>
        );
    }
    else{
        return(
            <div>
                {load}
            </div>
            
        ); 
    }
    
    
    
}

const mapStateToProps = (state, ownProps) => {
    const products = ownProps.products;
    const filter = ownProps.filter;
    const catalogo = ownProps.catalogo;
    // let idxFilteredProducts = [];
    // for(let i = 0; i < products.length; i++){
    //     idxFilteredProducts.push(i);
    // }

    if(filter != '' && catalogo){
        let filteredProducts;
        filteredProducts = products.filter((product, idx) => {
            if ((product.categoria === filter[0] || product.categoria === filter)){
                return product;
            }
        });
        
        return{
            products: filteredProducts,
        }
    }

    return{
        products: products
    }
}

export default connect(mapStateToProps, null)(ProductList);