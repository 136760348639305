const initState = {
    negocios: [
        {id: '1', Banco: '', Cuenta_de_Banco: '', Imagenes_de_Store: [], Logo: [], Nombre_de_Negocio: 'Tiffany & Co', Nombre_de_Vendedor: ''}
    ]
};

const negocioReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_IMAGES':
            console.log('Added Images', action.negocio);
            return state;
        case 'ADD_IMAGES_ERROR':
            console.log('Added Product Images Error', action.err);
            return state;
        case 'ADD_LOGO':
            console.log('Added Logo', action.negocio);
            return state;
        case 'ADD_LOGO_ERROR':
            console.log('Added Logo Error', action.err);
            return state;
        case 'ADD_LOGISTICS':
            console.log('Logistics', action.state);
            return state;
        case 'LOGISTICS_ERROR':
            console.log('Logistics error', action.err);
            return state;
        default:
            return state;
    }
}

export default negocioReducer;