export const addReto = (vendedorID, cantidad, fin, inicio, descripcion) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // make async call to database
        const firestore = getFirestore();
        firestore.collection('Retos').add({
            vendedorID: vendedorID,
            cantidad: cantidad,
            fin: fin,
            inicio: inicio,
            descripcion: descripcion,
            createdAt: new Date()
        }).then(() => {
            dispatch({ type: 'ADD_RETO', vendedorID}); 
        }).catch((err) => {
            dispatch({ type: 'ADD_RETO_ERROR', err});
        }) 
    }
};