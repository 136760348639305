import React, {Component} from 'react';
import axios from 'axios';
import '../../Stripe.css';
import {addVenta} from '../../store/actions/ventaActions';
import {connect} from 'react-redux';
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');

class StripeCheckoutButton extends Component{
  
  state = {
    status: null
  }

  triggerAnalyticEvents = (eventType, product, precio) => {
    gtag("event", "begin_checkout", {
        currency: "MXN",
        value: Number(precio),
        items: [
          {
            item_id: product.objectID,
            item_name: product.nombre,
            currency: "MXN",
            item_brand: product.negocio,
            item_category: product.categoria,
            price: Number(precio)
          }
        ]
    });
   
}

  handleToken = async () =>  {
    
    const product = this.props.product;
    const vendedorID = this.props.vendedorID;
    const productID = this.props.productID;
    const personalizado = this.props.personalizado;
    const variacion = this.props.variacion;
    const cantidad = this.props.cantidad;
    const precio = this.props.precio;
    const codigoReferidoAfiliado = this.props.codigoReferidoAfiliado;
    const accountID = this.props.accountID;
    const comision = this.props.comision;
    const originURL = window.location.href;
    const negocio = this.props.negocio;
    //Google Analytics
    this.triggerAnalyticEvents("begin_checkout", product, precio);
    
    const response = await axios.post(
      "https://back-end-kilig.herokuapp.com/create-checkout-session",
      {product, negocio, precio, vendedorID, productID, personalizado, variacion, accountID, cantidad, comision, codigoReferidoAfiliado, originURL}
    );
    const { status } = response.data;
    console.log("Response:", response.data);
    
    if (response.data.url) {
      window.location = response.data.url;
    } else {
      console.log('No se completó la venta');
      alert(response.data);
    }
    
  }
  
  render(){
    const {loGenera, product, accountID, variacion} = this.props;

    //Sólo se hace la compra si la cantidad está disponible
    let checkoutButon;
    if(product.cantidades[variacion] > 0){
      if(window.location.href.indexOf("catalogoenlinea.mx") > -1){
        checkoutButon = (
          <button onClick={() => this.handleToken("catalogoenlinea.mx")} className="btn btn-secondary checkout-button">
            Comprar Ahora
          </button>
        );
      }
      else{
        checkoutButon = (
          <button onClick={() => this.handleToken("kangupago.com")} className="btn btn-secondary checkout-button-kangupago">
            Comprar Ahora
          </button>
        );
      }
    }
    else{
      checkoutButon = (
        <button className="btn btn-secondary checkout-button-agotado">
          Agotado
        </button>
      );
    }
    
    if(product && accountID){
      return (
        <div className="mt-4">
          {checkoutButon}
        </div>
      );
    }else{
      return(
          null
      );
  }
  }
}


const mapDispatchToProps = (dispatch) => {
  return{
      addVenta: (token, product, vendedorID, productID, personalizado, talla) => dispatch(addVenta(token, product, vendedorID, productID, personalizado, talla))
  }
}

export default connect(null, mapDispatchToProps)(StripeCheckoutButton);



