import React, {Component} from 'react';
import firebase from 'firebase';
import {deleteProduct, displayProduct, editProduct} from '../../../store/actions/productActions';
import FileUploader from 'react-firebase-file-uploader';
import {connect} from 'react-redux';

class ProductInventorySummary extends Component{
    state = {
        nombre: this.props.product.nombre,
        descripcion: this.props.product.descripcion,
        precio: this.props.product.precio,
        variaciones: this.props.product.variaciones,
        cantidades: this.props.product.cantidades,
        personalizado: this.props.product.personalizado,
        precioMayoreo: this.props.product.precioMayoreo,
        cantMinMayoreo: this.props.product.cantMinMayoreo,
        loyaltyBonus: this.props.product.loyaltyBonus,
        categoria: this.props.product.categoria,
        peso: this.props.product.peso,

        //Imagenes de Producto
        filenames: [],
        downloadURLs: [],
        isUploading: false,
        uploadProgress: 0,
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    
    handleProgress = progress => this.setState({ progress });
    
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleDownload = async (newFileFinal) => {
        const downloadURL = await firebase
          .storage()
          .ref("images")
          .child(newFileFinal)
          .getDownloadURL();

        this.setState(oldState => ({
            filenames: [...oldState.filenames, newFileFinal],
            downloadURLs: [...oldState.downloadURLs, downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
        
    }

    handleUploadSuccess = async (filename, tipo) => {
        //New File URL
        let newFile;
        let newFileFinal;
        if(filename.substring(filename.length - 5, filename.length) === ".jpeg"){
            //JPEG
            newFile = filename.substring(0,filename.length - 5);
            newFileFinal = newFile + '_576x691.jpeg';
        }
        else{
            //PNG O JPG
            newFile = filename.substring(0,filename.length - 4);
            newFileFinal = newFile + '_576x691.jpeg';
        }

        setTimeout(() => {
            this.handleDownload(newFileFinal, tipo);
        }, 5000);
    };
    
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
        console.log(this.state.categoria);
    }

    handleChangeVariaciones = (e, index) => {
        const list = [...this.state.variaciones];
        list[index] = e.target.value;
        this.setState({
            variaciones: list
        })
    }

    handleChangeCantidad = (e) => {
        const list = [e.target.value];
                        
        this.setState({
            cantidades: list
        })
    }

    handleChangeCantidades = (e, index) => {
        const list = [...this.state.cantidades];
        list[index] = e.target.value;
        this.setState({
            cantidades: list
        })
    }

    handleAddVariacion = () => {
        const listV = [...this.state.variaciones];
        let listC = [...this.state.cantidades];
        if(listV.length === 0)
            listC = []
        listV.push("");
        listC.push("");
        this.setState({
            variaciones: listV,
            cantidades: listC
        })
    }

    handleRemoveVariacion = index => {
        const listV = [...this.state.variaciones], listC = [...this.state.cantidades];
        listV.splice(index, 1);
        listC.splice(index, 1);
        this.setState({
            variaciones: listV,
            cantidades: listC
        })
    }

    handleSubmitDelete = (e) => {
        e.preventDefault();
        this.props.deleteProduct(this.props.product);
        alert("Se ocultó el producto. Puede tomar unos segundos en que vea el cambio. Por favor recarga la página.");
    };

    handleSubmitDisplay = (e) => {
        e.preventDefault();
        this.props.displayProduct(this.props.product);
        alert("Se hizo visible el producto. Puede tomar unos segundos en que vea el cambio. Por favor recarga la página.");
    };

    handleSubmitEdit = (e) => {
        e.preventDefault();
        if(this.state.nombre != '' && this.state.descripcion != '' && this.state.precio != 0 && this.state.comision != 0){
            this.props.editProduct(this.state, this.props.product);
        }
        else if(this.state.nombre === '' || this.state.descripcion === '' || this.state.precio === 0 || this.state.comision === 0){
            alert(
                'La información es incompleta.'
            ); 
        }
    };



    render(){
        const {product} = this.props;
        let costoDeEnvio = this.state.peso === "de2a3" || this.state.peso === "de3a5" ? 110 : this.state.peso === "de5a25" ? 150 : 80;
        let precioBase = Number(this.state.precio);
        let comisionDelVendedor = Math.round((product.comision * 0.01 * precioBase));
        let comisionDeKanguru = Math.round(precioBase*0.07);
        let costoPorProcesarPorcentaje = 0.04;
        let ingresoNeto = precioBase - comisionDeKanguru;
        let precioFinal = Math.round((precioBase + costoDeEnvio + comisionDelVendedor) / (1 - costoPorProcesarPorcentaje));
        let costoPorProcesarCantidad = Math.round(precioFinal * 0.04);
        
        let weightOptions;
        if(this.state.peso == "menosDe1"){
            weightOptions = (
                <select onChange={this.handleChange} id="peso" className="form-select" aria-label="No">
                        <option disabled>Peso</option>
                        <option selected value="menosDe1">Menos de 1 Kg</option>
                        <option value="de1A2">De 1 a 2 Kg</option>
                        <option value="de2a3">De 2 a 3 Kg</option>
                        <option value="de3a5">De 3 a 5 Kg</option>
                        <option value="de5a25">De 5 a 25 Kg</option>
                </select>
            );
        }
        else if(this.state.peso == "de1A2"){
            weightOptions = (
                <select onChange={this.handleChange} id="peso" className="form-select" aria-label="No">
                        <option disabled>Peso</option>
                        <option value="menosDe1">Menos de 1 Kg</option>
                        <option selected value="de1A2">De 1 a 2 Kg</option>
                        <option value="de2a3">De 2 a 3 Kg</option>
                        <option value="de3a5">De 3 a 5 Kg</option>
                        <option value="de5a25">De 5 a 25 Kg</option>
                </select>
            );
        }
        else if(this.state.peso == "de2a3"){
            weightOptions = (
                <select onChange={this.handleChange} id="peso" className="form-select" aria-label="No">
                        <option disabled>Peso</option>
                        <option value="menosDe1">Menos de 1 Kg</option>
                        <option value="de1A2">De 1 a 2 Kg</option>
                        <option selected value="de2a3">De 2 a 3 Kg</option>
                        <option value="de3a5">De 3 a 5 Kg</option>
                        <option value="de5a25">De 5 a 25 Kg</option>
                </select>
            );
        }
        else if(this.state.peso == "de3a5"){
            weightOptions = (
                <select onChange={this.handleChange} id="peso" className="form-select" aria-label="No">
                        <option disabled>Peso</option>
                        <option value="menosDe1">Menos de 1 Kg</option>
                        <option value="de1A2">De 1 a 2 Kg</option>
                        <option value="de2a3">De 2 a 3 Kg</option>
                        <option selected value="de3a5">De 3 a 5 Kg</option>
                        <option value="de5a25">De 5 a 25 Kg</option>
                </select>
            );
        }
        else{
            weightOptions = (
                <select onChange={this.handleChange} id="peso" className="form-select" aria-label="No">
                        <option disabled>Peso</option>
                        <option value="menosDe1">Menos de 1 Kg</option>
                        <option value="de1A2">De 1 a 2 Kg</option>
                        <option value="de2a3">De 2 a 3 Kg</option>
                        <option value="de3a5">De 3 a 5 Kg</option>
                        <option selected value="de5a25">De 5 a 25 Kg</option>
                </select>
            );
        }

        let isDisplayed;
        if(product.isDisplayed === true){
            isDisplayed = (
                <h5 onClick={this.handleSubmitDelete} className="btn btn-grey ms-3"><i class="bi bi-eye"></i></h5>
            )
        }
        else{
            isDisplayed = (
                <h5 onClick={this.handleSubmitDisplay} className="btn btn-grey ms-3"><i class="bi bi-eye-slash"></i></h5>
            )
        }

        let collapsePrecioFinalYIngresoNeto = (
            <div class="collapse" id="collapsePrecioFinalYIngresoNeto">
                <div className="mt-2">
                    <p>Precio Final: {precioFinal}</p>
                    <p>Ingreso Neto: {ingresoNeto}</p>
                </div>
            </div>
        );

        let modalEditarProducto = (
            <div class="modal fade" id={'n' + product.objectID} tabindex="-1" role="dialog" aria-labelledby='modalProducto' aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header mt-3">
                        <div>
                            <h3 class="modal-title" id="productoModal">Editar Producto</h3>
                            <p>Puedes editar sólo los campos que necesites.</p>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form className="row g-3">
                            {/* Nombre del Producto */}
                            <div className="col-12">
                                <label for="nombreProducto" className="form-label addProductFormLabel">Nombre del Producto</label>
                                <input type="text" defaultValue={product.nombre} onChange={this.handleChange} className="form-control" id="nombre"/>
                            </div>

                            {/* Descripción */}
                            <div className="col-12 mt-4">
                                <label for="descripcion" className="form-label addProductFormLabel">Descripción</label>
                                <textarea maxLength="3000" defaultValue={product.descripcion} onChange={this.handleChange} className="form-control" id="descripcion" rows="10"></textarea>
                            </div>

                            {/* Divider Line */}
                            <div className="col-12 mt-4">
                                <div className="divider"></div>
                            </div>

                            {/* Precio */}
                            <div className="col-12 mt-4">
                                <label for="precio" className="form-label addProductFormLabel">Precio</label>
                                <input type="text" defaultValue={product.precio} onChange={this.handleChange} className="form-control" id="precio"/>
                            </div>

                            {/* Peso*/}
                            <div className="col-12">
                                <label for="peso" className="form-label addProductFormLabel">Peso del Producto</label>
                                {weightOptions}
                            </div>

                            {/* Ingreso Neto */}
                            <div className="col-12 mt-4">
                                {/* <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseIngresoNeto" aria-expanded="false" aria-controls="collapseIngresoNeto">
                                    Precio Final en Kanguru y tu Ingreso Neto con Datos Actuales
                                    <i class="bi bi-caret-down-fill ms-2"></i>
                                </button> */}
                                <div className="d-flex">
                                    <label for="precioFinalYIngresoNeto" data-bs-toggle="collapse" data-bs-target="#collapsePrecioFinalYIngresoNeto" className="form-label addProductFormLabel pointer">Precio Final en Kanguru y tu Ingreso Neto</label>
                                    <i class="bi bi-caret-down-fill pointer ms-2"></i>
                                </div>
                                {collapsePrecioFinalYIngresoNeto}
                            </div>
                            
                            {/* Divider Line */}
                            <div className="col-12 mt-4">
                                <div className="divider"></div>
                            </div>
                            
                            {/* Categoría */}
                            <div className="col-12 mt-4">
                                <label for="categoria" className="form-label addProductFormLabel">Categoría</label>
                                <select onChange={this.handleChange} id="categoria" className="form-select" aria-label="No">
                                    <option disabled selected>Categoría</option>
                                    <option value="accesoriosParaVehiculos">Accesorio para Vehículos</option>
                                    <option value="belleza">Belleza</option>
                                    <option value="bienestar">Bienestar</option>
                                    <option value="fitness">Fitness</option>
                                    <option value="joyería">Joyería</option>
                                    <option value="hogar">Hogar</option>
                                    <option value="cocina">Cocina</option>
                                    <option value="moda">Moda</option>
                                    <option value="calzado">Calzado</option>
                                    <option value="juegosYJuguetes">Juegos y Juguetes</option>
                                    <option value="mascotas">Mascotas</option>
                                    <option value="tecnologia">Tecnología</option>
                                    <option value="bebes">Bebés</option>
                                    <option value="optica">Lentes y Óptica</option>
                                </select>
                            </div>

                            
                            {/* Variaciones */}
                            <div className="col-12 mt-4">
                                <label for="variaciones" className="form-label addProductFormLabel">¿Este producto diferentes opciones?</label>
                                <i type="button" onClick={this.handleAddVariacion} className="ms-3 addIconVariacion bi bi-plus-lg"></i>
                                
                                {this.state.variaciones.map((valor, idx) => {
                                    return (
                                        <div className="d-flex mb-2 mt-2" key={idx}>
                                            <input type="text" placeholder={"Ej. Talla S "} value={valor} onChange={e => this.handleChangeVariaciones(e, idx)} id="variaciones" className="form-control me-2"/>
                                            <input type="number" placeholder={"Cantidad Disponible"} value={this.state.cantidades[idx]} onChange={e => this.handleChangeCantidades(e, idx)} className="form-control" id="cantidades"/>
                                            <i type="button" id="borrarVar" onClick={() => this.handleRemoveVariacion(idx)} className="ms-3 addIcon2Variacion bi bi-trash"></i>
                                        </div> 
                                    )
                                })}
                            </div>

                            {/* Cantidad */}
                            {this.state.variaciones.length === 0 &&
                                <div className="col-12 mt-4">
                                    <label for="cantidades" className="form-label addProductFormLabel">Cantidad Disponible</label>
                                    <input type="number" defaultValue={product.cantidades} onChange={this.handleChangeCantidad} className="form-control" id="cantidades"/>
                                </div>
                            }

                            {/* Personalizado */}
                            {/* <div className="col-12">
                                <label for="variaciones" className="form-label addProductFormLabel">¿Este se puede personalizar?</label>
                                <select onChange={this.handleChange} id="personalizado" className="form-select" aria-label="No">
                                    <option defaultValue>No</option>
                                    <option value="1">Sí</option>
                                </select>
                            </div>    */}

                            {/* Imagen Principal */}
                            <div className="col-12">
                                {this.state.avatarURL && <img src={this.state.avatarURL} />}
                                <h6 for="imagenes" className="form-label addProductFormLabel mt-3">Imagen Principal del Producto (.jpg o .jpeg o .png)</h6>
                                <FileUploader
                                    accept="image/*"
                                    name="image-uploader"
                                    randomizeFilename
                                    storageRef={firebase.storage().ref("images")}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    onProgress={this.handleProgress}
                                />
                            </div>

                            {/* Imagenes de Producto */}
                            <div className="col-12">
                                {this.state.avatarURL && <img src={this.state.avatarURL} />}
                                <h6 for="imagenes" className="form-label addProductFormLabel mt-3">Otras Imagenes del Producto (.jpg o .jpeg o .png)</h6>
                                <FileUploader
                                    accept="image/*"
                                    name="image-uploader-multiple"
                                    randomizeFilename
                                    storageRef={firebase.storage().ref("images")}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    onProgress={this.handleProgress}
                                    multiple
                                />
                            </div>
                            <p className="mt-4">*Carga la imagen principal primero. Para las otras imagenes, puedes seleccionar multiples imagenes al mismo tiempo.*</p>
                            {this.state.isUploading && <p className="mt-3">Se están cargando las imágenes...</p>}

                        </form>
                    </div>
                    <div class="modal-footer">
                        <h5 type="button" className="btn btn-grey" data-bs-dismiss="modal">Cancelar</h5>
                        <h5 onClick={this.handleSubmitEdit} className="btn btn-primary" data-bs-dismiss="modal">Guardar</h5>
                    </div>
                    </div>
                </div>
            </div>
        );

        return(
            <div>

                {/* Modals */}
                {modalEditarProducto}
                
                <ul className="list-group-item">
                    <li class="d-md-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex">
                                <div>
                                    <img src={product.imagenesDeProducto[0]} alt="" className="imgThumbnailList"/>
                                </div>
                                
                                <div>
                                    <div class="fw-bold d-flex mb-3">
                                        {product.nombre}
                                    </div>
                                    <div className="d-flex">
                                        <div className="">
                                            Tu Precio: {product.precio}
                                        </div>

                                        <div className="ms-3">
                                            Inventario: {product.cantidades[0]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-4 mt-md-0">
                            <h5 data-bs-toggle="modal" data-bs-target={'#n'+product.objectID} className="btn btn-primary">Editar</h5>
                            {isDisplayed}
                        </div>
                    </li>  
                </ul>
            </div> 
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return{
        deleteProduct: (product) => dispatch(deleteProduct(product)),
        displayProduct: (product) => dispatch(displayProduct(product)),
        editProduct: (state, product) => dispatch(editProduct(state, product))
    }
}

export default connect(null, mapDispatchToProps)(ProductInventorySummary);