import React, { Component } from 'react';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import ProductList from '../../products/ProductList';
import CatalogoCliente from './CatalogoCliente';
import copy from 'copy-to-clipboard';
import '../../../App.css';
import {Helmet} from "react-helmet";
import algoliasearch from 'algoliasearch';
import ReactPixel from 'react-facebook-pixel';
import toast, { Toaster } from 'react-hot-toast';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";
const SEARCH_API_KEY_CATALOGOS = "465a5e3eb841eff90af7f9c36278dd44";
const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "37bbdced854246edc06c7fb3c072275c";
const SEARCH_API_KEY_DISENOENCATALOGO = "7fdb8d87725c540857fc680054155691";


//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";
// const SEARCH_API_KEY_CATALOGOS = "fc1440dbc9593332f235bbc14852b8a3";
// const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "a4785ad7a7f68b11da33cfcd839116ac";
// const SEARCH_API_KEY_DISENOENCATALOGO = "ceb37b088c17d1ae94ffc81222bc2736";

const ALGOLIA_INDEX_PRODUCTOSENCATALOGO = 'Kanguru_ProductosEnCatalogo';
const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_DISENOENCATALOGO = 'Kanguru_DisenoEnCatalogo';
const ALGOLIA_INDEX_USUARIOS = 'Kanguru_Usuarios';

const client_productosEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOSENCATALOGO);
const indexProductosEnCatalogo = client_productosEnCatalogo.initIndex(ALGOLIA_INDEX_PRODUCTOSENCATALOGO);
const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_disenoEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_DISENOENCATALOGO);
const indexDisenoEnCatalogo = client_disenoEnCatalogo.initIndex(ALGOLIA_INDEX_DISENOENCATALOGO);
const client_usuarios = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_USUARIOS);
const indexUsuarios = client_usuarios.initIndex(ALGOLIA_INDEX_USUARIOS);

class Catalogo extends Component {
    
    state = {
        productsCatalogo: [],
        oDisenoEnCatalogo: "",
        show: true,
        vendedor: ""
    }

    notify = (type) => {
        if(type == "copioEnlaceCatalogo"){
            toast.success('¡Un paso más cerca a vender! Se copió el enlace de tu catálogo.');
        }
    }

    copyLink = (catalogoID, vendedorID, sDisenoEnCatalogoID) => {
        let link = 'http://catalogoenlinea.mx/' + "catalogo" + '/' + vendedorID + '/' + catalogoID+ '/' + sDisenoEnCatalogoID + '/' + "checkout";
        copy(link);
        this.notify("copioEnlaceCatalogo");
    }

    showLoading = () => {
        this.setState( () => ({
            show: false
        }));
    }

    async componentDidMount(){
        const {catalogoID, sDisenoEnCatalogoID, vendedorID} = this.props;
        
        //Goes to the top of the page when the page first loads
        window.scrollTo(0, 0);

        if(catalogoID && sDisenoEnCatalogoID && vendedorID){
            const vendedor = await indexUsuarios.getObject(vendedorID);
            // Get ProductosEnCatalogo del Catalogo con el respectivo CatalogoID 
            const products = await indexProductosEnCatalogo.search(catalogoID, {filters: 'bActivo:true', hitsPerPage: 100});
            const productsEditedPrice = products.hits.map(async (product) => {
                //Get Product
                let editedProduct = await indexProductos.getObject(product.sProductoID);
    
                //Edit Price, adds nIncremento to the originals product price to get edited price
                editedProduct.nIncremento = product.nIncremento;
                editedProduct.sProductoEnCatalogoID = product.objectID;
               
                return editedProduct;
            })


            //Gets array of objects from promise
            const productsCatalogo = await Promise.all(productsEditedPrice).then((products) => {
                return products;
            });

            //Gets products with isDisplayed equal to true
            const availableProductsCatalogo = productsCatalogo.filter((product) => {
                if(product.isDisplayed){
                    return product;
                }
            })

            //Get Diseno de Catalogo
            const disenoEnCatalogo = await indexDisenoEnCatalogo.getObject(sDisenoEnCatalogoID);
            
            if(disenoEnCatalogo && productsCatalogo){
                this.setState({
                    productsCatalogo: availableProductsCatalogo,
                    oDisenoEnCatalogo: disenoEnCatalogo,
                    vendedor: vendedor
                });
            }
        }

        this.showLoading();
    }

    render(){
        const {catalogoID, vendedorID, sDisenoEnCatalogoID} = this.props;
        const productsCatalogo = this.state.productsCatalogo;
        const oDisenoEnCatalogo = this.state.oDisenoEnCatalogo;
        const vendedor = this.state.vendedor;
        
        let load;
    
        if(this.state.show){
            load = (
                <div className="container spinner">
                    <div class="spinner-border spinner-color" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
        }
        else{
            load = (
                <div className="noProducts">
                    <h1>No hay productos.</h1>
                </div>
            );
        }
        
        if(productsCatalogo && oDisenoEnCatalogo && window.location.href.indexOf("checkout") == -1){
            return(
                <div className="container-fluid custom-container catalogoMobile footer-spacing">
                    <Toaster 
                        toastOptions={{
                            duration: 5000,
                            position: 'top-center',
                        }}
                    />

                    <div className="mt-4">
                        <div className="d-flex">
                            <h2 className="catalogoTitle me-3">Mi Catálogo</h2>
                            <p className="btn btn-compartir2" onClick={() => this.copyLink(catalogoID, vendedorID, oDisenoEnCatalogo.objectID )}>
                                Compartir
                            </p>
                        </div>
                    </div>
                    <div className="">
                        <ProductList numero={0} products={productsCatalogo} vendedorID={vendedorID} filter='' catalogo={true} oDisenoEnCatalogo={oDisenoEnCatalogo}/>
                    </div>
                </div>
            );
        }
        else if(productsCatalogo && oDisenoEnCatalogo && window.location.href.indexOf("checkout") > -1){
            return (
                <CatalogoCliente productsCatalogo={productsCatalogo} vendedorID={vendedorID} vendedor={vendedor} oDisenoEnCatalogo={oDisenoEnCatalogo}/>
            );
        }
        else{
            return(
                <div className="footer-spacing">
                    {load}
                </div>
            ); 
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const catalogoID = ownProps.match.params.catalogoID;
    const vendedorID = ownProps.match.params.vendedorID;
    const sDisenoEnCatalogoID = ownProps.match.params.sDisenoEnCatalogoID;

    return{
        catalogoID: catalogoID,
        vendedorID: vendedorID,
        sDisenoEnCatalogoID: sDisenoEnCatalogoID
     }
}

export default connect(mapStateToProps, null) (Catalogo);

