import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import './DashboardNegocio.css';
import '../../../App.css';
import ProductList from '../../products/ProductList';
import AddProduct from '../../products/addProducts/AddProduct';
import Inventory from '../../products/inventory/Inventory';
import VentasList from '../../ventas/VentasList';
import Promociones from '../../promociones/Promociones';
import NegocioStoreDashboard from '../NegocioStoreDashboard';
import NegocioLogisticsDashboard from '../NegocioLogisticsDashboard';
import StripeConnect from '../../stripe/StripeConnect';
import {signOut} from '../../../store/actions/authActions';
import algoliasearch from 'algoliasearch';
import toast, { Toaster } from 'react-hot-toast';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_PROMOCIONES = "5895f21d82547ab167e3faed6868a6e4";

//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";

const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_VENTAS = 'Kanguru_Ventas';
const ALGOLIA_INDEX_PROMOCIONES = 'Kanguru_Promociones';

const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_ventas = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_VENTAS);
const indexVentas = client_ventas.initIndex(ALGOLIA_INDEX_VENTAS);
const client_promociones = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PROMOCIONES);
const indexPromociones = client_promociones.initIndex(ALGOLIA_INDEX_PROMOCIONES);

const DashboardNegocioContent = (props) => {
   
    const { auth, profile, state } = props;
    const [dashboardContent, setDashboardContent] = useState("productos");
    const [products, setProducts] = useState(state.products);
    const [ventas, setVentas] = useState(state.ventas);
    const [promociones, setPromociones] = useState(state.promociones);
    const [pageAlgolia, setPageAlgolia] = useState(0);

    console.log(promociones);
    
    const handleChange = (e) => {
        e.preventDefault();
        setDashboardContent(e.target.value);
    }

    const changeContentDesktop = (content) => {
        setDashboardContent(content);
        setVentas(state.ventas);
        setProducts(state.products);
        setPromociones(state.promociones);
        setPageAlgolia(0);
    }

    const notify = (type) => {
        if(type == "noProducts"){
            toast.error("¡Uy! Ya no hay más productos.");
        }
        else if( type == "noVentas"){
            toast.error("¡Uy! Ya no hay más ventas.");
        }
    }

    const loadMore = async (e) => {
        e.preventDefault();
        
        const pageAlgoliaNew = pageAlgolia + 1;
        
        if(dashboardContent == "productos" || dashboardContent == "inventario"){
            const moreProducts = await indexProductos.search(auth.uid, {page: pageAlgoliaNew, hitsPerPage: 48});
            const productsNew = products.concat(moreProducts.hits);

            if(moreProducts.hits.length === 0){
                notify("noProducts");
            }
            else{
                setPageAlgolia(pageAlgoliaNew);
                setProducts(productsNew);
            }
        }
        else if(dashboardContent == "ventas"){
            const moreVentas = await indexVentas.search(auth.uid, {page: pageAlgoliaNew, hitsPerPage: 48});
            const ventasNew = ventas.concat(moreVentas.hits);

            if(moreVentas.hits.length === 0){
                notify("noVentas");
            }
            else{
                setPageAlgolia(pageAlgoliaNew);
                setVentas(ventasNew);
            }
        } 
    }

    //CONTENT
    let content;
    if(!profile.Connect_Account_ID || state.status === false){
        content = (
            <div className="stripeConnect">
                <i class="bi bi-bank bankIcon"></i>
                <h4 className="mt-3 stripeConnectTitle">Configura tu Cuenta para</h4>
                <h4 className="stripeConnectTitle">Recibir Pagos</h4>
                <StripeConnect/>
                <h6 className="mt-4">Serás redirigido a Stripe para completar el processo.</h6>
            </div> 
        );
    }
    else if(dashboardContent == "productos"){
        content = (
            <div>
                <ProductList numero={0} products={products} filter='' productLinks={0}/>
                <div className="ver-mas-parent-home">
                    <p onClick={(e) => loadMore(e)} className="btn btn-ver-mas">Ver Más</p>
                </div>
            </div>
            
        );
    }
    else if(dashboardContent == "ventas"){
        content = (
            <div>
                <VentasList ventas={ventas} />
                <div className="ver-mas-parent-home">
                    <p onClick={(e) => loadMore(e)} className="btn btn-ver-mas">Ver Más</p>
                </div>
            </div>
            
        );
    }
    else if(dashboardContent == "inventario"){
        content = (
            <div>
                <Inventory products={products} uid={auth.uid}/>
                <div className="ver-mas-parent-home">
                    <p onClick={(e) => loadMore(e)} className="btn btn-ver-mas">Ver Más</p>
                </div>
            </div>
        );
    }
    else if(dashboardContent == "tienda"){
        content = (
            <NegocioStoreDashboard/>
        );
    }
    else if(dashboardContent == "promociones"){
        content = (
            <div>
                <Promociones promociones={promociones} uid={auth.uid}/>
                {/* <div className="ver-mas-parent-home">
                    <p onClick={(e) => loadMore(e)} className="btn btn-ver-mas">Ver Más</p>
                </div> */}
            </div>
        );
    }
    else if(dashboardContent == "logistica"){
        content = (
            <div>
                <NegocioLogisticsDashboard negocio={profile} uid={auth.uid}/>
            </div>
        );
      
    }
    else if(dashboardContent == "add product"){
        content = (
            <AddProduct pendingProducts={state.pendingProducts}/>
        );
    }
    else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    //MENU
    let menu;
    let title;
    if(window.innerWidth < 600){
        menu = (
            <select onChange={(e) => handleChange(e)} className="form-select mb-3 dashboardSelect" aria-label="No">
                <option selected value="productos">Mis Productos</option>
                <option value="inventario">Inventario</option>
                <option value="ventas">Ventas</option>
                <option value="tienda">Tienda</option>
                {/* <option value="promociones">Promociones</option> */}
                <option value="logistica">Logistica</option>
                {profile.direccionDeRecoleccion ? <option value="add product">Agregar Producto</option> : null}
            </select>
        )
        
        title = (
            <div className="d-flex mt-4">
                <p className="dashboardTitle">Dashboard</p>
                <Link to="/proveedores" onClick={() => props.signOut()} className="logout nav-black-text"><i  className="bi bi-arrow-bar-right"></i></Link>
            </div>
            
        )
    }
    else{
        menu = (
            <div className="col-md-2 side-menu mt-4">
                {/* Productos */}
                <p onClick={() => changeContentDesktop("productos")} className="side-menu-text" value="productos" id="productos">Productos</p>

                {/* Inventario */}
                <p onClick={() => changeContentDesktop("inventario")} className="side-menu-text" value="inventario" id="inventario">Inventario</p>

                {/* Ventas */}
                <p onClick={() => changeContentDesktop("ventas")} className="side-menu-text" value="ventas" id="ventas">Ventas</p>
                
                {/* Tienda */}
                <p onClick={() => changeContentDesktop("tienda")} className="side-menu-text" value="tienda" id="tienda">Tienda</p>

                {/* Promociones */}
                {/* <p onClick={() => changeContentDesktop("promociones")} className="side-menu-text" value="promociones" id="tienda">Promociones</p> */}

                {/* Logísitca */}
                <p onClick={() => changeContentDesktop("logistica")} className="side-menu-text" value="logistica" id="logistica">Logística</p>
                
                {/* Agrega Producto */}
                <div className="mt-3">
                    {profile.direccionDeRecoleccion ? <h5 onClick={() => setDashboardContent("add product")} className="btn btn-primary" value="add product" id="add product">+ Producto</h5> : null}
                </div>
            </div>
        )

        title = (
            null
        )
    }

    return(
        <div className="container footer-spacing">

            {/* Notifications Toast*/}
            <Toaster 
                toastOptions={{
                    duration: 5000,
                    position: 'top-center',
                }}
            />

            {title}
            <div className="row">
                {/* Menu */}
                {menu}

                {/*DashboardContent*/}
                <div className="col-md-10 mt-md-4 ms-2 bottom-mobile-dashboard-negocio">
                    {content}
                </div>
            </div>
        </div>
   );
    
}

const mapDispatchToProps = (dispatch) => {
    return {
      signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(DashboardNegocioContent);