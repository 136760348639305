import React, { useState, useEffect } from 'react';
import './ChatSendForm.css';
import toast, { Toaster } from 'react-hot-toast';

const ChatSendForm = (props) => {
    const [mensaje, setMensaje] = useState("");

    const {sendMessage, handleWarning} = props;
    
    const notify = (type) => {
        if(type == "EmailOrPhoneNumber"){
            toast.error('¡Cuidado! Manten toda comunicación dentro de Kanguru. Sí vuelves a intentar mandar un email o teléfono tu cuenta será bloquiada.');
        }
    }

    const clearForm = () => {
        var form = document.getElementById("sendMessage");
        form.reset();
    }

    const containsEmail = (mensaje) => {
        let regex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        if(regex.test(mensaje)){
            return true;
        }

        return false;
    }

    const containsPhoneNumber = (mensaje) => {
        // let regex = /((^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$))/;
        let regex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
        
        if(regex.test(mensaje)){
            return true;
        }

        return false;
    }

    const handleSubmit = (e, mensaje) => {
        e.preventDefault();
        if(containsEmail(mensaje) || containsPhoneNumber(mensaje)){
            //Notify, mencionar que si lo vuelves hacer tu cuenta será bloquiada
            notify("EmailOrPhoneNumber");

            //Mandar Email a Kanguru que este usuario está intentando brincarnos
            handleWarning(e);
        }
        else{
            sendMessage(e, mensaje);
            clearForm();
        }
    }
    
    return(
        <div className="chat-send-form-container">
            
            <Toaster 
                toastOptions={{
                    duration: 4000,
                    position: 'top-center',
                }}
            />

            <form id="sendMessage" onSubmit={(e) => handleSubmit(e, mensaje)} autoComplete="off">
                <input onInput={e => setMensaje(e.target.value)} className="input-send-message ps-4" aria-label="sendMessage"/>
                <button type="submit" className="btn send-btn ms-3">Enviar</button>
            </form>    
        </div> 
    )
    
}

export default ChatSendForm;