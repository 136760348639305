import React from 'react';

const RetoSummary = (props) => {

    const {reto} = props;

    const fechaInicio = new Date(reto.inicio).toString().substring(3,new Date().toString().indexOf("2022")+4);
    const fechaFin = new Date(reto.fin).toString().substring(3,new Date().toString().indexOf("2022")+4);
    const periodo = "Periodo del reto: " + fechaInicio + " al " + fechaFin;

    return(
        <div>
            <a className="list-group-item list-group-item-action pointer">
                <li class="d-flex justify-content-between align-items-left">
                    <div>
                        <div class="fw-bold">
                            {reto.descripcion}
                        </div>
                        <div className="bottomInfoComision">
                            {periodo}
                        </div>
                    </div>
                    <h5 className="comisionVenta">${reto.cantidad}</h5>
                </li>  
            </a>
        </div>
    )
    
}

export default RetoSummary;