export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
      //make async call to database
      const firebase = getFirebase();
      firebase.auth().signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      ).then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' })
      }).catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err})
      });
    };
  };

export const forgotPass = (email) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();

    firebase.auth().sendPasswordResetEmail(email, {url: 'https://kanguru.mx/signin'
    }).then(() => {
      dispatch({ type: 'EMAIL_SENT'});
      alert("Correo de recuperación enviado.\nRevisa tu correo para reestablecer tu contraseña.")
    }).catch(err => {
      dispatch({ type: 'EMAIL_ERROR', err })
    });
  }
}

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS'});
    });
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    
    //Crea el usuario en firebase auth y después agrega usuario nuevo a la colección de Usuarios en firestore
    firebase.auth().createUserWithEmailAndPassword(
      newUser.email,
      newUser.password
    ).then((resp) => {
      return firestore.collection('Usuarios').doc(resp.user.uid).set({
        Nombre_de_Negocio: newUser.nombre_de_negocio,
        Nombre_de_Vendedor: newUser.nombre_de_vendedor,
        Telefono: newUser.telefono,
        Imagenes_de_Store:[],
        Logo:[],
        Banco:'',
        Cuenta_de_Banco:'',
        codigoReferidoExterno: newUser.codigoReferidoExterno,
        codigoReferidoPersonal: newUser.codigoReferidoPersonal,
        bCompletoOnboarding: false,
        CreatedAt: new Date()
      })
    }).then(() => {
      dispatch({ type: 'SIGNUP_SUCCESS' })
    }).catch(err => {
      dispatch({ type: 'SIGNUP_ERROR', err })
    })
  }
}

export const solicitudSignUpProveedor = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    
    //Crea el usuario en firebase auth y después agrega usuario nuevo a la colección de Usuarios en firestore
    return firestore.collection('SolicitudesDeProveedores').add({
      sNombre: newUser.sNombre,
      sPaginaDeFacebook: newUser.sPaginaDeFacebook,
      sTelefono: newUser.sTelefono,
      sEmail: newUser.sEmail,
      tCreatedAt: new Date()
    }).then(() => {
      dispatch({ type: 'SOLICITUD_PROVEEDOR_SUCCESS' })
    }).catch(err => {
      dispatch({ type: 'SOLICITUD_PROVEEDOR_ERROR', err })
    })
  }
}

export const addConnectAccount = (accountID, userID) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firestore.collection('Usuarios').doc(userID).set({
        Connect_Account_ID:accountID
      },{ merge: true })
      .then(() => {
      dispatch({ type: 'ADD_CONNECT_ACCOUNT_SUCCESS' })
      })
      .catch(err => {
      dispatch({ type: 'ADD_CONNECT_ACCOUNT_ERROR', err })
      })
  }
};

export const completeOnboarding = (sUserID) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    
    return firestore.collection('Usuarios').doc(sUserID).update({
        bCompletoOnboarding: true
    }).then(() => {
      dispatch({ type: 'COMPLETE_ONBOARDING_SUCCESS' });
      window.location.reload();
    })
    .catch(err => {
      dispatch({ type: 'COMPLETE_ONBOARDING_ERROR', err })
    })
  }
};
 

