import React, { useState, useEffect, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';
import '../../App.css';
import {signOut} from '../../store/actions/authActions';
import {connect} from 'react-redux';
import Searchbar from './Searchbar';

const SignedInLinks = (props) => {
    const {auth, profile} = props;
    
    let logo;
    let history = useHistory ();
    
    const handleClick = () => {
        //Para refrescar la historia y que aparesca home y no la última vista categoría al momento de picarle al kanguru
        history.push('/', { from: '' } );
    }

    if(auth.isLoaded){
        logo = (
            <img src="/img/Kanguru_logo.png" alt="" className="logo"/>
        );
    }


    if(logo){
        return(
            <div>
                <div>
                    <nav class="navbar navbar-expand-md navbar-light fixed">
                        <div class="container-fluid custom-container">
                            {/* Toggle Botton for mobile nav*/}
                            <button class="navbar-toggler mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            
                            {/* Logo and Title of Page */}
                            <Link to="/" className="me-md-5" onClick={handleClick}>
                                {logo}
                            </Link>
                            
                            {/* Search Bar*/}
                            <Searchbar profile={profile}/>
        
                            {/* Main Nav */}
                            <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
                                <ul className="navbar-nav nav-black-text">
                                    <li className="nav-item">
                                        <Link to="/cobertura" className="nav-link nav-black-text center-text">
                                            <i class="bi bi-geo-alt"></i>
                                            <br></br>
                                            Cobertura
                                        </Link>
                                    </li>
                                    <li className="nav-item ms-md-2">
                                        <Link to="/chats" className="nav-link nav-black-text center-text">
                                            <i class="bi bi-chat-square-text"></i>
                                            <br></br>
                                            Chats
                                        </Link>
                                    </li>
                                    <li className="nav-item ms-md-2">
                                        <Link to="/catalogos" className="nav-link nav-black-text center-text">
                                            <i class="bi bi-shop"></i>
                                            <br></br>
                                            Catalogos
                                        </Link>
                                    </li>
                                    <li className="nav-item ms-md-2">
                                        <Link to={'/cuenta-vendedor/' + auth.uid} className="nav-link nav-black-text center-text">
                                            <i class="bi bi-person-circle"></i>
                                            <br></br>
                                            Cuenta
                                        </Link>
                                    </li>
                                    <li className="nav-item ms-md-2">
                                        <Link to="/" onClick={props.signOut} className="nav-link nav-black-text center-text">
                                            <i  className="bi bi-arrow-bar-right"></i>
                                            <br></br>
                                            Salir
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        ); 
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);