import React, { useState } from 'react';
import './Categorias.css';
import CategorySummary from './CategorySummary';
import {Helmet} from "react-helmet";

const Categorias = (props) => {
   
    let categorias = [
        {nombre: "Tiendas", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_tiendas_576x691.jpeg?alt=media&token=5517a2bf-514e-4ef8-a090-68d1a6ccf33f", categoria:"buscarPorTienda"},
        {nombre:"Hogar", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_home_576x691.jpeg?alt=media&token=07d629be-76e6-4fe9-883d-471934cd3458", categoria:"hogar"}, 
        {nombre:"Cocina", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_cocina_576x691.jpeg?alt=media&token=8715aaaa-0321-40e9-98ec-0c0a0d8926d9", categoria:"cocina"},
        {nombre:"Tecnología", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_tecnologia_576x691.jpeg?alt=media&token=0114db24-8a77-482d-ab86-9daa17325707", categoria:"tecnologia"},
        {nombre:"Moda", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_moda_576x691.jpeg?alt=media&token=4b5b7efa-c774-47a7-a04e-f9462052974b", categoria:"moda"},
        {nombre:"Joyería", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_joyeri%CC%81a_576x691.jpeg?alt=media&token=61a8ce4c-1246-45ac-bb15-fc3725eda4af", categoria:"joyería"},
        {nombre:"Bebes", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_bebes_576x691.jpeg?alt=media&token=34365bd6-d88b-446f-9019-2a53911adfee", categoria:"bebes"},
        {nombre:"Mascotas", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_mascotas_576x691.jpeg?alt=media&token=439b9a78-4520-4154-8787-021c33c596d3", categoria:"mascotas"},
        {nombre:"Calzado", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_calzado_576x691.jpeg?alt=media&token=0a46d0b2-67d7-45d3-b4f6-306347b69d09", categoria:"calzado"}, 
        {nombre:"Sex Toys", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_sex_toys_576x691.jpeg?alt=media&token=f114abaf-67aa-441a-b983-a020dd7da988", categoria:"sexToys"},
        {nombre:"Para Autos", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_autos_576x691.jpeg?alt=media&token=315b04dc-cc11-4f12-b8dd-0b16ac77fd4e", categoria:"accesoriosParaVehiculos"},
        {nombre:"Juegos", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_gaming_576x691.jpeg?alt=media&token=ec2416a0-a6e2-4568-baeb-62a4d4e33f26", categoria:"juegosYJuguetes"},
        {nombre:"Belleza", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_belleza_576x691.jpeg?alt=media&token=77dd65a0-02f9-4861-bb9d-e820acc8bac1", categoria:"belleza"},
        {nombre:"Bienestar", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_bienestar_576x691.jpeg?alt=media&token=48a621b2-d9bb-440a-b1f0-aa89ff5ed469", categoria:"bienestar"},
        {nombre:"Fitness", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_fitness_576x691.jpeg?alt=media&token=beedfd2c-1340-41d5-95d9-bc53e292cfae", categoria:"fitness"},
        {nombre:"Lentes y Óptica", img:"https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/imagenesDeCategorias%2Fcategoria_optica_576x691.jpeg?alt=media&token=8fb5a054-5ebe-44d8-9c5f-78290741c045", categoria:"optica"},
    ]

    return(
        <div className="container-fluid categorias">
            {/* Dynamic Description*/}
            <Helmet>
                <title>Categorías</title>
                <meta name="title" content="Categorias" />
            </Helmet>

            <h3 className="mt-5">Todos los productos</h3>
            {/* Lista de Categorias */}
            <div className="mt-3 row row-cols-2 gx-4 overflow-hidden card-category notranslate" lang="es" translate="no">
                {categorias && categorias.map((categoria, idx) => {
                    return (
                        <div key={idx}> 
                            <CategorySummary categoria={categoria}/>
                        </div>  
                    )
                })}
            </div>
        </div>
   );
    
}

export default Categorias