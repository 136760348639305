import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import algoliasearch from 'algoliasearch';
import TemplatesList from './TemplatesList';

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";
const SEARCH_API_KEY_CATALOGOS = "465a5e3eb841eff90af7f9c36278dd44";
const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "37bbdced854246edc06c7fb3c072275c";
const SEARCH_API_KEY_DISENOENCATALOGO = "7fdb8d87725c540857fc680054155691";
const SEARCH_API_KEY_TEMPLATESDECATALOGOS = "09f69540e1018aa38960f4584f5b9bfc";


//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";
// const SEARCH_API_KEY_CATALOGOS = "fc1440dbc9593332f235bbc14852b8a3";
// const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "a4785ad7a7f68b11da33cfcd839116ac";
// const SEARCH_API_KEY_DISENOENCATALOGO = "ceb37b088c17d1ae94ffc81222bc2736";
// const SEARCH_API_KEY_TEMPLATESDECATALOGOS = "d29b1a636899cbc1097dff191e5ad8c0";

const ALGOLIA_INDEX_CATALOGOS = 'Kanguru_Catalogos';
const ALGOLIA_INDEX_PRODUCTOSENCATALOGO = 'Kanguru_ProductosEnCatalogo';
const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_DISENOENCATALOGO = 'Kanguru_DisenoEnCatalogo';
const ALGOLIA_INDEX_TEMPLATESDECATALOGOS = 'Kanguru_TemplatesDeCatalogos';


const client_productosEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOSENCATALOGO);
const indexProductosEnCatalogo = client_productosEnCatalogo.initIndex(ALGOLIA_INDEX_PRODUCTOSENCATALOGO);
const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_catalogos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_CATALOGOS);
const indexCatalogos = client_catalogos.initIndex(ALGOLIA_INDEX_CATALOGOS);
const client_disenoEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_DISENOENCATALOGO);
const indexDisenoEnCatalogo = client_disenoEnCatalogo.initIndex(ALGOLIA_INDEX_DISENOENCATALOGO);
const client_templatesDeCatalogos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_TEMPLATESDECATALOGOS);
const indexTemplatesDeCatalogos = client_templatesDeCatalogos.initIndex(ALGOLIA_INDEX_TEMPLATESDECATALOGOS);

const Templates = (props) => {
    //State Variables
    const [templates, setTemplates] = useState("");
    const [show, setShow] = useState(true);
    const {sCatalogoID, sDisenoEnCatalogoID, sIsNew, auth} = props;

    const fetchData = async () => {
        //Gets Available Templates
        const templates = await indexTemplatesDeCatalogos.search("", {filters: 'bActivo: true', hitsPerPage: 50});
        setTemplates(templates.hits);
    }

    useEffect(() => {
        //Goes to the top of the page when the page first loads
        window.scrollTo(0, 0);
        
        fetchData();

    }, []);

    if(templates){
        return (
            <div className="container-fluid custom-container footer-spacing">
                <div className="mt-4 d-flex">
                    <p className="catalogoTitle me-3">Escoge una Plantilla</p>
                </div>
                <div className="templatesList">
                    <TemplatesList templates={templates} sCatalogoID={sCatalogoID} sDisenoEnCatalogoID={sDisenoEnCatalogoID} sIsNew={sIsNew}/>
                </div>
            </div>
        );
    }
    else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        ); 
    }
}

const mapStateToProps = (state, ownProps) => {
    const sCatalogoID = ownProps.match.params.sCatalogoID;
    const sDisenoEnCatalogoID = ownProps.match.params.sDisenoEnCatalogoID;
    const sIsNew = ownProps.match.params.sIsNew;

    return{
        sCatalogoID: sCatalogoID,
        sDisenoEnCatalogoID: sDisenoEnCatalogoID,
        sIsNew: sIsNew
     }
}

export default connect(mapStateToProps, null) (Templates);

