import React, { Component } from 'react'
import {connect} from 'react-redux';
import {solicitudSignUpProveedor} from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

class solicitudSignUpNegocio extends Component {
  state = {
    sNombre: '',
    sTelefono: '+52',
    sEmail: '',
    sPaginaDeFacebook: ''
  }

  

  notify = (type) => {
    if(type == "nombreDeNegocioInvalido"){
      toast.error('El nombre de tu marca es inválido.');
    }
    else if(type == "telefonoInvalido"){
        toast.error('Necesitas agregar un número de teléfono válido. Asegúrate de incluir +52');
    }
    else if(type == "emailInvalido"){
      toast.error('Tu correo es inválido.');
    }
    else if(type == "completoFormulario"){
        toast.success('¡Excelente! Se envío el formulario con éxito. Nos comunicaremos contigo pronto.');
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.state.sNombre == ""){
      this.notify("nombreDeNegocioInvalido");
    }
    else if(!this.state.sTelefono.match(/^\+52(\s*[0-9]\s*){10}\s*$/)){
      this.notify("telefonoInvalido");
    }
    else if(this.state.sEmail == "" || !this.state.sEmail.indexOf("@")){
      this.notify("emailInvalido");
    }
    else{
      this.props.solicitudSignUpProveedor(this.state);
      ReactPixel.track('solicitudRegistroProveedor', {
        content_name: 'solicitudSignUpNegocio'
      })
      this.notify("completoFormulario");
    }
  }

  componentDidMount(){  
    window.scrollTo(0, 0);
  }

  render() {
    const { authError, auth } = this.props;
    if (auth.uid) return <Redirect to ='/'/>
      return (
        <div>
          {/* DYNAMIC META INFORMATION */}
          <Helmet>
              <title>Solicitud para Registrarte como Proveedor</title>
              <meta name="description" content="¡Es Gratis! Regístrate como proveedor y publica tus productos sin costo en Kanguru."/>
          </Helmet>

          {/* Notifications Toast*/}
          <Toaster 
              toastOptions={{
                  duration: 5000,
                  position: 'top-center',
              }}
          />

          {/* <h1 className="sign-up-title">KANGURU</h1> */}
          <div className="container position-relative start-50 translate-middle signUpForm row">
            {/* Titulo */}
            <h3 className = "text-black signUpTitle">Sé Proveedor de Kanguru</h3>
            <p>Llena esta información completa, recuerda que tu perfil será evaluado.</p>
            <form className="mt-4 col-11">
              {/* Nombre de Negocio */}
              <div className="mb-4">
                <label for="sNombre" class="form-label">Nombre de tu Marca</label>
                <input onChange={this.handleChange} type="text" class="form-control" id="sNombre"/>
              </div>

              {/* Página de Facebook */}
              <div className="mb-4">
                <label for="sPaginaDeFacebook" class="form-label">Página de Facebook (opcional)</label>
                <input onChange={this.handleChange} type="text" class="form-control" id="sPaginaDeFacebook"/>
              </div>
              
              {/* Email */}
              <div className="mb-4">
                <label for="sEmail" class="form-label">Correo Electrónico</label>
                <input onChange={this.handleChange} type="email" class="form-control" id="sEmail"/>
              </div>

              {/* Teléfono */}
              <div className="mb-4">
                <label for="sTelefono" class="form-label">Teléfono (+52 seguido por 10 números)</label>
                <input onChange={this.handleChange} value={this.state.sTelefono} type="text" class="form-control" id="sTelefono"/>
              </div>
                

              {/* Submit Button */}
              <div className="">
                <button onClick={this.handleSubmit} type="button" class="btn btn-primary buttonSignUp">Enviar</button>
              </div>
            </form>
          </div>
        </div>
        
      )
  } 
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    solicitudSignUpProveedor: (newUser) => dispatch(solicitudSignUpProveedor(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(solicitudSignUpNegocio);