import React, { useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import ProductInventoryList from './ProductInventoryList';
import toast, { Toaster } from 'react-hot-toast';
import gtag, { install } from 'ga-gtag';
import './Inventory.css';
import { CSVLink, CSVDownload } from "react-csv";
import CSVReader from 'react-csv-reader';
import algoliasearch from 'algoliasearch';
import {editProductCSV} from '../../../store/actions/productActions';

install('G-GQ8M7NNRJ7');

const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);


const Inventory = (props) => {
    const {products, uid} = props;
    const [inventory, setInventory] = useState([]);
    const [dataFromCSV, setDataFromCSV] = useState([]);
    const [dataFromCSVHasError, setDataFromCSVHasError] = useState(false);
    const [errorImportingDataFromCSV, setErrorImportingDataFromCSV] = useState({});
    const [loader, setLoader] = useState(false);
    const excelRef = useRef();
    
    const notify = (type) => {
        if(type == "downloadInventory"){
            toast.success("Excelente, se descargó tu inventario con éxito a un archivo .csv");
        }
        else if(type == "editedInventory"){
            toast.success("Se editó tu inventario con éxito, puede tomar un poco en reflejarse.");
        }
    }
    
    const headers = [
        { label: "ID", key: "objectID" },
        { label: 'Nombre', key: "nombre" },
        { label: 'Descripcion', key: 'descripcion' },
        { label: 'Precio', key: 'precio' },
        { label: 'Categoria', key: 'categoria' },
        { label: 'Peso', key: 'peso' },
        { label: 'Inventario', key: 'inventario' },
        { label: 'Variaciones', key: 'variaciones' },
        { label: 'Imagen Principal', key: 'imagenPrincipal' },
        { label: 'Otras Imagenes', key: 'otrasImagenes' }
    ]

    const csvLink = {
        filename: "inventario_kanguru.csv",
        headers: headers,
        data: inventory
    }

    const getDataToDownload = (products) => {
        //Iteramos los productos
        for(let i = 0; i < products.length; i++){
            
            //Formateamos el nombre y descripción para evitar que haya double quotations
            let descripcion = products[i].descripcion.replace(/['"]+/g, '');
            let nombre = products[i].nombre.replace(/['"]+/g, '');
            
            //Formateamos el peso
            let peso = products[i].peso === "de1a2" ? 2: products[i].peso === "de2a3" ? 3: products[i].peso === "de3a5" ? 5 : products[i].peso === "de5a25" ? 10 : 1;
            
            //Formateamos el inventario
            let inventario = "";
            for(let j = 0; j < products[i].cantidades.length; j++){
                if(j == 0){
                    inventario = inventario + products[i].cantidades[j];
                }
                else{
                    inventario = inventario + ";" + products[i].cantidades[j];
                }
            }

            let variaciones = "";
            for(let l = 0; l < products[i].variaciones.length; l++){
                if(l == 0){
                    variaciones = variaciones + products[i].variaciones[l];
                }
                else{
                    variaciones = variaciones + "," + products[i].variaciones[l];
                }
            }

            let otrasImagenes = "";
            for(let k = 1; k < products[i].imagenesDeProducto.length; k++){
                if(k == 1){
                    otrasImagenes = otrasImagenes + products[i].imagenesDeProducto[k];
                }
                else{
                    otrasImagenes = otrasImagenes + "," + products[i].imagenesDeProducto[k];
                }
            }

            //Creamos objeto
            let product = {
                objectID: products[i].objectID,
                nombre: nombre,
                descripcion: descripcion,
                precio: products[i].precio,
                categoria: products[i].categoria,
                peso: peso,
                inventario: inventario,
                variaciones: variaciones,
                imagenPrincipal: products[i].imagenesDeProducto[0],
                otrasImagenes: otrasImagenes
            }

            //Insertamos objeto en arreglo
            setInventory(oldArray => [...oldArray, product]); 
        }
    }

    const downloadInventory = async (e) => {
        e.preventDefault();
        
        //Get All Products of This User
        const allProducts = await indexProductos.search(uid, {filters: 'isDisplayed:true', hitsPerPage: 1000});
        
        //Get New Formatted Array of Products
        let dataToDownload;
        if(inventory.length == 0){
            dataToDownload = await getDataToDownload(allProducts.hits);
        }
        else{
            dataToDownload = inventory;
        }
        
        //Download Data
        excelRef.current.link.click();

        //Notify User that data has been downloaded
        // notify("downloadInventory");
    }

    const createArrayFromStringSeparatedByChar = (string, char) => {
        let baseString = string;
        let newArray = [];
        //Mientras siga habiendo una comma presente
        while(baseString.indexOf(char) > -1){
            let idxDeChar = baseString.indexOf(char);
            let element = baseString.substring(0, idxDeChar);
            newArray.push(element);
        
            //Borrar elemento de arreglo base
            baseString = baseString.replace(element+char, "");
        }
        //Para el último elemento que ya no tiene comma presente en el inicio
        newArray.push(baseString);

        return newArray;
    }

    const uploadInventory = (e) => {
        e.preventDefault();
        //Iteramos Data CSV
        for(let i = 1; i < dataFromCSV.length; i++){
            if(dataFromCSV[i][0] !== ""){
                 //Creamos el arreglo de imagenesDeProducto
                let imagenesDeProducto = [];
                imagenesDeProducto.push(dataFromCSV[i][8]);
                
                let otrasImagenesDeProducto = createArrayFromStringSeparatedByChar(dataFromCSV[i][9], ",");
                imagenesDeProducto = imagenesDeProducto.concat(otrasImagenesDeProducto);

                //Creamos los arreglos de cantidades y variaciones
                let cantidades = [];
                let variaciones = [];
                
                //Si hay variaciones
                if(dataFromCSV[i][7] !== ""){
                    cantidades = createArrayFromStringSeparatedByChar(dataFromCSV[i][6], ";");
                    variaciones = createArrayFromStringSeparatedByChar(dataFromCSV[i][7], ",");
                }
                else{
                    cantidades.push(dataFromCSV[i][6]);
                }

                // Llamamos función para editar producto en base a los datos de la fila actual
                props.editProductCSV(dataFromCSV[i], imagenesDeProducto, cantidades, variaciones);
            }
        }
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
            notify("editedInventory");
        }, "3000")
        
    }

    const handleImportCSV = (data, fileInfo, originalFile) => {
        setDataFromCSV(data);
    }

    useEffect(() => {
        //Error handling after uploading
        if(dataFromCSV != undefined){
            for(let i = 1; i < dataFromCSV.length; i++){
                if(dataFromCSV[i][0] == ""){
                    setDataFromCSVHasError(true);
                    setErrorImportingDataFromCSV({
                        error: "Se borró el ID del producto",
                        row: i+1
                    })
                    break;
                }
                else if(dataFromCSV[i][3].indexOf("$") > -1){
                    setDataFromCSVHasError(true);
                    setErrorImportingDataFromCSV({
                        error: "Precio tiene signo de $",
                        row: i+1
                    })
                    break;
                }
                else if(dataFromCSV[i][3].indexOf(",") > -1){
                    setDataFromCSVHasError(true);
                    setErrorImportingDataFromCSV({
                        error: "Precio tiene comma",
                        row: i+1
                    })
                    break;
                }
                else if(dataFromCSV[i][5].indexOf(".") > -1){
                    setDataFromCSVHasError(true);
                    setErrorImportingDataFromCSV({
                        error: "Peso es decimal",
                        row: i+1
                    })
                    break;
                }
            }
        }
        
     }, [dataFromCSV]);

    let publishBtn;
    if(dataFromCSV[0] && dataFromCSV[0].length == 10 && !dataFromCSVHasError){
        publishBtn = (
            <button onClick={(e) => uploadInventory(e)} type="button" className="btn btn-primary" data-bs-dismiss="modal">Importar</button>
        );
    }
    else{
        publishBtn = (
            <button type="button" className="btn btn-grey btn-no-import">Importar</button>
        );
    }

    let error;
    if(dataFromCSVHasError){
        error = (
            <p className="mt-3 error">Error: {errorImportingDataFromCSV.error} en fila {errorImportingDataFromCSV.row}</p>
        );
    }
    else{
        error = (
            null
        );
    }

    let downloadInventoryModal = (
        <div class="modal fade" id="downloadInventoryModal" tabindex="-1" aria-labelledby="downloadInventoryModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Descarga tus Productos</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p className="bold">Recuerda</p>
                    <p>No cambies el nombre de las columnas.</p>
                    <p>Revisa el formato antes de editar el excel: <a target="_blank" href="https://docs.google.com/document/d/1PXBkbUxwtm0MxIrBprvMYLuta4P3ABGxmAgLHw54qvM/edit?usp=sharing">aquí</a>.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(e) => downloadInventory(e)}>Descargar</button>
                </div>
                </div>
            </div>
        </div>
    );

    let uploadInventoryModal = (
        <div class="modal fade" id="uploadInventoryModal" tabindex="-1" aria-labelledby="uploadInventoryModal" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edita tu Inventario de Forma Masiva</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p className="bold">Pasos</p>
                    <ol>
                        <li>Descarga tu inventario a un archivo .csv: <p className="download-csv underline" onClick={(e) => downloadInventory(e)}>Descargar</p></li>
                        <li className="mt-4">Edita los productos dentro del archivo </li>
                        <li className="mt-4">
                            Importa tu archivo editado:
                            <div className="mt-2">
                                <CSVReader onFileLoaded={(data, fileInfo, originalFile) => handleImportCSV(data, fileInfo, originalFile)} />
                                {error}
                            </div>
                        </li>
                    </ol>
                   
                    
                    {/* Divider Line */}
                    <div className="col-12 mt-3">
                        <div className="divider"></div>
                    </div>

                    <p className="bold mt-3">Recuerda</p>
                    <p>No cambies el nombre de las columnas.</p>
                    <p>Revisa el formato antes de editar el excel: <a target="_blank" href="https://docs.google.com/document/d/1PXBkbUxwtm0MxIrBprvMYLuta4P3ABGxmAgLHw54qvM/edit?usp=sharing">aquí</a>.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                    {publishBtn}
                </div>
                </div>
            </div>
        </div>
    );

    if(!loader){
        return(
            <div>
                {/* Modals */}
                {downloadInventoryModal}
                {uploadInventoryModal}
    
                <div className="d-flex mt-4">
                    <h3>Inventario</h3>
                    <CSVLink 
                        {...csvLink} 
                        className="noDisplay" 
                        ref={excelRef} 
                    >
                    </CSVLink>;
                    <button className="btn btn-inventory-util ms-4" data-bs-toggle="modal" data-bs-target="#uploadInventoryModal">Editar Masivamente</button>
                </div>
                <div className="mt-4">
                    <ProductInventoryList products={products} />
                </div>
            </div>
        );
    }
    else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status"></div>
                <p className="mt-3">Editando Productos.</p>
                <p className="mt-3">Al terminar, recarga la página.</p>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        editProductCSV: (product, imagenesDeProducto, cantidades, variaciones) => dispatch(editProductCSV(product, imagenesDeProducto, cantidades, variaciones))
    }
}

export default connect(null, mapDispatchToProps)(Inventory);






