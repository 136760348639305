import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import ProductList from '../../products/ProductList';
import copy from 'copy-to-clipboard';
import '../../../App.css';
import {Helmet} from "react-helmet";
import algoliasearch from 'algoliasearch';
import ReactPixel from 'react-facebook-pixel';
import CatalogosList from './CatalogosList';
import {addCatalogo} from '../../../store/actions/catalogoActions';
import toast, { Toaster } from 'react-hot-toast';
import gtag, { install } from 'ga-gtag';
import { useHistory } from 'react-router-dom';


install('G-GQ8M7NNRJ7');

//CREDENCIALES DE PRODUCCION
const APPLICATION_ID = "2CWP6XVNL4";
const SEARCH_API_KEY_PRODUCTOS = "02bc8f928e9f76b01f0c2c7e04130555";
const SEARCH_API_KEY_VENTAS = "2dfef2a277968be91a246194cac3cda6";
const SEARCH_API_KEY_USUARIOS = "bc64447a4980d8abca2ea6b5b1658241";
const SEARCH_API_KEY_RESENAS = "5eac62090721bf89f95d676d1159f7cf";
const SEARCH_API_KEY_RETIROS = "cd6a266aaef9496a802bf69cb958a298";
const SEARCH_API_KEY_RETOS = "4f005c66397b01f240e810d2f913d5ef";
const SEARCH_API_KEY_CATALOGOS = "465a5e3eb841eff90af7f9c36278dd44";
const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "37bbdced854246edc06c7fb3c072275c";
const SEARCH_API_KEY_DISENOENCATALOGO = "7fdb8d87725c540857fc680054155691";


//CREDENCIALES DE DESARROLLO
// const APPLICATION_ID = "9DH0HO81S4";
// const SEARCH_API_KEY_USUARIOS = "8a532e2272a916e32d48369affdec469";
// const SEARCH_API_KEY_PRODUCTOS = "7e0c47859c389c2066ad3ef11eae970e";
// const SEARCH_API_KEY_VENTAS = "c7b3a19bfe67a44be6cf7b96e629ea35";
// const SEARCH_API_KEY_RESENAS = "1dedec61632b5d8652fb359ea1bef1d0";
// const SEARCH_API_KEY_RETIROS = "98e25e90da068f7d4b1dc65332f001a6";
// const SEARCH_API_KEY_RETOS = "20be1039fdb75c97c6929b3ab00351d5";
// const SEARCH_API_KEY_CATALOGOS = "fc1440dbc9593332f235bbc14852b8a3";
// const SEARCH_API_KEY_PRODUCTOSENCATALOGO = "a4785ad7a7f68b11da33cfcd839116ac";
// const SEARCH_API_KEY_DISENOENCATALOGO = "ceb37b088c17d1ae94ffc81222bc2736";

const ALGOLIA_INDEX_CATALOGOS = 'Kanguru_Catalogos';
const ALGOLIA_INDEX_PRODUCTOSENCATALOGO = 'Kanguru_ProductosEnCatalogo';
const ALGOLIA_INDEX_PRODUCTOS = 'Kanguru_Productos';
const ALGOLIA_INDEX_DISENOENCATALOGO = 'Kanguru_DisenoEnCatalogo';

const client_productosEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOSENCATALOGO);
const indexProductosEnCatalogo = client_productosEnCatalogo.initIndex(ALGOLIA_INDEX_PRODUCTOSENCATALOGO);
const client_productos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_PRODUCTOS);
const indexProductos = client_productos.initIndex(ALGOLIA_INDEX_PRODUCTOS);
const client_catalogos = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_CATALOGOS);
const indexCatalogos = client_catalogos.initIndex(ALGOLIA_INDEX_CATALOGOS);
const client_disenoEnCatalogo = algoliasearch(APPLICATION_ID, SEARCH_API_KEY_DISENOENCATALOGO);
const indexDisenoEnCatalogo = client_disenoEnCatalogo.initIndex(ALGOLIA_INDEX_DISENOENCATALOGO);

const Catalogos = (props) => {
    //State Variables
    const [catalogos, setCatalogos] = useState([]);
    const [disenoEnCatalogo, setDisenoEnCatalogo] = useState("");
    const [showLoader, setShowLoader] = useState(true);
    const [auth, setAuth] = useState(props.auth);
    const history = useHistory();
   
    const triggerAnalyticEvents = (eventType) => {
        gtag("event", "select_content", {
            content_type: eventType
        });
    }

    const notify = (type) => {
        if(type == "creoCatalogo"){
            toast.success('¡Buenísimo! Acabas de crear un catálogo. El siguiente paso es personalizarlo.');
        }
    }

    const createCatalogo = async () => {
        const catalogo = await props.addCatalogo(auth.uid);
        triggerAnalyticEvents("createdCatalogue");
        // showLoader();
        
        if(catalogo){
            const link = "/templates/" + catalogo.sCatalogoID + '/' + catalogo.sDisenoEnCatalogoID + '/' + "new"
            history.push(link);
        }
    }

    const fetchData = async (vendedorID) => {
        //Gets Catalogos of this Afiliado
        const catalogos = await indexCatalogos.search(vendedorID, {filters: 'bActivo: true', hitsPerPage: 50});

        //Gets DisenosDeCatalogos de Catalogos de Este Afiliado
        //Obtiene los diseños con los ids de los catalgoos
        let catalogosID = [];
        let filter = '';
        catalogos.hits.map(catalogo => {
            catalogosID.push(catalogo.objectID);
            filter = filter + "sCatalogoID:" + catalogo.objectID + " OR "; 
        })
        filter = filter.substring(0, filter.length-4);

        const disenoEnCatalogo = await indexDisenoEnCatalogo.search("", {filters: filter, hitsPerPage: 50});

        setCatalogos(catalogos.hits);
        setDisenoEnCatalogo(disenoEnCatalogo.hits);
    }

    const showLoaderFunction = () => {
        console.log("hey");
        setTimeout(() => {
            setShowLoader(false);
        }, "2000");
    }

    useEffect(() => {
        //Goes to the top of the page when the page first loads
        window.scrollTo(0, 0);
        
        if(auth){
            fetchData(auth.uid);
        }

        showLoaderFunction();

    }, []);

    let loader = (
        <div className="spinner">
             <div class="spinner-border spinner-color" role="status"></div>
             <p className="mt-3">¡Listo para alcanzar tus metas!</p>
             <p className="mt-3">Al terminar, si no ves tu catálogo, intenta recargar la página.</p>
         </div>
     );

     
     let content = (
         <div>
             <div className="mt-4 d-flex">
                 <p className="catalogosTitle me-3">Mis Catálogos</p>
                 <p className="btn btn-compartir2" onClick={() => createCatalogo()}>Crear Catálogo</p>
             </div>
             <div className="catalogoList">
                 <CatalogosList catalogos={catalogos} vendedorID={auth.uid} disenoEnCatalogo={disenoEnCatalogo}/>
             </div>      
         </div>
     )

    if(catalogos && disenoEnCatalogo){
        return (
            <div className="container-fluid custom-container footer-spacing-catalogos">
                {/* Dynamic Description*/}
                <Helmet>
                    <title>Catalogos</title>
                    <meta name="title" content="Catalogos" />
                </Helmet>

                {/* Notifications Toast*/}
                <Toaster 
                    toastOptions={{
                        duration: 5000,
                        position: 'top-center',
                    }}
                />

                {/* Whats App Btn */}
                <div>
                    <a href="https://api.whatsapp.com/send?phone=+524438011119&text=Hola, buenos días" className="floating" target="_blank">
                        <i className="bi bi-whatsapp float-button"></i>
                    </a>
                </div>
                

                {/* Contenido */}
                {!showLoader ? content:loader}
            </div>
        );
    }
    else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        ); 
    }
}


const mapStateToProps = (state, ownProps) => {
    const auth = state.firebase.auth;
    
    if(auth){
        return{
            auth: auth
         }
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return{
        addCatalogo: (vendedorID) => dispatch(addCatalogo(vendedorID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalogos);

