import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';

const LandingCapacitacionNegocios = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Capacitación de Negocios</title>
                <meta name="description" content="Aprende cómo funciona Kanguru para empezar a vender tus productos."/>
            </Helmet>
            <div>
                <div className="landingClabeImgVendedor">
                    <div className="textCapacitacion">
                        <h4 className="textNosotros2">Aprende Sobre Kanguru</h4>
                    </div>
                    <img src="img/capacitacion-negocios.png" className="landingClabeImgVendedor2" alt=""/>
                </div>
            </div>
            <div className="landingPage2-Capacitacion">
                <div className="container-fluid custom-container row">
                    <div className="col-md-5">
                        <div>
                            <h2 className="landingText-Contacto mt-5">Tutoriales</h2>
                            <h4 className="mt-3">Canal de Youtube con Tutoriales: <a target="_blank" href="https://www.youtube.com/channel/UCqYbGgZB1vaD0f950RJZUmA">aquí</a></h4>
                            <p className="mt-3">Aprende a publicar productos, definir tu punto de recolección, decorar tu tienda, y más.</p>
                            <div className="divider mt-4"></div>
                        </div>
                        <div>
                            <h2 className="landingText-Contacto mt-5">Cómo Funciona Kanguru</h2>
                            <h4 className="mt-3">Documento: <Link to="/funcionamiento-negocios">aquí</Link></h4>
                            <p className="mt-3">Aprende cómo funcionan los envíos, el proceso de venta, y tu ingreso neto en cada venta.</p>
                            <div className="divider mt-4"></div>
                        </div>
                        <div>
                            <h2 className="landingText-Contacto mt-5">Términos y Condiciones</h2>
                            <h4 className="mt-3">Documento: <Link to="/terminos-y-condiciones">aquí</Link></h4>
                            <p className="mt-3">Aprende sobre lo que te comprometes al vender en Kanguru.</p>
                            <div className="divider mt-4"></div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <img src="img/contact-img.png" className="landingCatchImgCapacitacion" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default LandingCapacitacionNegocios;
