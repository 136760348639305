import React, {Component} from 'react';
import FileUploader from 'react-firebase-file-uploader';
import {connect} from 'react-redux';
import firebase from 'firebase';
import {addResena} from '../../store/actions/resenaActions';
import ReseñasList from '../reviews/ReseñasList';
import ReseñasEstrellas from '../reviews/ReseñasEstrellas';

class Reseñas extends Component{
    state = {
        //Add Reseñas
        calificacion: "5",
        titulo: "",
        descripcion: "",
        esVistoPor: "afiliados",
        //Imagenes de Producto
        filenames: [],
        downloadURLs: [],
        isUploading: false,
        uploadProgress: 0
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    addReview = (e) => {
        const productoID = this.props.productoID;
        const vendedorID = this.props.vendedorID;
        this.props.addResena(this.state, productoID, vendedorID);
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    handleDownload = async newFileFinal=> {
        const downloadURL = await firebase
          .storage()
          .ref("images")
          .child(newFileFinal)
          .getDownloadURL();

        this.setState(oldState => ({
            filenames: [...oldState.filenames, newFileFinal],
            downloadURLs: [...oldState.downloadURLs, downloadURL],
            uploadProgress: 100,
            isUploading: false
        }));
    }
    handleUploadSuccess = async filename => {
        //New File URL
        let newFile;
        let newFileFinal;
        if(filename.substring(filename.length - 5, filename.length) === ".jpeg"){
            //JPEG
            newFile = filename.substring(0,filename.length - 5);
            newFileFinal = newFile + '_576x691.jpeg';
        }
        else{
            //PNG O JPG
            newFile = filename.substring(0,filename.length - 4);
            newFileFinal = newFile + '_576x691.jpeg';
        }

        setTimeout(() => {
            this.handleDownload(newFileFinal);
        }, 5000);
    };


    render(){
        const {reseñas} = this.props;
        const {reseñasTotal} = this.props;
        const {product} = this.props;
        const {location} = this.props;
        let tituloLabel;
        let descripcionLabel;
        let tituloPlaceholder;
        let descripcionPlaceholder;

        if(this.state.esVistoPor === "afiliados"){
            tituloLabel = "En una linea describe tu opinión:";
            descripcionLabel = "Comparte tu experiencia más a detalle:";
            tituloPlaceholder = "Ejemplo: 100% Recomendable.";
            descripcionPlaceholder = "Ejemplo: Excelente producto para vender en redes sociales. Se me ha hecho facil de vender y mis clientes están satisfechos."
        }
        else{
            tituloLabel = "En una linea describe la opinión de tu cliente:";
            descripcionLabel = "Comparte la experiencia de tu cliente más a detalle:";
            tituloPlaceholder = "Ejemplo: Excelente Calidad y Precio";
            descripcionPlaceholder = "Ejemplo: Producto de alta calidad. Para el precio es muy bueno, lo recomiendo."
        }

        let agregarReseñasModal;
        agregarReseñasModal = (
            <div class="modal fade" id="modalAgregarResena" tabindex="-1" aria-labelledby="modalAgregarResena" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Qué Opinas de este Producto</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="mb-3">  

                                    <h6 clasName="">Comparte tu experiencia vendiendo este producto. Puedes escribir sobre tu experiencia como vendedor o la de tu cliente, en primera persona. </h6>
                                    
                                    <div clasName="mt-3">
                                        <label for="esVistoPor" className="form-label addProductFormLabel">¿Quién quieres que vea tus comentarios?</label>
                                        <select onChange={this.handleChange} id="esVistoPor" className="form-select" aria-label="No">
                                            <option value="afiliados">Afiliados</option>
                                            <option value="clientes">Clientes</option>
                                        </select>
                                    </div>
                                    
                                    <div className="mt-3">
                                        <label for="calificacion" className="form-label addProductFormLabel">Calificación</label>
                                        <select onChange={this.handleChange} id="calificacion" className="form-select" aria-label="No">
                                            <option disabled selected>Del 1 al 5</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    

                                    <div className="mt-3">
                                        <label for="titulo" className="col-form-label cuentaVendedorLabelForm">{tituloLabel}</label>
                                        <input onChange={this.handleChange} placeholder={tituloPlaceholder} type="text" className="form-control" id="titulo"/>
                                    </div>

                                    <div className="mt-3">
                                        <label for="descripcion" className="col-form-label cuentaVendedorLabelForm">{descripcionLabel}</label>
                                        <textarea maxLength="600" placeholder={descripcionPlaceholder} onChange={this.handleChange} className="form-control" id="descripcion" rows="4"></textarea>
                                    </div>
                                    <div className="mt-3">
                                        <label for="descripcion" className="col-form-label cuentaVendedorLabelForm">Imagen Real del Producto (Opcional)</label>
                                        <FileUploader
                                            accept="image/*"
                                            name="image-uploader-multiple"
                                            randomizeFilename
                                            storageRef={firebase.storage().ref("images")}
                                            onUploadStart={this.handleUploadStart}
                                            onUploadError={this.handleUploadError}
                                            onUploadSuccess={this.handleUploadSuccess}
                                            onProgress={this.handleProgress}
                                        />
                                        {this.state.isUploading && <p>Se están cargando las imágenes...</p>}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-grey" data-bs-dismiss="modal">Cerrar</button>
                            <button onClick={this.addReview} type="button" className="btn btn-primary" data-bs-dismiss="modal">Agregar</button>
                        </div>
                    </div>
                </div>
            </div>
        );

        let verReseñasModal;
        verReseñasModal = (
            <div class="modal fade" id="modalShowReviews" tabindex="-1" aria-labelledby="showReviews" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="editPrice">Opiniones de este Producto</h4>
                    </div>
                    <div class="modal-body modalReseñas">
                        <ReseñasList reseñas={reseñasTotal}/>
                    </div>
                    <div class="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                    </div>
                </div>
            </div>
        );

        let rating;
        let fixedRating;
        let estrellas;
        let numeroRatings;
        let calificacionPromedioClassName;
        if(product.calificacionPromedio !== undefined){
            rating = product.calificacionPromedio;
            fixedRating = rating.toFixed(1);
            calificacionPromedioClassName = window.location.href.indexOf("kangupago.com") === -1 ? "calificacionPromedio" : "calificacionPromedioKanguPago";
            numeroRatings = "Promedio entre " + reseñasTotal.length + " opiniones";
            estrellas = (
                <ReseñasEstrellas rating={rating}/>
            );
        }
        else{
            rating = null;
            fixedRating = null;
            estrellas = (
                null
            );
            numeroRatings = null;
        }

        let agregarReseñasBtn;
        if(location === "productDetails"){
            agregarReseñasBtn = (
                 <p className="btn btn-reseñaMas ms-3" data-bs-toggle="modal" data-bs-target="#modalAgregarResena"><i class="bi bi-plus-lg"></i></p>
            );
        }
        else{
            agregarReseñasBtn = (
                null
            );
        }


        return(
            <div>
                {/* MODALS */}
                {verReseñasModal}
                {agregarReseñasModal}

                {/* Esto ya no es parte del modal */}
                <div>
                    {/* Title and Rating */}
                    <div>
                        <div className="d-flex">
                            <h4 className="material-titulo">Opiniones sobre el Producto</h4>
                            {agregarReseñasBtn}
                        </div>
                        <div className="d-flex">
                            <div>
                                {/* Rating */}
                                <p className={calificacionPromedioClassName}>{fixedRating}</p>
                                {/* Stars */}
                                <div className="estrellasPromedio">
                                    {estrellas}
                                </div>
                                
                                {/* Número de Ratings ej. Promedio entre X opiniones.*/}
                                <p className="reseñasPromedio">{numeroRatings}</p>
                            </div>
                        </div>
                        {/* Divider Line */}
                        <div className="col-8 mt-3 mt-md-5">
                            <div className="divider"></div>
                        </div>
                    </div>
                    
                    {/* Review List */}
                    <div className="mb-3">
                        <ReseñasList reseñas={reseñas}/>
                    </div>

                    <p className="linkToPage mb-5" data-bs-toggle="modal" data-bs-target="#modalShowReviews">Ver todas las opiniones</p>
                </div>
            </div> 
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addResena: (state, productoID, vendedorID) => dispatch(addResena(state,productoID, vendedorID))
    }
}


export default connect(null, mapDispatchToProps)(Reseñas);