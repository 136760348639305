import React, { useState } from 'react';
import {completeOnboarding} from '../../store/actions/authActions';
import {connect} from 'react-redux';
import './OnBoarding.css';
import '../../App.css';

const OnBoarding = (props) => {

    const {auth} = props;
    const [page, setPage] = useState(1);

    const completeOnboardingFunction = async (e) => {
        e.preventDefault();
        props.completeOnboarding(auth.uid);
    }

    let content;
    if(page === 1){
        content = (
            <div>
                {/* Image */}
                <div className="onboarding-img-container">
                    <img src="img/onboarding-1.png"  className="onboarding-img" alt="..."/>
                </div>

                {/* Message */}
                <div className="onboarding-message-container">
                    <p className="onboarding-message-main">Empieza creando tu catálogo digital</p>
                    <p className="onboarding-message-text">Después, agrega los productos que más te gusten para vender. Tu catálogo digital es tu tienda online.</p>

                </div>

                {/* Button */}
                <div className="onboarding-btn-container">
                    <button className="btn btn-primary btn-onboarding" onClick={() => setPage(2)}>Continuar</button>
                </div>

                 {/* Dots to Indicate Page */}
                 <div className="d-flex onboarding-dots-container">
                    <i className="bi bi-circle-fill me-3 onboarding-active-dot" onClick={() => setPage(1)}></i>
                    <i className="bi bi-circle-fill me-3 onboarding-inactive-dot" onClick={() => setPage(2)}></i>
                    <i className="bi bi-circle-fill me-3 onboarding-inactive-dot" onClick={() => setPage(3)}></i>
                 </div>

            </div>
        )
    }
    else if(page === 2){
        content = (
            <div>
                {/* Image */}
                <div className="onboarding-img-container">
                    <img src="img/onboarding-2.png"  className="onboarding-img" alt="..."/>
                </div>

                {/* Message */}
                <div className="onboarding-message-container">
                    <p className="onboarding-message-main">¡Comparte tu Catálogo!</p>
                    <p className="onboarding-message-text">Ofrécele a tus vecinos y familia productos de calidad y con los mejores precios</p>

                </div>

                {/* Button */}
                <div className="onboarding-btn-container">
                    <button className="btn btn-primary btn-onboarding" onClick={() => setPage(3)}>Continuar</button>
                </div>

                {/* Dots to Indicate Page */}
                <div className="d-flex onboarding-dots-container">
                    <i className="bi bi-circle-fill me-3 onboarding-inactive-dot" onClick={() => setPage(1)}></i>
                    <i className="bi bi-circle-fill me-3 onboarding-active-dot" onClick={() => setPage(2)}></i>
                    <i className="bi bi-circle-fill me-3 onboarding-inactive-dot" onClick={() => setPage(3)}></i>
                 </div>
            </div>
        )
    }
    else if(page === 3){
        content = (
            <div>
                {/* Image */}
                <div className="onboarding-img-container">
                    <img src="img/onboarding-3.png"  className="onboarding-img" alt="..."/>
                </div>

                {/* Message */}
                <div className="onboarding-message-container">
                    <p className="onboarding-message-main">Recibe comisiones por tus ventas</p>
                    <p className="onboarding-message-text">Empieza a generar ingresos desde tu primer día, sin invertir. Tu trabajo es sólo vender.</p>

                </div>

                {/* Button */}
                <div className="onboarding-btn-container">
                    <button className="btn btn-primary btn-onboarding" onClick={(e) => completeOnboardingFunction(e)}>¡Empezar!</button>
                </div>

                {/* Dots to Indicate Page */}
                <div className="d-flex onboarding-dots-container">
                    <i className="bi bi-circle-fill me-3 onboarding-inactive-dot" onClick={() => setPage(1)}></i>
                    <i className="bi bi-circle-fill me-3 onboarding-inactive-dot" onClick={() => setPage(2)}></i>
                    <i className="bi bi-circle-fill me-3 onboarding-active-dot" onClick={() => setPage(3)}></i>
                 </div>
            </div>
        )
    }

    return (
        <div className="onboarding-container">
            
            {/* Exit */}
            <div>
                <i className="bi bi-x-lg exit-icon" onClick={(e) => completeOnboardingFunction(e)}></i>
            </div>

            {content}

        </div>
    );
    
    
}

const mapDispatchToProps = (dispatch) => {
    return{
        completeOnboarding: (sUserID) => dispatch(completeOnboarding(sUserID)),
    }
}

export default connect(null, mapDispatchToProps)(OnBoarding);