const initState = {
    ventas: [
        {id: '1', vendedorID: '1234', Fecha: '', nombreProducto: 'Brazalete de Oro', negocio_id: 'Tiffany & Co', negocio:'Tiffany & Co', cantidad: 1, comision: 500, ingreso : 2000, comisionPorcentaje:15}
    ]
};


const ventaReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_VENTA':
            console.log('Added Venta', action.product);
            return state;
        case 'ADD_VENTA_ERROR':
            console.log('Added Venta Error', action.err);
            return state;
        case 'ADD_CODIGO_RASTREO':
            console.log('Added Codigo Rastreo', action.codigoRastreo);
            return state;
        case 'ADD_CODIGO_RASTREO_ERROR':
            console.log('Added Codigo Rastreo Error', action.err);
            return state;
        default:
            return state;
    }
}

export default ventaReducer;