import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import '../../../App.css';
import toast, { Toaster } from 'react-hot-toast';

const CatalogoAgregaProductos = () => {
    
    const history = useHistory();

    const terminar = (e) => {
        e.preventDefault();
        const link = "/";
        history.push(link);
    }

    return(
        <div className="container footer-spacing agrega-productos">

            <div className="mt-4">
                <h1 className="terminosCondicionesCatch">Agrega Productos a tu Catalogo</h1>
                <h4 className="center mt-4">Ahora sólo falta que escogas qué productos quieres vender, el siguiente video te muestra cómo</h4>
            </div>
            <div className="col-12 mt-4 center">
                <iframe className="video-center" frameborder="0" allowfullscreen
                    src="https://www.youtube.com/embed/OKXVREACcv8">
                </iframe>
            </div>
            <div className="mt-4 center">
                <button onClick={(e) => terminar(e)} type="submit" className="btn btn-primary mt-3">Listo para Agregar Productos</button>
            </div>
            
        </div>

        
    )
        
}



export default CatalogoAgregaProductos;