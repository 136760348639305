import React, { useState, useEffect } from 'react';
import '../../App.css';
import {connect} from 'react-redux';
import gtag, { install } from 'ga-gtag';

install('G-GQ8M7NNRJ7');


const VentaSuccessPCE = (props) => {
    const {sVentaID, sProductoID, precio} = props;
    const [nIngresoTotal, setnIngresoTotal] = useState(0);

    const triggerAnalyticEvents = (sVentaID, ProductoID, precio) => {
        gtag("event", "purchase", {
            transaction_id: sVentaID,
            value: precio,
            currency: "MXN",
            items: [
             {
              item_id: sProductoID
            }]
        });
    }

    useEffect(async () => {
        //Google Analytics Purchase Event
        triggerAnalyticEvents(sVentaID, sProductoID, precio);

    }, []); 


    if(precio){
        return(
            <div className="container mt-5 successOrder">
                <div>
                    <div className="">
                        <h2>¡Gracias por tu orden!</h2>
                        <h5 className="mt-4">Tú pedido ha sido creado con éxito. Sólo falta que el proveedor acepte la orden, una vez que se acepte la orden, se enviará el producto.</h5>
                        <h5 className="mt-4">Tú pedido llegará dentro de <span className="municipio">7 días hábiles</span>.</h5>
                        <h5 className="mt-4">Además, enviamos un correo de confirmación.</h5>
                        <h5 className="mt-4">Te pedimos estar al pendiente de tu correo para notificaciones sobre el pedido.</h5>
                        <h5 className="mt-4">Recuerda que al recibir el paquete se necesitará pagar la siguiente cantidad exacta en efectivo:</h5>
                        <h2 className="mt-4">MXN ${precio}</h2>
                    </div>
                    <div className="mt-5">
                        <h5>Si deseas cancelar el pedido puedes contactarnos a través de info@kanguru.mx</h5>
                    </div>
                </div> 
            </div> 
        );  
    }
    else{
        return(
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }
    
}

const mapStateToProps = (state, ownProps) => {
    const sVentaID = ownProps.match.params.sVentaID;
    const sProductoID = ownProps.match.params.sProductID;
    const precioHash = ownProps.match.params.precio;
    let alphabet = "abcdefghijklmnopqrstuvwxyz";
    let precioSinHash = "";

    for(let i = 0; i < precioHash.length; i=i+2){
        precioSinHash = precioSinHash + alphabet.indexOf(precioHash[i]);
    }

    return{
        sVentaID: sVentaID,
        sProductoID: sProductoID,
        precio: precioSinHash
    }
}

export default connect(mapStateToProps, null)(VentaSuccessPCE);;