import React, { useEffect } from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingTC = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return(
        <div className="container">
            {/* Dynamic Description*/}
            <Helmet>
                <title>Términos y Condiciones</title>
                <meta name="description" content="Conoce nuestros Términos y Condiciones."/>
            </Helmet>
            
            <h1 className="terminosCondicionesCatch">Términos y Condiciones</h1>
            
            <div>
                <p className="mt-5">
                    Los Términos y Condiciones de uso que a continuación se presentan (los <span className="underline">"Términos"</span>) constituyen el acuerdo íntegro entre Vendedores Digitales Mexicanos, S.A.P.I. de C.V. (<span className="underline">"Kanguru"</span>), con domicilio en Rio Grijalva no. 200, Colonia del Valle, San Pedro Garza Garcia, Nuevo León, México (el <span className="underline">"Domicilio"</span>), con dirección electrónica www.kanguru.mx (el <span className="underline">"Sitio"</span>) y con correo electrónico de contacto contacto@kanguru.mx (el <span className="underline">"Correo Electrónico"</span>); y la persona física y/o moral que acceda a ella. La utilización de el Sitio, por parte de cualquier persona, le atribuye la calidad de usuario y/o cliente (el <span className="underline">"Usuario"</span>) y ello implica su adhesión plena e incondicional a estos Términos.
                </p>
            </div>
            
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">1. OBJETO</h5>
                <p>
                    Kanguru pone el Sitio a disposición del Usuario una plataforma de ventas en línea, cuya finalidad principal es la creación, operación y explotación comercial de la misma, ya sea que se trate de un sitio patrocinado, administrado por intermedio de un tercero o alojado por un tercero, a través de la cual se brinda una plataforma en la que el Usuario Proveedor pone a disposición de venta ciertos productos en línea (los <span>"Productos"</span>) para que el Usuario Afiliado pueda vender y/o promocionar para su venta los Productos a un Usuario Comprador que podrá realizar la compra de dichos Productos; que es proporcionada por  Kanguru o por personas vinculadas a dicha información de manera directa o indirecta (los <span>"Contenidos"</span>) facilitando el enlace con los Usuarios. El Usuario reconoce que el uso del Sitio no le confiere ningún derecho de propiedad sobre la misma, o cualquiera de sus elementos o Contenidos. Kanguru se reserva el derecho a modificar, total o parcialmente, en cualquier momento y sin aviso previo, la presentación, configuración, información, Contenidos y en general cualquier parte o aspecto relacionado directa o indirectamente con el Sitio.
                </p>
                <p>
                    El Usuario Cliente reconoce que las descripciones de los Productos contenidas en el Sitio se realizan de forma ilustrativa, y tendrán el alcance y las especificaciones comunes, básicas y simples, que regularmente ofrece el mercado, salvo cuando dichos alcances y especificaciones se especifiquen en el Sitio. De igual forma, el Usuario Cliente reconoce que la información contenida y/o publicada a través del Sitio, tiene fines meramente informativos sobre los Productos que ofrece el  Usuario Proveedor.
                </p>
                <div>
                    <p>
                        La plataforma tiene diversos componentes y funcionalidades entre las cuales se encuentran las siguientes: 
                    </p>
                    <ol type="a">
                        <li>Usuario Proveedor: Usuario que pone a disposición Productos a la venta en el Sitio con la finalidad de que el Usuario Afiliado los promueva y venda por medios electrónicos. Es responsable de la calidad del producto, descripción y especificaciones, y de la entrega del producto al Usuario Cliente.</li>
                        <li>Usuario Afiliado: Podrá promover a la venta los Productos disponibles para los Usuarios Clientes, a su propio costo. Determinar discrecionalmente el precio de venta de los Productos disponibles para los Usuarios Clientes, sin que en ningún caso pueda ser un precio inferior al precio definido por Usuario Proveedor. Una vez que el Usuario Cliente quiera realizar una orden, el Usuario Afiliado podrá realizar el pedido por parte del Cliente dentro de la Plataforma o compartirle un enlace de pago bajo la dirección electrónica y dominio de www.kangupago.com. El Usuario Afiliado también podrá crear tiendas virtuales personalizadas (“Catalogo Digital”) uniendo Productos de diversos Usuarios Proveedores. Dichos Catálogos Digitales serán públicos para Usuarios Clientes dentro de la dirección electrónica y dominio de www.catalogoenlinea.mx o el URL definido por el Usuario Afiliado. Una vez concrete la venta de uno o varios Productos podrá ser acreedor a la Comisión correspondiente según lo establecido por Kanguru. </li>
                        <li>Usuario Cliente: Comprador de Productos a través de los medios señalados. Podrá realizar su compra con método de pago en línea o pago contra entrega, según lo establezca el Usuario Proveedor. </li>
                    </ol>  
                </div>
                <p>
                    La comisión que recibirá el Usuario Vendedor por concretar la venta del Producto será sobre el precio del producto definido por el Usuario Proveedor (“Precio Base”). Kanguru a su vez tomará una comisión del 7% más los montos de impuestos aplicables, sobre el Precio Base.
                </p>
                <p>
                    Portal Usuario Proveedor: Sitio mediante el cual el Usuario Proveedor podrá cargar y administrar sus productos para que el Usuario Afiliado pueda ponerlos a disposición de Usuarios Clientes para su venta (“Sitio Usuario Proveedor”). En el Sitio Usuario Proveedor podrá publicar productos, administrar ventas e inventario, definir punto de recolección de ventas con método de pago contra entrega, y decorar tienda dentro de la plataforma.
                </p>
                <p>
                    Portal Usuario Afiliado: Sitio mediante el cual El Usuario Afiliado puede encontrar Productos del Usuario Proveedor para publicar y promocionar a la venta. 
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">2. LICENCIA DE USO DEL SITIO</h5>
                <p>
                    Sujeto al cumplimiento de estos Términos, así como al pago del precio aplicable, en este acto, Kanguru autoriza y otorga al Usuario el uso y goce de 1 (una) licencia de uso del Sitio para uso personal, bajo la modalidad “as is”, temporal, limitada, no exclusiva, revocable, no comercializable y no sublicenciable; para acceso y utilización con fines personales no comerciales. 
                </p>
                <p>
                    Kanguru y sus licenciantes, proveedores, editores, titulares de derechos u otros proveedores de Contenidos, se reservan cualquier derecho que no esté expresamente comprendido en estos Términos. Cualquier incumplimiento por parte del Usuario a estos términos, supondrá la terminación inmediata de la licencia otorgada por Kanguru.
                </p>
                <p>
                    <span className="underline">Hospedaje.</span> Kanguru hospedará las Licencias en la nube de su elección (nube significa espacio de procesamiento y almacenamiento de datos y aplicaciones en servidores físicos que están en un Centro de Datos de algún tercero). El hospedaje tiene una disponibilidad adecuada. No obstante lo anterior, Kanguru no será responsable de cualquier caída, ausencia total o parcial de disponibilidad, ni de pérdida total o parcial de datos.
                </p>
                <p>
                    <span className="underline">Acceso y estancia en el Sitio.</span> El Usuario es el único responsable frente a Kanguru, y cualquier tercero, respecto de su conducta al acceder, consultar y proporcionar información en el Sitio y de las consecuencias que se puedan derivar de una utilización, con fines o efectos ilícitos o contrarios al objeto del Sitio, su contenido, elaborado o no por Kanguru, publicado o no bajo su nombre de forma oficial; así como aquellas consecuencias que se puedan derivar de la utilización contraria al contenido de estos Términos que sea lesiva de los intereses o derechos de terceros, o que de cualquier forma pueda dañar, inutilizar o deteriorar el Sitio e impedir el normal disfrute de otros usuarios.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">3. CONDICIONES DE USO DE COMERCIO ELECTRÓNICO</h5>
                <p>
                    Para acceder a los Productos, es necesario ser mayor de edad y proporcionar la información requerida, de tal manera que es responsabilidad del Usuario mantener la información actualizada y disponible. El Usuario es responsable de toda la actividad que ocurra bajo su cuenta, y se compromete a mantener la seguridad y el secreto de su nombre de usuario y contraseña, en todo momento, ya que dichos datos son confidenciales. Por lo anterior, el Usuario asume toda responsabilidad sobre cualesquier actividades realizadas desde su cuenta o utilizando su contraseña. Los Productos de Kanguru podrán ser adquiridos por el Usuario Cliente siempre y cuando sean solicitados y pagados por persona mayor de edad y con capacidad jurídica y económica para asumir las obligaciones y responsabilidades que dicho acto genere. El Usuario Cliente reconoce y acepta que el uso del Producto(s) adquirido es bajo su exclusiva y estricta responsabilidad, por lo que  Kanguru no será, en ningún momento y bajo ninguna circunstancia, responsable por cualquier desperfecto o problema que se presente.
                </p>
                <p>
                    El acceso y uso del Sitio se hará de conformidad con la ley, la moral y las buenas costumbres, así como de conformidad con estos Términos y deberá utilizarse con fines lícitos, respetando los derechos de terceros.  Kanguru se reserva el derecho de dar de baja cuentas, remover o editar Contenido, o cancelar ordenes en caso de violación a estos Terminos, derechos de terceros o en caso de comisión de un delito o cualquier conducta que dañe la reputación de Kanguru o la experiencia a la comunidad de otros clientes.
                </p>
                <p>
                    <span className="underline">Veracidad de datos.</span> El Usuario reconoce que  Kanguru no realizará investigación alguna para validar la exactitud y veracidad de los datos provistos por el Usuario, por lo que en caso que presentan omisiones, inexactitudes o errores, libera de cualquier responsabilidad a Kanguru, respecto de cualquier daño o perjuicio que dichos actos pudieran causarle.
                </p>
                <p>
                    <span className="underline">Imprecisiones en el Sitio.</span> El Contenido y/o el Sitio, pueden contener inexactitudes y/o errores tipográficos. Kanguru no garantiza la exactitud del Contenido y se reserva el derecho, a su entera discreción, de corregir cualquier error u omisión en cualquier parte del Sitio y a realizar cualquier cambio en las características, funcionalidad o Contenido en cualquier momento. Kanguru, así como cualquier persona relacionada y/o afiliada al mismo, incluyendo, sin limitar, directores, apoderados, representantes, administradores, empleados, accionistas y/o agentes, presentes o anteriores, o aliados, no serán responsables de errores u omisiones en los Contenidos.
                </p>
                <p>
                    <span className="underline">Newsletter.</span> Mediante la elección de registro de su correo electrónico personal, el Usuario podrá recibir, de manera periódica y gratuita, el Newsletter de Kanguru, con información referente a ofertas, nuevos lanzamientos de Productos e información relacionada con ellos. Dicho Newsletter podrá ser proporcionado por  Kanguru, o por personas vinculadas de manera directa o indirecta con la misma. El Usuario tendrá la facultad de cancelar, en cualquier momento, su registro al Newsletter mediante comunicación al Correo Electrónico. La cancelación producirá efecto en un plazo no mayor a 72 (setenta y dos) horas, a partir de la fecha de recepción de la solicitud de cancelación, mediante eliminación de los datos de correo electrónico del Cliente en la base de datos de Kanguru. 
                </p>
                <p>
                    <span className="underline">Producto.</span> Todos los Productos publicados en el Sitio están dirigidos exclusivamente a consumidores y Usuarios Clientes.  Kanguru a través del cátalogo de sus Usuarios Proveedores se reserva el derecho de sacar nuevos productos, sin restricción alguna de periodicidad, cantidad, ingredientes, usos y características del mismo. Asimismo, Kanguru se reserva el derecho a retirar, reponer o cambiar los Productos mediante el simple cambio en el contenido del Sitio. Asimismo, Kanguru tiene la facultad de dejar de ofrecer, sin previo aviso y en cualquier momento, el acceso a los Productos publicados. En caso de que un Producto se encuentre en oferta, Kanguru lo indicará en forma clara, detallando el tiempo de validez de la misma.
                </p>
                <p>
                    <span className="underline">Descripción del Producto.</span> Toda la información acerca de los Productos, mediante el Sitio, se proporciona con fines informativos. Es responsabilidad única del Usuario, leer y comprender las etiquetas del Producto e instrucciones de uso, y, en su caso, tomar las precauciones necesarias. Kanguru no garantiza que las descripciones o su contenido esté libre de errores. Si el Usuario Cliente considera que un Producto no cumple con su descripción, el único recurso del que dispone será devolverlo, siempre y cuando sea devuelto sin haberse utilizado y conforme a la Política de Devoluciones aquí contenida.
                </p>
                <p>
                    <span className="underline">Disponibilidad del Producto.</span> Los Productos de Kanguru al ser publicados por los Usuarios Proveedores, la disponibilidad depende directamente de factores de ellos mismos y  que son ajenos al control de Kanguru. No obstante lo anterior,  El Usuario Proveedor manifiesta su compromiso de mantener una producción adecuada a la demanda de los Productos que estén en el Sitio, que permita satisfacer las necesidades del  Usuario Cliente, sin vulnerar la calidad comprometida del Producto.
                </p>
                <p>
                    <span className="underline">Pedido de los Productos.</span>
                </p>
                <p>
                    Para realizar un Pedido con método de pago contra entrega, el Usuario Cliente podrá proporcionar sus datos personales al Usuario Afiliado para que el mismo pueda realizar el pedido dentro de la plataforma. Dichos datos proporcionados por el Usuario Cliente serán Nombre Completo, Correo Electrónico, Número de Teléfono y Dirección de Envío. Una vez que el Usuario Afiliado tenga los datos y confirme el pedido con el Usuario Cliente y el monto total de la orden, este procederá a Realizar el Pedido ingresando los datos proporcionados por el Usuario Cliente. Tras realizar el pedido el Usuario Afiliado será redirijido a una página de confirmación de orden donde se generará un mensaje electrónico automático confirmando el pedido y el monto que tendrá que pagar el Usuario Cliente en efectivo al recibir el pedido.
                </p>
                <p>
                    Para realizar un Pedido con método de pago contra entrega a través del Catálogo Digital de un Usuario Afiliado, el Usuario Afiliado le podrá compartir su Catálogo Digital de productos al Usuario Cliente. El Usuario Cliente podrá encontrar los (el) producto(s)  deseado(s) dentro del Catalogo Digital del Usuario Afiliado, seleccionarlo, y seleccionar el botón de “Realizar Pedido”.  Se abrirá un modal donde el Usuario Cliente ingresará sus datos personales para completar su compra. Dichos datos serán Nombre Completo, Correo Electrónico, Número de Teléfono, Dirección de Envío. Tras realizar el pedido el Usuario Cliente será redirijido a una página de confirmación de orden donde se generará un mensaje electrónico automático confirmando el pedido y el monto que tendrá que pagar el Usuario Cliente en efectivo al recibir el pedido. 
                </p>
                <p>
                    Para realizar un Pedido con método de pago contra entrega a través del enlace de pago electrónico de un Usuario Afiliado, el Usuario Afiliado le podrá compartir un enlace de pago electrónico al Usuario Cliente. Al seleccionar el enlace de pago, el Usuario Cliente será redirijido a una página donde podrá encontrar el producto deseado, elegir la cantidad, y seleccionar el botón de “Realizar Pedido”. Se abrirá un modal donde el Usuario Cliente ingresará sus datos personales para completar su compra. Dichos datos serán Nombre Completo, Correo Electrónico, Número de Teléfono, Dirección de Envío. Tras realizar el pedido el Usuario Cliente será redirijido a una página de confirmación de orden donde se generará un mensaje electrónico automático confirmando el pedido y el monto que tendrá que pagar el Usuario Cliente en efectivo al recibir el pedido. 
                </p>
                <p>
                    Para realizar un Pedido con método de pago en línea a través del catálogo digital de un Usuario Afiliado, el Usuario Afiliado le podrá compartir su Catalogo Digital de productos al Usuario Cliente. El Usuario Cliente podrá encontrar los (el) producto(s)  deseado(s) dentro del catálogo electrónico del Usuario Afiliado, seleccionarlo, y seleccionar el botón de “Comprar Ahora”. El Usuario Cliente será redirjido a una sesión de checkout de Stripe, el servicio de procesador de pagos de la plataforma, donde ingresará sus datos personales para completar su compra. Una vez que Stripe apruebe la compra, el Usuario Cliente será redirijido a una página de confirmación de orden donde se generará un mensaje electrónico automático confirmando el pedido y el monto que tendrá que pagar el Usuario Cliente en efectivo al recibir el pedido.
                </p>
                <p>
                    Para realizar un Pedido con método de pago en línea a través del enlace de pago electrónico de un Usuario Afiliado, el Usuario Afiliado le podrá compartir su enlace de pago electrónico al Usuario Cliente. Al seleccionar el enlace, el Usuario Cliente será redirjido a una página de checkout del producto para completar su compra. Al seleccionar el botón de “Comprar Ahora”, el Usuario Cliente será redirjido a una sesión de checkout de Stripe, el servicio de procesador de pagos de la plataforma, donde ingresará sus datos personales para completar su compra. Una vez que Stripe apruebe la compra, el Usuario Cliente será redirijido a una página de confirmación de orden donde se generará un mensaje electrónico automático confirmando el pedido y el monto que tendrá que pagar el Usuario Cliente en efectivo al recibir el pedido. 
                </p>
                <p>
                    Los datos registrados en el Sitio constituyen la prueba máxima de todas las transacciones realizadas. En caso de existir conflicto alguno entre Kanguru y el  Usuario Cliente, en relación a cualquier Pedido y Compra realizada en el Sitio, los datos registrados por  Kanguru serán considerados prueba irrefutable del contenido de dicha transacción. 
                </p>
                <p>
                    <span className="underline">Precio.</span> Salvo que se indique lo contrario, el precio de lista o precio sugerido que aparece en cada Producto representa el precio de venta al público. Salvo que se indique de otra manera, todos los precios publicados en el Sitio se encuentran expresados en pesos mexicanos, y se presentan con el Impuesto al Valor Agregado (IVA) incluido. Kanguru se reserva el derecho de modificar el precio en cualquier momento y sin necesidad de previo aviso. Los precios y promociones realizadas en la tienda al público no aplicarán en el Sitio, a menos que se indique lo contrario, y viceversa. En caso de que, por error, el Precio publicado en el Sitio sea incorrecto, se notificará al Usuario tan pronto como sea posible, y se le otorgará la opción de seguir con la Compra o cambiar de Producto por uno con características y precio similar al solicitado originalmente. Al monto total del Pedido se le añadirá los gastos de envío, que son calculados sobre el(los) Producto(s) contenidos en el mismo.
                </p>
                <div>
                    <p>
                        <span className="underline">Métodos de Pago.</span> El Usuario Cliente se obliga a realizar el pago de los Productos incluidos en su Pedido en pesos mexicanos. Kanguru pone a disposición del Usuario Cliente los métodos de pago siguientes:
                    </p>
                    <ol type="1">
                        <li>Con cargo a tarjeta de crédito o débito, ya sea Visa, Mastercard o American Express, a través del servidor de Stripe y mediante el llenado del formulario dispuesto para este efecto en el Sitio. Strip es operado por un tercero ajeno a Kanguru, por lo que  Kanguru no es responsable de errores, fallas, cargos extras u otros que se generen en Stripe.</li>
                        <li>Pago contra entrega en efectivo directamente al servicio de mensajería. </li>
                    </ol>
                </div>
                <p>
                    Cualquier cargo operativo, o tasa establecidos por los servicios ofrecidos a través de servidores o portales de terceros, están completamente regulados por términos y condiciones dispuestos por dichos terceros o por las leyes aplicables, por lo cual el Usuario deslinda en este acto a Kanguru de cualquier responsabilidad respecto a la forma, tiempo y cantidad en que sean efectuados los cobros, aún cuando dicho cobro sea considerado excedido, indebido o que viole algún derecho del Usuario. De esta forma, el Usuario  se obliga a mantener en paz y a salvo en todo momento a Kanguru de cualquier proceso judicial que se llegare a entablar por razón del uso de servidores o portales de terceros. Cualquier cambio en la forma de pago del Usuario, deberá ser realizado en línea. Dicho cambio podrá generar la interrupción temporal del Pedido, mientras se realiza la verificación de la nueva información otorgada.
                </p>
                <p>
                    El Usuario Cliente reconoce y acepta que Kanguru podrá usar los servicios de cobranza de terceros, con fines de cobro de cualquier adeudo pendiente de pago por razón de los Productos, para lo cual, el Usuario se obliga a colaborar de buena fe para la liquidación total de los adeudos.
                </p>
                <p>
                    <span className="underline">Facturación y Impuestos.</span> El Usuario Proveedor y el Usuario Cliente se obligan a dar cumplimiento a la legislación fiscal que les sea aplicable de los actos jurídicos que surjan en sus relaciones en virtud con Kanguru y pagar todas las contribuciones e impuestos que sean aplicables. Kanguru realizará las retenciones y entero de los montos de Impuestos que sean aplicables, sin embargo, el Usuario es responsable de pagar todos los Impuestos aplicables relacionados con el uso del Sitio o ingresos provenientes de la misma. 
                </p>
                <p>
                    En caso de que el Usuario Cliente requiera comprobante fiscal, deberá solicitar el mismo por escrito al Correo Electrónico para que este sea generado. Es condición imprescindible para la emisión de dicho comprobante, que el Usuario Cliente compruebe fehacientemente el pago de los Productos en cuestión. El Usuario Cliente acepta que Kanguru podrá contactarle periódicamente, vía correo electrónico a la dirección de correo electrónico asociada a su pedido, con avisos de facturación y otras comunicaciones relacionadas con los Productos, ya sean por promoción, mejora, cambios, etc.
                </p>
                <p>
                    <span className="underline">Territorio.</span> El territorio de entrega de los Productos es exclusivamente en los Estados Unidos Mexicanos. 
                </p>
                <p>
                    <span className="underline">Envío de la Orden.</span> Los gastos de envío son calculados y añadidos al Precio Base del producto, de tal modo que el precio público del producto ya incluye el costo de envío. Kanguru enviará la Orden del Cliente bajo el servicio de paquetería contratado con un tercero. Los tiempos de entrega serán de 7 (siete)) días hábiles a partir de ordenar el Producto respectivo. Cualquier variación será notificada al correo electrónico del Usuario Afiliado. En caso de que el Cliente no reciba su Orden en el tiempo antes mencionado, deberá notificarlo al Correo Electrónico. Kanguru no cuenta con una garantía de tiempo de entrega. Los tiempos de entrega son estimados y dependerán de la distancia recorrida por el paquete, factores climáticos, desastres naturales, eventos públicos que impidan el libre paso de la unidad de la paquetería, la disponibilidad del  Usuario Cliente al momento de la entrega y que los datos de entrega se hayan proporcionado completos y correctos, así como de casos fortuitos o de fuerza mayor.
                </p>
                <p>
                    <span className="underline">Recepción de la Orden.</span> A la recepción de la Orden por parte del Usuario Cliente, éste  deberá comprobar que los mismos cumplen con el Pedido. Cualquier anomalía presentada en los Productos deberá ser notificado al Departamento de Atención al Cliente mediante notificación al Correo Electrónico, detallando el número de Orden asignado, el defecto, daño, o error de Producto entregado, junto con fotografías claras que sean evidencia de lo que indica el Usuario Cliente. Para lo cual, el Usuario Cliente se tendrá a lo dispuesto en la Política de Devolución de Kanguru.
                </p>
                <p>
                    <span className="underline">Política de Cambios y Devoluciones.</span> El Usuario Cliente gozará de un plazo de devolución del(los) Producto(s) de 3  (tres) días naturales, contados a partir de la fecha de entrega del Pedido. Los productos deben ser devueltos en el mismo estado en el que fueron recibidos, eliminando todos los datos personales del Usuario Cliente y/o terceros que puedan estar presentes en dicho artículo, ya que Kanguru no se hará responsable de salvaguardar dicha información. De igual forma, el Usuario Cliente reconoce que cualquier artículo que haya sido incluido por error dentro del paquete de devolución, no será responsabilidad de Kanguru salvaguardar la integridad del mismo. En caso de encontrar evidencia de uso o alteración flagrante del Producto,  Kanguru y el Usuario Proveedor no tendrán obligación alguna de procesar el cambio de producto. Así mismo, si el motivo de la devolución del Producto es distinto a “producto dañado, defectuoso o incorrecto”,  Kanguru no tendrá obligación de procesar el cambio ni ningún otro servicio relacionado con el Producto.
                </p>
                <p>
                    Para generar un cambio, se requiere que el cliente envíe a devoluciones@kanguru.mx la descripción del problema,  fotos y video del producto y empaque. Una vez que Kanguru acepte que el producto llegó defectuoso,  se le enviará una guía de envío al Usuario Cliente para devolver su pedido. Dicha guía de envío será generada y pagada  por Kanguru. Sin embargo, el costo de la guía será deducido del siguiente pago al Usuario Proveedor. Una vez que el Usuario Proveedor reciba el producto defectuoso, Kanguru generará la guía de envío para que el Usuario Proveedor le envíe el producto nuevo al Usuario Cliente.  Dicha guía de envío será generada y pagada  por Kanguru. Sin embargo, el costo de la guía será deducido del siguiente pago al Usuario Proveedor. En total, el Usuario Proveedor terminará absorbiendo el costo de 2 guías de envío, la primera generada para que el Usuario Cliente le devuelva el producto defectuoso al Usuario Proveedor, y la segunda para que el Usuario Proveedor le envíe al Usuario Cliente el (los) producto(s)  nuevo(s). 
                </p>
                <p>
                    <span className="underline">Política de Reembolsos.</span> En caso que el Usuario Cliente haga un pedido con método de pago “Pago en Línea” y este (Producto)  no cumpla con los tiempos de entrega (14 días,) el Cliente  tiene derecho a pedir un reembolso del total pagado por el Producto. Para solicitar un reembolso es necesario enviar a contacto@kanguru.mx una  solicitud de reembolso. Se envía número de rastreo del pedido y paquetería. Cuando esta sea evaluada, si es aceptada (solicitud), se deposita a la cuenta bancaria del cliente la cantidad pagada por producto.  
                </p>
                <p>
                    <span className="underline">Política de Extravío de Productos. </span>En caso que un Producto(s) haya(n) sido “extraviado(s)” / “perdido(s)” por parte de la paquetería el Usuario Proveedor será recompensado por el monto igual al  precio definido por el Usuario Proveedor al publicar el producto. 
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">4. CONTENIDOS</h5>
                <p>
                    <span className="underline">Uso correcto de los Contenidos.</span> El Usuario se compromete a: 
                </p>
                <ol type="a">
                    <li>utilizar el Sitio y sus Contenidos de acuerdo a las leyes aplicables y de orden público, absteniéndose de realizar acto que menoscabe, deteriore, inutilice o dañe la imagen y/o información divulgada por Kanguru o de alguna manera lesione derechos o intereses de terceras personas, vinculadas directa o indirectamente a éste; </li>
                    <li>no copiar, difundir, modificar, reproducir, distribuir o utilizar de manera alguna con o sin fines de lucro los contenidos y los elementos utilizados en el Sitio, a menos que se cuente con la autorización expresa y por escrito de Kanguru; </li>
                    <li>no modificar o manipular las marcas, logotipos, avisos comerciales, nombres comerciales y signos distintivos en general de Kanguru, del Sitio o de las personas vinculadas con Kanguru (salvo que cuente con su autorización por escrito); </li>
                    <li>suprimir, eludir o modificar los Contenidos y los elementos utilizados en el Sitio, así como los dispositivos técnicos de protección, o cualquier mecanismo o procedimiento establecido en el Sitio.</li>
                </ol>
                <p>
                    Queda excluida de los puntos anteriores, aquella información generada a través del Sitio para uso y manejo del Usuario, misma que podrá ser impresa y/o copiada para los intereses que más convengan al mismo. En caso de que el Usuario sea una persona moral, se apegará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor. El Usuario reconoce y acepta que el uso del Sitio y de los Contenidos, es bajo su exclusiva y estricta responsabilidad, por lo que  Kanguru no será, en ningún momento y bajo ninguna circunstancia, responsable por cualquier desperfecto o problema que se presente en el equipo de cómputo (hardware) o programas de cómputo (software) que utilice el Usuario para acceder o navegar en cualquier parte del Sitio. 
                </p>
                <p>
                    Kanguru tiene derecho a realizar, durante intervalos temporales definidos, campañas promocionales para promover el registro de nuevos miembros en su Sitio. Kanguru se reserva el derecho de modificar los términos y condiciones de las compras de Productos, así como de proceder a la exclusión de cualquiera de los mismos. Kanguru declara que todos los Contenidos, y los elementos utilizados en el Sitio, se encuentran debidamente registrados y protegidos bajo las autoridades y leyes correspondientes en México. El Usuario se obliga a respetar todos los derechos contenidos en el Aviso de Derecho de Autor establecido en el Sitio.
                </p>
                <p>
                    <span className="underline">Sitios y contenidos ajenos al Sitio y a los Contenidos de Kanguru.</span> Kanguru podrá hacer uso de su derecho de publicación de cualquier material informativo y/o de sitios o subsitios propiedad de terceros, vinculados o no a Kanguru, que considere de interés para los Usuarios. No obstante lo anterior, Kanguru se deslinda de toda responsabilidad, del acceso y/o uso que realicen los Usuarios de la información ahí contenida y/o del uso, origen y destino de la información que se desprenda de ligas distintas (vínculo, hipervínculo, link). Toda publicación realizada dentro del Sitio, por parte de los Usuarios, no genera obligación de pago ante terceros por razón de promoción, publicación y/o manejo de información y/o imagen, a menos que se cuente con un contrato previamente firmado con Kanguru.
                </p>
                <div>
                    <p>
                        <span className="underline">Negación y retiro de acceso al Sitio y los Contenidos.</span> Kanguru se reserva el derecho a negar o retirar el acceso al Sitio, o sus Contenidos, en cualquier momento, sin responsabilidad alguna para Kanguru y sin previo aviso al Usuario o usuarios que incumplan de manera total o parcial con las condiciones aquí establecidas y/o que realicen acciones o actos tendientes a: 
                    </p>
                    <ol type="1">
                        <li>“asediar" o de otra manera acosar o molestar a otros Clientes;</li>
                        <li>hacerse pasar como representante o empleado de Kanguru, realizando declaraciones falsas o de otro modo erróneas de su vinculación con  Kanguru;</li>
                        <li>recopilar o almacenar datos personales de otros usuarios en relación con la conducta y las actividades prohibidas;</li>
                        <li>falsificar encabezados o manipular identificadores del Sitio, con la finalidad de ocultar el origen de los Contenidos;</li>
                        <li>cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo, cualquier contenido respecto del cual no tenga derecho a transmitir, en virtud de los términos contenidos en la Ley Federal de Protección a la Propiedad Industrial (“LFPPI”), la Ley Federal del Derecho de Autor (“LFDA”), y la Ley Federal de Protección de Datos Personales en Posesión de Particulares  (“LFPDPPP”) o de relaciones contractuales protegidos por convenios de confidencialidad y no divulgación; </li>
                        <li>cargar, publicar, enviar por correo electrónico, transmitir o proporcionar de otro modo, materiales que contengan virus informáticos o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad del software, hardware o de equipos de telecomunicaciones conectados al Sitio; </li>
                        <li>hacer uso del Sitio de una manera que pudiera dañar, deshabilitar, recargar o alterar los servidores de Kanguru o las conexiones de redes;</li>
                        <li>ignorar requisitos, procedimientos, políticas o normas de redes conectadas al Sitio que pudieran interferir con el uso y goce del Sitio por parte de cualquier tercero; y</li>
                        <li>acceder de manera no autorizada a cuentas, sistemas informáticos o redes conectadas a los servidores de Kanguru, a través de ataques propios de piratas informáticos, el descifrado de contraseñas o cualquier otro método para obtener o tratar de obtener materiales o información con cualquier medio que no se ofrece intencionalmente a través del Sitio.</li>
                        <li>Cualquier Producto que infrinja con la legislación aplicable, por ejemplo, artículos falsos, que infrinjan con derechos de autor o marcas registradas. </li>
                    </ol>
                </div>
                <p>
                    El Usuario acepta indemnizar y mantener en paz y a salvo a Kanguru y sus funcionarios, agentes, empleados, socios, proveedores y licenciantes frente a cualquier reclamo o demanda, así como a cubrir los honorarios razonables de abogados, que promueva cualquier tercero en contra de Kanguru a causa del contenido que el Usuario envíe, publique, transmita o proporcione de un modo distinto al previsto en el Sitio. Lo anterior, sin perjuicio del derecho de Kanguru de realizar las acciones judiciales necesarias para reclamar los daños y perjuicios que dichas acciones por parte del Ususario pudieran causarle. 
                </p>
                <p>
                    <span className="underline">Responsabilidad respecto a los Contenidos.</span> Kanguru no asume responsabilidad alguna derivada, de manera enunciativa más no limitativa de: (i) la utilización que el Usuario pueda hacer de los materiales de este Sitio, o de los Contenidos, o de los sitios web de enlace, ya sean prohibidos o permitidos, en infracción de los derechos de propiedad intelectual y/o industrial de contenidos de la web o de terceros; (ii) los eventuales daños y perjuicios al Usuario causados por un funcionamiento normal o anormal de las herramientas de búsqueda, de la organización o la localización de los Contenidos y/o acceso al Sitio y, en general, de los errores o problemas que se generen en el desarrollo o instrumentación de los elementos técnicos que el Sitio facilite al Usuario; (iii) los contenidos de aquellas páginas a las que el Usuario pueda acceder desde enlaces incluidos en el Sitio, ya sean autorizados o no; (iv) los actos u omisiones de terceros, independientemente de la relación que dichos terceros pudieran tener con  Kanguru; (v) el acceso de menores de edad a los Contenidos, así como el envío de información personal que estos pudieran realizar; (vi) las comunicaciones o diálogos en el transcurso de los debates, foros, chats y comunidades virtuales que se organicen a través de o en torno al Sitio de enlace, ni responderá, por tanto, de los eventuales daños y perjuicios que sufra el Usuario a consecuencia de dichas comunicaciones y/o diálogos; etc. 
                </p>
                <p>
                    <span className="underline">Responsabilidad respecto a fallas tecnológicas.</span> Kanguru no será responsable en forma alguna, cuando se produzcan: (i) errores o retrasos en el acceso al Sitio a la hora de introducir los datos en el formulario de solicitud, la lentitud o imposibilidad de recepción por parte de los destinatarios de la confirmación de la solicitud o cualquier anomalía que pueda surgir cuando tales incidencias sean debidas a problemas en la red Internet, caso fortuito o fuerza mayor o cualquier otra contingencia imprevisible ajena a Kanguru; (ii) fallos o incidencias que pudieran producirse en las comunicaciones, ya sea borrándose o por transmisiones incompletas, de manera que no se garantiza que los servicios del Sitio estén constantemente operativos; (iii) de los errores o daños producidos al Sitio por un mal uso del servicio por parte del Usuario; (iv) de la no operatividad o problemas en la dirección de correo electrónico facilitada por el Usuario para el envío de la confirmación de la solicitud realizada. En todo caso, Kanguru se compromete a solucionar los problemas que puedan surgir y a ofrecer todo el apoyo necesario al Usuario para llegar a una solución rápida y satisfactoria de la incidencia. Asimismo, Kanguru tiene derecho a realizar durante intervalos temporales definidos, campañas promocionales para promover el registro de nuevos miembros en su servicio. Kanguru se reserva el derecho de modificar las condiciones de comercio electrónico, así como proceder a la exclusión de cualquiera de los términos allí contenidos.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">5. DATOS DE CARÁCTER PERSONAL</h5>
                <p>
                    En cumplimiento a los términos previstos en la LFPDPPP,  Kanguru, como responsable del tratamiento de sus datos personales, hace del conocimiento del Usuario que la información que el Usuario provea en este Sitio será tratada de conformidad con lo indicado en el Aviso de Privacidad contenido en el Sitio. Para utilizar o gozar de algunos de los Contenidos, es necesario que el Usuario proporcione previamente a Kanguru ciertos datos de carácter personal (<span className="underline">"Datos Personales"</span>). 
                </p>
                <p>
                    Al acceder al Sitio, o a cualquiera de los Contenidos en que los Datos Personales son requeridos, el Usuario está autorizando a Kanguru a realizar análisis y estudios con base en ellos. El Usuario se obliga a proporcionar Datos Personales verdaderos y fidedignos. En caso de que el Usuario diera información falsa o confusa,  Kanguru no asume responsabilidad alguna de los resultados que dichos actos ocasionen al Usuario, teniendo la facultad de negar el acceso al Sitio y sus Contenidos, sin perjuicio de que pueda requerir las indemnizaciones a que hubiere lugar. 
                </p>
                <p>
                    El Usuario reconoce y acepta que, para el cumplimiento del objetivo del Sitio, será necesario que Kanguru recabe Datos Personales, a fin de incluirla en formatos, listados, bases de datos u otros medios físicos, digitales y/o electrónicos, para llevar a cabo el registro adecuado de los mismos y ofrecer los Productos. Kanguru no se responsabiliza de las consecuencias que pudieran derivarse de la omisión, consciente o inconsciente, hecha por parte del Usuario al respecto.
                </p>
                <p>
                    Por tal motivo, mediante la aceptación a los presentes Términos, el Usuario renuncia expresamente a presentar cualquier tipo de reclamación, demanda, juicio o procedimiento legal ante cualquier autoridad mexicana o extranjera en contra de Kanguru, así como cualquier persona relacionada y/o afiliada a Kanguru, incluyendo, sin limitar, directores, apoderados, representantes, administradores, empleados, accionistas y/o agentes, presentes o anteriores, por cualquier acto que se derive, o pudiere derivar, del uso del Sitio y de los Servicios, o de cualquier servicio derivado de dicho uso.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">6. INDICADORES DE DATOS</h5>
                <p>
                    La información que el Usuario provea en el Sitio, real o histórica, se procesa y ordena, para que genere indicadores de datos, mismos que  Kanguru podrá usar para tomar decisiones pertinentes a su negocio, siempre de manera estadística y no individualizada. El Usuario, en este acto, autoriza el acceso a Kanguru a la información proporcionada y generada en el Sitio, en términos del presente documento y del Aviso de Privacidad.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">7. USO DE COOKIES</h5>
                <p>
                    Kanguru informa al Usuario que, mediante el uso de cookies y tecnologías similares, busca: i) garantizar la mejor experiencia posible en el Sitio; y ii) proporcionar al Cliente información sobre sus preferencias de servicios y de mercadeo, ayudándolo así a obtener la información adecuada. En caso de que el Cliente requiera de mayor información respecto al uso de cookies y tecnologías similares,  Kanguru pone a su disposición la Política de Uso de Cookies. 
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">8. INDEMNIZACIÓN</h5>
                <p>
                    El Usuario acepta indemnizar y sacar en paz y a salvo a Kanguru y sus consejeros, funcionarios, empleados, y socios de y contra todas y cada una de las pérdidas, responsabilidades, demandas, daños, costos, reclamaciones y gastos, incluyendo honorarios de abogados, que surjan de o estén relacionados con su uso del Sitio o nuestros Servicios, que promueva cualquier tercero ajeno a Kanguru, incluyendo al Usuario, en virtud de cualquier violación de los Términos y Condiciones o de la legislación aplicable por parte del Usuario o los representantes del mismo. Lo anterior, sin perjuicio del derecho de Kanguru de realizar las acciones judiciales necesarias para reclamar los daños y perjuicios que dichas acciones por parte del Usuario pudieran causarle. 
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">9. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD</h5>
                <p>
                    El Usuario es el único responsable del uso que haga al Sitio y su Contenido. El Usuario reconoce que la información del SItio y de los servicios de Kanguru se proporcionan “como están”, sin ninguna garantía expresa o implícita de comerciabilidad o de aptitud para un fin determinado. Kanguru no garantiza la precisión ni la integridad de la información, textos, gráficos, enlaces u otros elementos contenidos en el Sitio o Contenido. Kanguru no garantiza la operación ininterrumpida o libre de todo error del Sitio y/o su Contenido. Puesto que toda la información referida en el Sitio y su Contenido se encuentra en la nube, Kanguru no controla ni garantiza la ausencia de virus en los Contenidos, ni la ausencia de otros elementos en los Contenidos que puedan producir alteraciones en el sistema informático del Usuario (software y/o hardware) o en los documentos electrónicos almacenados en su sistema informático. 
                </p>
                <p>
                    Todo material descargado u obtenido de un modo distinto al previsto en el Sitio, será bajo responsabilidad y riesgo único del Usuario, respecto de los daños que pudiera causar en el sistema informático del dispositivo a través del cual realice su conexión y/o la pérdida de datos que derive de la descarga de ese material. En ningún caso, ni  Kanguru ni sus proveedores serán responsables de daño alguno derivado del uso del Sitio o Contenido, o de no poder usarlos (EN PARTICULAR, SIN LIMITACIÓN ALGUNA, DE LOS DAÑOS DIRECTOS O INDIRECTOS, MORALES, INCIDENTALES, EXCESIVOS, REMOTOS Y/O EVENTUALES, PERJUICIOS, LUCRO CESANTE, INTERRUPCIÓN DE LA ACTIVIDAD COMERCIAL O PÉRDIDA DE INFORMACIÓN O DATOS Y/O INFRACCIONES DE SEGURIDAD), aún cuando se hubiera advertido a Kanguru de dicha posibilidad. La responsabilidad máxima de Kanguru, por daños  perjuicios causados por cualquier actividad relacionada con el Sitio o un Pedido, estará limitada a una cantidad equivalente al precio contenido en el Pedido que originó el reclamo o causal de reclamación.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">10. COMPATIBILIDAD DE LOS DISPOSITIVOS ELECTRÓNICOS</h5>
                <p>
                    El Usuario será responsable de obtener los dispositivos o hardware que sean compatibles con el Sitio, toda vez que  Kanguru no garantiza que este funcione correctamente en cualquier dispositivo. De igual manera, el Usuario acepta no utilizar dispositivos, software o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones del Sitio, como en las bases de datos y/o a la información que se contenga en el mismo.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">11. DURACIÓN Y TERMINACIÓN</h5>
                <p>
                    El Sitio y los Contenidos tienen una duración indefinida. Sin embargo, Kanguru podrá dar por terminado o suspender temporalmente, en cualquier momento y sin necesidad de previo aviso, el Sitio y/o cualquiera de los Contenidos.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">12. MANTENIMIENTO</h5>
                <p>
                    Para llevar a cabo trabajos de mantenimiento,  Kanguru se reserva el derecho de suspender el acceso y/o modificar el Contenido, así como a eliminar o deshabilitar el acceso al Sitio, sin previo aviso. El acceso al Sitio depende de la disponibilidad de la red que tenga el Usuario, por lo que Kanguru no será responsable por cualquier imposibilidad de acceder a la misma, derivada de circunstancias que se encuentren fuera de control de Kanguru, así como por caso fortuito o de fuerza mayor. Kanguru, cuando lo considere necesario para el correcto funcionamiento del Sitio, realizará los parches, actualizaciones, correcciones de “bugs” y mejoras menores.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">13. SOPORTE</h5>
                <p>
                    Kanguru ofrece al Usuario el servicio de soporte técnico y orientación básica para la utilización de las herramientas y las funcionalidades del Sitio, pudiendo ser por vía Chat en Línea, correo electrónico, o cualquier otro medio que Kanguru considere conveniente y factible, en los horarios indefinidos que de igual forma designe para tal efecto, mediante previo aviso. Este servicio no tendrá ningún costo adicional. Asimismo, el Usuario que hubiere solicitado el Soporte, acepta y autoriza a Kanguru para tener acceso pleno a toda la información proporcionada en el Sitio, sin ninguna limitación. En este sentido y en beneficio del Usuario, Kanguru se obliga a guardar plena secrecía y confidencialidad, respecto a la información a la que tenga acceso.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">14. PROPIEDAD INDUSTRIAL Y DERECHO DE AUTOR</h5>
                <p>
                    Kanguru autoriza al Usuario a utilizar el Sitio, exclusivamente bajo los términos aquí expresados, sin que ello implique que concede al Usuario licencia o autorización alguna, o algún tipo de derecho distinto al antes mencionado, respecto de la Propiedad Industrial y Derecho de Autor de Kanguru,  entendiéndose como ello: todas las marcas registradas y/o usadas en México o en el extranjero por  Kanguru; todo derecho sobre invenciones (patentadas o no), diseños industriales, modelos de utilidad, información confidencial, nombres comerciales, secretos industriales, avisos comerciales, reservas de derechos, nombres de dominio; así como todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por derechos de autor y demás formas de propiedad industrial o intelectual reconocida o que lleguen a reconocer las leyes correspondientes.
                </p>
                <p>
                    El Usuario reconoce y acepta que  Kanguru es legítimo propietario, o tiene los derechos necesarios, sobre el Sitio, incluidos los nombres comerciales de Kanguru, marcas comerciales, marcas de servicio, logotipos, nombres de dominio y otras características distintivas de la marca contenidas en ellos (las <span>"Marcas Registradas de Kanguru"</span>), independientemente de que esos derechos estén registrados o no, y de cualquier lugar del mundo en el que puedan existir esos derechos, y que están protegidos por las leyes y tratados internacionales sobre propiedad industrial y derecho de autor. Por lo anterior, el Usuario acepta que las Marcas Registradas de Kanguru no podrán ser objeto de copia, reproducción, modificación, publicación, carga, envío, transmisión o distribución en modo alguno. Salvo indicación expresa en contrario en este documento,  Kanguru no concede al Usuario ningún derecho expreso ni implícito en virtud de patentes, derecho de autor, marcas comerciales o información de secretos industriales. El Usuario reconoce y conviene que el Sitio, así como todos los diseños del mismo, son y, serán en todo momento, propiedad de Kanguru.
                </p>
                <p>
                    <span className="underline">Retroalimentación.</span> En caso de que el Usuario proporcione algún comentario a Kanguru respecto de la funcionalidad y el rendimiento del Sitio (incluida la identificación de posibles errores y mejoras), en este acto, el Usuario autoriza a Kanguru para que haga uso, sin restricción, de todos los derechos, títulos e intereses sobre los comentarios expresados. Lo anterior, sin que ello se considere como un derecho moral del Usuario para requerir participación o retribución monetaria alguna, o restricción en el uso de dichos comentarios para su explotación por parte de Kanguru.
                </p>
            </div>
            <div>
                <h5 className="mt-5 terminosCondicionesEnfasis">15. OTRAS DISPOSICIONES</h5> 
                <ol type="1">
                    <li><span className="underline">Validez:</span> El Usuario acepta que una versión impresa de los presentes Términos, y de cualquier comunicación enviada y/o recibida en forma electrónica será admisible como medio probatorio en cualquier procedimiento judicial y/o administrativo.</li>
                    <li><span className="underline">Divisibilidad.</span> En caso de que cualquier término, condición o estipulación contenida en estos Términos se determine ineficaz, ilegal o sin efecto, el mismo podrá ser excluido del cuerpo del presente y el restante continuará en vigor y efecto en forma tan amplia como en derecho proceda. </li>
                    <li><span className="underline">Derechos.</span> Cualquier derecho que no se haya conferido expresamente en este documento, se entiende reservado a Kanguru.</li>
                    <li><span className="underline">Actualizaciones.</span> Kanguru podrá revisar y actualizar, en cualquier momento, estos Términos, manteniendo en todo momento el acceso libre a todo usuario que desee conocerlo. Kanguru se reserva el derecho de modificar, en cualquier momento, la presentación y configuración del Sitio, así como estos Términos. Por ello,  Kanguru recomienda al Usuario dar lectura atenta cada vez que acceda al Sitio. No obstante lo anterior, el Usuario siempre dispondrá estos Términos en el Sitio, de forma visible y accesible en cualquier momento. Algunas cláusulas de estos Términos pueden estar supeditadas a términos y condiciones designados expresamente y que se encuentren en el SItio o en determinados sitios web.</li>
                    <li><span className="underline">Ley aplicable y Jurisdicción.</span> En todo lo relacionado con la interpretación y cumplimiento de lo aquí dispuesto, por el solo hecho de acceder al Sitio, el Usuario acepta someterse a las legislación federal de México y a la jurisdicción de los tribunales competentes en la Ciudad de México; renunciando a cualquier otra jurisdicción que, por razón de sus domicilios presentes o futuros, pudiese corresponderles.</li>
                    <li><span className="underline">Forma digital, electrónica o en línea.</span> La Partes acuerdan que la forma para perfeccionar el acuerdo de voluntades entre ellas es el de formato Digital, Electrónico o en Línea, en donde bastará manifestar su voluntad por medio de la aceptación de los presentes Términos, así como proporcionar los datos personales e información bancaria en el Sitio o en las distintas aplicaciones de los licenciantes, sin requerir estampar la firma en documento alguno.</li>
                    <li><span className="underline">Aceptación de lo Términos y Condiciones.</span> El Usuario reconoce que, mediante el ingreso y uso del Sitio, manifiesta su aceptación expresa y adhesión a estos Términos, en la versión publicada al momento en que se acceda al Sitio, en términos de lo establecido por los artículos 1803 y 1834 Bis del Código Civil Federal, 80, 81, 89 y demás relativos y aplicables del Código de Comercio y la legislación aplicable para la República Mexicana. Es responsabilidad única y exclusiva del Usuario, leer previamente estos Términos, y sus modificaciones correspondientes, cada vez que accede al Sitio, por lo que si en cualquier momento, el Usuario no estuviera de acuerdo, total o parcialmente con estos Términos, deberá abstenerse inmediatamente de acceder al Sitio y su Contenido. Por lo anterior, con la aceptación de estos Términos, el Usuario consiente expresamente sujetarse al mismo, por lo que manifiesta haber leído el contenido de todas y cada una de las disposiciones y ratifica su contenido.</li>
                    <li><span className="underline">Acuerdo Total.</span> El Usuario reconoce y acepta que Kanguru puso a su disposición toda la información necesaria para entender el alcance y características del Sitio y los Productos. De igual forma, manifiesta que, previo al acceso al Sitio, analizó las características de este y, por consiguiente, está de acuerdo con él.</li>
                </ol>
            </div>
            <div>
                <p>Fecha de primera emisión: [Mayo 23 2022].</p>
                <p>Fecha de última modificación: [Diciembre 6 2022].</p>
            </div>
        </div>
    );
}

export default LandingTC;
