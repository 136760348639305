import React, {useEffect} from 'react';
import '../../App.css';

const VentaSuccess = () => {

    return(
        <div className="container mt-5 successOrder">
            <div>
                <h1>¡Gracias por Comprar con Nosotros!</h1>
                <h5 className="mt-4">Tú orden ha sido confirmada y te llegará dentro de 7 días hábiles.</h5>
                <h5 className="mt-4">Además, te enviamos un correo con la confirmación de tu orden. Puede que el correo llegue a tu folder de spam o promociones.</h5>
                <h5 className="mt-4">Si tienes una pregunta de tu orden nos puedes mandar un mensaje por whats app. Nuestro teléfono es: +52 1 81 1791 8821.</h5>
            </div>
            
        </div>
        
    );  
}

export default VentaSuccess;