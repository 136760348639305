import React from 'react';
import ProductoActualizadoSummary from './ProductoActualizadoSummary';
import './ProductosActualizados.css'

{/* Despliega Inventario en Dashboard de Negocio */}
const ProductoActualizadoList = ({products}) => {
    return(
        <div>
            <ul className="list-group productosActualizadosList">
                {products && products.map(product => {
                    return (
                        <div key={product.objectID}>
                            <ProductoActualizadoSummary product={product}/>
                        </div>  
                    )
                })}
            </ul>
        </div> 
    )
}

export default ProductoActualizadoList;