import React from 'react';
import StoreSummary from './StoreSummary';
import {connect} from 'react-redux';

const StoreList = (props) => {
    const {stores, storesNoLogo, bestStores, mobile} = props;
    let storeListClass;
    if(mobile){
        storeListClass = "row row-cols-2 gx-4 overflow-hidden card-catalogo products-dashboard notranslate"
    }
    else{
        storeListClass = "card-group"
    }

    return(
        <div>
            <div className={storeListClass}>
                {bestStores && bestStores.map(store => {
                    return (
                        <div key={store.objectID}>
                            <StoreSummary store={store} hasLogo={true}/>
                        </div>  
                    )
                })}
                {stores && stores.map(store => {
                    return (
                        <div key={store.objectID}>
                            <StoreSummary store={store} hasLogo={true}/>
                        </div>  
                    )
                })}
                {storesNoLogo && storesNoLogo.map(store => {
                    return (
                        <div key={store.objectID}>
                            <StoreSummary store={store} hasLogo={false}/>
                        </div>  
                    )
                })}
            </div>
        </div> 
    )
    
}

const mapStateToProps = (state, ownProps) => {
    const stores = ownProps.stores;
    const storesNoLogo = [];
    const bestStores = [];
    const bestStoresIDs = ["ZFZFJYNa0dPaukfKxu9SYjAbjNZ2", "iKsis1ukwXNldxzxUI6aLPbsRmY2", "KdapLIGuTESaIfDmmVWyXvKNbj02", "pUpcy3D7yMQZZ24QWxLU4LnfePB3", "d8qcskWNioam0TRajVU3yYQCXiN2", "p8DjDs3r7AUdYhPgFkC0F0At8Lf2", "rHzReJm4p8TZccEg0srSgRrDeVC3", "ZSZRT2zDKkc72WthH9jbFNutDtF3", "EG80TpociEb6YT6Rkv1gRercmHI2", "t8oLxehnOgNV2x3YO1mWaozlAAt1", "hYcxg5jQDCb0mPh75Z05DwClAl23", "XbHVCU07Wfh00uoa0dN8UZq7fn92", "0tskUsErRuVle7Xu8r7KOntoHLl2", "360tkOXiWdXFzI4woedRelbIuUo2", "BLl34HKMc1bcawzMHaALPqheuzx2"];
    const storesWithLogo = stores.filter((store) => {
        if(bestStoresIDs.includes(store.objectID)){
            bestStores.push(store);
        }
        else if(store.Logo && store.Logo.length == 0){
            storesNoLogo.push(store);
        }
        else if(store.Logo){
           return store;
        }
    });
    
    
    return{
        stores: storesWithLogo,
        storesNoLogo: storesNoLogo,
        bestStores: bestStores
    }
}

export default connect(mapStateToProps, null)(StoreList);