const initState = {
    catalogos: [
        {id: '1', sVendedorID:'5', bActive:true}
    ]
};

const catalogoReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_CATALOGO':
            console.log('Added Catalogo', action.catalogo);
            return state;
        case 'ADD_CATALOGO_ERROR':
            console.log('Added Catalogo Error', action.err);
            return state;
        case 'DELETE_CATALOGO':
            console.log('Deleted Catalogo', action.catalogo);
            return state;
        case 'DELETE_CATALOGO_ERROR':
            console.log('Deleted Catalogo Error', action.err);
            return state;
        case 'ADD_CATALOGO_ALL':
            console.log('Added Catalogo All', action.catalogo);
            return state;
        case 'ADD_CATALOGO_ALL_ERROR':
            console.log('Added Catalogo All Error', action.err);
            return state;
        case 'EDIT_INFO_CATALOGO':
            console.log('Edited Info Catalogo');
            return state;
        case 'EDIT_INFO_CATALOGO_ERROR':
            console.log('Edited Info Catalogo Error', action.err);
            return state;
        case 'EDIT_NOMBRE_CATALOGO':
            console.log('Edited Nombre Catalogo');
            return state;
        case 'EDIT_NOMBRE_CATALOGO_ERROR':
            console.log('Edited Nombre Catalogo Error', action.err);
            return state;
        case 'EDIT_TEMPLATE_CATALOGO':
            console.log('Edited Template');
            return state;
        case 'EDIT_TEMPLATE_CATALOGO_ERROR':
            console.log('Edited Template Error', action.err);
            return state;
        default:
            return state;
    }
}


export default catalogoReducer;