import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";

const LandingKanguPago = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Kangupago</title>
                <meta name="description" content="Compra lo que te gusta de forma segura. Siempre."/>
            </Helmet>
            <div>
                <div className="landingImgCatalogoEnLinea">
                    <div className="textCatalogo">
                        <div className="d-flex">
                            <div className="rectangle_catalogo"></div>
                            <h4 className="textCatalogo2">COMPRA LO QUE TE GUSTA DE FORMA SEGURA. SIEMPRE.</h4>
                        </div>
                        <h6 className="textCatalogo3">Paga en línea sin preocupaciones. Simple. Facil. Rápido.</h6>
                    </div>
                    
                    <img src="img/landing_kangupago_bg.png" className="landingImgKanguPago" alt=""/>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="squareLandingImgCatalogo squareLandingImgCatalogo3">
                                <h4 className="textCatalogo4 textKanguPagoFix">Compras Seguras</h4>
                                <img src="img/first_sq_catalogo.png" className="first_sq_catalogo" alt=""/>
                                <h6 className="textCatalogo5">Compra productos en línea de forma <span className="landingTextEnfasis">segura</span>. Paga con tarjeta de crédito y débito.</h6>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="squareLandingImgCatalogo2 squareLandingImgCatalogo4">
                                <h4 className="textCatalogo4 textKanguPagoFix">Garantías y Devoluciones</h4>
                                <img src="img/second_sq_catalogo.png" className="second_sq_catalogo" alt=""/>
                                <h6 className="textCatalogo5">Te ofrecemos garantía y devoluciones <span className="landingTextEnfasis">sin costo</span>. Recibe el producto que esperabas o te devolvemos tu dinero.</h6>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="landingCatalogo2"></div>
        </div>
    );
}

export default LandingKanguPago;
