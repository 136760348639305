import React from 'react';
import '../../../../App.css';
import './LandingPage.css';
import ReactPixel from 'react-facebook-pixel';
import { Link } from 'react-router-dom';

const LandingVendedores = () => {

    ReactPixel.init('908315683105799', {}, { debug: true, autoConfig: false });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');

    return(
       <div className="landingPage-content">
            <div className="landingPage-section-1">
                <div className="row">
        
                    {/* Right Content */}
                    <div className="landing-section-1-left col-md-6">
                        <h1 className="landing-section-1-left-text"><span className="signUpTitle">Gana dinero</span> vendiendo <br></br> productos desde tu casa.</h1>
                        <p className="landing-section-1-left-text-pg">Más de 2,000 productos que puedes vender en internet a cambio de comisiones. No necesitas invertir en inventario o encargarte del envío.</p>
                        <Link to="/signup-vendedor" className="btn btn-landing-section-1">Registrate Gratis</Link>
                    </div>

                    {/* Catch Image */}
                    <div className="landing-section-1-center-mobile col-md-6">
                        <img src="img/landing-1-mobile.png" alt="" className="landing-section-1-center-img"/>
                    </div>

                    {/* Catch Image */}
                    <div className="landing-section-1-right col-md-6">
                        <img src="img/landing-1-right.png" alt="" className="landing-section-1-right-img"/>
                    </div>
                </div>
                <div className="landing-section-1-center">
                    <h1 className="landing-section-1-center-text">Emprender nunca fue tan facil</h1>
                    <p className="landing-section-1-center-text-pg">Tu propio negocio a un click</p>
                </div>
            </div>
            <div className="landingPage-section-2">
                <div className="row">
                    {/* Mobile Image */}
                    <div className="landing-section-2-center-image col-md-6">
                        <img src="img/landing-section-2.png" alt="" className="landing-section-2-center-img"/>
                    </div>

                    {/* Right Content */}
                    <div className="landing-section-2-left col-md-6">
                        <h1 className="landing-section-2-left-text">No necesitas invertir o almacenar inventario</h1>
                        <div className="landing-section-bulletpoints">
                            <div className="row landing-section-bulletpoints-row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Más de 2,000 productos que puedes vender</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Proveedores Oficiales</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Precios Competitivos</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Fotos Reales</p>
                                </div>
                            </div>
                        </div>
                        <Link to="/signup-vendedor" className="btn btn-landing-section-1">Registrate Gratis</Link>
                        
                    </div>

                    {/* Catch Image */}
                    <div className="landing-section-2-right col-md-6">
                        <img src="img/landing-section-2.png" alt="" className="landing-section-2-right-img"/>
                    </div>
                </div>
            </div>
            <div className="landingPage-section-3">
                <div className="row">
                    {/* Catch Image */}
                    <div className="landing-section-3-left col-md-6">
                        <img src="img/landing-section-3-img.png" alt="" className="landing-section-3-left-img"/>
                    </div>

                    {/* Left Content */}
                    <div className="landing-section-3-right col-md-6">
                        <h1 className="landing-section-3-right-text">Despreocúpate del Envío</h1>
                        <div className="landing-section-bulletpoints">
                            <div className="row landing-section-bulletpoints-row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">El proveedor le envía el producto <br></br> al tu cliente</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Ofrecemos Pago Contra Entrega</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Recibe Notificaciones de tu Venta</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Rastrea en Tiempo Real</p>
                                </div>
                            </div>
                        </div>
                        <Link to="/signup-vendedor" className="btn btn-landing-section-1">Registrate Gratis</Link>
                    </div>
                </div>
            </div>
            <div className="landingPage-section-4">
                <div className="row">
                    {/* Catch Image */}
                    <div className="landing-section-4-center-image col-md-6">
                        <img src="img/landing-section-4.png" alt="" className="landing-section-4-center-img"/>
                    </div>

                    {/* Right Content */}
                    <div className="landing-section-4-left col-md-6">
                        <h1 className="landing-section-2-left-text">No necesitas tener <br></br>experiencia</h1>
                        <p className="landing-section-4-left-text-helper">Nuestro equipo te acompañará en todo el proceso.</p>
                        <div className="landing-section-bulletpoints">
                            <div className="row landing-section-bulletpoints-row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Asesoría Gratis</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Videos Tutoriales</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Talleres de Expertos</p>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <i class="bi bi-check-circle-fill me-2"></i>
                                    <p className="landing-section-bulletpoints-text">Grupos de Vendedores</p>
                                </div>
                            </div>
                        </div>
                        <Link to="/signup-vendedor" className="btn btn-landing-section-1">Registrate Gratis</Link>
                    </div>

                    {/* Catch Image */}
                    <div className="landing-section-4-right col-md-6">
                        <img src="img/landing-section-4.png" alt="" className="landing-section-4-right-img"/>
                    </div>
                </div>
            </div>
            <div className="landingPage-section-5">
                {/* Center Text */}
                <div className="landing-section-5-center">
                    <h1 className="landing-section-5-center-text">Testimonio</h1>
                </div>
                {/* Testimony */}
                <div className="row">
                    {/* Catch Image */}
                    <div className="landing-section-5-left col-md-6">
                        <iframe className="landing-section-5-left-video" frameborder="0" allowfullscreen
                            src="https://www.youtube.com/embed/g2aWyQGk32E">
                        </iframe>
                    </div>

                    {/* Left Content */}
                    <div className="landing-section-5-right col-md-6">
                        <p className="landing-section-5-right-text">“Kanguru me ha permitido generar ingresos extras <br></br>significativos de forma sencilla.”</p>
                        <p className="landing-section-5-right-text-name">Itzamna</p>
                        <Link to="/signup-vendedor" className="btn btn-landing-section-1 right-btn">Registrate Gratis</Link>
                    </div>
                </div>
            </div>
            <div className="landingPage-section-6">
                {/* Center Text */}
                <div className="landing-section-6-center">
                    <h1 className="landing-section-6-center-text">¡Listo para Emprender!</h1>
                    <Link to="/signup-vendedor" className="btn btn-landing-section-6">Regístrate Gratis</Link>
                </div>
            </div>
       </div>
    );
}

export default LandingVendedores;
