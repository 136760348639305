import React from 'react';
import '../../../App.css';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';

const LandingFuncionamientoVendedores = () => {
    return(
        <div>
            {/* Dynamic Description*/}
            <Helmet>
                <title>Cómo Funciona Kanguru</title>
                <meta name="description" content="Aprende cómo funcionan los envíos y cómo se cobran las comisiones."/>
            </Helmet>
            <div>
                <div className="landingClabeImgVendedor">
                    <div className="textCapacitacion">
                        <h4 className="textNosotros2">Cómo Funciona Kanguru</h4>
                    </div>
                    <img src="img/capacitacion-negocios.png" className="landingClabeImgVendedor2" alt=""/>
                </div>
            </div>
            <div className="landingPage2-Capacitacion">
                <div className="container-fluid custom-container row">
                    <div className="col-md-9">
                        {/* Qué es Kanguru */}
                        <div>
                            <h2 className="landingText-Contacto mt-5">Qué es Kanguru y cómo te puede ayudar a ganar dinero sin inversión</h2>
                            <h5 className="mt-3">Kanguru es una plataforma que te permite vender productos en internet directamente a través de un catálogo en línea o de forma individual. No necesitas invertir o comprar los productos y nosotros nos encargamos del envío y la cobranza.</h5>
                            
                            <div className="divider mt-4"></div>
                        </div>

                        {/* Cómo Funciona */}
                        <div>
                            <h2 className="landingText-Contacto mt-5">Cómo Funciona</h2>
                            <h5 className="mt-3">Dentro de Kanguru verás muchos productos disponibles, tú puedes vender estos productos en internet, por ejemplo, publicandolos en facebook marketplace. Una vez que recibes una orden, simplemente generas el pedido con la información del cliente. Puedes ofrecerle a tu cliente pagar en línea o contra entrega (al recibir su pedido).</h5>
                            <div className="divider mt-4"></div>
                        </div>

                        {/* Envíos */}
                        <div>
                            <h2 className="landingText-Contacto mt-5">Cómo Funcionan los Envíos</h2>
                            <h5 className="mt-3">Una vez que levantes el pedido, el proveedor que publicó el producto le enviará el producto al cliente. Si el método de pago del pedido es contra entrega, entonces tu cliente le pagará el monto al repartidor cuando reciba su pedido. Para ti esto es genial, porque no tienes que hacer las entregas o encargarte de cobrar.</h5>
                            <div className="divider mt-4"></div>
                        </div>

                        {/* Comisiones */}
                        <div>
                            <h2 className="landingText-Contacto mt-5">Cómo me paga Kanguru</h2>
                            <h5 className="mt-3">Una vez que la venta se complete con éxito, es decir, que tu cliente reciba y pague su orden, se verá reflejada tu comisión automaticamente en tu balance, dentro de la página de cuenta. Agrega tu número de tarjeta y retira tu comisión para que Kanguru te deposite el dinero. Los pagos se hacen los días 16 y 1 de cada mes, siendo los días corte el 15 y 30, respectivamente.</h5>
                            <div className="divider mt-4"></div>
                        </div>


                    </div>
                </div>
            </div>

            
            
            
            
        </div>
    );
}

export default LandingFuncionamientoVendedores;
