import React from 'react';

{/* Despliega Lista de Ventas en CuentaVendedor */}
const ReseñasEstrellas = (props) => {

    const {rating} = props;
    let estrellas;
    if(window.location.href.indexOf("kangupago.com") === -1){
        if(rating === 5){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                </div>
            );
        }
        else if(rating >= 4 && rating < 5){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i> 
                </div>
            );
        }
        else if(rating >= 3 && rating < 4){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i> 
                    <i className="bi bi-star ms-2 estrella"></i> 
                </div>
            );
        }
        else if(rating >= 2 && rating < 3){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella"></i>
                    <i className="bi bi-star-fill ms-2 estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i> 
                    <i className="bi bi-star ms-2 estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i> 
                </div>
            );
        }
        else{
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i>
                    <i className="bi bi-star ms-2 estrella"></i> 
                </div>
            );
        }
    }
    else{
        if(rating === 5){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                </div>
            );
        }
        else if(rating >= 4 && rating < 5){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i> 
                </div>
            );
        }
        else if(rating >= 3 && rating < 4){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i> 
                    <i className="bi bi-star ms-2 estrella-kangupago"></i> 
                </div>
            );
        }
        else if(rating >= 2 && rating < 3){
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella-kangupago"></i>
                    <i className="bi bi-star-fill ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i> 
                    <i className="bi bi-star ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i> 
                </div>
            );
        }
        else{
            estrellas = (
                <div className="d-flex">
                    <i className="bi bi-star-fill estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i>
                    <i className="bi bi-star ms-2 estrella-kangupago"></i> 
                </div>
            );
        }
    }
    

    return(
        estrellas
    )
}

export default ReseñasEstrellas;