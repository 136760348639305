import React, { useState } from 'react';

const PricingExercise = () => {

    const [precio, setPrecio] = useState(0);
    
    let comisionDeKanguru = Math.round(Number(precio) * 0.07);
    let ingresoNeto = Number(precio) - comisionDeKanguru;

    let modalCalculoIngresoNeto = (
        <div class="modal fade" id="modalCalculoIngresoNeto" tabindex="-1" aria-labelledby="modalCalculoIngresoNeto" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Cálculo de Ingreso Neto</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <p>Kanguru toma una comisión fija de 7% sobre tu precio, el precio base de tu producto.</p>
                    </div>
                    <div>
                        <p className="addProductFormLabel">Parámetros</p>
                        <p>Precio Base: {precio}</p>
                        <p>Comisión de Kanguru: {comisionDeKanguru}</p>
                    </div>
                    <div className="mt-4">
                        <p className="addProductFormLabel">Formula para Calcular Ingreso Neto</p>
                        <p>Ingreso Neto = precioBase - comisionDeKanguru</p>
                        <p>ó</p>
                        <p>Ingreso Neto = precioBase * 0.93</p>
                    </div>

                    <div className="mt-4">
                        <p className="addProductFormLabel">Ingreso Neto</p>
                        <p>Ingreso Neto: {ingresoNeto}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
        </div>
    );

    return(
        <div>
            {/* Modals */}
            {modalCalculoIngresoNeto}

            <form className="row g-3">
                {/* Precio */}
                <div className="col-12 col-md-6 mt-3">
                    <label for="nombreProducto" className="form-label addProductFormLabel">Precio</label>
                    <input type="text" onChange={(e) => setPrecio(e.target.value)} className="form-control" id="precio"/>
                </div>

                {/* Comision Plataforma*/}
                <div className="col-12 col-md-6 mt-3">
                    <label for="nombreProducto" className="form-label addProductFormLabel">Comisión (%) de Kanguru</label>
                    <input type="text" className="form-control" id="nombre" placeholder="7%" disabled/>
                </div>
            </form>

            {/* Ingreso Neto*/}
            <div className="mt-4">
                <div className="d-flex">
                    <p className="addProductFormLabel me-2">Ingreso Neto</p>
                    <i data-bs-toggle="modal" data-bs-target="#modalCalculoIngresoNeto" class="bi bi-question-circle pointer"></i>
                </div>
                <p>Ingreso Neto: {ingresoNeto}</p>
            </div>
        </div>
    )
    
}



export default PricingExercise;