import React, { useState, useEffect} from 'react';
import VentaSummaryNegocio from './VentaSummaryNegocio';
import VentaSummaryVendedor from './VentaSummaryVendedor';

{/* Despliega Lista de Ventas en CuentaVendedor */}
const VentasList = ({ventas, numero, tipo}) => {

    const [show, setShow] = useState(true);

    useEffect(() => {
        setTimeout(() => setShow(false), 10000);
    }, []); 

    let load;
    if(show){
        load = (
            <div className="container spinner">
                <div class="spinner-border spinner-color" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    else{
        load = (
            <div className="noProducts">
                <h1>No hay ventas.</h1>
            </div>
        );
    }

    if(ventas.length != 0){
        if(tipo === "vendedor"){
            return(
                <div>
                    <ul className="list-group">
                        {ventas && ventas.map(venta => {
                            return (
                                <div key={venta.objectID}>
                                    <VentaSummaryVendedor venta={venta} numero={numero}/>
                                </div>  
                            )
                        })}
                    </ul>
                </div> 
            )
        }
        else{
            return(
                <div>
                    <ul className="list-group">
                        {ventas && ventas.map(venta => {
                            return (
                                <div key={venta.objectID}>
                                    <VentaSummaryNegocio venta={venta}/>
                                </div>  
                            )
                        })}
                    </ul>
                </div> 
            )
        }
        
    }
    else{
        return(
            <div>
                {load}
            </div>
        ); 
    }
    
}

export default VentasList;