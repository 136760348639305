import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from "react-helmet";
import ProductList from '../../../../products/ProductList';
import './Template1.css';

const Template1 = (props) => {
    
    const {productsCatalogo, vendedorID, vendedor, oDisenoEnCatalogo} = props;
    let customerEthos;
    let img_banner;
    const [filter, setFilter] = useState("");
    const [showOnlyProducts, setShowOnlyProducts] = useState(false);

    
    const usePrevious = (value) =>  {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const onClickCategory = (categoria) => {
        setShowOnlyProducts(true);
        setFilter(categoria);
    }

    const oldFilter = usePrevious(filter);

    useEffect(() => {
        if(oldFilter != '' && filter != ""){
            const element = document.getElementById(oldFilter);
            const element2 = document.getElementById(filter);
            element.style.color = 'black';
            element2.style.fontweight = 'bold';
        }
        else if(filter != ""){
            const element = document.getElementById(filter);
            element.style.color = 'black';
        }
    }); 

    const categorias = productsCatalogo.map((product, idx, self) => {
        //Solo muestra categoría si el producto está displayed
        return product.categoria;
    });

    const categoriasDisponibles = categorias.map((categoria, idx, self) => {
        //This works because the indexOf will return the index of the first instance of that item in the array
        //Permite que las categorías no se repitan
        if (self.indexOf(categoria) === idx){
            return (
                 <p className="ms-4 category" id={categoria} onClick={() => onClickCategory(categoria)}>{categoria.charAt(0).toUpperCase() + categoria.slice(1)}</p>
            );
        }
    })

    let mobile;
    if(window.innerWidth < 600){
        mobile = true;
    }
    else{
        mobile = false;
    }


    let content;
    if(showOnlyProducts){
        content = (
            <div>
                <div className="divider-template1"></div>
                {/* Productos */}
                <div className="third-content-template1">
                    <div className="container-fluid custom-container">
                        <div className="products mt-4">
                            <ProductList numero={0} mobile={mobile} products={productsCatalogo} filter={filter} vendedorID={vendedorID} catalogo={true} oDisenoEnCatalogo={oDisenoEnCatalogo}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else{
        content = (
            <div>
                {/* First Content */}
                <div className="first-content-template1">
                    <div className="container-fluid custom-container">
                        <div className="invite">
                            <p className="inviting-text-big">Sientete cómodo comprando con nosotros</p>
                            <div className="d-md-flex inviting-text-ethos">
                                <p className="me-md-5 ethos-text-template1">Envío Gratis<i class="bi bi-truck ms-3"></i></p>
                                <p className="me-md-5 ethos-text-template1">Devoluciones<i class="bi bi-arrow-left-right ms-3"></i></p>
                                <p className="ethos-text-template1">Pago Contra Entrega<i class="bi bi-shield-check ms-3"></i></p>
                            </div>
                        </div>
                        <div className="d-flex parent-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fvertical%2Ftemplate1_vertical_1_420x680.jpeg?alt=media&token=828931f7-796d-4f22-b21f-0270b1d2fe87" alt="" className="banner-first-template1 me-md-5"/>
                            <img src="https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fvertical%2Ftempla1_vertical_2_420x680.jpeg?alt=media&token=698de0e5-3bfe-4b5b-a506-af06c643e0fa" alt="" className="banner-first-template1 me-md-5 no-display-sm no-display-tablet"/>
                            <img src="https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fvertical%2Ftemplate1_vertical_3_420x680.jpeg?alt=media&token=bab189fe-f191-4bc3-8af5-ad1b5a372a48" alt="" className="banner-first-template1 no-display-sm"/>
                        </div>
                    </div>
                </div>

                {/* Second Content */}
                <div className="second-content-template1">
                    <div className="container-fluid custom-container">
                        <div className="row">
                            <div className="col-md-6 mb-5 no-display-md">
                                <p className="nosotros-title-template1">Quiénes Somos</p>
                                <p className="nosotros-text-template1">Entendemos que comprar en internet es difícl y queremos ayudar a hacer esta experiencia mejor. </p>
                                <p className="nosotros-text-template1 mt-2">Por eso te ofrecemos productos de calidad, a precios accesibles. Siempre con devolución y envío gratis. Despreocupate y disfruta nuestra selección.</p>
                            </div>
                            <div className="col-md-5 parent-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fvertical%2Ftemplate1_vertical_4_420x680.jpeg?alt=media&token=13181693-9590-4edc-90ed-df82756219d1" alt="" className="banner-second-template1"/>
                            </div>
                            <div className="col-md-5 no-display-sm">
                                <p className="nosotros-title-template1">Quiénes Somos</p>
                                <p className="nosotros-text-template1">Entendemos que comprar en internet es difícl y queremos ayudar a hacer esta experiencia mejor. </p>
                                <p className="nosotros-text-template1 mt-2">Por eso te ofrecemos productos de calidad, a precios accesibles. Siempre con devolución y envío gratis. Despreocupate y disfruta nuestra selección.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>

                {/* Productos */}
                <div className="third-content-template1">
                    <div className="container-fluid custom-container">
                        <p className="products-title-template1">Novedades</p>
                        <div className="divider-template1"></div>
                        <div className="products mt-4">
                            <ProductList numero={0} mobile={mobile} products={productsCatalogo} filter="" vendedorID={vendedorID} catalogo={true} oDisenoEnCatalogo={oDisenoEnCatalogo}/>
                        </div>
                    </div>
                </div>

                <div className="fourth-content-template1">
                    <div className="container">
                        <p className="reviews-title-template1">Lo que dicen nuestros clientes</p>
                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div className="testimonial-template1 mt-4">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fsquare%2Ftemplate1_square_resena3_560x560.jpeg?alt=media&token=56c01479-bddd-4472-9f36-027710487e30" className="testimonial-img-template1" alt=""/>
                                        <p className="testimonial-text-template1 mt-4">"¡Genial! He pedido 6 veces y todo ha salido excelente."</p>
                                        <p className="testimonial-name-template1">Mariana</p>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div className="testimonial-template1 mt-4">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fsquare%2Ftemplate1_square_resena2_560x560.jpeg?alt=media&token=44def389-7f60-4a41-9653-fcfa061d194d" className="testimonial-img-template1" alt=""/>
                                        <p className="testimonial-text-template1 mt-4">"Hice un pedido de 2 cantidades y sólo me llegó una. Me comuniqué con la tienda y me atiendieron bien, muy buen servicio. Me devolvieron la diferencia."</p>
                                        <p className="testimonial-name-template1">Regina</p>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div className="testimonial-template1 mt-4">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/kanguru-dev.appspot.com/o/imagesCatalogoTemplate%2Fsquare%2Ftemplate1_square_resena1_560x560.jpeg?alt=media&token=dd4a5592-a57b-4f89-89aa-20aba9d92ddb" className="testimonial-img-template1" alt=""/>
                                        <p className="testimonial-text-template1 mt-4">"Cliente Frecuente aquí. Me han salido muy bien todos los pedidos que he hecho."</p>
                                        <p className="testimonial-name-template1">Valeria</p>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>             
                    </div>
                </div>
            </div>
        );
    }

    return(
        <div className="template1">
            {/* Dynamic Description*/}
            <Helmet>
                <title>{oDisenoEnCatalogo.sNombre}</title>
                <meta name="title" content={oDisenoEnCatalogo.sNombre} />
                <meta name="description" content="Encuentra productos de alta calidad y realiza tu pedido con seguridad pagando en línea o con pago contra entrega."/>
                <link rel="canonical" href="https://catalogoenlinea.mx/" />
                <link rel="icon" href="https://firebasestorage.googleapis.com/v0/b/kilig-cac58.appspot.com/o/images%2F372f2ea8-e2e1-4552-b928-ff24b8d860bc_576x691.jpeg?alt=media&token=6497cd5b-b907-427a-921d-7cd02ba685f4" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://catalogoenlinea.mx/" />
                <meta property="og:title" content="Precios Bajos – Envíos Rápidos - Compras Seguras" />
                <meta property="og:description" content="Encuentra productos de alta calidad y realiza tu pedido con seguridad pagando en línea o con pago contra entrega." />
            </Helmet>

            {/* Whats App Btn */}
            <div>
                <a href={"https://api.whatsapp.com/send?phone=" + vendedor.Telefono + "&text=Hola, buenos días"} className="floating" target="_blank">
                    <i className="bi bi-whatsapp float-button"></i>
                </a>
            </div>

            {/* Navbar */}
            <div className="navbar-template template1">
                <div className="container">
                    <p className="nombreCatalogo" onClick={() => setShowOnlyProducts(false)}>{oDisenoEnCatalogo.sNombre}</p>
                    <div className="d-flex categories scrollmenu-template1">
                        {categoriasDisponibles}
                    </div>
                </div>
            </div>
            
            {content}

            <div className="fifth-content-template1">
                <div className="container-fluid custom-container">
                    <p className="footer-name-template1">{oDisenoEnCatalogo.sNombre}</p>
                    <div className="d-flex">
                        <p className="footer-social-template1 me-3"><i class="bi bi-instagram"></i></p>
                        <p className="footer-social-template1"><i class="bi bi-facebook"></i></p>
                    </div>
                    <p className="footer-derechos-template1">Todos los derechos reservados 2022.</p>
                </div>
            </div>
        </div>
    ); 
}

export default Template1;
