const initState = {
    authError: null
};

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case 'LOGIN_ERROR':
            console.log('login error');
            return {
                ...state,
                authError: 'Login failed'
            } 
        case 'LOGIN_SUCCESS':
            console.log('login success');
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('signout success');
            return state;
        case 'EMAIL_SENT':
            console.log('email sent');
            return state;
        case 'EMAIL_ERROR':
            console.log('email error', action.err)
        case 'SIGNUP_SUCCESS':
            console.log('signup successs');
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('signup error');
            alert(action.err.message);
            return {
                ...state,
                authError: action.err.message
            }
        case 'ADD_CONNECT_ACCOUNT_SUCCESS':
            console.log('Added Connect Account Successfully', action.err);
            return state;
        case 'ADD_CONNECT_ACCOUNT_ERROR':
            console.log('Connect Account Error', action.err);
            return state;
        case 'SOLICITUD_PROVEEDOR_SUCCESS':
            console.log('Sent request successfully', action.err);
            return state;
        case 'SOLICITUD_PROVEEDOR_ERROR':
            console.log('Error sending request.', action.err);
            return state;
        case 'COMPLETE_ONBOARDING_SUCCESS':
            console.log('completed onboarding', action.err);
            return state;
        case 'COMPLETE_ONBOARDING_ERROR':
            console.log('Error sending request.', action.err);
            return state;
        default:
            return state;
    }
}

export default authReducer;